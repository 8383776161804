import LoadingDots from '@alltrails/shared/denali/components/LoadingDots';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import * as styles from './styles/styles.module.scss';

// https://www.figma.com/file/YubcZX08vX2JgZf6jlKglQ/Google-pay-with-Recurly?node-id=227%3A31309&t=oWm862mnaTwhiJ4D-4

function LoadingOverlay() {
  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <LoadingDots />
        <h2 className={styles.heading}>
          <FormattedMessage defaultMessage="Hold tight! We're processing your payment." />
        </h2>
      </div>
    </div>
  );
}

export default LoadingOverlay;
