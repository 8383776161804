import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from '@alltrails/shared/react-intl';
import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import Accordion from '@alltrails/shared/denali/components/Accordion';
import IconButton from '@alltrails/shared/denali/components/IconButton';
import Logo from '@alltrails/shared/denali/components/Logo';
import Menu from '@alltrails/shared/icons/Menu';
import Close from '@alltrails/shared/icons/Close';
import Mobile from '@alltrails/shared/icons/Mobile';
import { DropdownMenuItem } from '@alltrails/shared/denali/components/DropdownMenu';
import Button from '@alltrails/shared/denali/components/Button';
import useUser from 'hooks/useUser';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import useAnimateInOutComponent from '@alltrails/shared/hooks/useAnimateInOutComponent';
import useDeeplink from 'components/DeepLink/hooks/useDeeplink';
import PortalModal from 'components/shared/PortalModal';
import WebHeaderSection from '@alltrails/analytics/enums/WebHeaderSection';
import logWebHeaderLoginButtonTapped from '@alltrails/analytics/events/logWebHeaderLoginButtonTapped';
import { wrapUrlSafe } from 'utils/language_support_util';
import * as styles from './styles/styles.module.scss';
import useHeaderLinks from './useHeaderLinks';
import { getHeaderLinkOnClickWithAnalytics, getUserLinks } from './headerLinks';

const useScreenLock = (isOpen: boolean) => {
  useEffect(() => {
    const htmlDocument = document.querySelector('html') as HTMLElement;

    if (isOpen) {
      htmlDocument.classList.add('modalOpen');
    }

    return () => {
      htmlDocument.classList.remove('modalOpen');
    };
  }, [isOpen]);
};

const MobileMenu = () => {
  const intl = useIntl();
  const user = useUser();
  const languageRegionCode = useLanguageRegionCode();

  const [isOpen, setIsOpen] = useState(false);
  const deeplink = useDeeplink({ isOpen });

  const { isAnimating, isVisible, onAnimationEnd } = useAnimateInOutComponent(isOpen);

  useScreenLock(isOpen);

  const { headerLinks, getLinkInteractivity, signUpModal } = useHeaderLinks();

  const sections = headerLinks.slice(0);

  if (user) {
    const userLinks = getUserLinks(user, languageRegionCode, true);
    sections.push({
      label: <FormattedMessage defaultMessage="Account" />,
      links: userLinks,
      testId: 'header-account',
      webHeaderSection: WebHeaderSection.MyAccount
    });
  }

  return (
    <>
      <IconButton
        className={styles.mobileIconButton}
        icon={{ Component: Menu }}
        onClick={() => setIsOpen(true)}
        testId="mobile-header-menu"
        title={intl.formatMessage({ defaultMessage: 'Navigation Menu' })}
        variant="flat"
      />
      {(isAnimating || isVisible) && (
        <PortalModal isOpen>
          <div
            className={classNames(styles.mobileDrawerBackground, {
              [styles.isAnimating]: isAnimating,
              [styles.isVisible]: isVisible
            })}
            onAnimationEnd={onAnimationEnd}
          >
            <FocusTrap
              active
              focusTrapOptions={{
                clickOutsideDeactivates: true,
                escapeDeactivates: true,
                onDeactivate: () => setIsOpen(false),
                fallbackFocus: '#mobile-header-drawer'
              }}
            >
              {/* Need to stop propagation of any animationEnd event within the drawer so that it doesn't trigger the wrapping onAnimationEnd */}
              <div
                className={classNames(styles.mobileDrawer, {
                  [styles.isAnimating]: isAnimating,
                  [styles.isVisible]: isVisible
                })}
                id="mobile-header-drawer"
                onAnimationEnd={e => e.stopPropagation()}
              >
                <div>
                  <div className={styles.drawerHeader}>
                    <Logo color="dark" size="sm" variant="logomark" />
                    <IconButton
                      className={styles.mobileIconButton}
                      icon={{ Component: Close }}
                      onClick={() => setIsOpen(false)}
                      testId="mobile-header-menu-close"
                      title={intl.formatMessage({ defaultMessage: 'Close' })}
                      variant="flat"
                    />
                  </div>
                  {sections.map((section, index) => (
                    <Accordion
                      key={section.testId}
                      borderBottomContainerClassName={index === sections.length - 1 ? styles.accordionNoBorder : undefined}
                      headingTypography="heading400"
                      initiallyOpen={index === 0}
                      title={section.label}
                      variant="minimal"
                    >
                      <div className={styles.accordionContents} data-testid={`${section.testId}-contents`}>
                        {section.links.map((link, linkIndex) => {
                          const { linkInfo, onClick } = getLinkInteractivity(link);
                          const onClickWithAnalytics = getHeaderLinkOnClickWithAnalytics(
                            linkIndex,
                            link.webHeaderDropDownLink,
                            section.webHeaderSection,
                            () => {
                              setIsOpen(false);
                              onClick?.();
                            }
                          );

                          return (
                            <DropdownMenuItem
                              key={link.testId}
                              option={{
                                className: classNames(styles.mobileMenuItem, link.className),
                                leadingIcon: link.leadingIcon,
                                linkInfo,
                                onClick: onClickWithAnalytics,
                                subtext: link.subtext,
                                testId: link.testId,
                                title: link.title,
                                trailingIcon: link.trailingIcon
                              }}
                              textVariant="text200"
                            />
                          );
                        })}
                      </div>
                    </Accordion>
                  ))}
                </div>
                <div className={styles.drawerCtas}>
                  {user ? null : (
                    <>
                      <Button
                        linkInfo={{ href: wrapUrlSafe('/signup', languageRegionCode) }}
                        text={<FormattedMessage defaultMessage="Sign up" />}
                        testId="header-sign-up"
                        variant="default"
                        width="full"
                      />
                      <Button
                        linkInfo={{ href: wrapUrlSafe('/login', languageRegionCode) }}
                        onClick={() => {
                          logWebHeaderLoginButtonTapped();
                        }}
                        text={<FormattedMessage defaultMessage="Log in" />}
                        testId="header-log-in"
                        variant="default"
                        width="full"
                      />
                    </>
                  )}
                  <Button
                    icon={{ Component: Mobile }}
                    linkInfo={{ href: deeplink }}
                    testId="header-continue-in-app"
                    text={<FormattedMessage defaultMessage="Continue in app" />}
                    variant={user ? 'default' : 'primary'}
                    width="full"
                  />
                </div>
              </div>
            </FocusTrap>
          </div>
        </PortalModal>
      )}
      {signUpModal}
    </>
  );
};

export default MobileMenu;
