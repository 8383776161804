import WebProPurchaseLocation from '@alltrails/analytics/enums/WebProPurchaseLocation';
import Typography from '@alltrails/shared/denali/components/Typography';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import useCurrencyFormatter from 'hooks/useCurrencyFormatter';
import { useState } from 'react';
import CurrencyCode from 'types/CurrencyCode';
import CheckoutBase from 'components/CheckoutBase';
import Plan from 'types/Plan';
import CountryCode from 'types/CountryCode';
import { UPDATE_PURCHASED_GIFT_CARD, UPDATE_SERVER_ERRORS, useGiftFlowContext } from 'pages/Gift/giftContext';
import Link from '@alltrails/shared/denali/components/Link';
import { wrapUrlSafe } from 'utils/language_support_util';
import { ApiError, post } from '@alltrails/shared/api';
import { pushHistory } from 'utils/client_side_routing_helpers';
import { useHistory } from '@alltrails/shared/components/ReactRouter';
import Logo from '@alltrails/shared/denali/components/Logo';
import * as styles from './GiftCheckout.module.scss';

type Props = {
  billingCountry: CountryCode;
  currencyCode: CurrencyCode;
  plan: Plan;
  setBillingCountry: (billingCountry: CountryCode) => void;
  setCurrencyCode: (currencyCode: CurrencyCode) => void;
};

// Desktop: https://www.figma.com/file/Q9rFHuwqViPUzYclrqPdm3/Gifting-checkout?type=design&node-id=19-5718&mode=design&t=76CLByLg3cuEcAyh-4
// Mobile: https://www.figma.com/file/Q9rFHuwqViPUzYclrqPdm3/Gifting-checkout?type=design&node-id=1-2233&mode=dev
export default function GiftCheckout({ billingCountry, currencyCode, setBillingCountry, setCurrencyCode, plan: initialPlan }: Props) {
  const languageRegionCode = useLanguageRegionCode();
  const [plan, setPlan] = useState(initialPlan);
  const history = useHistory();
  const formatter = useCurrencyFormatter(currencyCode);

  const {
    dispatchGiftFlow,
    giftFlowState: { giftCustomizeParams }
  } = useGiftFlowContext();

  async function handleSubmit(
    genericPayload:
      | { apple_pay_token: string; recaptcha: string }
      | { google_pay_token: string; recaptcha: string }
      | { paypal_token: string; recaptcha: string }
      | { cc_token: string; recaptcha: string }
  ) {
    const { recipientName, recipientEmail, message, sendDate, variant, sendMethod, senderEmail, senderName } = giftCustomizeParams;

    const timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const data = {
      ...genericPayload,
      price_code: plan.priceCode,
      background_id: variant,
      message,
      sender_info: { name: senderName, email: senderEmail },
      recipient_info: { name: recipientName },
      timezone_id: timezoneId
    };

    if (sendMethod === 'email') {
      Object.assign(data, { recipient_info: { ...data.recipient_info, email: recipientEmail }, delivery_date: sendDate });
    }

    type GiftResponse = {
      metadata: { deliveryDate: string };
    }[];

    try {
      const response = await post<GiftResponse>('/api/alltrails/gift', data);
      const payload = response[0];

      // yuck
      Object.assign(payload, { isEmail: !!payload.metadata.deliveryDate });
      dispatchGiftFlow({ type: UPDATE_PURCHASED_GIFT_CARD, payload });

      pushHistory('/gift/review', history, languageRegionCode);
    } catch (e) {
      if (e instanceof ApiError) {
        const invalidEmailErrors = e.data.errors ? e.data.errors.filter((error: { code: string }) => error.code === 'invalid_email') : [];

        if (invalidEmailErrors.length > 0) {
          dispatchGiftFlow({ type: UPDATE_SERVER_ERRORS, payload: invalidEmailErrors });
          pushHistory('/gift/customize', history, languageRegionCode);
        }
      }

      throw e;
    }
  }

  const dateFormatter = new Intl.DateTimeFormat(languageRegionCode, { day: 'numeric', month: 'long', year: 'numeric' });

  const features = (
    <>
      <div>
        <Typography variant="text200">
          <FormattedMessage defaultMessage="To:" />
        </Typography>{' '}
        <Typography variant="text200bold">{giftCustomizeParams.recipientName}</Typography>
        <br />
        {giftCustomizeParams.sendMethod === 'email' && (
          <>
            <Typography variant="text200">
              <FormattedMessage defaultMessage="Email:" />
            </Typography>{' '}
            <Typography variant="text200bold">{giftCustomizeParams.recipientEmail}</Typography>
            <br />
          </>
        )}
        <Typography variant="text200">
          <FormattedMessage defaultMessage="Send date:" />
        </Typography>{' '}
        <Typography variant="text200bold">{dateFormatter.format(giftCustomizeParams.sendDate)}</Typography>
      </div>
      <Link className={styles.link} href={wrapUrlSafe('/gift/customize', languageRegionCode)} useReactRouter size="md">
        <FormattedMessage defaultMessage="Edit" />
      </Link>
    </>
  );

  const annualCost = formatter.format(Number(plan.priceTotal));

  return (
    <CheckoutBase
      billedHeading={<FormattedMessage defaultMessage="Annual plan" />}
      billedToday={annualCost}
      billingCountry={billingCountry}
      buttonText={<FormattedMessage defaultMessage="Give AllTrails+" />}
      className={styles.content}
      currencyCode={currencyCode}
      heading={
        <>
          <Logo className={styles.logo} color="dark" size="sm" variant="plus-logomark" />
          <FormattedMessage defaultMessage="Please confirm recipient details:" />
        </>
      }
      headingClassName={styles.heading}
      onBillingCountryChange={(value: CountryCode, response) => {
        setBillingCountry(value);
        setCurrencyCode(response.currencyCode);
        setPlan(response.nonTrial);
      }}
      onApplePayToken={async ({ apple_pay_token, recaptcha }) => {
        await handleSubmit({ apple_pay_token, recaptcha });
      }}
      onGooglePayToken={async ({ google_pay_token, recaptcha }) => {
        await handleSubmit({ google_pay_token, recaptcha });
      }}
      onPayPalToken={async ({ paypal_token, recaptcha }) => {
        await handleSubmit({ paypal_token, recaptcha });
      }}
      onSubmitTokenizedCardForm={async payload => {
        await handleSubmit({
          cc_token: payload.cc_token,
          recaptcha: payload.recaptcha
        });
      }}
      plan={plan}
      webProPurchaseLocation={WebProPurchaseLocation.GiftPage}
      features={features}
    />
  );
}
