import { defineMessages } from '@alltrails/shared/react-intl';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import AlertDialog from '@alltrails/shared/denali/components/AlertDialog';

const DISCONNECT_MODAL_STRINGS = defineMessages({
  DISCONNECT: { defaultMessage: 'Disconnect from Garmin?' },
  DESCRIPTION: { defaultMessage: 'Confirming will unlink your AllTrails account from your Garmin account.' },
  CONFIRM: { defaultMessage: 'Confirm' },
  CANCEL: { defaultMessage: 'Cancel' }
});

type Props = {
  onDisconnect: () => void;
  onRequestClose: () => void;
};

const GarminDisconnectModal = ({ onDisconnect, onRequestClose }: Props) => {
  const {
    formattedDefaultMessages: { DISCONNECT, DESCRIPTION, CONFIRM, CANCEL }
  } = useFormatMessage(DISCONNECT_MODAL_STRINGS);

  return (
    <AlertDialog
      title={DISCONNECT}
      body={DESCRIPTION}
      buttons={{
        primary: { onClick: onDisconnect, text: CONFIRM, testId: 'confirm-disconnect' },
        flat: { onClick: onRequestClose, text: CANCEL, testId: 'cancel-disconnect' }
      }}
      onRequestClose={onRequestClose}
      size="md"
    />
  );
};

export default GarminDisconnectModal;
