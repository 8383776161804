import { useMemo } from 'react';
import { defineMessages, FormattedMessage } from '@alltrails/shared/react-intl';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import { toATLocalString } from 'utils/number_util';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import * as styles from './styles/styles.module.scss';
import CommunityStat from './CommunityStat';
import { MapStatsUtil } from '../../../utils/map_stats_util';
import useDisplayMetric from '../../../hooks/useDisplayMetric';

const COMMUNTIY_STATS_STRINGS = defineMessages({
  CURATED_TRAILS: {
    defaultMessage: 'curated trails'
  },
  DISCOVER_UNEXPECTED_GEMS: {
    defaultMessage: 'Discover unexpected gems, even in your own backyard.'
  },
  FELLOW_EXPLORERS: {
    defaultMessage: 'fellow explorers'
  },
  SHARE_YOUR_ADVENTURES: {
    defaultMessage: 'Share your adventures and learn from our global community.'
  },
  LOGGED_MILES: {
    defaultMessage: 'logged miles'
  },
  LOGGED_KILOMETERS: { defaultMessage: 'logged kilometers' },
  NAVIGATE_YOUR_WAY: { defaultMessage: 'Navigate your way and keep a record of all your travels.' }
});

const ONE_BILLION = 1000000000;

// Helper function to format the logged distance in miles or kilometers for alltrails landing pages

const formatLoggedDistance = (meters: number, languageRegionCode: LanguageRegionCode, displayMetric = false) => {
  const distance = displayMetric ? meters / 1000 : MapStatsUtil.metersToMiles(meters);

  return toATLocalString(Math.round(distance) / ONE_BILLION, languageRegionCode, 1);
};

/**
 * CommunityStats takes numbers provided from the parent and parses
 * them into translatable text that can be dynamic
 *
 *
 * @param {number} curatedTrails number of curated trails in thousands
 * @param {number} fellowExplorers Number of users in millions
 * @param {number} loggedDistanceMeters Number of logged usage of the app in meters
 */

type Props = {
  curatedTrails: number;
  fellowExplorers: number;
  loggedDistanceMeters: number;
};

const CommunityStats = ({ curatedTrails, fellowExplorers, loggedDistanceMeters }: Props): JSX.Element => {
  const {
    formattedDefaultMessages: {
      LOGGED_KILOMETERS,
      LOGGED_MILES,
      CURATED_TRAILS,
      DISCOVER_UNEXPECTED_GEMS,
      FELLOW_EXPLORERS,
      SHARE_YOUR_ADVENTURES,
      NAVIGATE_YOUR_WAY
    }
  } = useFormatMessage(COMMUNTIY_STATS_STRINGS);
  const displayMetric = useDisplayMetric();
  const languageRegionCode = useLanguageRegionCode();
  const [totalDistance, loggedDistanceMeasurement] = useMemo(
    () => [formatLoggedDistance(loggedDistanceMeters, languageRegionCode, displayMetric), displayMetric ? LOGGED_KILOMETERS : LOGGED_MILES],
    [loggedDistanceMeters, languageRegionCode, displayMetric, LOGGED_KILOMETERS, LOGGED_MILES]
  );

  return (
    <div className={styles.container}>
      <CommunityStat
        title={<FormattedMessage defaultMessage="{curatedTrails}K+" values={{ curatedTrails }} />}
        subtitle={CURATED_TRAILS}
        text={DISCOVER_UNEXPECTED_GEMS}
      />
      <CommunityStat
        title={<FormattedMessage defaultMessage="{fellowExplorers}M+" values={{ fellowExplorers }} />}
        subtitle={FELLOW_EXPLORERS}
        text={SHARE_YOUR_ADVENTURES}
      />
      <CommunityStat
        title={<FormattedMessage defaultMessage="{totalDistance}B+" values={{ totalDistance }} />}
        subtitle={loggedDistanceMeasurement}
        text={NAVIGATE_YOUR_WAY}
      />
    </div>
  );
};

export default CommunityStats;
