import { garminPermissionsReturnKey } from './modalHelpers';

export default function getGarminConnectUrl(isPlus?: boolean) {
  // We use this to know if a plus user has returned from their Garmin permissions page after trying
  // to send a route. If they have, and they did enable courses, we send their route to Garmin
  const currentUrl = new URL(window.location.href);
  const garminConnectUrl = new URL('/users/auth/garmin_connect', currentUrl);

  if (isPlus) {
    const originUrl = new URL(currentUrl.href);
    originUrl.searchParams.set(garminPermissionsReturnKey, 'true');
    garminConnectUrl.searchParams.set('origin', originUrl.href);
  } else {
    garminConnectUrl.searchParams.set('origin', currentUrl.href);
  }

  return garminConnectUrl.toString();
}
