import Button from '@alltrails/shared/denali/components/Button';
import OutlinedTextField from '@alltrails/shared/components/OutlinedTextField';
import Link from '@alltrails/shared/denali/components/Link';
import { verify } from 'api/Coupons';
import useToggle from '@alltrails/shared/hooks/useToggle';
import { ReactNode, useEffect, useState } from 'react';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import Coupon from 'types/Coupon';
import { ApiError } from '@alltrails/shared/api';
import * as styles from './CouponCode.module.scss';

// Desktop: https://www.figma.com/file/vJ30CAAm8qdbDO0j5C4Ce7/Web-Pro-Purchase-Flow

type Props = {
  coupon?: Coupon;
  setCoupon: (coupon: Coupon) => void;
  setPlanToAnnual: () => void;
};

export default function CouponCode({ coupon, setCoupon, setPlanToAnnual }: Props) {
  const [error, setError] = useState<ReactNode>();
  const [isOpen, toggle] = useToggle(Boolean(coupon));
  const [value, setValue] = useState(coupon?.code);

  // Display a coupon error on page load if no coupon data exists for an existing coupon query param.
  useEffect(() => {
    const params = new URLSearchParams(__AT_DATA__.search);
    const couponParam = params.get('k');
    if (couponParam && !coupon) {
      setValue(couponParam);
      setError(<FormattedMessage defaultMessage="This coupon code is invalid" />);
      toggle();
    }
  }, [coupon, toggle]);

  async function handleClick() {
    try {
      const coupons = await verify(value);

      // clear a previous bad coupon
      setError(undefined);

      setCoupon(coupons[0]);

      const params = new URLSearchParams(window.location.search);
      params.set('k', coupons[0].code);
      window.history.replaceState({}, '', `${window.location.pathname}?${params}`);

      setPlanToAnnual();
    } catch (e) {
      if (e instanceof ApiError && e.data?.errors?.length > 0) {
        setError(e.data.errors[0].message);
      } else {
        setError(<FormattedMessage defaultMessage="This coupon code is invalid" />);
      }
    }
  }

  return isOpen ? (
    <div className={styles.content}>
      <div className={styles.coupon}>
        <OutlinedTextField
          changeHandler={e => {
            if (error) {
              setError(undefined);
            }
            setValue(e.target.value);
          }}
          errorMessage={error}
          isDisabled={Boolean(coupon)}
          isSuccess={Boolean(coupon)}
          label={<FormattedMessage defaultMessage="Coupon code" />}
          value={value}
        />
        <Button
          onClick={handleClick}
          testId="coupon"
          text={coupon ? <FormattedMessage defaultMessage="Applied" /> : <FormattedMessage defaultMessage="Apply" />}
          variant="default"
        />
      </div>
      <Link className={styles.button} size="sm" onClick={toggle}>
        <FormattedMessage defaultMessage="Hide coupon code" />
      </Link>
    </div>
  ) : (
    <Link className={styles.button} size="sm" onClick={toggle}>
      <FormattedMessage defaultMessage="Enter a coupon code" />
    </Link>
  );
}
