import { createSlice } from '@reduxjs/toolkit';

const experimentsSlice = createSlice({
  name: 'experiments',
  initialState: null, // populated from preloadState
  reducers: {
    setExperiments(state, action) {
      return action.payload;
    }
  }
});

export const { setExperiments } = experimentsSlice.actions;

export default experimentsSlice.reducer;
