import { ReactNode, useState } from 'react';
import Button from '@alltrails/shared/denali/components/Button';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import { execute } from 'utils/recaptcha_helpers';
import { defineMessages, FormattedMessage, useIntl } from '@alltrails/shared/react-intl';
import Checkbox from '@alltrails/shared/denali/components/Checkbox';
import Typography from '@alltrails/shared/denali/components/Typography';
import OutlinedTextField from '@alltrails/shared/components/OutlinedTextField';
import * as formStyles from 'components/forms/styles/styles.module.scss';
import useCountryId from 'hooks/useCountryId';
import { ApiError, post } from '@alltrails/shared/api';
import LoginResponse from 'types/LoginResponse';
import AuthenticationType from '@alltrails/analytics/enums/AuthenticationType';
import logSignUpFailed from '@alltrails/analytics/events/logSignUpFailed';
import useFeatures from 'hooks/useFeatures';
import validateUserForm from './utils/validateUserForm';
import * as styles from './RegisterForm.module.scss';
import authSuccessCallback from '../../utils/AuthHandlers';

const messages = defineMessages({
  signUp: {
    defaultMessage: 'Sign up'
  }
});

type Props = {
  autoFocus?: boolean;
  referralLink?: string;
  handleRegistrationSuccess: (response: LoginResponse) => void;
  userEmailSubscribed?: boolean;
};

// https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=6514%3A101082
export default function RegisterForm({ autoFocus = false, referralLink, handleRegistrationSuccess, userEmailSubscribed }: Props) {
  const features = useFeatures();

  const {
    formattedDefaultMessages: { signUp }
  } = useFormatMessage(messages);

  const intl = useIntl();
  const countryId = useCountryId();
  const [serverError, setServerError] = useState<ReactNode>();

  const [state, setState] = useState<{
    userEmailSubscribed: boolean;
    userRegLastName: string;
    userRegEmail: string;
    userRegPassword: string;
    userRegFirstName: string;
    submitting: boolean;
    showErrorsOnType: boolean;
  }>({
    userRegEmail: '',
    userRegPassword: '',
    userRegFirstName: '',
    userRegLastName: '',
    userEmailSubscribed,
    submitting: false,
    showErrorsOnType: false
  });

  const [errors, setErrors] = useState<{ userRegEmail?: string; userRegPassword?: string; userRegFirstName?: string; userRegLastName?: string }>({});

  function showEmailSubscribed() {
    return countryId !== 313;
  }

  function handleSubscribedChange() {
    if (!showEmailSubscribed()) {
      return;
    }

    const { userEmailSubscribed } = state;
    setState({ ...state, userEmailSubscribed: !userEmailSubscribed });
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    e.preventDefault();
    const { name, value } = e.target;
    // we don't want to show errors on type unless a user has already tried to submit a form
    if (state.showErrorsOnType) {
      setErrors(validateUserForm(name, value, errors, intl).errors);
    }

    setState({ ...state, [name]: value });
  }

  async function submitRegister(e: React.KeyboardEvent<HTMLDivElement> | React.FormEvent) {
    e.preventDefault();

    if (state.submitting) {
      return;
    }

    let validationErrors = {};
    validationErrors = validateUserForm('userRegFirstName', state.userRegFirstName, validationErrors, intl).errors;
    validationErrors = validateUserForm('userRegLastName', state.userRegLastName, validationErrors, intl).errors;
    validationErrors = validateUserForm('userRegEmail', state.userRegEmail, validationErrors, intl).errors;
    validationErrors = validateUserForm('userRegPassword', state.userRegPassword, validationErrors, intl).errors;

    if (Object.keys(validationErrors).length !== 0) {
      setErrors(validationErrors);
      setState({ ...state, showErrorsOnType: true });
      return;
    }

    setState({ ...state, submitting: true });

    try {
      let recaptcha;

      // on alpha we disable recaptcha for Cypress testing
      if (features.includes('recaptcha_user_signup_login')) {
        recaptcha = await execute('userSignupLogin');
      }

      const { useIdentityMock, identityWebClientId } = __AT_DATA__;

      const response =
        features.includes('identity_service') && !useIdentityMock
          ? await post<LoginResponse>('/identity/auth/register', {
              client_id: identityWebClientId,
              user: {
                first_name: state.userRegFirstName,
                last_name: state.userRegLastName,
                email: state.userRegEmail,
                password: state.userRegPassword,
                create_session: true,
                subscribed: state.userEmailSubscribed,
                recaptcha,
                referral_code: referralLink
              }
            })
          : await post<LoginResponse>('/api/alltrails/register', {
              first_name: state.userRegFirstName,
              last_name: state.userRegLastName,
              email: state.userRegEmail,
              password: state.userRegPassword,
              create_session: true,
              subscribed: state.userEmailSubscribed,
              recaptcha,
              referral_code: referralLink
            });

      authSuccessCallback(response, AuthenticationType.Email, response.is_collab_list_invite);

      setErrors({});

      setState({
        userRegEmail: '',
        userRegPassword: '',
        userRegFirstName: '',
        userRegLastName: '',
        userEmailSubscribed,
        submitting: false,
        showErrorsOnType: false
      });

      setServerError(null);

      handleRegistrationSuccess(response);
    } catch (e) {
      if (e instanceof ApiError && e.data?.errors?.length > 0) {
        if (e.data.errors[0].target === 'email') {
          setErrors({ ...errors, userRegEmail: e.data.errors[0].message });
        }
        setServerError(e.data.errors[0].message);
      } else {
        setServerError(<FormattedMessage defaultMessage="Error, please try again" />);
      }

      logSignUpFailed({
        authentication_type: AuthenticationType.Email,
        failure_type: e instanceof ApiError && e.data?.errors?.length > 0 ? e.data.errors[0].code : 'unknown'
      });
    } finally {
      setState({ ...state, submitting: false });
    }
  }

  return (
    <form className={styles.form} onSubmit={submitRegister}>
      <OutlinedTextField
        autoFocus={autoFocus}
        name="userRegFirstName"
        errorMessage={errors.userRegFirstName}
        value={state.userRegFirstName}
        label={<FormattedMessage defaultMessage="First name" />}
        changeHandler={handleChange}
        data-testid="registrationForm-firstNameTextField"
      />
      <OutlinedTextField
        name="userRegLastName"
        errorMessage={errors.userRegLastName}
        value={state.userRegLastName}
        label={<FormattedMessage defaultMessage="Last name" />}
        changeHandler={handleChange}
        data-testid="registrationForm-lastNameTextField"
      />
      <OutlinedTextField
        name="userRegEmail"
        errorMessage={errors.userRegEmail}
        value={state.userRegEmail}
        label={<FormattedMessage defaultMessage="Email address" />}
        changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (serverError) {
            setServerError(null);
          }
          if (errors.userRegEmail) {
            setErrors({ userRegEmail: '' });
          }
          handleChange(e);
        }}
        type="email"
        data-testid="registrationForm-emailTextField"
      />
      <OutlinedTextField
        name="userRegPassword"
        errorMessage={errors.userRegPassword}
        value={state.userRegPassword}
        label={<FormattedMessage defaultMessage="Password" />}
        changeHandler={handleChange}
        type="password"
        data-testid="registrationForm-passwordTextField"
      />
      {serverError && <div className={`${formStyles.serverError} ${styles.center}`}>{serverError}</div>}
      <div className={styles.bottom}>
        <Button text={signUp} disabled={state.submitting} testId="register-form-sign-up" type="submit" variant="primary" width="full" />
        {showEmailSubscribed() && (
          <Checkbox
            className={styles.subscribedContainer}
            id="emailSubscribed"
            testId="checkbox-emailSubscribed"
            size="sm"
            onChange={handleSubscribedChange}
            selected={state.userEmailSubscribed}
            labelElement={
              <Typography variant="text200">
                <FormattedMessage defaultMessage="Get helpful product tips and special offers" />
              </Typography>
            }
          />
        )}
      </div>
    </form>
  );
}
