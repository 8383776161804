export const MOBILE_BREAKPOINT = 480;
export const DESKTOP_BREAKPOINT = 768;
export const HOME_PAGE_WIDTH_BREAKPOINT = 1380;

// New Feb 2021 design breakpoints
// see https://alltrails.atlassian.net/wiki/spaces/DES/pages/715227375/Grid+and+Breakpoints
// Material UI uses a step of 0.05px for exclusive breakpoints so we'll copy this
const STEP = 0.05;

export const MEDIA_QUERY_MOBILE_DOWN = `(max-width: ${MOBILE_BREAKPOINT - STEP}px)`;
export const MEDIA_QUERY_SMALL_DOWN = `(max-width: ${DESKTOP_BREAKPOINT - STEP}px)`;
