import { ReactNode } from 'react';
import Facebook from '@alltrails/shared/icons/Facebook';
import { COLOR_NEUTRAL_WHITE } from '@alltrails/shared/denali/tokens';
import * as styles from './FacebookButton.module.scss';

type Props = {
  children: ReactNode;
  onClick: () => void;
};

export default function FacebookLoginButton({ children, onClick }: Props) {
  return (
    <button className={styles.button} data-testid="facebookLoginButton" onClick={onClick} type="button">
      <Facebook color={COLOR_NEUTRAL_WHITE} />
      <span className={styles.children}>{children}</span>
    </button>
  );
}
