import CustomProvider from 'components/CustomProvider';
import { defineMessages } from '@alltrails/shared/react-intl';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import Edit from '@alltrails/shared/icons/Edit';
import Remove from '@alltrails/shared/icons/Remove';
import Repeat from '@alltrails/shared/icons/Repeat';
import Wallet from '@alltrails/shared/icons/Wallet';
import Link from '@alltrails/shared/denali/components/Link';
import * as styles from './styles/styles.module.scss';

const messages = defineMessages({
  EMAIL_PREFERENCES: {
    defaultMessage: 'Email preferences'
  },
  DELETE_ACCOUNT: {
    defaultMessage: 'Delete account'
  },
  UPDATE_BILLING: {
    defaultMessage: 'Update billing info'
  },
  REACTIVATE_SUBSCRIPTION: {
    defaultMessage: 'Reactivate subscription'
  },
  MANAGE_SUBSCRIPTION: {
    defaultMessage: 'Manage subscription'
  }
});

export enum AccountLinkType {
  EmailPreferences = 'email-preferences',
  DeleteAccount = 'delete-account',
  ReactivateSubscription = 'reactivate-subscription',
  ManageSubscription = 'manage-subscription',
  UpdateBilling = 'update-billing'
}

type Props = {
  type: AccountLinkType;
  href: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
};

const AccountLinks = ({ type, href, target }: Props): JSX.Element => {
  const {
    formattedDefaultMessages: { EMAIL_PREFERENCES, DELETE_ACCOUNT, UPDATE_BILLING, REACTIVATE_SUBSCRIPTION, MANAGE_SUBSCRIPTION }
  } = useFormatMessage(messages);

  const getTitle = () => {
    if (type === AccountLinkType.EmailPreferences) return EMAIL_PREFERENCES;
    if (type === AccountLinkType.DeleteAccount) return DELETE_ACCOUNT;
    if (type === AccountLinkType.ReactivateSubscription) return REACTIVATE_SUBSCRIPTION;
    if (type === AccountLinkType.ManageSubscription) return MANAGE_SUBSCRIPTION;
    return UPDATE_BILLING;
  };

  const getIcon = () => {
    switch (type) {
      case AccountLinkType.EmailPreferences:
        return Edit;
      case AccountLinkType.DeleteAccount:
        return Remove;
      case AccountLinkType.ReactivateSubscription:
      case AccountLinkType.ManageSubscription:
        return Repeat;
      default:
        return Wallet;
    }
  };

  return (
    <Link className={styles.link} href={href} size="md" icon={{ Component: getIcon() }} testId={type} target={target}>
      {getTitle()}
    </Link>
  );
};

// needed to bypass error related to translations
// Could not find required `intl` object. <IntlProvider> needs to exist in the component ancestry
export default function AccountLinksWrapper({ type, href, target }: Props) {
  return (
    <CustomProvider>
      <AccountLinks type={type} href={href} target={target || '_self'} />
    </CustomProvider>
  );
}
