import Typography from '@alltrails/shared/denali/components/Typography';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import Coupon from 'types/Coupon';
import CurrencyCode from 'types/CurrencyCode';
import Plan from 'types/Plan';
import { getPriceTotal, isTrial } from 'utils/PlanUtils';

type PaymentFinePrintProps = {
  coupon?: Coupon;
  currencyCode: CurrencyCode;
  isCouponApplied: boolean;
  plan: Plan;
};

// https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=637%3A55454
function PaymentFinePrintText({ currencyCode, coupon, isCouponApplied, plan }: PaymentFinePrintProps) {
  const languageRegionCode = useLanguageRegionCode();

  const formatter = new Intl.NumberFormat(languageRegionCode, {
    style: 'currency',
    currency: currencyCode
  });

  const months = plan.numberOfMonths;
  const totalCost = formatter.format(getPriceTotal(plan, coupon));
  const baseAnnualCost = formatter.format(Number(plan.priceTotal));

  if (isTrial(plan, coupon)) {
    // always multiple months here so we don't need singular form
    return (
      <FormattedMessage
        defaultMessage="You will be charged {baseAnnualCost} when your free trial ends and it will automatically renew after {months} months unless you update your renewal settings."
        values={{ baseAnnualCost, months }}
      />
    );
  }

  if (isCouponApplied) {
    return (
      <FormattedMessage
        defaultMessage="You will be charged {cost} today and automatically renew after {months} months at {baseAnnualCost} unless you update your renewal settings."
        values={{ baseAnnualCost, cost: totalCost, months }}
      />
    );
  }

  return (
    <FormattedMessage
      defaultMessage="You will be charged {cost} today and automatically renew after {months} months unless you update your renewal settings."
      values={{ cost: totalCost, months }}
    />
  );
}

export default function PaymentFinePrint({ currencyCode, coupon, isCouponApplied, plan }: PaymentFinePrintProps) {
  return (
    <Typography color="secondary" variant="paragraph100">
      <PaymentFinePrintText coupon={coupon} currencyCode={currencyCode} isCouponApplied={isCouponApplied} plan={plan} />
    </Typography>
  );
}
