import { wrapUrlSafe as wrapUrlSafeMonorepo, getLocale } from '@alltrails/shared/utils/languageSupport';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';

function wrapUrlSafe(url: string, languageRegionCode: LanguageRegionCode) {
  // No action if URL is already language-adjusted (wrapUrlSafe is sometimes called twice)
  if (url && languageRegionCode !== LanguageRegionCode.English) {
    const matchRegex = new RegExp(`^\\/${getLocale(languageRegionCode)}\\/(.*)`);
    if (url.match(matchRegex)) {
      return url;
    }
  }
  return wrapUrlSafeMonorepo(url, languageRegionCode);
}

const LanguageSupportUtil = {
  // deprecated
  // Do not use LanguageSupportUtil.wrapUrlSafe. Import wrapUrlSafe from the module exports below.
  wrapUrlSafe
};

export default LanguageSupportUtil;
export { LanguageSupportUtil, wrapUrlSafe };
