// extracted by mini-css-extract-plugin
export var container = "styles-module__container___HcQH3";
export var contentContainer = "styles-module__contentContainer___mb8uG";
export var destination = "styles-module__destination___EVCBE";
export var destinationSlider = "styles-module__destinationSlider___fYByJ";
export var details = "styles-module__details___guNPv";
export var fadeIn = "styles-module__fadeIn___ttwGo";
export var guideImage = "styles-module__guideImage___TBHu1";
export var imageContainer = "styles-module__imageContainer___UfimH";
export var introContainer = "styles-module__introContainer___VOiYB";
export var loadingContainer = "styles-module__loadingContainer____8TRM";
export var logo = "styles-module__logo___DUYV1";
export var noStretch = "styles-module__noStretch___Z9ELx";
export var none = "styles-module__none___p7E97";
export var slider = "styles-module__slider___PYaDd";
export var subtitle = "styles-module__subtitle___tABTZ";
export var title = "styles-module__title___ZN9ts";
export var zoomIn = "styles-module__zoomIn___qgdRe";
export var zoomInAnimation = "styles-module__zoomInAnimation___YSVYF";
export var zoomOut = "styles-module__zoomOut___pTPM1";
export var zoomOutAnimation = "styles-module__zoomOutAnimation___qwuBn";