import { useEffect, useState, useRef, PropsWithChildren } from 'react';
import * as styles from './BottomTray.module.scss';

interface Props {
  closeTray: any;
}

const BottomTray = ({ children, closeTray }: PropsWithChildren<Props>) => {
  const [trayPosition, updateTrayPosition] = useState(-500);
  const trayRef = useRef<HTMLDivElement>();
  const visibleRef = useRef(false);

  useEffect(() => {
    const handleTransitionEnd = () => {
      if (visibleRef.current) {
        closeTray();
      }
    };

    const { current } = trayRef;

    updateTrayPosition(trayRef.current.offsetHeight * -1);
    updateTrayPosition(0);
    trayRef.current.addEventListener('transitionend', handleTransitionEnd, false);

    return () => {
      current.removeEventListener('transitionend', handleTransitionEnd, false);
    };
  }, [closeTray]);

  const handleCloseTray = () => {
    visibleRef.current = true;
    updateTrayPosition(trayRef.current.offsetHeight * -1);
  };

  return (
    <div className={styles.container}>
      <div className={styles.opacScreen} onClick={handleCloseTray} />
      <div className={`${styles.tray}`} style={{ bottom: trayPosition }} ref={trayRef}>
        {children}
      </div>
    </div>
  );
};

export default BottomTray;
