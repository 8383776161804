import getStore from '../store';
import { X_AT_KEY } from '@alltrails/shared/api';

const ServerCommunicationUtil = {
  apiKey: X_AT_KEY,
  defaultAuthHeaders() {
    const state = getStore().getState();

    return {
      'X-AT-KEY': ServerCommunicationUtil.apiKey,
      'X-CSRF-TOKEN': state.formAuthenticityToken || document.querySelector('meta[name="csrf-token"]')?.content
    };
  },
  getApiEndpoint(
    url,
    params,
    successCallback,
    errorCallback,
    completeCallback,
    headerOptions = {},
    dataType = null // do not override in default case
  ) {
    const paramsHash = {
      headers: { ...this.defaultAuthHeaders(), ...headerOptions },
      type: 'GET',
      url,
      data: params,
      success(data, status, xhr) {
        successCallback(data, status, xhr);
      },
      error(xhr, status, errorString) {
        errorCallback(xhr, status, errorString);
      },
      complete(xhr, status) {
        completeCallback(xhr, status);
      }
    };

    if (dataType) {
      paramsHash.dataType = dataType;
    }
    return $.ajax(paramsHash);
  },
  loadMoreContent(
    category,
    countryId,
    locationType,
    locationId,
    page,
    perPage,
    includeTrail,
    attribute,
    completeCallback,
    onlyWithParks = false,
    sortOption,
    parkType,
    locale
  ) {
    let url;
    let params = {};

    if (locationType === 'trails') {
      // naming inconsistency between maps and tracks on front end vs API
      const apiCategory = category === 'tracks' ? 'maps' : category;
      url = `/api/alltrails/v2/${locationType}/${locationId}/${apiCategory}`;
    } else if (locationType === 'users') {
      url = `/api/alltrails/users/${locationId}/${category}`;
    } else if (locationType === 'lists') {
      url = `/api/alltrails/v2/lists/${locationId}/${category}`;
    } else {
      url = `/api/alltrails/locations/${locationType}/${locationId}/${category}`;
    }

    if (category === 'tracks' || category === 'maps') {
      params = {
        page,
        per_page: perPage,
        detail: 'shallow',
        presentation_type: 'track'
      };
      if (sortOption) params.sort_option = sortOption;
    } else if (category === 'reviews') {
      params = {
        page,
        per_page: perPage
      };
      if (sortOption) params.sort_option = sortOption;
    } else if (category === 'photos') {
      params = {
        page,
        per_page: perPage
      };
      if (sortOption) params.sort_option = sortOption;
    } else if (category === 'trails' || category === 'nearby_trails') {
      params = {
        page,
        per_page: perPage,
        algolia_formatted: true
      };
    } else if (category === 'items') {
      params = {
        page,
        per_page: perPage,
        include_obj: true
      };
    } else if (category === 'completed') {
      params = {
        page,
        per_page: perPage,
        include_obj: true
      };
    }

    // include country code to narrow down cities and states
    if (locationType === 'cities' || locationType === 'states') {
      params.country_id = countryId;
    }

    if (attribute) {
      if (['easy', 'moderate', 'hard'].includes(attribute)) {
        params.difficulty = attribute;
      } else if (['short', 'long'].includes(attribute)) {
        params.length = attribute;
      } else {
        params.attribute = attribute;
      }
    }

    if (onlyWithParks) {
      params.only_with_parks = true;
    }
    if (parkType) {
      params.park_type = parkType;
    }

    let additionalHeaders = {};
    // provide language header for translation if it is present
    if (locale) {
      additionalHeaders = { 'X-Language-Locale': locale };
    }

    this.getApiEndpoint(
      url,
      params,
      data => {
        completeCallback(data);
      },
      () => {},
      () => {},
      additionalHeaders
    );
  },
  putApiEndpoint(url, params, successCallback, errorCallback, completeCallback, headerOptions = {}, dataType = null) {
    const paramsHash = {
      headers: { ...this.defaultAuthHeaders(), ...headerOptions },
      type: 'PUT',
      url,
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(params),
      success(data, status, xhr) {
        successCallback(data, status, xhr);
      },
      error(xhr, status, errorString) {
        errorCallback(xhr, status, errorString);
      },
      complete(xhr, status) {
        completeCallback(xhr, status);
      }
    };

    if (dataType) {
      paramsHash.dataType = dataType;
    }
    return $.ajax(paramsHash);
  },
  postApiEndpoint(url, params, successCallback, errorCallback, completeCallback, headerOptions = {}) {
    return $.ajax({
      headers: { ...this.defaultAuthHeaders(), ...headerOptions },
      type: 'POST',
      url,
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(params),
      success(data, status, xhr) {
        successCallback(data, status, xhr);
      },
      error(xhr, status, errorString) {
        errorCallback(xhr, status, errorString);
      },
      complete(xhr, status) {
        completeCallback(xhr, status);
      }
    });
  },
  deleteApiEndpoint(url, params, successCallback, errorCallback, completeCallback) {
    $.ajax({
      headers: { ...this.defaultAuthHeaders() },
      type: 'DELETE',
      url,
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(params),
      success(data, status, xhr) {
        successCallback(data, status, xhr);
      },
      error(xhr, status, errorString) {
        errorCallback(xhr, status, errorString);
      },
      complete(xhr, status) {
        completeCallback(xhr, status);
      }
    });
  },
  usersPath(user) {
    return `/members/${user.slug}`;
  }
};

export { ServerCommunicationUtil };
