import Experiments from '@alltrails/shared/types/Experiments';
import useUser from 'hooks/useUser';
import useSelector from './useSelector';

// Moving forward, we'll want to use the Amplitude SDK to send exposure events (recommended by Amplitude, and de-dupes events).
// useExposureEvent.ts provides a "sendExposure" function that uses the Amplitude SDK.
// For now, you can opt-out of sending exposure events from useExperiment by passing shouldSendExposure = false.

// @deprecated
export default function useExperiment(flagKey: keyof Experiments, shouldSendExposure = true) {
  const user = useUser();

  // experiments can be undefined on rspec
  const experiment = useSelector(state => state.experiments?.[flagKey]);

  if (typeof window !== 'undefined' && experiment && shouldSendExposure) {
    global.fetch('https://api2.amplitude.com/2/httpapi', {
      body: JSON.stringify({
        api_key: __AT_DATA__.amplitudeApiKey,
        events: [
          {
            device_id: __AT_DATA__.amplitudeDeviceId,
            event_type: '$exposure',
            event_properties: { flag_key: flagKey, variant: experiment.value },
            user_id: user?.id
          }
        ]
      }),
      method: 'post'
    });
  }

  return experiment;
}
