import { FormattedMessage } from '@alltrails/shared/react-intl';
import Typography from '@alltrails/shared/denali/components/Typography';
import TermsOfService from 'components/TermsOfService';
import EmailPasswordLogin from 'components/EmailPasswordLogin';
import Link from '@alltrails/shared/denali/components/Link';
import AltLoginsForm from 'components/AltLoginsForm';
import { useDispatch } from 'react-redux';
import LoginResponse from 'types/LoginResponse';
import { GoogleLoginResponse } from 'react-google-login';
import type { User } from 'types/User';
import logLogInScreenContinueButtonTapped from '@alltrails/analytics/events/logLogInScreenContinueButtonTapped';
import SignUpScreenContinueButtonType from '@alltrails/analytics/enums/SignUpScreenContinueButtonType';
import logLogInScreenForgotPasswordTapped from '@alltrails/analytics/events/logLogInScreenForgotPasswordTapped';
import { ReactNode } from 'react';
import useGoogleHandler from 'hooks/useGoogleHandler';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import useFacebookHandler from 'hooks/useFacebookHandler';
import useAppleHandler from 'hooks/useAppleHandler';
import * as styles from './LogIn.module.scss';
import { setUser } from '../../userSlice';
import { setFormAuthenticityToken } from '../../formAuthenticityTokenSlice';

// Desktop: https://app.zeplin.io/project/6218dc5177101a641929be50/screen/6218e13d32440b60dea65769
// Mobile: https://app.zeplin.io/project/6218dc5177101a641929be50/screen/6218dec5dccf0d54f6dee4f4

type Props = {
  heading?: ReactNode;
  onSuccess: (user: User) => void;
  redirectUrl?: string;
};

export default function LogIn({ heading, onSuccess, redirectUrl }: Props) {
  const appleHandler = useAppleHandler();
  const facebookHandler = useFacebookHandler();
  const googleHandler = useGoogleHandler();
  const dispatch = useDispatch();

  const handleSuccess = ({ form_authenticity_token, user }: LoginResponse) => {
    if (redirectUrl) {
      window.location.assign(redirectUrl);
    } else {
      dispatch(setFormAuthenticityToken(form_authenticity_token));
      dispatch(setUser(user));
      onSuccess(user);
      global.google?.accounts.id.cancel(); // google can be undefined on mobile web
    }
  };

  const handleGoogle = async (response: GoogleLoginResponse) => {
    logLogInScreenContinueButtonTapped({ login_screen_continue_button_type: SignUpScreenContinueButtonType.Google });
    handleSuccess(await googleHandler(response, 'login'));
  };

  const handleFacebook = async (response: ReactFacebookLoginInfo) => {
    logLogInScreenContinueButtonTapped({ login_screen_continue_button_type: SignUpScreenContinueButtonType.Facebook });
    handleSuccess(await facebookHandler(response, 'login'));
  };

  const handleApple = () => {
    logLogInScreenContinueButtonTapped({ login_screen_continue_button_type: SignUpScreenContinueButtonType.Apple });
    appleHandler({ redirectTo: redirectUrl });
  };

  return (
    <div className={styles.content}>
      <Typography component="div" variant="heading400" mb="24">
        {heading || (
          <>
            <FormattedMessage defaultMessage="Welcome back." />
            <br />
            <FormattedMessage defaultMessage="Log in and start exploring." />
          </>
        )}
      </Typography>
      <div className={styles.grid}>
        <EmailPasswordLogin handleLoginSuccess={handleSuccess} />
        <Typography component="div" variant="paragraph200">
          <Link
            href="/users/password/new"
            onClick={() => {
              logLogInScreenForgotPasswordTapped();
            }}
          >
            <FormattedMessage defaultMessage="Forgot your password?" />
          </Link>
        </Typography>
        <AltLoginsForm googleLoginHandler={handleGoogle} facebookLoginHandler={handleFacebook} appleLoginHandler={handleApple} />
        <TermsOfService />
      </div>
    </div>
  );
}
