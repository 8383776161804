import { useRef, useEffect } from 'react';
import Coupon from 'types/Coupon';
import CurrencyCode from 'types/CurrencyCode';
import Plan from 'types/Plan';
import logError from 'utils/logError';
import { execute } from 'utils/recaptcha_helpers';
import ProResponse from 'types/ProResponse';
import { getPriceTotal } from 'utils/PlanUtils';
import useLanguageCode from '@alltrails/shared/hooks/useLanguageCode';
import type { RecurlyError } from '@recurly/recurly-js';
import * as styles from './GooglePayButton.module.scss';

type Props = {
  coupon?: Coupon;
  currencyCode: CurrencyCode;
  onGooglePayToken: ({ google_pay_token, recaptcha }: { google_pay_token: string; recaptcha: string }) => void;
  plan: Plan;
  setErrors: (errors: ProResponse['errors']) => void;
  setSubmitting: (submitting: boolean) => void;
};

// https://www.figma.com/file/YubcZX08vX2JgZf6jlKglQ/Google-pay-with-Recurly
function GooglePay({ coupon, currencyCode, onGooglePayToken, plan, setErrors, setSubmitting }: Props) {
  const ref = useRef<HTMLDivElement>();

  const languageCode = useLanguageCode();

  useEffect(() => {
    recurly.configure(__AT_DATA__.recurlyPublicKey);
  }, []);

  useEffect(() => {
    const googlePay = recurly.GooglePay({
      // see https://developers.google.com/pay/api/web/reference/request-objects#ButtonOptions
      buttonOptions: {
        buttonLocale: languageCode,
        buttonType: 'plain',
        buttonSizeMode: 'fill'
      },
      currency: currencyCode,
      country: 'US', // this is the merchants country which is always US
      total: String(getPriceTotal(plan, coupon)),
      googleMerchantId: 'BCR2DN4TQTTI56B4',
      requireBillingAddress: true
    });

    function handleReady(button: Node) {
      ref.current.replaceChildren(button);
    }

    googlePay.on('ready', handleReady);

    googlePay.on('error', (error: RecurlyError) => {
      // Google Pay is not available based upon several user agent strings. This includes the Google Search App on iOS
      // (https://apps.apple.com/us/app/google/id284815942) and the Instagram in app browser which use "GSA" and "Instagram" in the user agent strings
      // respectively. We don't want to hardcode a list here because the list is dynamically pushed down from Google's pay.js and could change. To see
      // the current strings you can grep GSA in pay.js. We'll ignore this error now that we know it is expected.
      //
      // To reproduce this error either navigate to checkout using Google Search App on iOS or set this user agent - Mozilla/5.0 (iPad; CPU OS 16_2
      // like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) GSA/250.0.505561494 Mobile/15E148 Safari/604.1
      if (error.code !== 'google-pay-not-available') {
        logError(error);
      }
    });

    googlePay.on('token', async token => {
      try {
        setSubmitting(true);

        const recaptcha = await execute('userPaymentAction');

        await onGooglePayToken({ google_pay_token: token.id, recaptcha });
      } catch (e) {
        setErrors(e);
        setSubmitting(false);
      }
    });

    return () => {
      googlePay.off('ready', handleReady);
    };
  }, [coupon, currencyCode, languageCode, plan, setErrors, setSubmitting]);

  return (
    <div className={styles.container} ref={ref}>
      {/* show a dummy button until Recurly renders the real Google Pay button client side */}
      <button aria-label="Google Pay" className={styles.button} disabled type="button" />
    </div>
  );
}

export default GooglePay;
