import type { UserId } from 'types/User';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import CustomProvider from 'components/CustomProvider';
import FacebookSettings from 'components/FacebookSettings';
import ConnectGoogle from 'components/ConnectGoogle';
import ConnectAppleButton from 'components/ConnectAppleButton';
import GarminSettings from 'components/GarminSettings';
import Typography from '@alltrails/shared/denali/components/Typography';
import { ComponentProps } from 'react';
import * as styles from './AccountConnectButtons.module.scss';

type Props = {
  userId: UserId;
  facebook?: ComponentProps<typeof FacebookSettings>;
  google?: ComponentProps<typeof ConnectGoogle>;
  apple?: ComponentProps<typeof ConnectAppleButton>;
  garmin?: ComponentProps<typeof GarminSettings>;
};

const AccountConnectButtons = ({ userId, facebook, google, apple, garmin }: Props) => {
  if (!facebook && !google && !apple && !garmin) {
    return null;
  }

  return (
    <CustomProvider>
      <div className={styles.container}>
        {facebook && (
          <>
            <Typography variant="heading300">
              <FormattedMessage defaultMessage="Facebook" />
            </Typography>
            <FacebookSettings
              userId={userId}
              facebookAppId={facebook.facebookAppId}
              userFacebookId={facebook.userFacebookId}
              unlinkPath={facebook.unlinkPath}
            />
          </>
        )}
        {google && (
          <>
            <Typography variant="heading300">
              <FormattedMessage defaultMessage="Google" />
            </Typography>
            <ConnectGoogle googlePlusId={google.googlePlusId} />
          </>
        )}
        {apple && (
          <>
            <Typography variant="heading300">
              <FormattedMessage defaultMessage="Apple" />
            </Typography>
            <ConnectAppleButton appleSigninId={apple.appleSigninId} authState={apple.authState} />
          </>
        )}
        {garmin && (
          <>
            <Typography variant="heading300">
              <FormattedMessage defaultMessage="Garmin" />
            </Typography>
            <GarminSettings garminConnectToken={garmin.garminConnectToken} isPlus={garmin.isPlus} />
          </>
        )}
      </div>
    </CustomProvider>
  );
};

export default AccountConnectButtons;
