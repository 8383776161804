import { LanguageSupportUtil } from 'utils/language_support_util';
import logHomepageSignupButtonTapped from '@alltrails/analytics/events/logHomepageSignupButtonTapped';
import { FormattedMessage, defineMessages } from '@alltrails/shared/react-intl';
import Button from '@alltrails/shared/denali/components/Button';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import ActivitySuitabilityRiver from './ActivitySuitabilityRiver';
import * as styles from './styles/styles.module.scss';

const ACTIVITY_SUITABILITY_STRINGS = defineMessages({
  SIGN_UP: {
    defaultMessage: 'Sign up'
  }
});

type Props = {
  mobileBrowser: boolean;
  windowWidth: number;
  activities: {
    title: string;
    explore_url: string;
    photo_urls: {
      square: {
        two: string;
        urlWithoutExt: string;
      };
    };
  }[];
};
const SignupActivitySuitability = ({ activities, mobileBrowser, windowWidth }: Props): JSX.Element => {
  const languageRegionCode = useLanguageRegionCode();
  const {
    formattedDefaultMessages: { SIGN_UP }
  } = useFormatMessage(ACTIVITY_SUITABILITY_STRINGS);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.signUp}>
          <h3 className={styles.header}>
            <FormattedMessage defaultMessage="Trails that fit your nature" />
          </h3>
          <div className={styles.subText}>
            <FormattedMessage defaultMessage={"Whether you’re pushing your limits or pushing a stroller, we've got you covered."} />
          </div>
          <Button
            text={SIGN_UP}
            linkInfo={{ href: LanguageSupportUtil.wrapUrlSafe('/signup?ref=homepage_signup_signupactivitysuitability', languageRegionCode) }}
            onClick={() => logHomepageSignupButtonTapped()}
            testId="activity-suitability-sign-up"
            variant="primary"
          />
        </div>
        <div className={styles.activityCarousel}>
          <ActivitySuitabilityRiver activities={activities} mobileBrowser={mobileBrowser} windowWidth={windowWidth} />
        </div>
      </div>
    </div>
  );
};

export default SignupActivitySuitability;
