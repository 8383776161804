import { PageStrings } from '@alltrails/shared/utils/constants/pageStringHelpers';
import type { Context } from 'types/Context';
import type { VisitorUsage } from '@alltrails/shared/types/VisitorUsage';
import {
  FilterViewModel,
  FilterCheckboxViewModel,
  FilterConfig,
  FilterRangeViewModel,
  FilterConfigStarRating,
  FilterConfigToggle,
  FilterConfigToggleKey,
  FilterRadioViewModel,
  FiltersKey,
  Filters,
  FilterStarRatingViewModel,
  FilterConfigRange,
  FilterFeatures
} from 'types/Search';
import { Sort } from 'types/Search/Sort';
import { Access, Activities, CompletedStatus, Difficulty } from 'types/Trails';
import type { RouteTypeUid } from '@alltrails/shared/types/trail';
import { SearchFiltersUtil } from 'utils/search_filters_util';
import { IntlShape } from '@alltrails/shared/react-intl';
import { applyRangeUpdates, FilterConfigConstructorArgs, filterKeyToConstructor, newFilterConfigForKey } from './filterConfigs';

type SavedFilterValue = number | string | string[] | number[] | { min: number; max: number };

// This is the server-side representation of saved filters and is probably what
// we should try to mimic in terms of naming and general conventions in our apps.
type SavedFilters = {
  activity: Activities[];
  difficulty: Difficulty[];
  elevation_gain: {
    min: number;
    max: number;
  };
  highest_point: {
    min: number;
    max: number;
  };
  feature: FilterFeatures[];
  length: {
    min: number;
    max: number;
  };
  min_rating: number;
  route_type: RouteTypeUid[];
  sort: Sort;
  suitability: Access[];
  trail_completion: CompletedStatus[];
  trail_traffic: VisitorUsage[];
};

type SavedFiltersKey = keyof SavedFilters;

type SavedFilterSet = {
  id: number;
  name: string;
  filters: SavedFilters;
};

const webFiltersToSavedFiltersMapping: Record<string, string> = {
  sort: 'sort',
  elevation_gain: 'elevation_gain',
  highest_point: 'highest_point',
  lengths: 'length',
  difficulty: 'difficulty',
  activities: 'activity',
  features: 'feature',
  access: 'suitability',
  route_type: 'route_type',
  visitor_usage: 'trail_traffic',
  completed: 'trail_completion',
  minRating: 'min_rating'
};

// We typically only leverage the raw number Algolia returns on the web, but our
// canonical server-driven representation of trail traffic is the text string.
const trailTrafficMapping = {
  '1': 'light',
  '2': 'moderate',
  '3': 'heavy'
};

function serializeSortFilter(filter: FilterRadioViewModel) {
  // The active state for Sort is only set when not using the application default choice.
  if ('best-match' in filter.config && filter.config['best-match'].selected) {
    return null;
  }

  return (
    Object.keys(filter.config)
      .find(key => filter.config[key as FilterConfigToggleKey].selected)
      ?.replace('-', '_') || null
  );
}

function serializeToggleFilter(filter: FilterCheckboxViewModel | FilterRadioViewModel) {
  // Collect all the keys where that config entry is selected.
  const serialized = Object.keys(filter.config).filter(key => filter.config[key as FilterConfigToggleKey].selected) as string[] | number[];

  if (serialized.length === 0) {
    return null;
  }

  return serialized;
}

function serializeRangeFilter(filter: FilterRangeViewModel) {
  // The slider/range is using the default values.
  if (!filter || (filter.config.displayValue[0] === 0 && filter.config.displayValue[1] === filter.config.upperBound)) {
    return null;
  }

  return {
    min: filter.config.value[0],
    max: filter.config.value[1]
  };
}

function serializeStarRatingFilter(filter: FilterStarRatingViewModel) {
  return parseFloat(filter.config);
}

function serializeTrailTrafficFilter(filter: FilterCheckboxViewModel) {
  return Object.keys(filter.config)
    .filter(key => filter.config[key as FilterConfigToggleKey].selected)
    .map(key => trailTrafficMapping[key as VisitorUsage]);
}

function serializeCompletedStatusFilter(filter: FilterCheckboxViewModel) {
  return Object.keys(filter.config).filter(key => filter.config[key as FilterConfigToggleKey].selected);
}

const serializeMappings: Record<string, (filter: FilterViewModel) => SavedFilterValue> = {
  sort: serializeSortFilter,
  elevation_gain: serializeRangeFilter,
  highest_point: serializeRangeFilter,
  lengths: serializeRangeFilter,
  difficulty: serializeToggleFilter,
  activities: serializeToggleFilter,
  features: serializeToggleFilter,
  access: serializeToggleFilter,
  minRating: serializeStarRatingFilter,
  route_type: serializeToggleFilter,
  visitor_usage: serializeTrailTrafficFilter,
  completed: serializeCompletedStatusFilter
};

function serializeFilters(filters: FilterViewModel[]): SavedFilters | null {
  const savedFilters = filters.reduce(
    (memo, filter) => {
      if (!filter) {
        return memo;
      }

      const savedFilterKey = webFiltersToSavedFiltersMapping[filter.key];
      if (!savedFilterKey) {
        return memo;
      }

      const serializer = serializeMappings[filter.key];
      if (!serializer) {
        console.warn('no serializer found for:', filter.key);
        return memo;
      }

      const serializedFilter = serializer(filter);
      if (!serializedFilter) {
        return memo;
      }

      memo[savedFilterKey] = serializedFilter;

      return memo;
    },
    {} as Record<string, SavedFilterValue>
  ) as SavedFilters;

  if (Object.keys(savedFilters).length === 0) {
    return null;
  }

  return savedFilters;
}

function deserializeSortFilter(key: FiltersKey, filter: string, args: FilterConfigConstructorArgs): FilterConfigToggle {
  const blankConfig = newFilterConfigForKey(key, args) as FilterConfigToggle;

  Object.keys(blankConfig).forEach((configKey: string) => {
    if (!(configKey in blankConfig)) {
      return;
    }

    blankConfig[configKey as FilterConfigToggleKey].selected = configKey === filter.replace('_', '-');
  });

  return blankConfig;
}

function deserializeToggleFilter(key: FiltersKey, filter: string[], args: FilterConfigConstructorArgs): FilterConfigToggle {
  const blankConfig = newFilterConfigForKey(key, args) as FilterConfigToggle;

  Object.keys(blankConfig).forEach((configKey: string) => {
    if (!(configKey in blankConfig)) {
      return;
    }

    blankConfig[configKey as FilterConfigToggleKey].selected = filter.includes(configKey);
  });

  return blankConfig;
}

function deserializeRangeFilter(key: FiltersKey, filter: { min: number; max: number }, args: FilterConfigConstructorArgs): FilterConfigRange {
  const blankConfig = newFilterConfigForKey(key, args) as FilterConfigRange;

  const { min, max } = filter;

  applyRangeUpdates(blankConfig, [min, max]);

  return blankConfig;
}

function deserializeStarRatingFilter(key: FiltersKey, filter: number): FilterConfigStarRating {
  return `${filter}`;
}

function deserializeTrailTrafficFilter(key: FiltersKey, filter: string[], args: FilterConfigConstructorArgs): FilterConfigToggle {
  const blankConfig = newFilterConfigForKey(key, args) as FilterConfigToggle;

  Object.keys(blankConfig).forEach((configKey: VisitorUsage) => {
    if (!(configKey in blankConfig)) {
      return;
    }

    if (!(configKey in trailTrafficMapping)) {
      return;
    }

    blankConfig[configKey as FilterConfigToggleKey].selected = filter.includes(trailTrafficMapping[configKey]);
  });

  return blankConfig;
}

function deserializeCompletedStatusFilter(key: FiltersKey, filter: string[], args: FilterConfigConstructorArgs): FilterConfigToggle {
  const blankConfig = newFilterConfigForKey(key, args) as FilterConfigToggle;

  Object.keys(blankConfig).forEach((configKey: CompletedStatus) => {
    if (!(configKey in blankConfig)) {
      return;
    }

    blankConfig[configKey as FilterConfigToggleKey].selected = filter.includes(configKey);
  });

  return blankConfig;
}

const deserializeMappings: Record<string, (key: FiltersKey, filter: SavedFilterValue, args: FilterConfigConstructorArgs) => FilterConfig> = {
  sort: deserializeSortFilter,
  elevation_gain: deserializeRangeFilter,
  highest_point: deserializeRangeFilter,
  lengths: deserializeRangeFilter,
  difficulty: deserializeToggleFilter,
  activities: deserializeToggleFilter,
  features: deserializeToggleFilter,
  access: deserializeToggleFilter,
  minRating: deserializeStarRatingFilter,
  route_type: deserializeToggleFilter,
  visitor_usage: deserializeTrailTrafficFilter,
  completed: deserializeCompletedStatusFilter
};

function deserializeSavedFilterSet(
  savedFilterSet: SavedFilterSet,
  blankFiltersFactory: () => FilterViewModel[],
  context: Context,
  page: string,
  intl: IntlShape
): FilterViewModel[] {
  return blankFiltersFactory().map((blankFilter: FilterViewModel) => {
    const savedFilterKey = webFiltersToSavedFiltersMapping[blankFilter.key];
    const serverValue = savedFilterSet.filters[savedFilterKey as SavedFiltersKey];

    if (!serverValue) {
      return blankFilter;
    }

    const deserializer = deserializeMappings[blankFilter.key];
    if (!deserializer) {
      console.warn('no deserializer found for:', blankFilter.key);
      return blankFilter;
    }

    const deserializedFilterConfig = deserializer(blankFilter.key as FiltersKey, serverValue, { context, page, intl });
    if (!deserializedFilterConfig) {
      return blankFilter;
    }

    blankFilter.config = deserializedFilterConfig;

    return blankFilter;
  });
}

function newFilters(args: FilterConfigConstructorArgs): Filters {
  const filters: Record<FiltersKey, FilterConfig> = {} as Record<FiltersKey, FilterConfig>;

  Object.keys(filterKeyToConstructor).forEach((key: FiltersKey) => {
    filters[key] = newFilterConfigForKey(key, args) as FilterConfig;
  });

  return filters;
}

function newFiltersReusingSearchState(
  atMaps: null | unknown[],
  baseFilters: Filters,
  results: unknown[],
  { context, initialUserSlug, page, intl }: FilterConfigConstructorArgs
): { filters: Filters; page: undefined | string; results: undefined | null } {
  let newFilterState = newFilters({ context, initialUserSlug, page, intl });

  let newResults;
  let newPage;

  // Keep search bounding box when clearing if on EXPLORE ALL Page
  if (page === PageStrings.EXPLORE_ALL_PAGE) {
    newFilterState.boundingBox = baseFilters.boundingBox;
  } else if (page === PageStrings.EXPLORE_CUSTOM_PAGE || page === PageStrings.EXPLORE_FAVORITE_PAGE || page === PageStrings.EXPLORE_COMPLETED_PAGE) {
    // Keep current bounds if results are present, but clear all other filters
    if (results?.length > 0) {
      newFilterState.boundingBox = baseFilters.boundingBox;
    }
    // Only add back in trailIds if they exist
    const { mapIds, trackIds, trailIds } = baseFilters;
    if ((trailIds && trailIds.length > 0) || (trackIds && trackIds.length > 0) || (mapIds && mapIds.length > 0)) {
      newFilterState = SearchFiltersUtil.setOrToggleFilter(newFilterState, 'trailIds', baseFilters.trailIds);
      newFilterState = SearchFiltersUtil.setOrToggleFilter(newFilterState, 'trackIds', baseFilters.trackIds);
      newFilterState = SearchFiltersUtil.setOrToggleFilter(newFilterState, 'mapIds', baseFilters.mapIds);
    } else {
      // Force the no result state to be searching for trails...
      newResults = null;
    }
  } else if (page === PageStrings.EXPLORE_USERS_TRACKS_PAGE || page === PageStrings.EXPLORE_USERS_MAPS_PAGE) {
    // Keep current bounds if results are present, but clear all other filters
    if (results?.length > 0) {
      newFilterState.boundingBox = baseFilters.boundingBox;
    }
    if (atMaps == null || (atMaps && atMaps.length === 0)) {
      newPage = PageStrings.EXPLORE_ALL_PAGE;
      // Force the no result state to be searching for trails...
      newResults = null;
    }
  }

  return { filters: newFilterState, page: newPage, results: newResults };
}

export { deserializeSavedFilterSet, newFilters, newFiltersReusingSearchState, serializeFilters };
export type { SavedFilters, SavedFilterSet };
