import { configureStore } from '@reduxjs/toolkit';
import experimentsReducer from './experimentsSlice';
import userReducer from './userSlice';
import formAuthenticityTokenReducer from './formAuthenticityTokenSlice';

const reducer = {
  experiments: experimentsReducer,
  formAuthenticityToken: formAuthenticityTokenReducer,
  user: userReducer
};

const store = configureStore({ preloadedState: global.__AT_DATA__?.store, reducer });

function getStore() {
  if (typeof window !== 'undefined') {
    return store;
  }

  return configureStore({ preloadedState: global.__AT_DATA__?.store, reducer });
}

export default getStore;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
