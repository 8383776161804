import Button from '@alltrails/shared/denali/components/Button';
import IconButton from '@alltrails/shared/denali/components/IconButton';
import Close from '@alltrails/shared/icons/Close';
import Typography from '@alltrails/shared/denali/components/Typography';
import PortalModal from 'components/shared/PortalModal/PortalModal';
import useIsMobile from '@alltrails/shared/hooks/useIsMobile';
import StarRating from '@alltrails/shared/denali/components/StarRating';
import { FormattedMessage, defineMessages, useIntl } from '@alltrails/shared/react-intl';
import Texture from '@alltrails/shared/denali/components/Texture';
import InstallPromptLocation from '@alltrails/analytics/enums/InstallPromptLocation';
import InstallPromptType from '@alltrails/analytics/enums/InstallPromptType';
import logInstallPromptViewed from '@alltrails/analytics/events/logInstallPromptViewed';
import { useEffect } from 'react';
import InstallPromptSource from '@alltrails/analytics/enums/InstallPromptSource';
import logInstallPromptDismissed from '@alltrails/analytics/events/logInstallPromptDismissed';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import classNames from 'classnames';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import useFeatures from 'hooks/useFeatures';
import * as styles from './InstallInterstitial.module.scss';
import appIconSrc from '../../../../assets/images/icons/app_icon.png';
import phoneSrcAvif from './phone.avif';
import phoneSrcWebp from './phone.webp';
import phoneSrc from './phone.png';
import useDeeplink from '../hooks/useDeeplink';
import { deepLinkRedirect } from '../utils';

type InstallInterstitialProps = {
  promptLocation: InstallPromptLocation;
  pageId?: number;
  isOpen?: boolean;
  onRequestClose?: () => void;
  displayTrigger?: CarouselDisplayTrigger;
};

// Design link: https://www.figma.com/file/I38YmC36f1JlMJS4pjkqS5/App-of-the-Year?type=design&node-id=1213-39039&mode=design&t=VFO4CHX18FDb3Y4R-0

const InstallInterstitial = ({ promptLocation, pageId, isOpen: isOpenAttempt = false, displayTrigger, onRequestClose }: InstallInterstitialProps) => {
  const isAppsFlyerEnabled = useFeatures()?.includes('appsflyer_migration');
  const isMobile = useIsMobile();
  const intl = useIntl();
  const ALT_TEXT = defineMessages({
    PHONE_ALT_Text: {
      defaultMessage: 'Navigation feature from the AllTrails app'
    }
  });
  const isOpen = isOpenAttempt && isMobile;
  const deeplink = useDeeplink({ pageType: 'trail', pageId, isOpen, promptType: 'interstitial' });

  const {
    formattedDefaultMessages: { PHONE_ALT_Text }
  } = useFormatMessage(ALT_TEXT);

  useEffect(() => {
    if (isOpen) {
      logInstallPromptViewed({
        install_prompt_location: promptLocation,
        install_prompt_type: InstallPromptType.Interstitial,
        install_prompt_source: isAppsFlyerEnabled ? InstallPromptSource.NativeWithAppsflyerLink : InstallPromptSource.Native,
        signup_modal_trigger: displayTrigger
      });
    }
  }, [displayTrigger, isOpen, promptLocation, isMobile, isAppsFlyerEnabled]);

  const handleCloseClick = () => {
    logInstallPromptDismissed({
      install_prompt_location: promptLocation,
      install_prompt_type: InstallPromptType.Interstitial,
      install_prompt_source: isAppsFlyerEnabled ? InstallPromptSource.NativeWithAppsflyerLink : InstallPromptSource.Native
    });
    onRequestClose();
  };

  return (
    <PortalModal isOpen={isOpen}>
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          <Texture variant="leaves" imageSize="full" className={styles.texture} />
          <picture>
            <source srcSet={phoneSrcAvif} type="image/avif" />
            <source srcSet={phoneSrcWebp} type="image/webp" />
            <img src={phoneSrc} alt={PHONE_ALT_Text} className={styles.phone} />
          </picture>
        </div>
        <div className={styles.contentContainer}>
          <Typography variant="heading500" className={styles.header}>
            <FormattedMessage defaultMessage="AllTrails is better in the app" />
          </Typography>
          <div className={styles.divider} />
        </div>

        <IconButton
          className={classNames(styles.close)}
          icon={{ Component: Close }}
          onClick={handleCloseClick}
          size="md"
          title={intl.formatMessage({ defaultMessage: 'Close' })}
          testId="close-button"
          variant="flat"
        />
        <div className={styles.contentContainer}>
          <div className={styles.logoAndRatingContainer}>
            <img src={appIconSrc} alt="" />
            <div className={styles.nameAndRatingContainer}>
              <Typography variant="heading200">AllTrails</Typography>
              <div className={styles.ratingContainer}>
                <StarRating rating={5} variant="complex" />
                <Typography variant="text100" component="span">
                  (4.9)
                </Typography>
              </div>
            </div>
          </div>
          <Button
            text={intl.formatMessage({ defaultMessage: 'Continue in app' })}
            onClick={() =>
              deepLinkRedirect(
                deeplink,
                InstallPromptType.Interstitial,
                promptLocation,
                isAppsFlyerEnabled ? InstallPromptSource.NativeWithAppsflyerLink : InstallPromptSource.Native
              )
            }
            testId="banner-button"
            variant="primary"
            size="md"
            allowWrap
            width="fullOnMobile"
          />
        </div>
      </div>
    </PortalModal>
  );
};

export default InstallInterstitial;
