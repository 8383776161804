import { ServerCommunicationUtil } from '../server_communication_util';

const ajaxPromise = opts => {
  const { resReducer } = opts;
  let xhr;
  const promise = new Promise((resolve, reject) => {
    xhr = $j.ajax({
      ...opts,
      success: res => {
        if (resReducer) {
          res = resReducer(res);
        }
        resolve(res);
      },
      error: jqXHR => {
        reject(jqXHR);
      },
      finally: () => {
        xhr = undefined;
      }
    });
  });
  promise.abort = () => {
    if (xhr) {
      xhr.abort();
    }
  };
  return promise;
};

const serverAjaxPromise = options => {
  if (options.type && options.type !== 'GET' && !options.contentType) {
    options.contentType = 'application/json; charset=utf-8';
    if (typeof options.data !== 'string') {
      options.data = JSON.stringify(options.data);
    }
  }
  if (!options.headers) {
    options.headers = {};
  }
  options.headers = { ...options.headers, ...ServerCommunicationUtil.defaultAuthHeaders() };
  return ajaxPromise(options);
};

export { ajaxPromise, serverAjaxPromise };
