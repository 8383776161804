import { useHistory as useHistoryBase } from '@alltrails/shared/components/ReactRouter';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { LanguageSupportUtil } from 'utils/language_support_util';

export default function useHistory() {
  const languageRegionCode = useLanguageRegionCode();
  const history = useHistoryBase();

  return {
    push: (path: string) => {
      history.push(LanguageSupportUtil.wrapUrlSafe(path, languageRegionCode));
    }
  };
}
