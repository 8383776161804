import { serverAjaxPromise } from './request_helpers';

export const getPrivacyPolicy = userId => {
  return serverAjaxPromise({
    method: 'GET',
    url: `/api/alltrails/community/users/${userId}/privacy_policy`,
    resReducer: data => {
      return data.privacy_policy;
    }
  });
};

export const updatePrivacyPolicy = (userId, policy, applyBulk) => {
  return serverAjaxPromise({
    method: 'PUT',
    url: `/api/alltrails/community/users/${userId}/privacy_policy`,
    data: {
      policy,
      apply_bulk: applyBulk
    },
    resReducer: data => {
      return data.privacy_policy;
    }
  });
};
