import { ReactNode } from 'react';
import * as styles from './styles/styles.module.scss';

export type Props = {
  title: ReactNode;
  subtitle: ReactNode;
  text: ReactNode;
  className?: string;
};

const CommunityStat = ({ title = '', subtitle = '', text = '', className = '' }: Props): JSX.Element => (
  <div className={`${styles.container} ${className}`}>
    <div className={styles.title}>{title}</div>
    <div className={styles.subtitle}>{subtitle}</div>
    <div className={styles.text}>{text}</div>
  </div>
);

export default CommunityStat;
