import { FormattedMessage } from '@alltrails/shared/react-intl';
import Typography from '@alltrails/shared/denali/components/Typography';
import CheckFilled from '@alltrails/shared/icons/CheckFilled';
import Button from '@alltrails/shared/denali/components/Button';
import * as styles from './AccountConnected.module.scss';

type Props = {
  linkText?: 'Manage' | 'Unlink';
  isLoading: boolean;
  onClick: () => void;
};

const AccountConnected = ({ linkText, isLoading, onClick }: Props) => (
  <div className={styles.container}>
    <div className={styles.text}>
      <CheckFilled />
      <Typography variant="text200">
        <FormattedMessage defaultMessage="Connected" />
      </Typography>
    </div>
    <Button
      text={linkText === 'Unlink' ? <FormattedMessage defaultMessage="Unlink" /> : <FormattedMessage defaultMessage="Manage" />}
      testId="profile-unlink"
      variant="flat"
      onClick={onClick}
      loading={isLoading}
    />
  </div>
);

export default AccountConnected;
