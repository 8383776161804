import { defineMessages, FormattedMessage } from '@alltrails/shared/react-intl';;
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import GiftCard1 from 'components/GiftCard1';
import { MEDIA_QUERY_MOBILE_DOWN } from 'utils/constants/breakpoints';
import * as styles from './styles/styles.module.scss';
import redemptionHeroAvif from './images/RedemptionHero-2880w.avif';
import redemptionHeroAvifSm from './images/RedemptionHero-768w.avif';
import redemptionHeroJpg from './images/RedemptionHero-2880w.jpg';
import redemptionHeroJpgSm from './images/RedemptionHero-768w.jpg';
import redemptionHeroWebp from './images/RedemptionHero-2880w.webp';
import redemptionHeroWebpSm from './images/RedemptionHero-768w.webp';

const messages = defineMessages({
  REDEEM_GIFT_SUBSCRIPTION: { defaultMessage: 'Redeem your gift subscription' }
});

// https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=586%3A31161
const RedemptionHeader = () => {
  const {
    formattedDefaultMessages: { REDEEM_GIFT_SUBSCRIPTION }
  } = useFormatMessage(messages);

  return (
    <main className={styles.heroContainer}>
      <picture>
        <source media={MEDIA_QUERY_MOBILE_DOWN} srcSet={redemptionHeroAvifSm} type="image/avif" />
        <source media={MEDIA_QUERY_MOBILE_DOWN} srcSet={redemptionHeroWebpSm} type="image/webp" />
        <source media={MEDIA_QUERY_MOBILE_DOWN} srcSet={redemptionHeroJpgSm} type="image/jpg" />
        <source srcSet={redemptionHeroAvif} type="image/avif" />
        <source srcSet={redemptionHeroWebp} type="image/webp" />
        <img alt={REDEEM_GIFT_SUBSCRIPTION} src={redemptionHeroJpg} className={styles.heroImage} />
      </picture>
      <div className={styles.heroOverlay} />
      <div className={styles.contentContainer}>
        <h1 className={styles.heroMessage}>
          <FormattedMessage
            defaultMessage="Redeem your {br}gift subscription"
            values={{
              br: <br />
            }}
          />
        </h1>
        <div className={styles.giftCards}>
          <GiftCard1 />
        </div>
      </div>
    </main>
  );
};

export default RedemptionHeader;
