import { useEffect, useState } from 'react';
import Button from '@alltrails/shared/denali/components/Button';
import Google from '@alltrails/shared/icons/Google';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import { post } from '@alltrails/shared/api';
import { unlinkRequest } from 'utils/requests/garminRequests';
import logError from 'utils/logError';
import useFeatures from 'hooks/useFeatures';
import LoginResponse from 'types/LoginResponse';
import AccountConnected from '../AccountConnectButtons/AccountConnected';
import * as styles from './ConnectGoogle.module.scss';

export type ConnectGoogleProps = {
  googlePlusId?: string;
};

const ConnectGoogle = ({ googlePlusId }: ConnectGoogleProps) => {
  const [currentGooglePlusId, setCurrentGooglePlusId] = useState(googlePlusId);
  const [currentGoogleAuth, setCurentGoogleAuth] = useState(null);
  const [isDisconnectingAccount, setIsDisconnectingAccount] = useState(false);

  const isIdentityServiceEnabled = useFeatures().includes('identity_service') && !__AT_DATA__.useIdentityMock;

  const initGoogle = () => {
    if (window && window.gapi) {
      window.gapi.load('auth2', () => {
        // Retrieve the singleton for the GoogleAuth library and set up the client.
        window.gapi.auth2
          .init({
            client_id: '127587500781-rjpk7g3rhiobeeqq33fis0a35olspsi0.apps.googleusercontent.com',
            cookie_policy: 'single_host_origin',
            scope: 'profile email'
          })
          .then(newGoogleAuth => {
            newGoogleAuth.signOut();
            setCurentGoogleAuth(newGoogleAuth);
          })
          .catch(logError);
      });
    }
  };

  const googleSuccessCallback = async (googleUser: gapi.auth2.GoogleUser) => {
    const token = googleUser.getAuthResponse().id_token;

    if (isIdentityServiceEnabled) {
      await post<LoginResponse>('/identity/auth/google', {
        client_id: __AT_DATA__.identityWebClientId,
        google_token: token
      });
    } else {
      await post<LoginResponse>('/api/alltrails/google/login', { google_token: token });
    }

    setCurrentGooglePlusId(token);
  };

  useEffect(() => {
    window.handleGoogleClientLoad = initGoogle;
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/platform.js?onload=handleGoogleClientLoad';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleUnlinkClick = () => {
    setIsDisconnectingAccount(true);
    unlinkRequest('google')
      .then(() => {
        window.location.reload(); // this is necessary for now to render the flash message
      })
      .catch(err => {
        logError(err);
        setIsDisconnectingAccount(false);
      });
  };

  const handleClick = () => {
    currentGoogleAuth
      ?.signIn()
      ?.then(() => googleSuccessCallback(currentGoogleAuth.currentUser.get()))
      ?.catch(logError);
  };

  if (currentGooglePlusId) {
    return <AccountConnected linkText="Unlink" isLoading={isDisconnectingAccount} onClick={handleUnlinkClick} />;
  }

  return (
    <Button
      className={styles.button}
      text={<FormattedMessage defaultMessage="Connect with Google" />}
      testId="connect-with-google"
      icon={{ Component: Google }}
      onClick={handleClick}
      variant="primary"
    />
  );
};

export default ConnectGoogle;
