import { useMemo } from 'react';
import sample from 'utils/sample';

/**
 * Given an array, memoizes and returns a random item in that array.
 * Useful when sampled items should persist between renders.
 * @param arr the array to sample.
 * @returns a random element within arr.
 */
export default function useSample<T>(arr: T[]): T {
  const memoizedSample = useMemo(() => sample<T>(arr), [arr]);
  return memoizedSample;
}
