import ApplicationLayout from 'components/ApplicationLayout';
import CustomProvider from 'components/CustomProvider';
import SignUpForm from 'components/SignUpForm';
import LoginSignupLayout from 'components/LoginSignupLayout';
import { Context } from 'types/Context';

type Props = {
  context: Context;
  currentPage: string;
  locationSlug: string;
  redirectTo: string;
};

export default function SignUp({ context, currentPage, locationSlug, redirectTo }: Props) {
  return (
    <CustomProvider>
      <ApplicationLayout context={context} currentPage={currentPage} locationSlug={locationSlug}>
        <LoginSignupLayout showBranchInterstitial={!redirectTo.includes('/plus/checkout')}>
          <SignUpForm redirectTo={redirectTo} />
        </LoginSignupLayout>
      </ApplicationLayout>
    </CustomProvider>
  );
}
