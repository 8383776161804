import turfDistance from '@turf/distance';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import { toATLocalString } from './number_util';

const METERS_TO_MILES = 0.000621371;
const METERS_TO_FEET = 3.28084;
const METERS_PER_SEC_TO_MPH = 2.23694;
const METERS_PER_SEC_TO_KPH = 3.6;
const MapStatsUtil = {
  metersToMiles(num, precision) {
    if (precision === undefined) precision = 5;
    return Math.round(METERS_TO_MILES * num * Math.pow(10, precision)) / Math.pow(10, precision);
  },
  metersToFeet(num, precision) {
    if (precision === undefined) precision = 2;
    return Math.round(METERS_TO_FEET * num * Math.pow(10, precision)) / Math.pow(10, precision);
  },
  secondsToTimeNo24HourLimit(duration) {
    if (!duration) return '--';

    const hours = Math.floor(duration / 3600);
    duration -= hours * 3600;

    let minutes = Math.floor(duration / 60);
    duration -= minutes * 60;

    if (hours && minutes < 10) minutes = `0${minutes}`;

    let seconds = Math.round(duration);
    if (seconds < 10) seconds = `0${seconds}`;

    return `${(hours ? `${hours}:` : '') + minutes}:${seconds}`;
  },
  minutesToFormattedTime(durationInMinutes) {
    const hours = Math.floor(durationInMinutes / 60);
    if (hours >= 12) {
      return <FormattedMessage defaultMessage="Multi-day" />;
    }
    const minutes = durationInMinutes - hours * 60;
    const fmtHours = hours ? `${hours}h ` : null;
    const fmtMinutes = minutes ? (
      <>
        {minutes}
        <FormattedMessage id="minute.abbreviated" defaultMessage="m" />
      </>
    ) : null;

    return fmtHours || fmtMinutes ? (
      <>
        {fmtHours}
        {fmtMinutes}
      </>
    ) : null;
  },
  elevationConversion(elev, metric) {
    if (!elev && elev !== 0) return null;
    if (metric) return elev;
    return elev * METERS_TO_FEET;
  },
  totalDistance(stats, metric) {
    if (!stats || (!stats.distanceTotal && stats.distanceTotal !== 0)) return null;
    if (metric) return stats.distanceTotal / 1000.0; // km
    return stats.distanceTotal * METERS_TO_MILES;
  },
  secondsToHours(seconds = 0) {
    return seconds / 3600;
  },
  maxElevation(stats, metric) {
    if (!stats || (!stats.elevationMax && stats.elevationMax !== 0)) return null;
    if (metric) return stats.elevationMax;
    return stats.elevationMax * METERS_TO_FEET;
  },
  minElevation(stats, metric) {
    if (!stats || (!stats.elevationMin && stats.elevationMin !== 0)) return null;
    if (metric) return stats.elevationMin;
    return stats.elevationMin * METERS_TO_FEET;
  },
  elevationGain(stats, metric) {
    if (!stats || (!stats.elevationGain && stats.elevationGain !== 0)) return null;
    if (metric) return stats.elevationGain;
    return stats.elevationGain * METERS_TO_FEET;
  },
  avgPace(stats, metric) {
    if (!stats || (!stats.paceAverage && stats.paceAverage !== 0)) return null;
    // Convert from seconds per meter to seconds per km or seconds per mile
    if (metric) return 1000.0 * stats.paceAverage;
    return stats.paceAverage / METERS_TO_MILES;
  },
  avgSpeed(stats, metric) {
    if (!stats || (!stats.speedAverage && stats.speedAverage !== 0)) return null;
    if (metric) return stats.speedAverage * METERS_PER_SEC_TO_KPH;
    return stats.speedAverage * METERS_PER_SEC_TO_MPH;
  },
  topSpeed(stats, metric) {
    if (!stats || (!stats.speedMax && stats.speedMax !== 0)) return null;
    if (metric) return stats.speedMax;
    return stats.speedMax * METERS_PER_SEC_TO_MPH;
  },
  totalDistanceFromPoints(pointsObjects) {
    let totalDistanceKm = 0.0;
    let prevPoint;
    let currPoint;
    for (let k = 0; k < pointsObjects.length; k++) {
      currPoint = [pointsObjects[k][1], pointsObjects[k][0]];
      if (k > 0) {
        totalDistanceKm += turfDistance(prevPoint, currPoint);
      }
      prevPoint = currPoint;
    }
    return totalDistanceKm * 1000;
  },
  totalElevationFromPoints(pointsObjects) {
    let totalElevationMeters = 0.0;
    let prevPoint;
    let currPoint;
    for (let k = 0; k < pointsObjects.length; k++) {
      currPoint = pointsObjects[k][2];
      if (k > 0) {
        const difference = currPoint - prevPoint;
        if (difference > 0) {
          totalElevationMeters += difference;
        }
      }
      prevPoint = currPoint;
    }
    return totalElevationMeters;
  },
  formatData(data, precision, measurementUnit, languageRegionCode = 'en-US') {
    if (data || data === 0) {
      let formatted = `${toATLocalString(data, languageRegionCode, precision)}`;

      if (measurementUnit) {
        formatted = `${formatted} ${measurementUnit}`;
      }

      return formatted;
    }

    if (measurementUnit) {
      return `0 ${measurementUnit}`;
    }

    return '';
  },
  metersToFormattedUserUnits(length, precision, metric, languageRegionCode) {
    // returns a formatted string of meters to either km or mi
    if (!precision && precision !== 0) {
      precision = 2;
    }

    const [distance, measurementType] = metric ? [length / 1000.0, 'km'] : [this.metersToMiles(length), 'mi'];
    return this.formatData(distance, precision, measurementType, languageRegionCode);
  },
  timeFormatted(value) {
    return this.secondsToTimeNo24HourLimit(value);
  },
  speedFormatted(value, metric, languageRegionCode) {
    if (!value && value !== 0) {
      return '--';
    }
    return this.formatData(value, 1, metric ? 'km/h' : 'mph', languageRegionCode);
  },
  elevationFormatted(value, metric, languageRegionCode) {
    if (!value && value !== 0) {
      return '--';
    }

    const [elevation, measurementType] = metric ? [value, 'm'] : [this.metersToFeet(value), 'ft'];
    return this.formatData(elevation, 0, measurementType, languageRegionCode);
  },
  caloriesFormatted(value, languageRegionCode = 'en-US') {
    if (!value && value !== 0) {
      return '--';
    }

    return toATLocalString(value, languageRegionCode);
  },
  distanceAndElevation({ distanceTotal, elevationGain }, displayMetric, languageRegionCode) {
    const distance = parseFloat(distanceTotal);
    const elevation = parseFloat(elevationGain);

    return {
      distance: this.metersToFormattedUserUnits(distance, 2, displayMetric, languageRegionCode),
      elevation: this.elevationFormatted(elevation, displayMetric, languageRegionCode)
    };
  }
};

export { MapStatsUtil, METERS_TO_FEET, METERS_TO_MILES };
