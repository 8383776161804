import { wrapUrlSafe } from 'utils/language_support_util';
import type { User } from 'types/User';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';

function handleAfterRoadblock(afterRoadblock: (() => void) | string, openInNewTab: boolean) {
  if (typeof afterRoadblock === 'function') {
    afterRoadblock();
  } else if (openInNewTab) {
    window.open(afterRoadblock, '_blank');
  } else {
    window.location.assign(afterRoadblock);
  }
}

function handleRoadblock({
  afterRoadblock,
  atRef,
  eventName,
  languageRegionCode,
  openInNewTab,
  returnToUrl,
  user,
  setIsInterstitialOpen
}: {
  afterRoadblock: (() => void) | string;
  atRef?: string;
  eventName: string;
  languageRegionCode: LanguageRegionCode;
  openInNewTab?: boolean;
  returnToUrl: string;
  user: User;
  setIsInterstitialOpen: (isInterstitialOpen: boolean) => void;
}) {
  if (!user) {
    const ref = atRef ? `ref=${atRef}&` : '';
    const returnTo = `returnTo=${encodeURIComponent(returnToUrl)}`;
    window.location.assign(wrapUrlSafe(`/signup?${ref}${returnTo}`, languageRegionCode));
    return;
  }

  // Proceed directly to next step if not mobile
  if (!__AT_DATA__.mobileBrowser) {
    handleAfterRoadblock(afterRoadblock, openInNewTab);
    return;
  }

  // Only a subset of roadblocks apply to signed-in-user
  const signedInRoadblocks = ['trail_header_map', 'trail_explore', 'trail_print', 'park_print', 'create_map', 'print_maps'];

  // Show only every third time
  const signedInRoadblockEvery = 3;
  if (window && window.localStorage && signedInRoadblocks.includes(eventName)) {
    const authRoadblockCount = (Number(window.localStorage.getItem('authRoadblockCount')) + 1) % signedInRoadblockEvery;
    window.localStorage.setItem('authRoadblockCount', String(authRoadblockCount));

    if (authRoadblockCount + 1 === signedInRoadblockEvery) {
      setIsInterstitialOpen(true);
      return;
    }
  }

  // No Journey shown, so proceed directly to next step
  handleAfterRoadblock(afterRoadblock, openInNewTab);
}

export default handleRoadblock;
