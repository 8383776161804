import { PropsWithChildren } from 'react';
import classNames from 'classnames';
import * as styles from './SectionDivider.module.scss';

type Props = {
  classes?: string;
};

function SectionDivider({ children, classes }: PropsWithChildren<Props>) {
  return (
    <div className={classNames(styles.wrapper, classes)}>
      <span className={styles.line} />
      <span className={styles.text}>{children}</span>
      <span className={styles.line} />
    </div>
  );
}

export default SectionDivider;
