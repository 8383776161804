import { serverAjaxPromise } from './request_helpers';

const retrievePrintedGift = (code, locale) => {
  const headers = locale ? { 'X-Language-Locale': locale } : {};
  return serverAjaxPromise({
    type: 'POST',
    contentType: 'application/json',
    headers,
    url: '/api/private/coupon',
    data: JSON.stringify({ code }),
    resReducer: resp => resp
  });
};

export { retrievePrintedGift };
