import { IntlShape } from '@alltrails/shared/react-intl';
import isValidEmail from 'utils/isValidEmail';

// Teasing this apart from a big function to individual validators similar to https://react-hook-form.com/get-started#Applyvalidation
const validateUserForm = (name: string, value: string, errors: Record<string, string>, intl: IntlShape) => {
  switch (name) {
    case 'userRegEmail':
      if (!isValidEmail(value)) {
        errors[name] = intl.formatMessage({ defaultMessage: 'Email is not valid.' });
      } else {
        delete errors[name];
      }
      break;
    case 'userRegPassword':
    case 'password':
    case 'confirmPassword':
      if (value.length < 6 || value.length > 128) {
        errors[name] = intl.formatMessage({ defaultMessage: 'Password must be 6 to 128 characters in length.' });
      } else {
        delete errors[name];
      }
      break;
    case 'userRegFirstName':
      if (value?.trim().length < 1) {
        errors[name] = intl.formatMessage({ defaultMessage: 'Enter your first name.' });
      } else {
        delete errors[name];
      }
      break;
    case 'userRegLastName':
      if (value?.trim().length < 1) {
        errors[name] = intl.formatMessage({ defaultMessage: 'Enter your last name.' });
      } else {
        delete errors[name];
      }
      break;
    default:
      break;
  }

  return { errors };
};

export default validateUserForm;
