import { ApiError, post } from '@alltrails/shared/api';
import LoginResponse from 'types/LoginResponse';
import authSuccessCallback from 'utils/AuthHandlers';
import logSignUpFailed from '@alltrails/analytics/events/logSignUpFailed';
import logLogInFailed from '@alltrails/analytics/events/logLogInFailed';
import { GoogleLoginResponse } from 'react-google-login';
import AuthenticationType from '@alltrails/analytics/enums/AuthenticationType';
import useFeatures from './useFeatures';

export default function useGoogleHandler() {
  const isIdentityServiceEnabled = useFeatures().includes('identity_service') && !__AT_DATA__.useIdentityMock;

  async function googleHandler(
    googleUserOrToken: GoogleLoginResponse | string,
    handlerType: 'login' | 'signup',
    referralLink?: string,
    oneTap?: boolean
  ) {
    const token = typeof googleUserOrToken === 'string' ? googleUserOrToken : googleUserOrToken.getAuthResponse().id_token;

    let response;

    try {
      response = isIdentityServiceEnabled
        ? await post<LoginResponse>('/identity/auth/google', {
            client_id: __AT_DATA__.identityWebClientId,
            google_token: token,
            ...(referralLink && { referral_code: referralLink }),
            ...(oneTap && { one_tap: oneTap })
          })
        : await post<LoginResponse>('/api/alltrails/google/login', {
            create_session: true,
            google_token: token,
            ...(referralLink && { referral_code: referralLink }),
            ...(oneTap && { one_tap: oneTap })
          });

      authSuccessCallback(response, oneTap ? AuthenticationType.GoogleOneTap : AuthenticationType.Google, response.is_collab_list_invite);
    } catch (e) {
      const failureType = e instanceof ApiError && e.data?.errors?.length > 0 ? e.data.errors[0].code : 'unknown';

      if (handlerType === 'signup') {
        logSignUpFailed({ authentication_type: oneTap ? AuthenticationType.GoogleOneTap : AuthenticationType.Google, failure_type: failureType });
      } else {
        logLogInFailed({
          authentication_type: oneTap ? AuthenticationType.GoogleOneTap : AuthenticationType.Google,
          failure_type: failureType
        });
      }

      throw e;
    }

    return response;
  }

  return googleHandler;
}
