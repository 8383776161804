import { useIntl, defineMessages } from '@alltrails/shared/react-intl';
import * as styles from './styles/styles.module.scss';

export type SocialCard = {
  handle: string;
  images: {
    avif: string;
    jpg: string;
    webp: string;
  };
};

const messages = defineMessages({
  altText: {
    defaultMessage: 'AllTrails users hitting the trails and finding their way outside'
  }
});

const SocialCard = ({ images, handle }: SocialCard) => {
  const intl = useIntl();

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <picture>
          <source srcSet={images.avif} type="image/avif" />
          <source srcSet={images.webp} type="image/webp" />
          <img className={styles.image} loading="lazy" src={images.jpg} alt={intl.formatMessage(messages.altText)} />
        </picture>
      </div>
      <div className={styles.handle}>{handle}</div>
    </div>
  );
};

export default SocialCard;
