import Bugsnag, { Client } from '@bugsnag/js';
import hasPermission from './hasPermission';

function startBugsnag(): Client | null {
  if (window?.Bugsnag) {
    return window.Bugsnag as Client;
  }

  // Bugsnag.start() will be called in all Rails server environments as long as NODE_ENV is set for production.
  if (process.env.NODE_ENV !== 'production') {
    console.warn('useBugsnag: not starting Bugsnag as this is not a NODE_ENV=production build');
    return null;
  }

  const store = global.__AT_DATA__;

  if (!store) {
    console.warn('useBugsnag: not starting Bugsnag as the global store is not defined');
    return null;
  }

  // user and metadata to be passed in for all JS errors globally
  // https://docs.bugsnag.com/platforms/javascript/customizing-error-reports/#global-metadata
  // https://docs.bugsnag.com/platforms/javascript/customizing-error-reports/#adding-user-data
  let userObj = null;
  const metadata = {
    is_pro_user: 'false',
    is_logged_in_user: 'false',
    is_admin_user: 'false'
  };

  if (store?.store?.user) {
    const storeUser = store.store.user;
    userObj = {
      id: storeUser.id.toString()
    };
    metadata.is_pro_user = storeUser.pro.toString();
    metadata.is_logged_in_user = 'true';
    metadata.is_admin_user = `${hasPermission({ permission: 'trails:manage' })}`;
  }

  Bugsnag.start({
    apiKey: store.BUGSNAG_KEY,
    appVersion: store.BUILD_DATE,
    releaseStage: store.RAILS_ENV,
    user: userObj,
    metadata
  });

  window.Bugsnag = Bugsnag;

  return Bugsnag;
}

function getBugsnag() {
  return window?.Bugsnag;
}

export { startBugsnag, getBugsnag };
