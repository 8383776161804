import { HAWAII_ID, UNITED_STATES_ID } from 'constants/CountryIDs';
import { LanguageSupportUtil } from '../language_support_util';

const isValidString = str => str && str.length > 0;

export const getLocationName = (result) => {
  const localizedAreaName = result.area_name;
  if (isValidString(localizedAreaName)) {
    return localizedAreaName;
  } else if (isValidString(result.park_name)) {
    return result.park_name;
  }
  const cityName = isValidString(result.city_name) ? result.city_name : '';
  const stateName = isValidString(result.state_name) ? result.state_name : '';
  const countryName =
    isValidString(result.country_name) &&
    result.country_id !== UNITED_STATES_ID &&
    result.country_id !== HAWAII_ID &&
    // Maps do not have a country_id attribute
    !result.country_name.includes('United States') &&
    !result.country_name.includes('Hawaii')
      ? result.country_name
      : '';
  return [cityName, stateName, countryName].filter(isValidString).join(', ');
};

export const getLocationSlug = result => {
  if (result.park_slug) {
    return `parks/${result.park_slug}`;
  }
  return result.area_slug || result.city_url || '';
};

/**
 * getTrailLocationUrl returns a localized url for a given trail.
 * @param {*} trail
 * @param {string} languageRegionCode
 * @returns string
 */
export const getTrailLocationUrl = (trail, languageRegionCode) => {
  const slug = getLocationSlug(trail);
  if (!slug) {
    return null;
  }
  let innerUrl = `/${slug}`;
  if (innerUrl.indexOf('?') < 0) {
    innerUrl = `${innerUrl}?ref=result-card`;
  }
  return LanguageSupportUtil.wrapUrlSafe(innerUrl, languageRegionCode);
};

/**
 * getLocationUrl returns a localized url for a given location and location type.
 * @param {string} locationString
 * @param {string} locationType
 * @param {string} languageRegionCode
 * @returns string
 */
export const getLocationUrl = (locationString, locationType, languageRegionCode) => {
  let locationPath = null;
  if (locationType === 'area') {
    locationPath = `/parks/${locationString}`;
  } else if (locationType === 'city') {
    locationPath = `/${locationString}`;
  }
  return LanguageSupportUtil.wrapUrlSafe(locationPath, languageRegionCode);
};

/**
 * getTrailLocationInfo returns a location and location type for a trail.
 * @param {*} trail
 * @returns [string, string]
 */
export const getTrailLocationInfo = trail => {
  let locationString = '';
  let locationType = '';
  if (trail.park_slug) {
    locationString = trail.park_slug;
    locationType = 'area';
  } else if (trail.area_slug) {
    locationString = trail.area_slug.replace(/^parks\//, '');
    locationType = 'area';
  } else if (trail.city_url) {
    locationString = trail.city_url;
    locationType = 'city';
  } else if (trail.area_id) {
    locationString = trail.area_id;
    locationType = 'area_id';
  } else if (trail.city_id) {
    locationString = trail.city_id;
    locationType = 'city_id';
  } else if (trail.state_id) {
    locationString = trail.state_id;
    locationType = 'state_id';
  }
  return [locationString, locationType];
};
