/**
 * Contains event to the confines of the component (preventing propagation and default behavior)
 * @param {*} eventHandler
 * @returns modified event handler
 */
const wrapEventHandler = eventHandler => {
  if (!eventHandler) {
    return undefined;
  }
  return e => {
    e.stopPropagation();
    e.preventDefault();
    return eventHandler(e);
  };
};

export { wrapEventHandler };
