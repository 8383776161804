import { ReactNode } from 'react';
import GlobalActions from 'components/GlobalActions';
import * as styles from './MinimalLayout.module.scss';
import MinimalHeader from '../MinimalHeader';

type Props = {
  children: ReactNode;
};

// search for "layout 'minimal'" in rb for backend configuration
function MinimalLayout({ children }: Props) {
  return (
    <div className={styles.minimal}>
      <GlobalActions />
      <MinimalHeader />
      {children}
    </div>
  );
}

export default MinimalLayout;
