import CustomProvider from 'components/CustomProvider';
import { defineMessages, FormattedMessage } from '@alltrails/shared/react-intl';
import Connect from '@alltrails/shared/icons/Connect';
import Add from '@alltrails/shared/icons/Add';
import Link from '@alltrails/shared/denali/components/Link';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';

import * as styles from './styles/styles.module.scss';

type Props = {
  newTrailPath: string;
  mobileBrowser: boolean;
};

const messages = defineMessages({
  FOLLOW_MEMBERS: {
    defaultMessage: 'Follow members'
  },
  ADD_NEW_TRAIL: {
    defaultMessage: 'Add new trail'
  }
});

const ProfileSidebar = ({ newTrailPath, mobileBrowser }: Props): JSX.Element => {
  const {
    formattedDefaultMessages: { FOLLOW_MEMBERS, ADD_NEW_TRAIL }
  } = useFormatMessage(messages);

  return (
    <CustomProvider>
      {!mobileBrowser && (
        <div>
          <div id="modalPortal" />
          <div className={styles.title}>
            <FormattedMessage defaultMessage="Contribute" />{' '}
          </div>
          <Link href={newTrailPath} size="md" icon={{ Component: Add }} testId="add-new-trail">
            {ADD_NEW_TRAIL}
          </Link>
        </div>
      )}
    </CustomProvider>
  );
};

// needed to bypass error related to translations
// Could not find required `intl` object. <IntlProvider> needs to exist in the component ancestry
export default function ProfileSidebarWrapper({ newTrailPath, mobileBrowser }: Props) {
  return (
    <CustomProvider>
      <ProfileSidebar newTrailPath={newTrailPath} mobileBrowser={mobileBrowser} />
    </CustomProvider>
  );
}
