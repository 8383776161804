// extracted by mini-css-extract-plugin
export var container = "styles-module__container___f009u";
export var heroImage = "styles-module__heroImage___YOF_w";
export var none = "styles-module__none____IMb4";
export var picture = "styles-module__picture___uW6p1";
export var progress = "styles-module__progress___KMojL";
export var slideButton = "styles-module__slideButton___y7KuR";
export var slideButtonSpan = "styles-module__slideButtonSpan___ajA3a";
export var slideButtonSpanActive = "styles-module__slideButtonSpanActive___VojvC";
export var slideButtonSpanComplete = "styles-module__slideButtonSpanComplete____xAdV";
export var slideButtons = "styles-module__slideButtons___olLwk";