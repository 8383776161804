import { defineMessages } from '@alltrails/shared/react-intl';
import Modal from 'components/Modal';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import Typography from '@alltrails/shared/denali/components/Typography';
import logGarminConnectModalTapped from '@alltrails/analytics/events/logGarminConnectModalTapped';
import GarminModalType from '@alltrails/analytics/enums/GarminModalType';
import GarminModalButtonTapped from '@alltrails/analytics/enums/GarminModalButtonTapped';
import garminAlltrailsPng from '../../../../assets/images/misc/garmin-alltrails.png';
import garminAlltrailsWebp from '../../../../assets/images/misc/garmin-alltrails.webp';
import garminAlltrailsAvif from '../../../../assets/images/misc/garmin-alltrails.avif';
import * as styles from './GarminInformationalModal.module.scss';

const messages = defineMessages({
  TITLE: { defaultMessage: 'Sync routes and activities seamlessly with Garmin' },
  SUBTITLE: {
    defaultMessage: 'Automatically pull completed activities from Garmin. With AllTrails+, you can also send routes to your Garmin device.'
  },
  CTA: { defaultMessage: 'Connect' },
  ALT: { defaultMessage: 'Garmin Connect + AllTrails logo' }
});

type Props = {
  isOpen: boolean;
  garminConnectUrl: string;
  onRequestClose: () => void;
};

const GarminInformationalModal = ({ isOpen, garminConnectUrl, onRequestClose }: Props) => {
  const {
    formattedDefaultMessages: { TITLE, SUBTITLE, CTA, ALT }
  } = useFormatMessage(messages);

  const handleConnectGarmin = () => {
    logGarminConnectModalTapped({ button_tapped: GarminModalButtonTapped.Connect, modal_type: GarminModalType.Connect });
    window.location.assign(garminConnectUrl);
  };

  return (
    <Modal
      baseCtas={{
        primaryCta: {
          onClick: handleConnectGarmin,
          text: CTA,
          testId: 'connect-garmin'
        }
      }}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <div className={styles.container}>
        <picture>
          <source srcSet={garminAlltrailsAvif} type="image/avif" />
          <source srcSet={garminAlltrailsWebp} type="image/webp" />
          <img className={styles.img} src={garminAlltrailsPng} loading="lazy" alt={ALT} />
        </picture>
        <Typography variant="heading400" className={styles.title}>
          {TITLE}
        </Typography>
        <Typography variant="paragraph200">{SUBTITLE}</Typography>
      </div>
    </Modal>
  );
};

export default GarminInformationalModal;
