import { FormattedMessage } from '@alltrails/shared/react-intl';
import Typography from '@alltrails/shared/denali/components/Typography';
import ProResponse from 'types/ProResponse';
import * as styles from './PaymentForm.module.scss';

type Props = {
  errors: ProResponse['errors'];
};

export default function ErrorText({ errors }: Props) {
  return (
    <div className={styles.error}>
      <Typography color="error" variant="text200">
        {errors.base ? (
          errors.base[0] // this is already localized
        ) : (
          <FormattedMessage defaultMessage="There was an error processing your payment. Please contact support." />
        )}
      </Typography>
    </div>
  );
}
