import { useCallback, useEffect, useState } from 'react';
import { useIntl } from '@alltrails/shared/react-intl';
import AlertDialog from '@alltrails/shared/denali/components/AlertDialog';
import Caution from '@alltrails/shared/icons/Caution';

const homePageDialogVariants = ['live-share-ended', 'live-share-expired'] as const;
type HomePageDialogVariant = typeof homePageDialogVariants[number];

const HomePageDialog = () => {
  const intl = useIntl();
  const [variant, setVariant] = useState<HomePageDialogVariant | null>(null);

  const closeDialog = useCallback(() => {
    setVariant(null);
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined' && window?.location?.search) {
      const params = new URLSearchParams(window.location.search);
      const dialog = params.get('dialog') as HomePageDialogVariant;
      if (dialog && homePageDialogVariants.includes(dialog)) {
        setVariant(dialog);
      }
    }
  }, []);

  switch (variant) {
    case 'live-share-ended':
      return (
        <AlertDialog
          body={intl.formatMessage({ defaultMessage: 'This member has stopped sharing their activity.' })}
          buttons={{
            primary: {
              onClick: closeDialog,
              testId: 'got-it',
              text: intl.formatMessage({ defaultMessage: 'Got it' })
            }
          }}
          icon={{ Component: Caution }}
          onRequestClose={closeDialog}
          size="md"
          title={intl.formatMessage({ defaultMessage: 'Live sharing ended' })}
        />
      );
    case 'live-share-expired':
      return (
        <AlertDialog
          body={intl.formatMessage({
            defaultMessage: 'Links are only good for 7 days. If the member is heading out soon, ask them to send you a new link.'
          })}
          buttons={{
            primary: {
              onClick: closeDialog,
              testId: 'got-it',
              text: intl.formatMessage({ defaultMessage: 'Got it' })
            }
          }}
          icon={{ Component: Caution }}
          onRequestClose={closeDialog}
          size="md"
          title={intl.formatMessage({ defaultMessage: 'Live share link has expired' })}
        />
      );
    default:
      return null;
  }
};

export default HomePageDialog;
