import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import useCurrencyFormatter from 'hooks/useCurrencyFormatter';
import { Key, ReactNode, useState } from 'react';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import PaymentFinePrint from 'components/PaymentFinePrint';
import WebProPurchaseLocation from '@alltrails/analytics/enums/WebProPurchaseLocation';
import CurrencyCode from 'types/CurrencyCode';
import Plan from 'types/Plan';
import Coupon from 'types/Coupon';
import Typography from '@alltrails/shared/denali/components/Typography';
import useToggle from '@alltrails/shared/hooks/useToggle';
import Chevron from '@alltrails/shared/icons/Chevron';
import CheckoutBase from 'components/CheckoutBase';
import getTrialDurationText from 'components/CheckoutBase/utils/getTrialDurationText';
import { getDiscountPercentage, getPriceTotal, isTrial } from 'utils/PlanUtils';
import badgeCheckSrc from 'components/icons/badge-check.svg';
import useUser from 'hooks/useUser';
import CountryCode from 'types/CountryCode';
import useIpCountryCode from 'hooks/useIpCountryCode';
import { handlePaymentFormSuccess } from 'utils/Payment';
import PaymentMethod from '@alltrails/analytics/enums/PaymentMethod';
import createSubscription from 'api/Pro';
import * as styles from './PlusCheckout.module.scss';

const br = () => <br />;

const features = [
  <FormattedMessage defaultMessage="Download offline maps" />,
  <FormattedMessage defaultMessage="Get alerts for wrong turns" />,
  <FormattedMessage defaultMessage="Watch immersive trail previews" />,
  <FormattedMessage defaultMessage="Print maps for backup" />,
  <FormattedMessage defaultMessage="Bring trails to life with 3D maps" />,
  <FormattedMessage defaultMessage="Explore ad free" />,
  <FormattedMessage defaultMessage="Give back with 1% for the Planet" />
];

// Desktop: https://www.figma.com/file/UsRZiiso2irMJYKykeo6xK/On-page-web-checkout?node-id=568-140368&t=lNEpGIMdlQlUuZg9-4
function Features() {
  const [isOpen, toggle] = useToggle();

  return (
    <div className={styles.features}>
      <ul className={styles.ul}>
        {features.slice(0, !isOpen ? 4 : features.length).map((feature, index) => (
          <li key={index as Key} className={styles.li}>
            <img alt="" src={badgeCheckSrc} />
            {feature}
          </li>
        ))}
      </ul>
      <button
        className={styles.more}
        onClick={() => {
          toggle();
        }}
        type="button"
      >
        <Chevron orientation={isOpen ? 'up' : 'down'} size="sm" />
        <Typography variant="text100">
          {isOpen ? <FormattedMessage defaultMessage="Show less" /> : <FormattedMessage defaultMessage="Show more" />}
        </Typography>
      </button>
    </div>
  );
}

function promotionFinePrint(couponCode: string) {
  if (couponCode?.startsWith('tmt24')) {
    return (
      <Typography color="secondary" variant="paragraph100" component="div">
        Limited-time offer; qualifying wireless plan required. See T-Mobile Tuesdays in T Life app for details.
      </Typography>
    );
  }

  return null;
}

type PlusCheckoutBaseProps = {
  billedHeading?: ReactNode;
  billedSubheading?: ReactNode;
  billedSubheadingRight?: ReactNode;
  billedToday: ReactNode;
  buttonText: ReactNode;
  className?: string;
  coupon?: Coupon;
  currencyCode: CurrencyCode;
  finePrint?: ReactNode;
  heading: ReactNode;
  plan: Plan;
  setCurrencyCode: (currencyCode: CurrencyCode) => void;
  setCoupon?: (arg0: Coupon) => void;
  setPlanToAnnual: () => void;
  subheading?: ReactNode;
  userId: number;
  setNonTrialPlan: (plan: Plan) => void;
  setPlan: (plan: Plan) => void;
  returnTo?: string | (() => string);
  multiyearConversion?: boolean;
};

function PlusCheckoutBase({
  buttonText,
  billedHeading,
  billedToday,
  billedSubheading,
  className,
  coupon,
  currencyCode,
  finePrint,
  heading,
  plan,
  subheading,
  userId,
  billedSubheadingRight,
  setCoupon,
  setPlanToAnnual,
  setCurrencyCode,
  setNonTrialPlan,
  setPlan,
  returnTo,
  multiyearConversion
}: PlusCheckoutBaseProps) {
  const languageRegionCode = useLanguageRegionCode();
  const user = useUser();
  const [billingCountry, setBillingCountry] = useState(useIpCountryCode());

  return (
    <CheckoutBase
      billedHeading={billedHeading}
      billedSubheading={billedSubheading}
      billedSubheadingRight={billedSubheadingRight}
      billedToday={billedToday}
      billingCountry={billingCountry}
      buttonText={buttonText}
      className={className}
      coupon={coupon}
      currencyCode={currencyCode}
      features={<Features />}
      finePrint={finePrint || <PaymentFinePrint coupon={coupon} currencyCode={currencyCode} isCouponApplied={Boolean(coupon)} plan={plan} />}
      promoText={promotionFinePrint(coupon?.code)}
      heading={heading}
      onBillingCountryChange={(value: CountryCode, response) => {
        setBillingCountry(value);
        setCurrencyCode(response.currencyCode);
        setNonTrialPlan(response.nonTrial);

        // similar logic in pro_controllers_helper.rb for initial load wonder if we can consolidate in one spot?
        if (!coupon && (!user || user.isPromoEligible)) {
          setPlan(response.trial);
        } else {
          setPlan(response.nonTrial);
        }
      }}
      onApplePayToken={async ({ apple_pay_token, recaptcha }) => {
        const body = {
          billing_country: billingCountry,
          price_code: plan.priceCode,
          ...(multiyearConversion && { multiyear_conversion: true }),
          apple_pay_token,
          recaptcha,
          uid: userId,
          user: {
            user_entered_coupon_code: coupon ? coupon.code : null
          }
        };

        const response = await createSubscription(body);

        if (response?.errors) {
          throw response.errors;
        } else {
          handlePaymentFormSuccess(
            userId,
            currencyCode,
            coupon,
            plan,
            languageRegionCode,
            coupon?.code,
            WebProPurchaseLocation.ProPage,
            PaymentMethod.ApplePay,
            response?.invoice_id
          );
        }
      }}
      onGooglePayToken={async ({ google_pay_token, recaptcha }) => {
        const body = {
          billing_country: billingCountry,
          price_code: plan.priceCode,
          ...(multiyearConversion && { multiyear_conversion: true }),
          google_pay_token,
          recaptcha,
          uid: userId,
          user: {
            user_entered_coupon_code: coupon ? coupon.code : null
          }
        };

        const response = await createSubscription(body);

        if (response?.errors) {
          throw response.errors;
        } else {
          handlePaymentFormSuccess(
            userId,
            currencyCode,
            coupon,
            plan,
            languageRegionCode,
            coupon?.code,
            WebProPurchaseLocation.ProPage,
            PaymentMethod.GooglePay,
            response?.invoice_id
          );
        }
      }}
      onPayPalToken={async ({ paypal_token, recaptcha }) => {
        const body = {
          billing_country: billingCountry,
          price_code: plan.priceCode,
          ...(multiyearConversion && { multiyear_conversion: true }),
          paypal_token,
          recaptcha,
          uid: userId,
          user: {
            user_entered_coupon_code: coupon ? coupon.code : null
          }
        };

        const response = await createSubscription(body);

        if (response?.errors) {
          throw response.errors;
        } else {
          handlePaymentFormSuccess(
            userId,
            currencyCode,
            coupon,
            plan,
            languageRegionCode,
            coupon?.code,
            WebProPurchaseLocation.ProPage,
            PaymentMethod.Paypal,
            response?.invoice_id
          );
        }
      }}
      onSubmitTokenizedCardForm={async payload => {
        const response = await createSubscription({
          billing_country: billingCountry,
          ...(multiyearConversion && { multiyear_conversion: true }),
          price_code: plan.priceCode,
          recaptcha: payload.recaptcha,
          cc_token: payload.cc_token,
          uid: userId,
          user: {
            user_entered_coupon_code: coupon ? coupon.code : null
          }
        });

        if (response?.errors) {
          throw response.errors;
        } else {
          handlePaymentFormSuccess(
            userId,
            currencyCode,
            coupon,
            plan,
            languageRegionCode,
            coupon?.code,
            WebProPurchaseLocation.ProPage,
            PaymentMethod.CreditCard,
            response?.invoice_id,
            returnTo
          );
        }
      }}
      plan={plan}
      setCoupon={setCoupon}
      setPlanToAnnual={setPlanToAnnual}
      subheading={subheading}
      webProPurchaseLocation={WebProPurchaseLocation.ProPage}
    />
  );
}

type CheckoutProps = {
  className?: string;
  coupon?: Coupon;
  currencyCode: CurrencyCode;
  initialNonTrialPlan: Plan;
  initialPlan: Plan;
  returnTo?: string | (() => string);
  userId: number;
  whenExpired?: string;
};

export default function PlusCheckout({
  className,
  coupon: initialCoupon,
  currencyCode: initialCurrencyCode,
  initialNonTrialPlan,
  initialPlan,
  returnTo,
  userId,
  whenExpired
}: CheckoutProps) {
  const languageRegionCode = useLanguageRegionCode();
  const [currencyCode, setCurrencyCode] = useState(initialCurrencyCode);
  const [nonTrialPlan, setNonTrialPlan] = useState(initialNonTrialPlan);

  const formatter = useCurrencyFormatter(currencyCode);

  const [coupon, setCoupon] = useState<Coupon>(initialCoupon);
  const [plan, setPlan] = useState(initialPlan);

  function setPlanToAnnual() {
    setPlan(nonTrialPlan);
  }

  const annualCost = formatter.format(Number(plan.priceTotal));

  const params = new URLSearchParams(__AT_DATA__.search);
  const reactivation = params.has('reactivation');

  // https://www.figma.com/file/LH2apabSVbXpCCxd25Xis3/P_2023_Pre-Churn%E2%80%93Multi-Year?type=design&node-id=638-8077&t=9qwfzNPCT8ihgDIH-4
  if (params.has('multiyear_conversion')) {
    const date = new Date(whenExpired);
    const monthDayFormatter = new Intl.DateTimeFormat(languageRegionCode, { day: 'numeric', month: 'long' });

    return (
      <PlusCheckoutBase
        billedHeading={<FormattedMessage defaultMessage="Billed on {date}" values={{ date: monthDayFormatter.format(date) }} />}
        billedToday={annualCost}
        buttonText={<FormattedMessage defaultMessage="Get AllTrails+" />}
        className={className}
        currencyCode={currencyCode}
        heading={<FormattedMessage defaultMessage="Renew your membership" />}
        multiyearConversion
        plan={plan}
        returnTo={returnTo}
        setCurrencyCode={setCurrencyCode}
        setNonTrialPlan={setNonTrialPlan}
        setPlan={setPlan}
        setPlanToAnnual={setPlanToAnnual}
        userId={userId}
        finePrint={
          <Typography color="secondary" variant="paragraph100">
            <FormattedMessage
              defaultMessage="You will be charged {cost} on {date, date, long} and it will automatically renew after {months} months unless you update your renewal settings."
              values={{ cost: annualCost, date, months: plan.numberOfMonths }}
            />
          </Typography>
        }
      />
    );
  }

  // Mobile: https://app.zeplin.io/project/627c18d6c6189e2a52ce6566/screen/627c24f591c20f2d3669a8ea
  // Desktop: https://www.figma.com/file/vJ30CAAm8qdbDO0j5C4Ce7/Web-Pro-Purchase-Flow?node-id=685%3A35997
  // Desktop: https://app.zeplin.io/project/627c18d6c6189e2a52ce6566/screen/627c238b04a41125e91d0422

  if (coupon && coupon.type !== 'FreeTrialCoupon') {
    const percent = getDiscountPercentage(plan, coupon) / 100;

    return (
      <PlusCheckoutBase
        billedSubheading={<FormattedMessage defaultMessage="Discount" />}
        billedSubheadingRight={<FormattedMessage defaultMessage="Save {percent, number, ::percent}" values={{ percent }} />}
        billedToday={
          <>
            <s className={styles.s}>{formatter.format(Number(plan.priceTotal))}</s> {formatter.format(getPriceTotal(plan, coupon))}
          </>
        }
        buttonText={<FormattedMessage defaultMessage="Save today" />}
        className={className}
        coupon={coupon}
        currencyCode={currencyCode}
        heading={
          reactivation ? (
            <FormattedMessage defaultMessage="Reactivate and save" />
          ) : (
            <FormattedMessage defaultMessage="Save {percent, number, ::percent}<br></br> on AllTrails+" values={{ br, percent }} />
          )
        }
        plan={plan} // this is always non trial so we could always pass nonTrial
        returnTo={returnTo}
        setCoupon={setCoupon}
        setCurrencyCode={setCurrencyCode}
        setNonTrialPlan={setNonTrialPlan}
        setPlan={setPlan}
        setPlanToAnnual={setPlanToAnnual}
        userId={userId}
      />
    );
  }

  // isPromoEligible
  if (isTrial(plan, coupon)) {
    const trialDurationText = getTrialDurationText(coupon?.freeTrialDuration);

    return (
      <PlusCheckoutBase
        billedSubheading={<FormattedMessage defaultMessage="Billed after trial" />}
        billedSubheadingRight={<FormattedMessage defaultMessage="{annualCost} annually" values={{ annualCost }} />}
        billedToday={formatter.format(0)}
        buttonText={<FormattedMessage defaultMessage="Start your free trial" />}
        className={className}
        coupon={coupon}
        currencyCode={currencyCode}
        heading={trialDurationText}
        plan={plan}
        returnTo={returnTo}
        setCoupon={setCoupon}
        setCurrencyCode={setCurrencyCode}
        setNonTrialPlan={setNonTrialPlan}
        setPlan={setPlan}
        setPlanToAnnual={setPlanToAnnual}
        subheading={
          <FormattedMessage defaultMessage="Then just {annualCost} a year after your trial. No commitment, cancel anytime." values={{ annualCost }} />
        }
        userId={userId}
      />
    );
  }

  // Desktop: https://app.zeplin.io/project/627c18d6c6189e2a52ce6566/screen/627c23891dd05a2a732656a0
  // Mobile: https://app.zeplin.io/project/627c18d6c6189e2a52ce6566/screen/627c24a858c4c42d0bd73f80
  return (
    <PlusCheckoutBase
      billedToday={annualCost}
      buttonText={<FormattedMessage defaultMessage="Get AllTrails+" />}
      className={className}
      currencyCode={currencyCode}
      heading={
        reactivation ? (
          <FormattedMessage defaultMessage="Reactivate AllTrails+" />
        ) : (
          <FormattedMessage defaultMessage="Go further with<br></br> AllTrails+" values={{ br }} />
        )
      }
      plan={plan} // this is always non trial so we could always pass nonTrial
      returnTo={returnTo}
      setCoupon={setCoupon}
      setCurrencyCode={setCurrencyCode}
      setNonTrialPlan={setNonTrialPlan}
      setPlan={setPlan}
      setPlanToAnnual={setPlanToAnnual}
      userId={userId}
    />
  );
}
