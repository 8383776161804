import { ComponentProps, useContext } from 'react';
import TrailCardV1 from '@alltrails/shared/components/TrailCard';
import TrailStandardCard from '@alltrails/shared/components/TrailStandardCard';
import logTrailCardAddToListButtonTapped from '@alltrails/analytics/events/logTrailCardAddToListButtonTapped';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import type Trail from 'types/Trails/Trail';
import useActivityDuration from 'hooks/useActivityDuration';
import { getTrailForTrailCard } from 'utils/cardInfo';
import logTrailCardClicked from '@alltrails/analytics/events/logTrailCardClicked';
import TrailCardLocation from '@alltrails/analytics/enums/TrailCardLocation';
import { PageContext, PageSource } from 'components/shared/Page/Page';
import useDisplayMetric from 'hooks/useDisplayMetric';
import CardLocation from '@alltrails/analytics/enums/CardLocation';
import { RiverAnalyticsData } from '@alltrails/modules/Lists/listAmplitudeHelpers';

function getTrailCardLocationFromPageSource(pageSource: PageSource): TrailCardLocation | null {
  switch (pageSource) {
    case PageSource.Explore:
      return TrailCardLocation.ExploreMapView;
    case PageSource.Seo:
      return TrailCardLocation.Seo;
    default:
      return null;
  }
}

type TrailCardProps = {
  variant?: 'standard' | 'wide';
  trail: Trail;
  handleCardClick?: () => void;
  handleFavoriteClick: () => void;
  isFavorite?: boolean;
  isCompleted?: boolean;
  isVerified?: boolean;
  messagingChannel?: {
    publish: (event: string, track: Trail) => void;
  };
  allowItemMoving?: boolean;
  dragHandleProps?: { [key: string]: any };
  allowItemDelete?: boolean;
  handleItemDeleteClick?: () => void;
  itemDeleteLabel?: string;
  displayRank?: number;
  className?: string;
  trailUrl?: string;
  maxPhotos?: number;
  handleClickAnalytics?: () => void;
  allowClickingCompletedBadge?: boolean;
  handleCompletedBadgeClick?: () => void;
  riverAnalyticsData?: RiverAnalyticsData;
  cardIndex?: number;
  updateCurrentlyHoveredResult?: (trail: Trail) => void;
  isNewMapsPage?: boolean;
};

function useCommonTrailCardProps({
  trail,
  handleCardClick,
  handleFavoriteClick,
  isFavorite,
  isCompleted,
  isVerified,
  messagingChannel,
  allowItemMoving,
  dragHandleProps,
  allowItemDelete,
  handleItemDeleteClick,
  itemDeleteLabel,
  displayRank,
  trailUrl,
  maxPhotos,
  handleClickAnalytics,
  allowClickingCompletedBadge,
  handleCompletedBadgeClick,
  riverAnalyticsData,
  cardIndex,
  updateCurrentlyHoveredResult,
  isNewMapsPage
}: ComponentProps<typeof TrailCard>) {
  const displayMetric = useDisplayMetric();
  const languageRegionCode = useLanguageRegionCode();
  const minutes = useActivityDuration(trail);
  const {
    globalState: { pageSource }
  } = useContext(PageContext);

  const deleteButton =
    allowItemDelete && handleItemDeleteClick && itemDeleteLabel
      ? {
          title: itemDeleteLabel,
          onClick: handleItemDeleteClick
        }
      : undefined;

  const handleTrailClick = () => {
    updateCurrentlyHoveredResult?.(undefined);
    if (pageSource === PageSource.Home) {
      logTrailCardClicked({
        detailed_card_location: CardLocation.WebHomepageRiver,
        river_filter_set: JSON.stringify(riverAnalyticsData.filters),
        river_index: riverAnalyticsData.riverIndex,
        river_item_index: cardIndex,
        river_section_type: riverAnalyticsData.riverSectionType,
        river_title_filter: riverAnalyticsData.riverTitleFilter,
        trail_id: String(trail.trail_id)
      });
    } else if (!isNewMapsPage) {
      logTrailCardClicked({ trail_id: String(trail.trail_id), card_location: getTrailCardLocationFromPageSource(pageSource) });
    }

    if (handleClickAnalytics) {
      handleClickAnalytics();
    }
    if (handleCardClick) {
      handleCardClick();
    }
  };

  const onFavoriteClick = () => {
    logTrailCardAddToListButtonTapped({ trail_id: trail.ID });
    handleFavoriteClick();
  };

  const handleMouseEnter = () => {
    messagingChannel?.publish?.('item.mouseenter', trail);
    updateCurrentlyHoveredResult?.(trail);
  };

  const handleMouseLeave = () => {
    messagingChannel?.publish?.('item.mouseleave', trail);
    updateCurrentlyHoveredResult?.(undefined);
  };

  return {
    languageRegionCode,
    onCardClick: handleTrailClick,
    onCardMouseEnter: handleMouseEnter,
    onCardMouseLeave: handleMouseLeave,
    onFavoriteClick,
    isFavorite,
    isCompleted,
    isVerified,
    trail: getTrailForTrailCard(trail, minutes),
    url: trailUrl,
    blockNavigation: Boolean(handleCardClick), // Navigation is handled by the click handler - this is existing behavior in TrailResultCard :(
    useMetric: displayMetric,
    maxPhotos: maxPhotos || trail.num_photos,
    dragHandleProps: allowItemMoving ? dragHandleProps : undefined,
    deleteButton,
    displayRank,
    onCompletionBadgeClick: allowClickingCompletedBadge ? handleCompletedBadgeClick : undefined,
    lazyLoadImages: true
  };
}

export default function TrailCard({ variant = 'standard', className, ...trailCardProps }: TrailCardProps) {
  const commonProps = useCommonTrailCardProps(trailCardProps);

  const card = <TrailCardV1 variant={variant} {...commonProps} />;

  return className ? <div className={className}>{card}</div> : card;
}

export function TrailCardV2({
  isCompletionBadgeAutoExpanded,
  isPhotoZoomEnabled,
  className,
  ...trailCardProps
}: TrailCardProps & Pick<ComponentProps<typeof TrailStandardCard>, 'isPhotoZoomEnabled' | 'isCompletionBadgeAutoExpanded'>) {
  const commonProps = useCommonTrailCardProps(trailCardProps);

  const card = (
    <TrailStandardCard isPhotoZoomEnabled={isPhotoZoomEnabled} isCompletionBadgeAutoExpanded={isCompletionBadgeAutoExpanded} {...commonProps} />
  );

  return className ? <div className={className}>{card}</div> : card;
}
