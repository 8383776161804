import { useEffect, useState } from 'react';

function QuoteRotator({ quotes }) {
  const [currentQuote, setCurrentQuote] = useState(0);

  useEffect(() => {
    const id = setInterval(() => {
      setCurrentQuote((currentQuote + 1) % quotes.length);
    }, 7000);
    return () => clearInterval(id);
  }, [currentQuote, quotes.length]);

  const quote = quotes[currentQuote];
  return (
    <blockquote>
      {quote.text}
      <cite>&mdash; {quote.cite}</cite>
    </blockquote>
  );
}

export default QuoteRotator;
