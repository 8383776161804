// extracted by mini-css-extract-plugin
export var atLogo = "InstallBanner-module__atLogo___pHyTt";
export var background = "InstallBanner-module__background___SmIH5";
export var browserImageContainer = "InstallBanner-module__browserImageContainer___LsXF5";
export var browserLogo = "InstallBanner-module__browserLogo___h0gwG";
export var button = "InstallBanner-module__button___TMFWU";
export var close = "InstallBanner-module__close___hFxFS";
export var container = "InstallBanner-module__container___OjaC0";
export var contentContainer = "InstallBanner-module__contentContainer___nxEp5";
export var divider = "InstallBanner-module__divider___o7ho5";
export var nameAndRatingContainer = "InstallBanner-module__nameAndRatingContainer___qPEQw";
export var noPointer = "InstallBanner-module__noPointer___FnDlf";
export var optionContainer = "InstallBanner-module__optionContainer___Waowc";
export var optionInfo = "InstallBanner-module__optionInfo___Mg4vH";
export var ratingContainer = "InstallBanner-module__ratingContainer___XNNcZ";
export var ratingNumber = "InstallBanner-module__ratingNumber___woVyM";
export var slideIn = "InstallBanner-module__slideIn___uG9C1";
export var variantContainer = "InstallBanner-module__variantContainer___Of6kg";