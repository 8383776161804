import { useReducer } from 'react';

// THIS HOOK ALLOWS useReducer to dispatch using thunks. Super cool
// because we are now able to share 'ducks' even if redux is not involved 
const useThunkReducer = (reducer, reducerName, initialState, additionalState = {}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const customGetState = () => {
    return {
      [reducerName]: state,
      ...additionalState
    };
  };

  const customDispatch = action => {
    if (typeof action === 'function') {
      action(customDispatch, customGetState);
    } else {
      dispatch(action);
    }
  };

  return [state, customDispatch];
};

export default useThunkReducer;
