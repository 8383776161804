import Logo from '@alltrails/shared/denali/components/Logo';
import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import useUser from 'hooks/useUser';
import * as styles from './MinimalHeader.module.scss';

export default function MinimalHeader() {
  const languageRegionCode = useLanguageRegionCode();
  const user = useUser();

  return (
    <header className={styles.header}>
      <a href={wrapUrlSafe('/?ref=header', languageRegionCode)}>
        <Logo size="sm" color="dark" variant={user?.pro ? 'plus-logomark' : 'logomark'} />
      </a>
    </header>
  );
}
