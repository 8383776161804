import { createSlice } from '@reduxjs/toolkit';

const formAuthenticityTokenSlice = createSlice({
  name: 'formAuthenticityToken',
  initialState: null,
  reducers: {
    setFormAuthenticityToken(state, action) {
      return action.payload;
    }
  }
});

export const { setFormAuthenticityToken } = formAuthenticityTokenSlice.actions;

export default formAuthenticityTokenSlice.reducer;
