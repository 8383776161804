import { useEffect } from 'react';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import Coupon from 'types/Coupon';
import Plan from 'types/Plan';
import logError from 'utils/logError';
import { execute } from 'utils/recaptcha_helpers';
import ProResponse from 'types/ProResponse';
import { getPriceTotal } from 'utils/PlanUtils';
import payPalLogoSrc from './paypal-logo.svg';
import * as styles from './PayPalButton.module.scss';
import { PaymentDisplayText } from '../../Constants';

type Props = {
  coupon?: Coupon;
  onPayPalToken: ({ paypal_token, recaptcha }: { paypal_token: string; recaptcha: string }) => void;
  plan: Plan;
  setErrors: (errors: ProResponse['errors']) => void;
  submitting: boolean;
  setSubmitting: (submitting: boolean) => void;
  showContinueWith?: boolean;
};

// Desktop: https://app.zeplin.io/project/627c18d6c6189e2a52ce6566/screen/627c1bcf26ba802db57c180b
// Mobile: https://app.zeplin.io/project/627c18d6c6189e2a52ce6566/screen/627c241f824169281f996dc1
export default function PayPalButton({ coupon, onPayPalToken, plan, setErrors, submitting, setSubmitting, showContinueWith }: Props) {
  useEffect(() => {
    recurly.configure(__AT_DATA__.recurlyPublicKey);
  }, []);

  function handleClick() {
    const payPal = recurly.PayPal({
      display: {
        displayName: PaymentDisplayText,
        // @ts-ignore - this is valid https://github.com/recurly/recurly-js/blob/4d702a0e6b514441cb3a3b41d528e214ab39df29/lib/recurly/paypal/strategy/direct.js#L16
        amount: String(getPriceTotal(plan, coupon))
      }
    });

    payPal.on('error', error => {
      logError(error);
    });

    payPal.on('token', async token => {
      try {
        setSubmitting(true);

        const recaptcha = await execute('userPaymentAction');

        await onPayPalToken({ paypal_token: token.id, recaptcha });
      } catch (e) {
        setErrors(e);
        setSubmitting(false);
      }
    });

    payPal.start();
  }

  return (
    <button className={styles.button} disabled={submitting} onClick={handleClick} type="button">
      {showContinueWith && (
        <span className={styles.text}>
          <FormattedMessage defaultMessage="Continue with" />
          &nbsp;
        </span>
      )}
      <span className={styles.logo}>
        <img alt="" src={payPalLogoSrc} />
      </span>
    </button>
  );
}
