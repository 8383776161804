import { FilterConfigRange, FilterConfigSlider, FilterRangeUpdates, FilterSliderUpdates } from 'types/Search';

type StepValue = number[];

export const calculateStep = (index: number, stepIncreaseAt: StepValue, stepIncreaseBy: StepValue): number => {
  if (stepIncreaseAt.length <= 1) {
    return index * (stepIncreaseBy[0] || 1);
  }

  // Figure out the largest possible value from the previous increaseAt step.
  const previousGroupMax = calculateStep(stepIncreaseAt[stepIncreaseAt.length - 1], stepIncreaseAt.slice(0, -1), stepIncreaseBy.slice(0, -1));

  const currentIncreaseAt = stepIncreaseAt[stepIncreaseAt.length - 1];
  const currentIncreaseBy = stepIncreaseBy[stepIncreaseAt.length - 1];

  if (currentIncreaseAt === 0) {
    return index * currentIncreaseBy;
  }

  return previousGroupMax + (index - currentIncreaseAt) * currentIncreaseBy;
};

export const convertIndexToStep = ({
  index,
  stepIncreaseAt,
  stepIncreaseBy
}: {
  index: number;
  stepIncreaseAt: StepValue;
  stepIncreaseBy: StepValue;
}) => {
  // Figure out which graduated step this would fall into.
  let maxIndex = [...stepIncreaseAt].reverse().findIndex(i => index >= i);
  maxIndex = maxIndex === -1 ? stepIncreaseAt.length : stepIncreaseAt.length - maxIndex;

  return calculateStep(index, stepIncreaseAt.slice(0, maxIndex), stepIncreaseBy.slice(0, maxIndex));
};

export const convertValueToIndex = ({
  value,
  multiplier,
  stepIncreaseAt,
  stepIncreaseBy
}: {
  value: number;
  multiplier: number;
  stepIncreaseAt: StepValue;
  stepIncreaseBy: StepValue;
}) => {
  let last = 0;
  let index = 0;

  // For example; convert miles or kilometers to ft and meters, our basic unit when serializing.
  // Rounding between serialized values is not ideal, but our UI _should_ always use whole
  // numbers with the user so a loss of precision around floats should not be noticeable.
  const normalizedValue = Math.round(value / multiplier);

  // Find the smallest step increase that fits this value.
  for (index = 0; index < stepIncreaseAt.length - 1; index++) {
    const currentIncreaseAt = stepIncreaseAt[index];
    const currentIncreaseBy = stepIncreaseBy[index];

    let next;
    if (currentIncreaseAt === 0) {
      next = stepIncreaseAt[index + 1] * (currentIncreaseBy || 1);
    } else {
      next = (stepIncreaseAt[index + 1] - currentIncreaseAt) * currentIncreaseBy;
    }

    // We've found a specific increaseAt that fits this value.
    if (last + next > normalizedValue) {
      break;
    }

    last += next;
  }
  
  return Math.round((normalizedValue - last) / stepIncreaseBy[index] + stepIncreaseAt[index]);
};

export const convertIndexToValue = ({
  index,
  stepIncreaseAt,
  stepIncreaseBy,
  multiplier
}: {
  index: number;
  stepIncreaseAt: StepValue;
  stepIncreaseBy: StepValue;
  multiplier: number;
}) => convertIndexToStep({ index, stepIncreaseAt, stepIncreaseBy }) * multiplier;

export const formattedStepForIndex = ({
  index,
  upperBound,
  stepIncreaseAt,
  stepIncreaseBy
}: {
  index: number;
  upperBound: number;
  stepIncreaseAt: StepValue;
  stepIncreaseBy: StepValue;
}) => {
  const step = convertIndexToStep({ index, stepIncreaseAt, stepIncreaseBy }).toLocaleString();

  const maxValueSuffix = index === upperBound ? '+' : '';

  return `${step}${maxValueSuffix}`;
};

export const getRangeValues = (indexes: number[], config: FilterConfigRange): FilterRangeUpdates => ({
  convertedRange: indexes.map((index, sequenceNumber) => {
    const isUpperBound = sequenceNumber === indexes.length - 1 && index === config.upperBound;
    if (isUpperBound) {
      return -1;
    }

    return convertIndexToValue({
      index,
      stepIncreaseAt: config.stepIncreaseAt,
      stepIncreaseBy: config.stepIncreaseBy,
      multiplier: config.multiplier
    });
  }),
  displayRange: [...indexes],
  formattedRange: indexes.map(index =>
    formattedStepForIndex({
      index,
      upperBound: config.upperBound,
      stepIncreaseAt: config.stepIncreaseAt,
      stepIncreaseBy: config.stepIncreaseBy
    })
  )
});

export const getSliderValues = (index: number, config: FilterConfigSlider): FilterSliderUpdates => {
  const r = getRangeValues([index], {
    ...config,
    value: [config.value],
    displayValue: [config.displayValue],
    formattedRange: [config.formattedRange]
  });

  return {
    convertedRange: r.convertedRange[0],
    displayRange: r.displayRange[0],
    formattedRange: r.formattedRange[0]
  };
};
