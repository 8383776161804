import { appleConstants } from '../Constants';

export default function useAppleHandler() {
  // Note: This handler works differently than the others, where the user is redirected to
  // an Apple login page and then redirected back to AllTrails.
  // Because of this, there is a tracking callback implemented in useAppleRedirectHandler
  // that tracks that the signup/login redirect was successful.
  const appleHandler = ({ redirectTo, referralLink }: { redirectTo?: string; referralLink?: string } = {}) => {
    const redirectURI = `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ''
    }/users/auth/apple/callback`;

    const params = new URLSearchParams();
    params.set('client_id', appleConstants.CLIENT_ID);
    params.set('redirect_uri', redirectURI);
    params.set('scope', appleConstants.SCOPES);

    if (redirectTo || referralLink) {
      params.set('state', JSON.stringify({ ...(referralLink && { referral_code: referralLink }), ...(redirectTo && { redirect_to: redirectTo }) }));
    }

    const authUrl = `${appleConstants.AUTHORIZE_URL + appleConstants.AUTHORIZE_PARAMS}&${params}`;

    window.location.href = authUrl;
  };

  return appleHandler;
}
