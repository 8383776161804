import { get } from '@alltrails/shared/api';
import CountryCode from 'types/CountryCode';
import { components } from './schema';

type AvailablePlansResponse = components['schemas']['AvailablePlansResponse'];

// eslint-disable-next-line import/prefer-default-export
export async function getAvailablePlans(billingCountry: CountryCode) {
  const params = new URLSearchParams();
  params.set('billing_country', billingCountry);

  return get<AvailablePlansResponse>(`/api/alltrails/purchases/available_plans?${params.toString()}`);
}
