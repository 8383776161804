// extracted by mini-css-extract-plugin
export var bold = "GiftCardPrint-module__bold___cmiPz";
export var card = "GiftCardPrint-module__card___fchPZ";
export var cardAndData = "GiftCardPrint-module__cardAndData___HqLNi";
export var cardContainer = "GiftCardPrint-module__cardContainer___igC5p";
export var cardMeta = "GiftCardPrint-module__cardMeta___oE9ha";
export var desktop = "GiftCardPrint-module__desktop___cDdP8";
export var giftCardPrint = "GiftCardPrint-module__giftCardPrint___FRbTZ";
export var giftMessage = "GiftCardPrint-module__giftMessage___lGAaT";
export var inMobile = "GiftCardPrint-module__inMobile___QcYnj";
export var metadata = "GiftCardPrint-module__metadata___lJaSg";
export var planType = "GiftCardPrint-module__planType___G8GF6";
export var printButton = "GiftCardPrint-module__printButton___uEr2m";
export var printContainer = "GiftCardPrint-module__printContainer___qE6z3";
export var redemptionCode = "GiftCardPrint-module__redemptionCode___nOyyD";
export var senderRecipient = "GiftCardPrint-module__senderRecipient___k3_Tp";
export var subText = "GiftCardPrint-module__subText___TwpkR";
export var subTextContainer = "GiftCardPrint-module__subTextContainer___Avv0g";
export var yourGiftCard = "GiftCardPrint-module__yourGiftCard___ZjmcY";