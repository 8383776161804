import Button from '@alltrails/shared/denali/components/Button';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import { wrapUrlSafe } from 'utils/language_support_util';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import * as styles from './GiftTray.module.scss';
import BottomTray from '../BottomTray';

type Props = {
  closeTray: () => void;
};

const GiftTray = ({ closeTray }: Props) => {
  const languageRegionCode = useLanguageRegionCode();

  return (
    <BottomTray closeTray={closeTray}>
      <div className={styles.container}>
        <div className={styles.trayContent}>
          <div className={styles.spacer} />
          <div className={styles.buttonContainer}>
            <div className={styles.headline}>
              <FormattedMessage defaultMessage="Choose a checkout method" />
            </div>
            <div className={styles.loginButton}>
              <Button
                linkInfo={{ href: wrapUrlSafe('/login', languageRegionCode), useReactRouter: true }}
                testId="gift-tray-login"
                text={<FormattedMessage defaultMessage="Log in" />}
                variant="primary"
                width="full"
              />
            </div>
            <Button
              allowWrap
              linkInfo={{ href: wrapUrlSafe('/gift/payment', languageRegionCode), useReactRouter: true }}
              testId="gift-tray-checkout-as-guest"
              text={<FormattedMessage defaultMessage="Checkout as guest" />}
              variant="default"
              width="full"
            />
          </div>
        </div>
      </div>
    </BottomTray>
  );
};

export default GiftTray;
