import { string, arrayOf } from 'prop-types';
import classNames from 'classnames';
import * as styles from './styles/styles.module.scss';

const PrivacyInformationBlock = ({ title, descriptions, className }) => {
  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.title}>{title}</div>
      {descriptions.map(description => {
        return (
          <p key={description} className={styles.description}>
            {description}
          </p>
        );
      })}
    </div>
  );
};

PrivacyInformationBlock.propTypes = {
  title: string.isRequired,
  descriptions: arrayOf(string),
  className: string
};

PrivacyInformationBlock.defaultProps = {
  descriptions: [],
  className: ''
};

export default PrivacyInformationBlock;
