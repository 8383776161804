import Link from '@alltrails/shared/denali/components/Link';
import Button from '@alltrails/shared/denali/components/Button';
import Typography from '@alltrails/shared/denali/components/Typography';
import TermsOfService from 'components/TermsOfService';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import { wrapUrlSafe } from 'utils/language_support_util';
import AltLoginsForm from 'components/AltLoginsForm';
import type { User } from 'types/User';
import EmailPasswordLogin from 'components/EmailPasswordLogin';
import { GoogleLoginResponse } from 'react-google-login';
import LoginResponse from 'types/LoginResponse';
import logLogInScreenContinueButtonTapped from '@alltrails/analytics/events/logLogInScreenContinueButtonTapped';
import SignUpScreenContinueButtonType from '@alltrails/analytics/enums/SignUpScreenContinueButtonType';
import logLogInScreenForgotPasswordTapped from '@alltrails/analytics/events/logLogInScreenForgotPasswordTapped';
import useGoogleHandler from 'hooks/useGoogleHandler';
import useFacebookHandler from 'hooks/useFacebookHandler';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import useAppleHandler from 'hooks/useAppleHandler';
import * as styles from './LoginForm.module.scss';

type Props = {
  handleLoginSuccess?: ({ user }: { user: User }) => void;
  returnTo?: string; // url
  useReactRouter?: boolean;
};

function LoginForm({ handleLoginSuccess, returnTo, useReactRouter }: Props) {
  const appleHandler = useAppleHandler();
  const facebookHandler = useFacebookHandler();
  const googleHandler = useGoogleHandler();
  const languageRegionCode = useLanguageRegionCode();

  const loginSuccessCallback = (data: LoginResponse) => {
    if (handleLoginSuccess) {
      return handleLoginSuccess(data);
    }

    const destinationUrl = returnTo || wrapUrlSafe('/', languageRegionCode);
    window.location.href = data.welcome_notice
      ? `${destinationUrl + (destinationUrl.includes('?') ? '&' : '?')}welcome=${data.welcome_notice}`
      : destinationUrl;
    return undefined;
  };

  const handleGoogle = async (response: GoogleLoginResponse) => {
    logLogInScreenContinueButtonTapped({ login_screen_continue_button_type: SignUpScreenContinueButtonType.Google });
    loginSuccessCallback(await googleHandler(response, 'login'));
  };

  const handleFacebook = async (response: ReactFacebookLoginInfo) => {
    logLogInScreenContinueButtonTapped({ login_screen_continue_button_type: SignUpScreenContinueButtonType.Facebook });
    loginSuccessCallback(await facebookHandler(response, 'login'));
  };

  const handleApple = () => {
    logLogInScreenContinueButtonTapped({ login_screen_continue_button_type: SignUpScreenContinueButtonType.Apple });
    appleHandler({ redirectTo: returnTo });
  };

  return (
    <div className={styles.content}>
      <Typography className={styles.heading} component="h1" variant="heading400">
        <FormattedMessage defaultMessage="Welcome back." />
        <br />
        <FormattedMessage defaultMessage="Log in and start exploring." />
      </Typography>
      <div>
        <EmailPasswordLogin handleLoginSuccess={loginSuccessCallback} returnTo={returnTo} />
        <div className={styles.forgotPassword}>
          <Button
            onClick={() => {
              logLogInScreenForgotPasswordTapped();
            }}
            text={<FormattedMessage defaultMessage="Forgot your password?" />}
            linkInfo={{ href: wrapUrlSafe('/users/password/new', languageRegionCode) }}
            testId="login-forgot-password"
            variant="flat"
            width="full"
            allowWrap
          />
        </div>
      </div>
      <div className={styles.gap}>
        <AltLoginsForm appleLoginHandler={handleApple} facebookLoginHandler={handleFacebook} googleLoginHandler={handleGoogle} />
        <div>
          <Typography color="secondary" variant="text200">
            <FormattedMessage defaultMessage="Don't have an account?" />
          </Typography>{' '}
          <Link href="/signup" useReactRouter={useReactRouter}>
            <FormattedMessage defaultMessage="Sign up for free" />
          </Link>
        </div>
        <div>
          <TermsOfService />
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
