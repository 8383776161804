import { FormattedMessage } from '@alltrails/shared/react-intl';
import isValidEmail from './isValidEmail';

const validateGiftForm = (name: string, value: string) => {
  switch (name) {
    case 'recipientName':
    case 'senderName':
      return value.trim().length === 0 ? <FormattedMessage defaultMessage="Enter a name" /> : '';
    case 'recipientEmail':
    case 'senderEmail':
      if (value.trim().length === 0) {
        return <FormattedMessage defaultMessage="Enter an email" />;
      }
      if (!isValidEmail(value)) {
        return <FormattedMessage defaultMessage="Enter a valid email" />;
      }
      return '';
    case 'message':
      return value.trim().length > 500 ? <FormattedMessage defaultMessage="500 character maximum" /> : '';
    default:
      return '';
  }
};

const hasFormErrors = (params: any) => {
  let keys = Object.keys(params);

  if (params.sendMethod === 'print') {
    keys = keys.filter(k => k !== 'recipientEmail');
  }

  return keys.some(key => validateGiftForm(key, params[key]));
};

export { validateGiftForm, hasFormErrors };
