import { createPortal } from 'react-dom';
import { PropsWithChildren } from 'react';

type Props = {
  isOpen: boolean;
};

// inspired by https://github.com/reactjs/react-modal
function PortalModal({ children, isOpen }: PropsWithChildren<Props>) {
  // because react-rails doesn't have support for SSR portals
  if (!global.document) {
    return null;
  }

  if (!isOpen) {
    return null;
  }

  const portal = global.document.getElementById('modalPortal');

  if (!portal) {
    const div = global.document.createElement('div');
    div.setAttribute('id', 'modalPortal');
    div.setAttribute('data-testid', 'modalPortal');
    const { body } = global.document;
    body.appendChild(div);
  }

  return createPortal(children, global.document && global.document.getElementById('modalPortal'));
}

export default PortalModal;
