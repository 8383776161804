// extracted by mini-css-extract-plugin
export var container = "styles-module__container___a6TES";
export var next = "styles-module__next___JmdYm";
export var overlayContainer = "styles-module__overlayContainer___qH5RL";
export var placeholder = "styles-module__placeholder___b2wiQ";
export var previous = "styles-module__previous___rne3d";
export var riverClass = "styles-module__riverClass____Heoq";
export var riverContainer = "styles-module__riverContainer___s2din";
export var riverShimmer = "styles-module__riverShimmer___ITyXx";
export var title = "styles-module__title___SV3oI";
export var trailCard = "styles-module__trailCard___aCO5K";
export var trailCardContainer = "styles-module__trailCardContainer___HZlF_";