import { useState } from 'react';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { wrapUrlSafe } from 'utils/language_support_util';
import { retrievePrintedGift } from 'utils/requests/gifting_requests';
import Button from '@alltrails/shared/denali/components/Button';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import useMobileWidth from 'hooks/useMobileWidth';
import Link from '@alltrails/shared/denali/components/Link';
import Typography from '@alltrails/shared/denali/components/Typography';
import GiftSubscription from 'types/GiftSubscription';
import * as styles from './GiftCardPrint.module.scss';
import GiftCard from '../GiftCard';
import ProgressDialog from '../../shared/ProgressDialog';

const NameAndMessage = ({ message, senderName, recipientName }: { message: string; senderName: string; recipientName: string }) => (
  <>
    <div className={styles.senderRecipient}>
      <span>
        <FormattedMessage defaultMessage="To:" />
      </span>{' '}
      {recipientName}
    </div>
    <div className={styles.giftMessage}>{message}</div>
    <div className={styles.senderRecipient}>
      <span>
        <FormattedMessage defaultMessage="From:" />
      </span>{' '}
      {senderName}
    </div>
  </>
);

type Props = {
  giftSubscription: GiftSubscription;
};

// https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=5566%3A98584
export default function GiftCardPrint({ giftSubscription }: Props) {
  const languageRegionCode = useLanguageRegionCode();
  const isMobileWidth = useMobileWidth();
  const [printingState, updatePrintingState] = useState(null);

  const { background, code, id, message, senderName, recipientName } = giftSubscription;

  const handlePrintSuccess = (data: any) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = data.download_url;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    updatePrintingState(null);
  };

  const printGiftCard = () => {
    updatePrintingState('loading');
    retrievePrintedGift(code, languageRegionCode)
      .then(handlePrintSuccess)
      .catch(() => updatePrintingState('error'));
  };

  return (
    <div className={styles.giftCardPrint}>
      <div className={styles.printContainer}>
        <div className={styles.cardAndData}>
          <div className={styles.cardContainer}>
            <div className={styles.card}>
              <GiftCard variant={background} />
              <div className={styles.inMobile}>
                <NameAndMessage message={message} senderName={senderName} recipientName={recipientName} />
              </div>
            </div>
            <div className={styles.cardMeta}>
              <div className={styles.planType}>
                <FormattedMessage defaultMessage="1 year of AllTrails+" />
              </div>
              <div className={styles.redemptionCode}>
                <FormattedMessage defaultMessage="Redemption code:" /> <span>{code}</span>
              </div>
            </div>
          </div>
          <div className={styles.metadata}>
            <div className={styles.yourGiftCard}>
              <FormattedMessage defaultMessage="Your gift subscription" />
            </div>
            <div className={styles.desktop}>
              <NameAndMessage message={message} senderName={senderName} recipientName={recipientName} />
            </div>
          </div>
        </div>
        <div className={styles.subTextContainer}>
          <div className={styles.subText}>
            <Typography variant="text200bold">
              <FormattedMessage defaultMessage="New to AllTrails?" />{' '}
            </Typography>
            <FormattedMessage
              defaultMessage="Go to <a></a> to create an account"
              values={{
                a: () => <Link href="/signup">{`www.alltrails.com${wrapUrlSafe('/signup', languageRegionCode)}`}</Link>
              }}
            />
          </div>
          <div className={styles.subText}>
            <Typography variant="text200bold">
              <FormattedMessage defaultMessage="Already have an AllTrails account?" />{' '}
            </Typography>
            <FormattedMessage
              defaultMessage="Use your redemption code at <a></a>"
              values={{
                a: () => <Link href={`/redeem?k=${code}`}>{`www.alltrails.com${wrapUrlSafe(`/redeem`, languageRegionCode)}`}</Link>
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.printButton}>
        <Button text={<FormattedMessage defaultMessage="Print" />} onClick={printGiftCard} testId="gift-card-print" variant="primary" />
      </div>
      {printingState && <ProgressDialog isMobileWidth={isMobileWidth} variant={printingState} updateProgressDialog={updatePrintingState} />}
    </div>
  );
}
