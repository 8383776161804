import { string } from 'prop-types';
import * as styles from './styles/styles.module.scss';

const LoadingSpinner = ({ className }) => {
  return (
    <div className={`${styles.ldsEllipsis} ${className}`}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

LoadingSpinner.propTypes = {
  className: string
};

LoadingSpinner.defaultProps = {
  className: ''
};

export default LoadingSpinner;
