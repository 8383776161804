import { ComponentProps } from 'react';
import classNames from 'classnames';
import { NoSsr } from '@material-ui/core';
import { Context } from 'types/Context';
import * as styles from './styles/styles.module.scss';
import LogoLink from './LogoLink';
import Dropdowns from './Dropdowns';
import UserCTA from './UserCTA';
import ConditionalRenderer from './ConditionalRender';
import MobileMenu from './MobileMenu';
import { HeaderBackground } from './types';
import SearchBox from './SearchBox';
import SkipToMainContentButton from './SkipToMainContentButton';

// https://www.figma.com/design/uArl1Yn1JPudAlXAqYFGs5/Global-Nav-[Handoff]?node-id=63-5827&t=2hSsVfor4WO27vQn-0

type NavigationHeaderProps = {
  background?: HeaderBackground;
  bottomBorder?: boolean;
  className?: string;
  context: Context;
  edgeToEdge?: boolean;
  hideSearch?: boolean;
} & ComponentProps<typeof SearchBox>;

const NavigationHeader = ({
  background = 'primary',
  bottomBorder,
  className,
  context,
  edgeToEdge,
  hideSearch,
  navigateOnSelect,
  onResultSelect,
  placeholder,
  useNewSearch,
  value
}: NavigationHeaderProps) => (
  <header
    className={classNames(
      styles.header,
      styles[background],
      {
        [styles.bottomBorder]: bottomBorder,
        [styles.edgeToEdge]: edgeToEdge
      },
      className
    )}
  >
    <SkipToMainContentButton />
    <nav className={styles.nav}>
      <div className={styles.left}>
        <LogoLink background={background} />
      </div>
      {!hideSearch ? (
        <SearchBox
          navigateOnSelect={navigateOnSelect}
          onResultSelect={onResultSelect}
          placeholder={placeholder}
          useNewSearch={useNewSearch}
          value={value}
        />
      ) : null}
      <div className={styles.right}>
        <NoSsr>
          <ConditionalRenderer renderWhen="mobile">
            <MobileMenu />
          </ConditionalRenderer>
          <ConditionalRenderer renderWhen="desktop">
            <Dropdowns background={background} />
            <UserCTA background={background} context={context} />
          </ConditionalRenderer>
        </NoSsr>
      </div>
    </nav>
  </header>
);
export default NavigationHeader;
