import { CSSProperties, PropsWithChildren, useState } from 'react';
import classNames from 'classnames';
import { MEDIA_QUERY_SMALL_DOWN } from 'utils/constants/breakpoints';
import { useIntl } from '@alltrails/shared/react-intl';
import useInterval from '../../../hooks/useInterval';
import * as styles from './styles/styles.module.scss';

type Props = {
  buttonContainerStyle?: CSSProperties;
  className: string;
  duration: number;
  images: { alt: string; w2880Jpg: string; w2880Avif: string; w750Jpg: string; w750Avif: string }[];
};

const RotatingHero = ({ images, className, duration, buttonContainerStyle, children }: PropsWithChildren<Props>) => {
  const intl = useIntl();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [paused, setPaused] = useState(false);
  const [delay, setDelay] = useState(duration);

  const handleSlideClick = (index: number) => {
    // pause or restart image rotation
    setCurrentImageIndex(index);
    setPaused(!paused);
    setDelay(paused ? duration : null);
  };

  const renderSlideButtons = () => (
    <div className={styles.slideButtons} style={buttonContainerStyle}>
      {images.map((image, index) => {
        const progressSpanStyle: CSSProperties = {};
        const innerSpanClassNames = [];

        if (index < currentImageIndex) {
          innerSpanClassNames.push(styles.slideButtonSpanComplete);
        } else if (index === currentImageIndex) {
          innerSpanClassNames.push(styles.slideButtonSpanActive);
          progressSpanStyle.animationDuration = `${duration}ms`;
          progressSpanStyle.animationPlayState = `${paused ? 'paused' : 'running'}`;
        }

        return (
          <button
            aria-label={intl.formatMessage({ defaultMessage: 'Carousel image {index}' }, { index: index + 1 })}
            key={image.alt}
            type="button"
            className={styles.slideButton}
            onClick={() => handleSlideClick(index)}
          >
            <span className={styles.slideButtonSpan}>
              <span className={classNames(innerSpanClassNames)} style={progressSpanStyle} />
            </span>
          </button>
        );
      })}
    </div>
  );

  useInterval(() => {
    let nextIndex = currentImageIndex + 1;

    if (nextIndex === images.length) {
      nextIndex = 0;
    }

    setCurrentImageIndex(nextIndex);
    setDelay(duration);
  }, delay);

  return (
    <div className={`${className} ${styles.container}`}>
      {images.map((image, index) => (
        <picture className={classNames(styles.picture, index !== currentImageIndex && styles.none)} key={image.w2880Jpg}>
          <source media={MEDIA_QUERY_SMALL_DOWN} srcSet={image.w750Avif} type="image/avif" />
          <source media={MEDIA_QUERY_SMALL_DOWN} srcSet={image.w750Jpg} type="image/jpg" />
          <source srcSet={image.w2880Avif} type="image/avif" />
          <img alt={image.alt} src={image.w2880Jpg} className={styles.heroImage} />
        </picture>
      ))}
      {children}
      {renderSlideButtons()}
    </div>
  );
};

export default RotatingHero;
