import { useMemo } from 'react';
import { arrayOf, shape, string, number } from 'prop-types';
import * as styles from './styles/styles.module.scss';
import ActivityRiverItem from './ActivityRiverItem';
import River from '../../shared/River';
import { LanguageSupportUtil } from '../../../utils/language_support_util';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { HOME_PAGE_WIDTH_BREAKPOINT } from '../../../utils/constants/breakpoints';

const slickSettings = { slidesToShow: 6, lazyLoad: 'progressive', slidesToScroll: 6, speed: 750 };
const ARROW_STYLES = { next: styles.next, previous: styles.previous };

const ActivitiesRiver = ({ activities, windowWidth, title }) => {
  const languageRegionCode = useLanguageRegionCode();
  const isWideScreen = useMemo(() => windowWidth && windowWidth >= HOME_PAGE_WIDTH_BREAKPOINT, [windowWidth]);

  const activityProps = activity => {
    return {
      title: activity.title,
      imgSrc: activity.photo_urls.square.two,
      url: LanguageSupportUtil.wrapUrlSafe(`/explore?a[]=${activity.filters.activity[0]}`, languageRegionCode)
    };
  };

  if (!activities) {
    return <></>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div className={styles.riverContainer}>
        <River useSlick={isWideScreen} slickSettings={slickSettings} className={styles.riverClass} arrowStyles={ARROW_STYLES}>
          {activities.map(activity => (
            <ActivityRiverItem key={activity.title} {...activityProps(activity)} />
          ))}
        </River>
      </div>
    </div>
  );
};

ActivitiesRiver.propTypes = {
  activities: arrayOf(
    shape({
      filters: shape({
        activity: arrayOf(string)
      }),
      photo_urls: shape({
        string: shape({
          two: string,
          three: string
        })
      }),
      title: string
    })
  ),
  windowWidth: number
};

ActivitiesRiver.defaultProps = {
  activities: [],
  windowWidth: null
};

export default ActivitiesRiver;
