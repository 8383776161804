import { useRef } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import * as styles from './styles/styles.module.scss';
import useScreenLock from '../../hooks/useScreenLock';

/**
 * @deprecated use ModalBackground and ModalInterior
 */
const LegacyModal = ({ children, handleBackgroundClick, modalStyle }) => {
  const [isMounted] = useScreenLock();
  const mouseDownTargetIdRef = useRef(null);

  // Prevent inner clicks from closing the modal
  // Also prevent a drag from inside the modal to the outside from closing it
  const onMouseDown = e => {
    mouseDownTargetIdRef.current = e.target.id;
  };
  const onMouseUp = e => {
    if (e.target.id === 'modal' && mouseDownTargetIdRef.current === 'modal') {
      handleBackgroundClick(e);
    }
    mouseDownTargetIdRef.current = null;
  };

  return (
    <CSSTransition
      in={isMounted}
      classNames={{
        enterDone: styles.enterDone,
        exit: styles.exit
      }}
      timeout={0}
    >
      <div role="dialog" id="modal" key="modal" className={styles.modal} onMouseDown={onMouseDown} onMouseUp={onMouseUp} style={modalStyle}>
        {children}
      </div>
    </CSSTransition>
  );
};

LegacyModal.defaultProps = {
  children: null,
  modalStyle: {},
  handleBackgroundClick: null
};

LegacyModal.propTypes = {
  children: PropTypes.node,
  modalStyle: PropTypes.object,
  handleBackgroundClick: PropTypes.func
};

export default LegacyModal;
