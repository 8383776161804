// extracted by mini-css-extract-plugin
export var active = "ExploreAndDiscover-module__active___SDCC_";
export var bottomContainer = "ExploreAndDiscover-module__bottomContainer___aMJjz";
export var carousel = "ExploreAndDiscover-module__carousel___kzE5O";
export var dot = "ExploreAndDiscover-module__dot___Vifph";
export var dots = "ExploreAndDiscover-module__dots___go7e6";
export var feature = "ExploreAndDiscover-module__feature___Vlre5";
export var featureText = "ExploreAndDiscover-module__featureText___GPJlq";
export var last = "ExploreAndDiscover-module__last___AWb_f";
export var mobileContainer = "ExploreAndDiscover-module__mobileContainer___tZQuZ";
export var nav = "ExploreAndDiscover-module__nav___IVeg8";
export var navLink = "ExploreAndDiscover-module__navLink____U3jX";
export var screen = "ExploreAndDiscover-module__screen___uleUl";
export var screenImage = "ExploreAndDiscover-module__screenImage___eNgfm";
export var signUpLink = "ExploreAndDiscover-module__signUpLink___IBjtK";
export var slideContainer = "ExploreAndDiscover-module__slideContainer___dvAif";
export var slideImage = "ExploreAndDiscover-module__slideImage___P4QUi";
export var texture = "ExploreAndDiscover-module__texture___oT3fu";
export var title = "ExploreAndDiscover-module__title___gRXLw";
export var upArrow = "ExploreAndDiscover-module__upArrow___B2Ra_";