import * as styles from './styles/styles.module.scss';

type ShopifyCardProps = {
  title: string;
  shopItemUrl: string;
  image: {
    src: string;
    altText: string;
  };
  price: string;
};

const ShopifyCard = ({ title, image, shopItemUrl, price }: ShopifyCardProps): JSX.Element => (
  <a className={styles.container} target="_blank" rel="noreferrer" href={shopItemUrl}>
    <div className={styles.imageContainer}>
      <img src={image.src} alt={image.altText} className={styles.image} loading="lazy" />
    </div>
    <div className={styles.title}>{title}</div>
    <div className={styles.price}>{price}</div>
  </a>
);

export default ShopifyCard;
