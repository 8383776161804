import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { defineMessages } from '@alltrails/shared/react-intl';
import getHelpCenterUrl from '@alltrails/shared/utils/constants/helpCenterUrl';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import Typography from '@alltrails/shared/denali/components/Typography';
import Button from '@alltrails/shared/denali/components/Button';
import PlusBadge from '@alltrails/shared/denali/components/PlusBadge';
import CheckFilled from '@alltrails/shared/icons/CheckFilled';
import Info from '@alltrails/shared/icons/Info';
import Link from '@alltrails/shared/denali/components/Link';
import Clear from '@alltrails/shared/icons/Clear';
import { COLOR_NEUTRAL_400 } from '@alltrails/shared/denali/tokens';
import logProUpsellScreenShown from '@alltrails/analytics/events/logProUpsellScreenShown';
import logSettingsGarminConnectPageViewed from '@alltrails/analytics/events/logSettingsGarminConnectPageViewed';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import Modal from 'components/Modal';
import { isTrial } from 'utils/PlanUtils';
import { getGarminPermissions, GarminPermissionsResponse, GarminPermissionTypes } from 'utils/requests/garminRequests';
import logError from 'utils/logError';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import * as styles from './GarminSettingsModal.module.scss';

const messages = defineMessages({
  TITLE: { defaultMessage: 'Garmin' },
  SUBTITLE: {
    defaultMessage: 'Pull completed activities from Garmin'
  },
  HELP: { defaultMessage: 'Help' },
  ACTIVE: { defaultMessage: 'Active' },
  NOT_ACTIVE: { defaultMessage: 'Not active' },
  CHANGE: { defaultMessage: 'Change' },
  SEND_ROUTES: { defaultMessage: 'Send routes to Garmin' },
  FREE_SEND_ROUTES_INFO: { defaultMessage: 'With AllTrails+, you can navigate AllTrails routes from your Garmin device.' },
  PLUS_SEND_ROUTES_INFO: { defaultMessage: 'Routes will be available to navigate on your Garmin device after syncing with Garmin Connect.' },
  GET_ALLTRAILS_PLUS: { defaultMessage: 'Get AllTrails+' },
  DISCONNECT: { defaultMessage: 'Disconnect Garmin' },
  CLOSE: { defaultMessage: 'Close' },
  MANAGE_ROUTES: { defaultMessage: 'Manage routes on Garmin' },
  ACTIVITIES: {
    defaultMessage:
      "Activities will be saved in your AllTrails profile. Depending on your AllTrails <link>privacy settings</link>, they may also appear in your followers' feeds."
  }
});

type Props = {
  isOpen: boolean;
  isVisible: boolean;
  garminConnectUrl: string;
  isPlus: boolean;
  handleDisconnect: () => void;
  onRequestClose: () => void;
};

const GarminSettingsModal = ({ isOpen, isVisible, garminConnectUrl, handleDisconnect, isPlus, onRequestClose }: Props) => {
  const {
    formatMessage,
    formattedDefaultMessages: {
      TITLE,
      SUBTITLE,
      HELP,
      ACTIVE,
      NOT_ACTIVE,
      CHANGE,
      SEND_ROUTES,
      FREE_SEND_ROUTES_INFO,
      PLUS_SEND_ROUTES_INFO,
      GET_ALLTRAILS_PLUS,
      DISCONNECT,
      CLOSE,
      MANAGE_ROUTES
    }
  } = useFormatMessage(messages);
  const formattedActivitiesMessage = formatMessage('ACTIVITIES', {
    // eslint-disable-next-line react/no-unstable-nested-components
    link: (text: string) => (
      <Link className={styles.privacyButton} href="/my/profile/edit?privacy=true" target="_blank">
        {text}
      </Link>
    )
  });
  const [garminPermissions, setGarminPermissions] = useState<GarminPermissionTypes[] | null>(null);
  const areActivitiesEnabled = garminPermissions?.includes('ACTIVITY_EXPORT');
  const areCoursesEnabled = garminPermissions?.includes('COURSE_IMPORT');
  const languageRegionCode = useLanguageRegionCode();
  const manageRoutesUrl = 'https://connect.garmin.com/modern/courses';
  const helpCenterUrl = getHelpCenterUrl(languageRegionCode, 360019246271);
  const handleClickGetPlus = () => {
    logProUpsellScreenShown({ is_free_7_day_trial: isTrial(__AT_DATA__?.plan), trigger: CarouselDisplayTrigger.SendRoutesToGarmin });
    window.location.assign('/plus');
  };
  const helpButton = (
    <Button
      variant="flat"
      text={HELP}
      icon={{ Component: Info }}
      testId="Help"
      linkInfo={{ href: helpCenterUrl, target: '_blank', rel: ['noreferrer'] }}
    />
  );

  const activeContainer = useCallback(
    (enabled: boolean, key: 'activities' | 'courses') => (
      <div className={styles.activeChangeContainer}>
        <div>
          {enabled ? <CheckFilled /> : <Clear color={COLOR_NEUTRAL_400} />}
          <Typography variant="text200bold" className={classNames(styles.textAfterIcon, { [styles.inactive]: !enabled })}>
            {enabled ? ACTIVE : NOT_ACTIVE}
          </Typography>
        </div>
        <Button text={CHANGE} testId={`change-${key}-permission`} linkInfo={{ href: garminConnectUrl }} />
      </div>
    ),
    [ACTIVE, CHANGE, NOT_ACTIVE, garminConnectUrl]
  );

  useEffect(() => {
    getGarminPermissions()
      .then((data: GarminPermissionsResponse) => setGarminPermissions(data.permissions))
      .catch(logError);

    return () => {
      setGarminPermissions(null);
    };
  }, []);

  const logPageView = useCallback(
    () => logSettingsGarminConnectPageViewed({ pull_completed_activities: areActivitiesEnabled, garmin_courses: areCoursesEnabled }),
    [areActivitiesEnabled, areCoursesEnabled]
  );

  useEffect(() => {
    if (isOpen) {
      logPageView();
    }
  }, [isOpen, logPageView]);

  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={onRequestClose}
      baseCtas={{
        primaryCta: {
          text: CLOSE,
          testId: 'close-garmin-settings',
          onClick: onRequestClose
        },
        secondaryCta: {
          text: DISCONNECT,
          testId: 'disconnect-garmin',
          onClick: handleDisconnect,
          variant: 'destructive',
          className: styles.disconnectButton,
          allowWrap: true
        }
      }}
      title={
        <div className={styles.title}>
          {TITLE}
          {helpButton}
        </div>
      }
    >
      <div className={styles.container}>
        <Typography variant="heading300">{SUBTITLE}</Typography>
        <Typography variant="text200" className={styles.description}>
          {formattedActivitiesMessage}
        </Typography>
        {activeContainer(areActivitiesEnabled, 'activities')}
        <div className={styles.divider} />

        <div className={classNames(styles.routesContainer, { [styles.routesUpsellContainer]: !isPlus })}>
          <div className={styles.routes}>
            <div className={styles.sendRoutes}>
              <PlusBadge size="md" />
              <Typography variant="heading300" className={styles.textAfterIcon}>
                {SEND_ROUTES}
              </Typography>
            </div>
            <Typography variant="text200">{isPlus ? PLUS_SEND_ROUTES_INFO : FREE_SEND_ROUTES_INFO}</Typography>
          </div>
          {isPlus ? (
            <>
              {activeContainer(areCoursesEnabled, 'courses')}
              {areCoursesEnabled && (
                <Button
                  className={styles.manageRoutes}
                  text={MANAGE_ROUTES}
                  testId="manage-routes"
                  linkInfo={{ href: manageRoutesUrl, rel: ['noopener', 'noreferrer'], target: '_blank' }}
                />
              )}
            </>
          ) : (
            <Button
              className={styles.getPlusButton}
              variant="primary"
              text={GET_ALLTRAILS_PLUS}
              testId="get-alltrails-plus"
              onClick={handleClickGetPlus}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default GarminSettingsModal;
