import { useDispatch } from 'react-redux';
import useHistory from 'hooks/useHistory';
import ProPageLayout from 'components/ProPageLayout';
import Coupon from 'types/Coupon';
import SignUpForm from 'components/SignUpForm';
import { variants } from 'utils/amplitudeHelper';
import { setUser } from '../../userSlice';
import { setExperiments } from '../../experimentsSlice';

type Props = {
  coupon?: Coupon;
};

export default function RegisterRoute({ coupon }: Props) {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <ProPageLayout>
      <SignUpForm
        handleRegistrationSuccess={async ({ user }) => {
          dispatch(setUser(user));

          const experiments = await variants(user);
          dispatch(setExperiments(experiments));

          const params = new URLSearchParams(__AT_DATA__.search);

          if (coupon) {
            params.set('k', coupon.code);
          }

          const search = params.toString();

          history.push(`/plus/checkout${search ? `?${search}` : ''}`);
        }}
        useReactRouter
      />
    </ProPageLayout>
  );
}
