// extracted by mini-css-extract-plugin
export var after = "Checkout-module__after___oTjFN";
export var billed = "Checkout-module__billed___VkJd4";
export var billedArea = "Checkout-module__billedArea___RYe7i";
export var billedCopyright = "Checkout-module__billedCopyright___InAjb";
export var button = "Checkout-module__button___Tkuov";
export var content = "Checkout-module__content___RP5Xl";
export var copyright = "Checkout-module__copyright___Cy55O";
export var coupon = "Checkout-module__coupon___yZGuW";
export var fine = "Checkout-module__fine___9vdrg";
export var form = "Checkout-module__form___MlZe9";
export var heading = "Checkout-module__heading___OoaN1";
export var hr = "Checkout-module__hr___CdU3Y";
export var link = "Checkout-module__link___N3Zj5";
export var promoText = "Checkout-module__promoText___PEnBq";
export var s = "Checkout-module__s___iYrpz";
export var subheading = "Checkout-module__subheading___k7257";
export var tryArea = "Checkout-module__tryArea___Rl2Wf";