import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import CurrencyCode from 'types/CurrencyCode';

export default function useCurrencyFormatter(currencyCode: CurrencyCode) {
  const languageRegionCode = useLanguageRegionCode();
  return new Intl.NumberFormat(languageRegionCode, {
    style: 'currency',
    currency: currencyCode
  });
}
