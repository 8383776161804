import { FormattedMessage, useIntl } from '@alltrails/shared/react-intl';
import { getAvailablePlans } from 'api/Purchases';
import CountryCode from 'types/CountryCode';
import { COLOR_TEXT_ERROR, COLOR_TEXT_PLACEHOLDER, COLOR_TEXT_PRIMARY, FONT_FAMILY_PRIMARY, FONT_SIZE_200 } from '@alltrails/shared/denali/tokens';
import { CardElement, CardElementChangeEvent, CardElementState } from '@recurly/react-recurly';
import { useState } from 'react';
import OutlinedTextField from '@alltrails/shared/components/OutlinedTextField';
import BillingCountrySelector, { isBillingCountryEu } from 'components/BillingCountrySelector';
import classNames from 'classnames';
import * as styles from './styles/styles.module.scss';
import { getBillingNames } from './utils';
import { CARD_RECURLY_IDs, CARD_VALIDATION_NAMES, TokenValidationErrors } from './types';

type Props = {
  billingCountry?: {
    onChange: (billingCountry: CountryCode, response: Awaited<ReturnType<typeof getAvailablePlans>>) => void;
    value: string;
  };
  isCouponInputExpanded?: boolean;
  isCouponInputShown?: boolean;
  toggleCouponCode?: () => void;
  validationErrors: TokenValidationErrors;
  handleInputChange: (
    inputName: string,
    value: string | { number: CardElementState; expiry: CardElementState; cvv: CardElementState } | CardElementChangeEvent
  ) => void;
  initialFullName?: string;
};

function TokenizedCreditCardForm({
  billingCountry,
  toggleCouponCode,
  isCouponInputExpanded = false,
  isCouponInputShown = false,
  handleInputChange,
  validationErrors,
  initialFullName
}: Props) {
  const { nameError, postalCodeError, ccInputStateError } = validationErrors;

  const [fullName, setFullName] = useState(initialFullName || '');
  const [postalCode, setPostalCode] = useState('');
  const { firstName, lastName } = getBillingNames(fullName);

  const intl = useIntl();
  const showBillingCountry = billingCountry && isBillingCountryEu(billingCountry.value as CountryCode);
  const selectLabel = intl.formatMessage({ defaultMessage: 'Billing country' });

  const cardElementStyles = {
    fontSize: `${FONT_SIZE_200}px`,
    fontColor: COLOR_TEXT_PRIMARY,
    fontFamily: FONT_FAMILY_PRIMARY,
    placeholder: {
      // placeholder styles can't be controlled via CSS since they're inside an iframe.
      color: `${ccInputStateError ? COLOR_TEXT_ERROR : COLOR_TEXT_PLACEHOLDER} !important`,
      content: {
        number: intl.formatMessage({ defaultMessage: 'Card number' }),
        cvv: 'CVV'
      }
    },
    invalid: {
      fontColor: COLOR_TEXT_ERROR
    }
  };

  return (
    <div>
      <div className={styles.formInputContainer}>
        <div>
          <CardElement
            // Manually apply the error styles to the card element container.
            // Recurly does this automatically onChange, but we also want to apply these styles when the form is submitted with an empty input.
            className={classNames('recurly-card-element-container', { invalid: ccInputStateError })}
            style={cardElementStyles}
            onChange={e => handleInputChange(CARD_VALIDATION_NAMES.CREDIT_CARD, e)}
          />
          {ccInputStateError && <p className={styles.errorMessage}>{ccInputStateError}</p>}
        </div>
        <div className={styles.namesContainer}>
          <OutlinedTextField
            autocomplete="cc-name"
            className={classNames(styles.formInput, { [styles.invalidInput]: !!nameError })}
            changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFullName(e.target.value);
              handleInputChange(CARD_VALIDATION_NAMES.NAME, e.target.value);
            }}
            label={<FormattedMessage defaultMessage="Name on card" />}
            name="name"
            errorMessage={nameError}
            value={fullName}
          />
        </div>
        <div>
          <OutlinedTextField
            recurlyDataId={CARD_RECURLY_IDs.POSTAL_CODE}
            className={classNames(styles.formInput, { [styles.invalidInput]: !!postalCodeError })}
            autocomplete="postal-code"
            changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPostalCode(e.target.value);
              handleInputChange(CARD_VALIDATION_NAMES.POSTAL_CODE, e.target.value);
            }}
            errorMessage={postalCodeError}
            label={<FormattedMessage defaultMessage="Postal code" />}
            name="postal code"
          />
        </div>

        {/* Hidden inputs accessed by Recurly */}
        <input type="hidden" name="recurly-token" data-recurly="token" />
        <input type="hidden" name="first-name" data-recurly={CARD_RECURLY_IDs.FIRST_NAME} value={firstName} />
        <input type="hidden" name="last-name" data-recurly={CARD_RECURLY_IDs.LAST_NAME} value={lastName} />
        <input type="hidden" name="postal-code" data-recurly={CARD_RECURLY_IDs.POSTAL_CODE} value={postalCode} />
        <input type="hidden" name="country" data-recurly={CARD_RECURLY_IDs.COUNTRY} value={billingCountry?.value || __AT_DATA__.ipCountry} />
      </div>

      {isCouponInputShown && (
        <div className={styles.couponCol}>
          <button className={styles.couponButton} onClick={toggleCouponCode} type="button">
            {isCouponInputExpanded ? (
              <FormattedMessage defaultMessage="Hide coupon code" />
            ) : (
              <FormattedMessage defaultMessage="Enter a coupon code" />
            )}
          </button>
        </div>
      )}

      {showBillingCountry && (
        <BillingCountrySelector
          className={styles.country}
          labelText={selectLabel}
          onChangeBillingCountry={async e => {
            const response = await getAvailablePlans(e as CountryCode);
            billingCountry.onChange(e as CountryCode, response);
          }}
          billingCountryCode={billingCountry.value}
        />
      )}
    </div>
  );
}

export default TokenizedCreditCardForm;
