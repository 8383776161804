import { useContext } from 'react';
import Track from '@alltrails/shared/types/track';
import { SearchStateContext } from 'components/SearchStateProvider';
import { Activities } from 'types/Trails';
import Trail from 'types/Trails/Trail';

const getDuration = (item: Track | Trail, activity?: Activities) => {
  switch (activity) {
    case Activities.MOUNTAIN_BIKING:
      return item.duration_minutes_mountain_biking;
    case Activities.ROAD_BIKING:
    case Activities.BIKE_TOURING:
      return item.duration_minutes_cycling;
    case Activities.TRAIL_RUNNING:
      return item.duration_minutes_trail_running;
    case Activities.HIKING:
    case Activities.WALKING:
    case Activities.BACKPACKING:
      return item.duration_minutes_hiking;
    default:
      return item.duration_minutes;
  }
};

const useActivityDuration = (item: Track | Trail) => {
  const { filters } = useContext(SearchStateContext);

  const activities = filters?.activities;
  if (activities) {
    const selected = (Object.keys(activities) as Activities[]).find(key => activities[key]?.selected);
    return getDuration(item, selected);
  }

  return item.duration_minutes;
};

export default useActivityDuration;
