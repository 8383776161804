enum Activities {
  BACKPACKING = 'backpacking',
  BIKE_TOURING = 'bike-touring',
  BIRDING = 'birding',
  CAMPING = 'camping',
  CROSS_COUNTRY_SKIING = 'cross-country-skiing',
  FISHING = 'fishing',
  HIKING = 'hiking',
  HORSEBACK_RIDING = 'horseback-riding',
  MOUNTAIN_BIKING = 'mountain-biking',
  NATURE_TRIPS = 'nature-trips',
  OFF_ROAD_DRIVING = 'off-road-driving',
  PADDLE_SPORTS = 'paddle-sports',
  ROAD_BIKING = 'road-biking',
  ROCK_CLIMBING = 'rock-climbing',
  SCENIC_DRIVING = 'scenic-driving',
  SKIING = 'skiing',
  SNOWSHOEING = 'snowshoeing',
  TRAIL_RUNNING = 'trail-running',
  VIA_FERRATA = 'via-ferrata',
  WALKING = 'walking'
}

export { Activities };
