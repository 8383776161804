export const TRAIL_LIST = 'trail_list';
export const ON_TRACK_WITH_PRO_CHECKLIST = 'on_track_with_pro_checklist';
export const ON_TRACK_WITH_PRO = 'on_track_with_pro';
export const ATTRIBUTE = 'attribute';
export const EDITORS_CHOICE_APP = 'editors_choice_app';
export const EXPLORE_AND_DISCOVER = 'explore_and_discover';
export const GIFTING_UPSELL = 'gifting_upsell';
export const MERCHANDISE_STORE = 'merchandise_store';
export const ONE_PERCENT_PLANET = 'one_percent_planet';
export const COMMUNITY_STATS = 'community_stats';
export const SIGNUP_ACTIVITY_SUITABILITY = 'signup_activity_suitability';
export const SEO_LINKS = 'seo_links';
export const GUIDES_CAROUSEL = 'guides_carousel';
export const UGC_SOCIAL_FOOTER = 'ugc_social_footer';
export const GOOGLEBOT_TRAIL_LIST = 'googlebot_trail_list';
