import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import * as baseStyles from '../../styles/baseStyles.module.scss';
import * as styles from './ProPageLayout.module.scss';

export default function ProPageLayout({ children }: PropsWithChildren<any>) {
  return (
    <div>
      <div className={baseStyles.container}>
        <div className={baseStyles.row}>
          <div className={classNames(styles.body, styles.topoBackground)}>
            <div className={styles.panel}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
