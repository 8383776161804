import { FormattedMessage, useIntl } from '@alltrails/shared/react-intl';
import { ComponentProps, useEffect } from 'react';
import Modal from 'components/Modal';
import Typography from '@alltrails/shared/denali/components/Typography';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import StarRating from '@alltrails/shared/denali/components/StarRating';
import logQRModalShown from '@alltrails/analytics/events/logQRModalShown';
import TrailDetailsActionLocation from '@alltrails/analytics/enums/TrailDetailsActionLocation';
import QRCodeSvg from './QRCode.svg';
import * as styles from './styles/InstallModal.module.scss';

type InstallModalProps = Pick<ComponentProps<typeof Modal>, 'isOpen' | 'onRequestClose'>;

const InstallModal = ({ isOpen, onRequestClose }: InstallModalProps) => {
  const intl = useIntl();
  const languageRegionCode = useLanguageRegionCode();

  useEffect(() => {
    if (isOpen) {
      logQRModalShown({ module_location: TrailDetailsActionLocation.GlobalNavProfileDropdown });
    }
  }, [isOpen]);

  // eslint-disable-next-line import/no-dynamic-require, global-require, @typescript-eslint/no-var-requires
  const appleLaurel = require(`../DiscoverTheAppOfTheYear/apple-laurel-${languageRegionCode}.svg`);

  // eslint-disable-next-line import/no-dynamic-require, global-require, @typescript-eslint/no-var-requires
  const googleLaurel = require(`../DiscoverTheAppOfTheYear/google-laurel-${languageRegionCode}.svg`);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={
        <div className={styles.title}>
          <FormattedMessage defaultMessage="Get the app" />
        </div>
      }
    >
      <div className={styles.container}>
        <div className={styles.laurels}>
          <img alt={intl.formatMessage({ defaultMessage: 'Apple App Store App of the Year' })} src={appleLaurel} height={32} />
          <img alt={intl.formatMessage({ defaultMessage: 'Google Play Editors’ Choice' })} src={googleLaurel} height={32} />
        </div>
        <Typography className={styles.salesPitch} color="secondary" variant="text100">
          <FormattedMessage defaultMessage="Navigate on the trail and track all your adventures with the award-winning app." />
        </Typography>
        <StarRating rating={4.8} ratingSide="left" shouldShowRating size="sm" />
        <img
          alt={intl.formatMessage({
            defaultMessage:
              'This QR code is intended to scan from a smartphone. If you have the AllTrails app, the link will open the app for you. If you don’t have the app, the link will take you to your device’s app store.'
          })}
          src={QRCodeSvg}
          loading="lazy"
          width={180}
          height={180}
        />
      </div>
    </Modal>
  );
};

export default InstallModal;
