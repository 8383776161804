import algoliasearch from 'algoliasearch';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';

class Search {
  constructor(indexId, languageRegionCode) {
    this.credentials = getApplicationConfigForIndex(indexId);
    this.algoliaClient = algoliasearch(this.credentials.applicationId, this.credentials.apiKey);
    this.algoliaIndex = this.algoliaClient.initIndex(indexId);
    this.languageRegionCode = languageRegionCode || LanguageRegionCode.English;
    this.indexId = indexId;
  }

  search(searchTerm, queryObj) {
    return this.algoliaIndex.search(searchTerm, this.optimizeQuery(searchTerm, queryObj));
  }

  batchSearch(queryObjs) {
    const queries = queryObjs.map(q => {
      return {
        indexName: this.algoliaIndex.indexName,
        query: '',
        params: this.optimizeQuery('', q)
      };
    });

    return this.algoliaClient.multipleQueries(queries);
  }

  clearCache() {
    this.algoliaClient.clearCache();
  }

  optimizeQuery(searchTerm, query) {
    const optimizedQuery = JSON.parse(JSON.stringify(query)); // deep clone

    if (!searchTerm) {
      optimizedQuery.attributesToHighlight = [];
    } else if (!optimizedQuery.attributesToHighlight) {
      optimizedQuery.attributesToHighlight = ['name'];
    }

    if (!optimizedQuery.responseFields) {
      optimizedQuery.responseFields = ['hits', 'hitsPerPage', 'nbHits'];
    }

    return optimizedQuery;
  }
}

const indices = [];

const getAlgoliaIndex = (indexId = null, providedLanguageRegionCode = null) => {
  let algoliaIndex = (indexId) ? indexId : getAlgoliaIndexId('primaryIndex');
  let languageRegionCode = providedLanguageRegionCode;

  if (!languageRegionCode) {
    languageRegionCode = LanguageRegionCode.English;
  }

  const cacheKey = [algoliaIndex, languageRegionCode].join('-');
  indices[cacheKey] ||= new Search(algoliaIndex, languageRegionCode);
  return indices[cacheKey];
};

const getAlgoliaConfig = () => {
  return global.__AT_DATA__.algoliaConfig;
}

const getAlgoliaIndexId = (key) => {
  return getAlgoliaConfig().indexes[key]?.id;
}

const getApplicationConfigForIndex = (indexId) => {
  const index = Object.values(getAlgoliaConfig().indexes).find((i) => i.id == indexId);
  if (index) {
    return getAlgoliaConfig().applications[index.application];
  } else {
    return {};
  }
}

const getPrimaryIndex = (languageRegionCode = null) => {
  // TODO: Remove languageRegionCode after language-specific indexes launch, will not be relevant
  return getAlgoliaIndex(getAlgoliaIndexId('primaryIndex'), languageRegionCode);
};

const getPendingIndex = () => {
  return getAlgoliaIndex(getAlgoliaIndexId('pendingIndex'));
}

const getMapsIndex = () => {
  return getAlgoliaIndex(getAlgoliaIndexId('mapsIndex'));
}

export { Search, getAlgoliaIndex, getPrimaryIndex, getPendingIndex, getMapsIndex, getApplicationConfigForIndex };
