import { useState } from 'react';
import { useHistory } from '@alltrails/shared/components/ReactRouter';
import useUser from 'hooks/useUser';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import Button from '@alltrails/shared/denali/components/Button';
import OutlinedTextField from '@alltrails/shared/components/OutlinedTextField';
import * as styles from './CustomizeGiftForm.module.scss';
import GiftCard from '../GiftCard';
import GiftCardRadioButtons from '../GiftCardRadioButtons';
import ToggleRadioButtons from '../ToggleRadioButtons';
import DatePicker from '@alltrails/shared/components/DatePicker';
import GiftTray from '../GiftTray';
import { validateGiftForm, hasFormErrors } from '../../../utils/GiftFlowHelper';
import { pushHistory } from '../../../utils/client_side_routing_helpers';
import { isToday } from '@alltrails/shared/utils/timeHelpers';
import { UPDATE_GIFT_CUSTOMIZE_FORM, UPDATE_SERVER_ERRORS, useGiftFlowContext } from 'pages/Gift/giftContext';

const printEmailOptions = [
  { label: <FormattedMessage defaultMessage="Email" />, value: 'email', name: 'sendMethod' },
  { label: <FormattedMessage defaultMessage="Print" />, value: 'print', name: 'sendMethod' }
];

const CustomizeGiftForm = () => {
  const user = useUser();
  const languageRegionCode = useLanguageRegionCode();
  const {
    giftFlowState: { giftCustomizeParams, serverErrors },
    dispatchGiftFlow
  } = useGiftFlowContext();

  const history = useHistory();

  const [formParams, updateFormParams] = useState({ ...giftCustomizeParams });
  const [hasSubmitted, updateHasSubmitted] = useState(false);
  const [renderBottomTray, toggleRenderBottomTray] = useState(false);

  const updateForm = (e: any) => {
    const { name, value } = e.currentTarget;
    updateFormParams((prevState: any) => ({ ...prevState, [name]: value }));
  };

  const navigateTo = (path: string) => {
    pushHistory(path, history, languageRegionCode);
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };

  const handleDateChange = (sendDate: any) => {
    updateFormParams((prevState: any) => ({ ...prevState, sendDate }));
  };

  const checkErrorMessage = (name: string, value: any, validate: any) => {
    if (!validate) {
      return '';
    }

    return validateGiftForm(name, value);
  };

  const checkServerError = (value: any) => {
    if (serverErrors.length === 0) {
      return '';
    }

    const error = serverErrors.find((err: any) => err.target === value);

    return error ? error.message : '';
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();

    if (!hasSubmitted) {
      updateHasSubmitted(true);
    }

    if (hasFormErrors(formParams)) {
      return;
    }

    if (serverErrors.length > 0) {
      dispatchGiftFlow({ type: UPDATE_SERVER_ERRORS, payload: [] });
    }

    dispatchGiftFlow({
      type: UPDATE_GIFT_CUSTOMIZE_FORM,
      payload: formParams
    });

    if (user) {
      navigateTo('/gift/payment');
    } else {
      toggleRenderBottomTray(true);
    }
  };

  const messageFieldError = () => {
    if (formParams.message.trim().length > 500) {
      return <FormattedMessage defaultMessage="500 character maximum" />;
    }

    return '';
  };

  return (
    <div className={styles.container}>
      <div className={styles.cardColumn}>
        <div className={styles.fixedContainer}>
          <GiftCard variant={formParams.variant} />
        </div>
      </div>
      <form className={styles.formColumn} onSubmit={handleFormSubmit}>
        <h2 className={styles.formHeader}>
          <FormattedMessage defaultMessage="Customize your gift" />
        </h2>
        <div className={styles.formSectionSm}>
          <div className={styles.mobileGiftCardContainer}>
            <GiftCard variant={formParams.variant} />
          </div>
          <GiftCardRadioButtons onChange={updateForm} selectedVariant={formParams.variant} />
        </div>
        <div className={styles.formSectionLg}>
          <div className={styles.label}>
            <FormattedMessage defaultMessage="How do you want to send it?" />
          </div>
          <ToggleRadioButtons options={printEmailOptions} handleOnChange={updateForm} activeOption={formParams.sendMethod} />
        </div>
        <div className={styles.formSection}>
          <div className={styles.label}>
            <FormattedMessage defaultMessage="To:" />
          </div>
          <div className={styles.inputContainer}>
            <OutlinedTextField
              name="recipientName"
              errorMessage={checkErrorMessage('recipientName', formParams.recipientName, hasSubmitted)}
              value={formParams.recipientName}
              label={<FormattedMessage defaultMessage="Name" />}
              changeHandler={updateForm}
            />
          </div>
          {formParams.sendMethod === printEmailOptions[0].value && (
            <div className={styles.inputContainer}>
              <OutlinedTextField
                name="recipientEmail"
                errorMessage={
                  checkErrorMessage('recipientEmail', formParams.recipientEmail, hasSubmitted) || checkServerError(formParams.recipientEmail)
                }
                value={formParams.recipientEmail}
                label={<FormattedMessage defaultMessage="Email address" />}
                changeHandler={updateForm}
              />
            </div>
          )}
          <div className={styles.inputContainer}>
            <OutlinedTextField
              name="message"
              errorMessage={messageFieldError()}
              value={formParams.message}
              label={<FormattedMessage defaultMessage="Message (500 characters max)" />}
              changeHandler={updateForm}
              rows={9}
              multiline
            />
          </div>
        </div>
        <div className={styles.formSection}>
          <div className={styles.label}>
            <FormattedMessage defaultMessage="From:" />
          </div>
          <div className={styles.inputContainer}>
            <OutlinedTextField
              name="senderName"
              errorMessage={checkErrorMessage('senderName', formParams.senderName, hasSubmitted)}
              value={formParams.senderName}
              label={<FormattedMessage defaultMessage="Name" />}
              changeHandler={updateForm}
            />
          </div>
          <div className={styles.inputContainer}>
            <OutlinedTextField
              name="senderEmail"
              errorMessage={checkErrorMessage('senderEmail', formParams.senderEmail, hasSubmitted) || checkServerError(formParams.senderEmail)}
              value={formParams.senderEmail}
              label={<FormattedMessage defaultMessage="Email address" />}
              changeHandler={updateForm}
            />
          </div>
        </div>
        {formParams.sendMethod === printEmailOptions[0].value && (
          <div className={styles.formSection}>
            <div className={styles.label}>
              <FormattedMessage defaultMessage="Send date:" />{' '}
              <span className={styles.sendDateHelperText}>
                {isToday(formParams.sendDate) ? (
                  <FormattedMessage defaultMessage="Send today." />
                ) : (
                  <FormattedMessage defaultMessage="Send at a later date." />
                )}
              </span>
            </div>
            <DatePicker
              fullWidth
              disablePast
              disableFuture={false}
              date={formParams.sendDate}
              onChange={handleDateChange}
              languageRegionCode={languageRegionCode}
            />
          </div>
        )}
        <div className={styles.formSection}>
          <Button testId="customize-gift-submit" text={<FormattedMessage defaultMessage="Next" />} type="submit" variant="primary" width="full" />
        </div>
      </form>
      {renderBottomTray && <GiftTray closeTray={() => toggleRenderBottomTray(false)} />}
    </div>
  );
};

export default CustomizeGiftForm;
