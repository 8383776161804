import NavigationHeader from 'components/NavigationHeader';
import NavigationWrapper from 'components/NavigationWrapper';
import CustomProvider from 'components/CustomProvider';
import { ComponentProps } from 'react';
import useFeatures from 'hooks/useFeatures';

type Props = {
  currentPage?: string;
  locationSlug?: string;
} & Pick<
  ComponentProps<typeof NavigationHeader>,
  | 'background'
  | 'bottomBorder'
  | 'context'
  | 'hideSearch'
  | 'edgeToEdge'
  | 'navigateOnSelect'
  | 'onResultSelect'
  | 'placeholder'
  | 'useNewSearch'
  | 'value'
>;

function NavBar({ currentPage, locationSlug, ...navProps }: Props) {
  const globalNavEnabled = useFeatures().includes('global_nav');
  return globalNavEnabled ? <NavigationHeader {...navProps} /> : <NavigationWrapper currentPage={currentPage} locationSlug={locationSlug} />;
}

function Wrapper(props: Props) {
  return (
    <CustomProvider>
      <NavBar {...props} />
    </CustomProvider>
  );
}

export default Wrapper;
