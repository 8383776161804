import CountryCode from 'types/CountryCode';
import ProResponse from 'types/ProResponse';
import { ServerCommunicationUtil } from 'utils/server_communication_util';

type ProSubscription = {
  apple_pay_token?: string;
  billing_country: CountryCode;
  google_pay_token?: string;
  multiyear_conversion?: boolean;
  paypal_token?: string;
  recaptcha: string;
  price_code: string;
  uid: number;
  cc_token?: string;
  user: {
    billing_first_name?: string;
    billing_last_name?: string;
    zip?: string;
    user_entered_coupon_code?: string;
  };
};

// see def subscribe_pro
export default async function pro(body: ProSubscription): Promise<ProResponse> {
  const response = await fetch('/api/alltrails/purchases/subscriptions', {
    body: JSON.stringify(body),
    headers: {
      ...ServerCommunicationUtil.defaultAuthHeaders(),
      'Content-Type': 'application/json',
      'X-Language-Locale': __AT_DATA__.languageRegionCode
    },
    method: 'POST'
  });

  if (response.ok) {
    return response.json();
  }

  const errors = await response.json();

  return { errors };
}
