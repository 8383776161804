import PortalModal from 'components/shared/PortalModal';
import ModalInterior from '@alltrails/shared/components/modals/ModalInterior';
import ModalBackground from '@alltrails/shared/components/modals/ModalBackground';
import { useEffect, PropsWithChildren, ReactNode, ComponentProps } from 'react';

type Props = {
  baseCtas?: any;
  isOpen: boolean;
  onRequestClose: () => void;
  title?: ReactNode;
  goBack?: () => void;
  background?: ComponentProps<typeof ModalInterior>['background'];
  size?: ComponentProps<typeof ModalInterior>['size'];
  headerClassName?: ComponentProps<typeof ModalInterior>['headerClassName'];
  iconButtonVariant?: ComponentProps<typeof ModalInterior>['iconButtonVariant'];
  closeButtonStyle?: ComponentProps<typeof ModalInterior>['closeButtonStyle'];
  interiorClassName?: ComponentProps<typeof ModalInterior>['interiorClassName'];
  footerClassName?: ComponentProps<typeof ModalInterior>['footerClassName'];
};

// API based upon https://github.com/reactjs/react-modal which has over 1.6 million downloads a week 😍
export default function Modal({
  baseCtas,
  children,
  isOpen,
  onRequestClose,
  goBack,
  title,
  background,
  size,
  headerClassName,
  iconButtonVariant,
  closeButtonStyle,
  interiorClassName,
  footerClassName
}: PropsWithChildren<Props>) {
  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        if (isOpen) {
          onRequestClose();
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown, false);

    return () => {
      document.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [isOpen, onRequestClose]);

  return (
    <PortalModal isOpen={isOpen}>
      <ModalBackground closeModal={onRequestClose} goBack={goBack}>
        <ModalInterior
          background={background}
          baseCtas={baseCtas}
          closeModal={onRequestClose}
          goBack={goBack}
          isMounted
          title={title}
          size={size}
          headerClassName={headerClassName}
          iconButtonVariant={iconButtonVariant}
          interiorClassName={interiorClassName}
          closeButtonStyle={closeButtonStyle}
          footerClassName={footerClassName}
        >
          {children}
        </ModalInterior>
      </ModalBackground>
    </PortalModal>
  );
}
