import { DESKTOP_BREAKPOINT } from 'utils/constants/breakpoints';
import chunks from 'utils/chunks';
import River from 'components/shared/River';
import { LanguageSupportUtil } from 'utils/language_support_util';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import * as styles from './styles/styles.module.scss';
import ActivitySuitabilityImage from './ActivitySuitabilityImage';

const ARROW_STYLES = { next: styles.next, previous: styles.prev };

type Activity = {
  title: string;
  explore_url: string;
  photo_urls: {
    square: {
      two: string;
      urlWithoutExt: string;
    };
  };
};

type Props = {
  activities: Activity[];
  mobileBrowser: boolean;
  windowWidth: number;
};

const ActivitySuitabilityRiver = ({ activities, mobileBrowser, windowWidth }: Props): JSX.Element => {
  const languageRegionCode = useLanguageRegionCode();
  const mobileColumns = mobileBrowser || (windowWidth && windowWidth <= DESKTOP_BREAKPOINT);

  const chunkedActivities = [...chunks(activities, mobileColumns ? 3 : 2)];

  const slickSettings = { slidesToShow: 3, lazyLoad: 'progressive', slidesToScroll: 3, speed: 750 };

  return (
    <River useSlick={windowWidth >= 1270} slickSettings={slickSettings} arrowStyles={ARROW_STYLES}>
      {chunkedActivities.map(group => (
        <div className={mobileColumns ? styles.mobileColumns : styles.columns} key={group[0].title}>
          {group.map((activity: Activity) => (
            <ActivitySuitabilityImage
              url={LanguageSupportUtil.wrapUrlSafe(activity.explore_url, languageRegionCode)}
              defaultSrc={activity.photo_urls.square.two}
              srcWithoutExt={activity.photo_urls.square.urlWithoutExt}
              title={activity.title}
              key={activity.title}
            />
          ))}
        </div>
      ))}
    </River>
  );
};

export default ActivitySuitabilityRiver;
