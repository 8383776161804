// extracted by mini-css-extract-plugin
export var cardColumn = "CustomizeGiftForm-module__cardColumn___FQLQZ";
export var container = "CustomizeGiftForm-module__container___uWmm5";
export var fixedContainer = "CustomizeGiftForm-module__fixedContainer___OFTfI";
export var formColumn = "CustomizeGiftForm-module__formColumn___fYlso";
export var formHeader = "CustomizeGiftForm-module__formHeader___UrC0C";
export var formSection = "CustomizeGiftForm-module__formSection___cVpwl";
export var formSectionLg = "CustomizeGiftForm-module__formSectionLg___RoCRh";
export var formSectionSm = "CustomizeGiftForm-module__formSectionSm___fU9Rx";
export var inputContainer = "CustomizeGiftForm-module__inputContainer___GWmrq";
export var label = "CustomizeGiftForm-module__label___HUsJc";
export var mobileGiftCardContainer = "CustomizeGiftForm-module__mobileGiftCardContainer___gA1Ci";
export var sendDateHelperText = "CustomizeGiftForm-module__sendDateHelperText___ampY7";