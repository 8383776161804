import AvatarStack from '@alltrails/shared/denali/components/AvatarStack';
import { UserConnection, UserConnectionListKey } from '@alltrails/shared/types/belay';
import LanguageSupportUtil from 'utils/language_support_util';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { UserConnectionListModal } from '@alltrails/modules/UserConnections';
import '@alltrails/modules/UserConnections/index.css';
import * as styles from './styles/styles.module.scss';

type Props = {
  connections: UserConnection[];
  count: number;
  title: JSX.Element;
  listKey: UserConnectionListKey;
  onOverflowClick: () => void;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  userId?: number;
};

const BelayUserConnections = ({ connections, count, title, listKey, onOverflowClick, showModal, setShowModal, userId }: Props): JSX.Element => {
  const languageRegionCode = useLanguageRegionCode();
  const avatars = connections.map(({ user }) => ({
    userId: Number(user.id),
    userName: `${user.firstName} ${user.lastName}`,
    linkInfo: {
      href: LanguageSupportUtil.wrapUrlSafe(`/members/${user.slug}`, languageRegionCode)
    },
    hasPlus: user.pro,
    onClick: () => {}
  }));

  return (
    <div className={styles.avatarStack}>
      <div className={styles.header}>
        <span className={styles.title}>{title}</span>
        <span className={styles.count}>{count}</span>
      </div>
      <AvatarStack avatars={avatars} totalAvatars={count} onClick={onOverflowClick} testId="avatars" />
      {showModal && (
        <UserConnectionListModal
          className={styles.connectionsModal}
          listKey={listKey}
          closeModal={() => setShowModal(false)}
          otherUserId={userId}
          totalCount={count}
        />
      )}
    </div>
  );
};

export default BelayUserConnections;
