import { validatePostcode } from 'utils/address_helpers';

export const isValidName = (name: string) => {
  if (!name || name?.length < 1) {
    return false;
  }
  return true;
};

export const hasValidLastName = (name: string) => isValidName(name) && name.split(' ').filter(item => item !== '').length >= 2;

export const isValidPostalCode = (postalCode: string) => {
  if (!postalCode || !validatePostcode(postalCode, 'INTL') || postalCode?.length < 1 || postalCode.length > 10) {
    return false;
  }
  return true;
};

export const getBillingNames = (name: string) => {
  const fullNameClean = name.split(' ').filter((item: string) => item !== '');
  const firstName = fullNameClean[0];
  const lastName = fullNameClean[fullNameClean.length - 1];
  return { firstName: firstName || '', lastName: lastName || '' };
};
