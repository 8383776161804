// extracted by mini-css-extract-plugin
export var button = "PlusUpsell-module__button___o_pMN";
export var container = "PlusUpsell-module__container___eGbOp";
export var headingTable = "PlusUpsell-module__headingTable___ZvwDe";
export var image = "PlusUpsell-module__image___AeaM7";
export var logo = "PlusUpsell-module__logo___OHYXK";
export var newFeature = "PlusUpsell-module__newFeature____qMzE";
export var plus = "PlusUpsell-module__plus___FyWLr";
export var table = "PlusUpsell-module__table___S3qwP";
export var tableAndImageContainer = "PlusUpsell-module__tableAndImageContainer___MZTN9";
export var title = "PlusUpsell-module__title___QXLvZ";
export var upsellTable = "PlusUpsell-module__upsellTable___tMDHv";