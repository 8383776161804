const toATLocalString = (data = 0, languageRegionCode = 'en-US', precision = 0) => {
  // the minimum grouping behavior according to CLDR says for spanish the minimum grouping is 2
  // which causes numbers like 1000 to be written as 1000 instead of 1.000 where as 10000 will be written as 10.000
  // current work around is to set a spanish locale as german because that achieves our desired formatting in the app
  // https://stackoverflow.com/questions/57628055/tolocalestring-not-working-on-numbers-less-than-10000-in-all-browsers
  // http://cldr.unicode.org/translation/-core-data/numbering-systems#TOC-Minimum-digits-for-grouping

  const locale = languageRegionCode === 'es-ES' ? 'de-DE' : languageRegionCode;
  return Number(data).toLocaleString(locale, { minimumFractionDigits: precision, maximumFractionDigits: precision });
};

export { toATLocalString };
