import { useEffect, useState } from 'react';

const useScreenLock = () => {
  const [isMounted, toggleIsMounted] = useState(false);

  useEffect(() => {
    toggleIsMounted(true);
    document.querySelector('html').classList.add('modalOpen');

    return () => {
      document.querySelector('html').classList.remove('modalOpen');
      toggleIsMounted(false);
    };
  }, []);

  return [isMounted, toggleIsMounted];
};

export default useScreenLock;
