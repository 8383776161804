import { multiPolygon, polygon } from '@turf/helpers';
import bbox from '@turf/bbox';
import mask from '@turf/mask';
import { decodeLatLngs, decodedObjectsFromPolyline, decodeSegmentLngLat, reverseCoord } from '@alltrails/maps/utils/legacyGeoJSONConversions';
import { encodeObjectsToPolyline } from './polyline_util';

const decodeLngLats = pointsData => decodeLatLngs(pointsData).map(pt => reverseCoord(pt));

const encodeLatLngs = latLngs => encodeObjectsToPolyline(latLngs, 2, false, [5, 5]);

const decodeIdxElev = idxElevData => decodedObjectsFromPolyline(idxElevData, [2, 5]);

const decodeNDim = ndim => decodedObjectsFromPolyline(ndim, [5, 5, 5, 2, 5, 5]);

// Convert array of n-dim encoded polylines to standard (2-dim) polylines
const convertNDimTo2DimPolylines = nDimPolylines =>
  nDimPolylines.map(nDimPolyline => {
    const latLngs = decodeNDim(nDimPolyline).map(point => [point[0], point[1]]);
    return encodeLatLngs(latLngs);
  });

const decodeSegmentIdxElev = segment => {
  if (!segment || !segment.polyline) {
    return [];
  }
  const idxElevData = segment.polyline.indexedElevationData;
  if (!idxElevData) {
    return [];
  }
  return decodeIdxElev(idxElevData);
};

const decodeSegmentIdxElevHash = segment => {
  const hash = {};
  decodeSegmentIdxElev(segment).forEach(pair => {
    // eslint-disable-next-line prefer-destructuring
    hash[pair[0]] = pair[1];
  });
  return hash;
};

const demPtToNdim = pt => [pt.location.latitude, pt.location.longitude, pt.elevation, 0, 0, 0];

const encodeSegmentLatLng = (latLngs, sequenceNum) => ({
  polyline: {
    pointsData: encodeLatLngs(latLngs)
  },
  sequence_num: sequenceNum
});

const encodeSegmentLngLat = (lngLats, sequenceNum) =>
  encodeSegmentLatLng(
    lngLats.map(lngLat => reverseCoord(lngLat)),
    sequenceNum
  );

const buildAtMap = (data, mapString) => ({
  name: mapString,
  source: 1033,
  presentationType: 'M',
  ...data // Destructing should override any default params above
});

const lngLatBoundsToAtBounds = lngLatBounds => ({
  latitudeBottomRight: lngLatBounds.getSouth(),
  latitudeTopLeft: lngLatBounds.getNorth(),
  longitudeBottomRight: lngLatBounds.getEast(),
  longitudeTopLeft: lngLatBounds.getWest()
});

const atBoundsToLngLatBounds = atBounds => {
  const { longitudeTopLeft, latitudeTopLeft, longitudeBottomRight, latitudeBottomRight } = atBounds;
  const sw = [longitudeTopLeft, latitudeBottomRight];
  const ne = [longitudeBottomRight, latitudeTopLeft];
  return [sw, ne];
};

const lngLatToAtLocation = lngLat => {
  const { lat, lng } = lngLat;
  return {
    latitude: lat,
    longitude: lng
  };
};

const lngLatBoundsToAlgoliaBounds = map => {
  const bounds = map.getBounds();
  const boundingBox = [bounds.getNorth(), bounds.getWest(), bounds.getSouth(), bounds.getEast()].join(',');
  return boundingBox;
};

/**
 * isMultiPolygon checks the length of isochrone data coordinates array
 * if number is higher than 1 that means that polygon is multi polygon
 *
 * @param {[number, number][][]} isochroneDataCoordinates
 * @returns boolean
 */

const isMultiPolygon = isochroneDataCoordinates => isochroneDataCoordinates?.length > 1;

/**
 * normalizeCoordinates normalize coordinates in the way so
 * they can be added inside polygon
 *
 * @param {[number, number][][]} isochroneDataCoordinates
 * @returns
 */

const normalizeCoordinates = coordinates => {
  const concatArray = coordinates[0].concat(coordinates[1]);
  return [].concat([...concatArray]);
};

/**
 * normalizeMultiPolygonData normalize multiple polygon data in the way so
 * they can be used in algoliaSearchQuery
 *
 * @param {[number, number][][]} data
 * @returns
 */

const normalizeMultiPolygonData = data => {
  const flatArray = [].concat(...data);
  const flatPolygon = [].concat(...flatArray);
  return [flatPolygon];
};

/**
 * isochroneDataToInsidePolygon converts our API's isochrone JSON structure to
 * an inside polygon compatible with Algolia's search parameter.
 *
 * @param {IsochroneData} isochroneData
 * @returns
 */
const isochroneDataToInsidePolygon = isochroneData => {
  const isochroneDataCoordinates = isochroneData?.features?.[0]?.geometry?.coordinates;
  let coordinates = isochroneDataCoordinates;

  if (isMultiPolygon(coordinates)) {
    coordinates = normalizeCoordinates(coordinates);
  }

  return coordinates?.map(polygonCoordinates => polygonCoordinates.reduce((f, latLngPair) => f.concat([latLngPair[1], latLngPair[0]]), []));
};

/**
 * isochroneDataToBoundingBox converts an isochrone JSON structure to a bounding
 * box, which is helpful for calculating map bounds/zoom levels to fit the
 * isochrone layer.
 *
 * @param {IsochroneData} isochroneData
 * @returns {[number, number, number, number]}
 */
const isochroneDataToBoundingBox = isochroneData => bbox(isochroneData);

/**
 * isochroneDataToInvertedIsochrone inverts a given isochrone polygon so that it
 * is the entire earth with the isochrone polygon missing and set as a hole.
 *
 * @param {IsochroneData} isochroneData
 * @returns
 */
const isochroneDataToInvertedIsochrone = isochroneData => {
  const coordinates = isochroneData?.features?.[0]?.geometry?.coordinates;
  if (isMultiPolygon(coordinates)) {
    return mask(multiPolygon(coordinates));
  }

  // By omitting a second argument to mask() it is implied that we are masking
  // the entire earth/global map bounds.
  return mask(polygon(coordinates));
};

const getMapDistance = map => {
  // Use != to match both null and undefined
  if (map?.summaryStats && map?.summaryStats.distanceTotal != null) {
    return map.summaryStats.distanceTotal;
  }
  let distance = 0.0;
  if (map?.routes) {
    distance += map.routes
      .map(route => {
        if (route.lineGeoStats) {
          const segDistance = parseFloat(route.lineGeoStats.distanceTotal);
          return Number.isNaN(segDistance) ? 0.0 : segDistance;
        }
        return 0.0;
      })
      .reduce((a, b) => a + b, 0);
  }
  if (map?.tracks) {
    distance += map.tracks
      .map(track => {
        if (track.lineTimedGeoStats) {
          const segDistance = parseFloat(track.lineTimedGeoStats.distanceTotal);
          return Number.isNaN(segDistance) ? 0.0 : segDistance;
        }
        return 0.0;
      })
      .reduce((a, b) => a + b, 0);
  }
  return distance;
};

const isMapFaulty = map => {
  if (!map) {
    return true;
  }

  if (map.routes) {
    const missingLineSegments = map.routes.some(route => !route.lineSegments || route.lineSegments.length < 1);

    if (missingLineSegments) {
      return true;
    }
  }
  return false;
};

const getLineCenterCoordinates = route => {
  if (!route) {
    return null;
  }
  const coordinates = route.lineSegments.map(segment => decodeSegmentLngLat(segment)).flat();
  if (coordinates.length === 0) {
    return coordinates;
  }

  return coordinates[Math.round(coordinates.length / 2)];
};

/**
 * Functions to calculate min lat/lng from IP address lat/lng
 * Approximate locally the Earth surface as a sphere with radius given by the WGS84
 * ellipsoid at the given latitude. We suspect that the exact computation of latMin and latMax
 * would require elliptic functions and would not yield an appreciable increase in accuracy
 * (WGS84 is itself an approximation).
 *
 * https://stackoverflow.com/questions/238260/how-to-calculate-the-bounding-box-for-a-given-lat-lng-location
 * http://JanMatuschek.de/LatitudeLongitudeBoundingCoordinates
 */

// degrees to radians
const deg2rad = degrees => Math.PI * (degrees / 180);
// radians to degrees
const rad2deg = radians => 180 * (radians / Math.PI);
// earth radius at a given latitude, according to the WGS-84 ellipsoid
const WGS84EarthRadius = lat => {
  const WGS84_a = 6378137.0; // semimajor axis
  const WGS84_b = 6356752.3; // semiminor axis

  // earth radius: https://en.wikipedia.org/wiki/Earth_radius
  const An = WGS84_a * WGS84_a * Math.cos(lat);
  const Bn = WGS84_b * WGS84_b * Math.sin(lat);
  const Ad = WGS84_a * Math.cos(lat);
  const Bd = WGS84_b * Math.sin(lat);
  return Math.sqrt((An * An + Bn * Bn) / (Ad * Ad + Bd * Bd));
};
const getBoundingBoxFromLatLng = (latitudeInDegrees, longitudeInDegrees, halfSideInKm) => {
  const lat = deg2rad(latitudeInDegrees);
  const lon = deg2rad(longitudeInDegrees);
  const halfSide = 1000 * halfSideInKm;

  // Radius of Earth at given latitude
  const radius = WGS84EarthRadius(lat);
  // Radius of the parallel at given latitude
  const pradius = radius * Math.cos(lat);

  const latMin = lat - halfSide / radius;
  const latMax = lat + halfSide / radius;
  const lonMin = lon - halfSide / pradius;
  const lonMax = lon + halfSide / pradius;

  return {
    latitudeBottomRight: rad2deg(latMin),
    longitudeTopLeft: rad2deg(lonMin),
    latitudeTopLeft: rad2deg(latMax),
    longitudeBottomRight: rad2deg(lonMax)
  };
};

export {
  atBoundsToLngLatBounds,
  buildAtMap,
  convertNDimTo2DimPolylines,
  decodeNDim,
  decodeLngLats,
  decodeSegmentIdxElev,
  decodeSegmentIdxElevHash,
  demPtToNdim,
  encodeLatLngs,
  encodeSegmentLngLat,
  getLineCenterCoordinates,
  getMapDistance,
  isMapFaulty,
  isochroneDataToBoundingBox,
  isochroneDataToInsidePolygon,
  isochroneDataToInvertedIsochrone,
  lngLatBoundsToAlgoliaBounds,
  lngLatBoundsToAtBounds,
  lngLatToAtLocation,
  getBoundingBoxFromLatLng,
  isMultiPolygon,
  normalizeMultiPolygonData
};
