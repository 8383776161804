import { defineMessages } from '@alltrails/shared/react-intl';

const FILTER_ACCESS_STRINGS = defineMessages({
  ADA: {
    defaultMessage: 'Wheelchair-friendly'
  },
  WHEELCHAIR_FRIENDLY: {
    defaultMessage: 'Wheelchair-friendly'
  },
  DOGS: {
    defaultMessage: 'Dog-friendly'
  },
  DOGS_LEASH: {
    defaultMessage: 'Dogs on leash'
  },
  DOGS_ON_LEASH: {
    defaultMessage: 'Dogs on leash'
  },
  KIDS: {
    defaultMessage: 'Kid-friendly'
  },
  KID_FRIENDLY: {
    defaultMessage: 'Kid-friendly'
  },
  NO_DOGS: {
    defaultMessage: 'Dogs not allowed'
  },
  DOGS_NO: {
    defaultMessage: 'Dogs not allowed'
  },
  PARTIALLY_PAVED: {
    defaultMessage: 'Partially paved'
  },
  PAVED: {
    defaultMessage: 'Paved'
  },
  STROLLERS: {
    defaultMessage: 'Stroller-friendly'
  },
  STROLLER_FRIENDLY: {
    defaultMessage: 'Stroller-friendly'
  }
});

const FILTER_ACTIVITIES_STRINGS = defineMessages({
  BACKPACKING: {
    defaultMessage: 'Backpacking'
  },
  BIKE_TOURING: {
    defaultMessage: 'Bike touring'
  },
  BIRDING: {
    defaultMessage: 'Birding'
  },
  CAMPING: {
    defaultMessage: 'Camping'
  },
  CROSS_COUNTRY_SKIING: {
    defaultMessage: 'Cross-country skiing'
  },
  FISHING: {
    defaultMessage: 'Fishing'
  },
  HIKING: {
    defaultMessage: 'Hiking'
  },
  HORSEBACK_RIDING: {
    defaultMessage: 'Horseback riding'
  },
  MOUNTAIN_BIKING: {
    defaultMessage: 'Mountain biking'
  },
  OFF_ROAD_DRIVING: {
    defaultMessage: 'Off-road driving'
  },
  PADDLE_SPORTS: {
    defaultMessage: 'Paddle sports'
  },
  ROAD_BIKING: {
    defaultMessage: 'Road biking'
  },
  ROCK_CLIMBING: {
    defaultMessage: 'Rock climbing'
  },
  SCENIC_DRIVING: {
    defaultMessage: 'Scenic driving'
  },
  SKIING: {
    defaultMessage: 'Skiing'
  },
  SNOWSHOEING: {
    defaultMessage: 'Snowshoeing'
  },
  SURFING: {
    defaultMessage: 'Surfing'
  },
  TRAIL_RUNNING: {
    defaultMessage: 'Running'
  },
  RUNNING: {
    defaultMessage: 'Running'
  },
  VIA_FERRATA: {
    defaultMessage: 'Via ferrata'
  },
  WALKING: {
    defaultMessage: 'Walking'
  },
  NATURE_TRIPS: {
    defaultMessage: 'Nature trips'
  },
  BIRD_WATCHING: {
    defaultMessage: 'Birding'
  }
});

const FILTER_DIFFICULTIES_STRINGS = defineMessages({
  EASY: {
    defaultMessage: 'Easy'
  },
  MODERATE: {
    defaultMessage: 'Moderately challenging'
  },
  HARD: {
    defaultMessage: 'Challenging'
  }
});

const FILTER_FEATURE_TYPES_STRINGS = defineMessages({
  BEACH: {
    defaultMessage: 'Beaches'
  },
  CAVE: {
    defaultMessage: 'Caves'
  },
  CITY_WALK: {
    defaultMessage: 'City walks'
  },
  EVENT: {
    defaultMessage: 'Events'
  },
  FOREST: {
    defaultMessage: 'Forests'
  },
  HISTORIC_SITE: {
    defaultMessage: 'Historic sites'
  },
  HOT_SPRINGS: {
    defaultMessage: 'Hot springs'
  },
  LAKE: {
    defaultMessage: 'Lakes'
  },
  PUB_WALK: {
    defaultMessage: 'Pub walks'
  },
  PUB_CRAWL: {
    defaultMessage: 'Pub walks'
  },
  RAILS_TRAILS: {
    defaultMessage: 'Rail trails'
  },
  RIVER: {
    defaultMessage: 'Rivers'
  },
  VIEWS: {
    defaultMessage: 'Views'
  },
  WATERFALL: {
    defaultMessage: 'Waterfalls'
  },
  WILD_FLOWERS: {
    defaultMessage: 'Wildflowers'
  },
  WILDLIFE: {
    defaultMessage: 'Wildlife'
  }
});

const FILTER_LENGTHS_STRINGS = defineMessages({
  SHORT: {
    defaultMessage: 'Short'
  },
  LONG: {
    defaultMessage: 'Long'
  }
});

const FILTER_OBSTACLES_STRINGS = defineMessages({
  BLOWDOWN: {
    defaultMessage: 'Blowdown'
  },
  BRIDGE_OUT: {
    defaultMessage: 'Bridge out'
  },
  BUGS: {
    defaultMessage: 'Bugs'
  },
  CLOSED: {
    defaultMessage: 'Closed'
  },
  FEE: {
    defaultMessage: 'Fee required'
  },
  FLOODED: {
    defaultMessage: 'Flooded'
  },
  GREAT: {
    defaultMessage: 'Great!'
  },
  ICY: {
    defaultMessage: 'Icy'
  },
  MUDDY: {
    defaultMessage: 'Muddy'
  },
  NO_SHADE: {
    defaultMessage: 'No shade'
  },
  OFF_TRAIL: {
    defaultMessage: 'Off-trail (bushwhack)'
  },
  OVER_GROWN: {
    defaultMessage: 'Overgrown'
  },
  PRIVATE_PROPERTY: {
    defaultMessage: 'Private property'
  },
  ROCKY: {
    defaultMessage: 'Rocky'
  },
  SCRAMBLE: {
    defaultMessage: 'Scramble'
  },
  SNOW: {
    defaultMessage: 'Snow'
  },
  WASHED_OUT: {
    defaultMessage: 'Washed out'
  }
});

const FILTER_ROUTE_TYPES_FILTER = defineMessages({
  LOOP: {
    defaultMessage: 'Loop'
  },
  OUT_AND_BACK: {
    defaultMessage: 'Out & back'
  },
  POINT_TO_POINT: {
    defaultMessage: 'Point to point'
  }
});

const FILTER_SORT_TYPES_STRINGS = defineMessages({
  BEST_MATCH: {
    defaultMessage: 'Best match'
  },
  MOST_POPULAR: {
    defaultMessage: 'Most popular'
  },
  NEWLY_ADDED: {
    defaultMessage: 'Newly added'
  },
  CLOSEST: {
    defaultMessage: 'Closest'
  },
  LIST_ORDER: {
    defaultMessage: 'List order'
  },
  DATE: {
    defaultMessage: 'Date'
  },
  NAME: {
    defaultMessage: 'Name'
  },
  RECENTLY_ADDED: {
    defaultMessage: 'Recently added'
  }
});

const FILTER_USAGES_STRING = defineMessages({
  LIGHT: {
    defaultMessage: 'Light'
  },
  MODERATE: {
    id: 'usage.Moderate',
    defaultMessage: 'Moderate'
  },
  HEAVY: {
    defaultMessage: 'Heavy'
  },
  EXTRA_HEAVY: {
    defaultMessage: 'Extra heavy'
  }
});

const FILTER_STRINGS = defineMessages({
  MORE_FILTERS: {
    defaultMessage: 'More filters'
  },
  TRAIL_LINKED: {
    defaultMessage: 'Linked to trail'
  },
  TRAIL_UNLINKED: {
    defaultMessage: 'Not linked to trail'
  },
  TRAIL_COMPLETION: {
    defaultMessage: 'Trail completion'
  },
  DIFFICULTY: {
    defaultMessage: 'Difficulty'
  },
  ACTIVITY: {
    defaultMessage: 'What to do'
  },
  ACTIVITY_LABEL: {
    defaultMessage: 'Activity'
  },
  FEATURES: {
    defaultMessage: 'What to see'
  },
  FEATURES_LABEL: {
    defaultMessage: 'Attractions'
  },
  SUITABILITY: {
    defaultMessage: 'Suitability'
  },
  PARK: {
    defaultMessage: 'Park'
  },
  CITY: {
    defaultMessage: 'City'
  },
  STATE: {
    defaultMessage: 'State'
  },
  COUNTRY: {
    defaultMessage: 'Country'
  },
  ELEV_GAIN: {
    defaultMessage: 'Elevation gain'
  },
  HIGHEST_POINT: {
    defaultMessage: 'Highest point'
  },
  LENGTH: {
    defaultMessage: 'Length'
  },
  TIME: {
    defaultMessage: 'Time'
  },
  EST: {
    defaultMessage: 'Est.'
  },
  ROUTE_TYPE: {
    defaultMessage: 'Route type'
  },
  TRAIL_TRAFFIC: {
    defaultMessage: 'Trail traffic'
  },
  RATING: {
    defaultMessage: 'Rating'
  },
  SORT: {
    defaultMessage: 'Sort by'
  },
  SORT_LABEL: {
    defaultMessage: 'Sort'
  },
  DISTANCE_AWAY: {
    defaultMessage: 'Distance away'
  },
  AND_UP: {
    defaultMessage: '& up'
  },
  COMPLETED: {
    defaultMessage: 'Completed'
  },
  NOT_COMPLETED: {
    defaultMessage: 'Not completed'
  },
  VERIFIED_COMPLETED: {
    defaultMessage: 'Verified completed'
  },
  OPEN: {
    defaultMessage: 'Open'
  }
});

export {
  FILTER_ACCESS_STRINGS,
  FILTER_ACTIVITIES_STRINGS,
  FILTER_DIFFICULTIES_STRINGS,
  FILTER_FEATURE_TYPES_STRINGS,
  FILTER_LENGTHS_STRINGS,
  FILTER_OBSTACLES_STRINGS,
  FILTER_ROUTE_TYPES_FILTER,
  FILTER_SORT_TYPES_STRINGS,
  FILTER_USAGES_STRING,
  FILTER_STRINGS
};
