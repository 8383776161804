import { ComponentProps, PropsWithChildren, useMemo, useState } from 'react';
import FooterWrapper from 'components/FooterWrapper';
import GlobalActions from 'components/GlobalActions';
import NavBar from 'components/NavBar';
import NavBarSearchStateSetterContext from 'components/NavigationHeader/NavBarSearchStateSetterContext';

type OnResultSelect = ComponentProps<typeof NavBar>['onResultSelect'];

type Props = {
  doNotRenderMainContentId?: boolean;
  wideFooter?: boolean;
} & Omit<ComponentProps<typeof NavBar>, 'navigateOnSelect' | 'onResultSelect' | 'value'>;

export default function ApplicationLayout({ children, doNotRenderMainContentId, wideFooter = false, ...navBarProps }: PropsWithChildren<Props>) {
  const [navBarOnResultSelect, setNavBarOnResultSelect] = useState<OnResultSelect>();
  const [navBarValue, setNavBarValue] = useState('');

  const onResultSelect = useMemo<OnResultSelect>(() => {
    if (navBarOnResultSelect) {
      // We only care to set the nav bar value if the onResultSelect callback has been set
      // because otherwise, navigateOnSelect is true and the header will be unmounted anyways
      return (result, additionalInfo) => {
        navBarOnResultSelect(result, additionalInfo);
        setNavBarValue(result.name);
      };
    }
    return undefined;
  }, [navBarOnResultSelect]);

  const contextValue = useMemo(() => ({ setNavBarOnResultSelect, setNavBarValue }), []);

  return (
    <NavBarSearchStateSetterContext.Provider value={contextValue}>
      <GlobalActions />
      <div id="header">
        <NavBar {...navBarProps} navigateOnSelect={!onResultSelect} onResultSelect={onResultSelect} value={navBarValue} />
      </div>
      {doNotRenderMainContentId ? children : <main id="main-content">{children}</main>}
      <FooterWrapper wideFooter={wideFooter} />
    </NavBarSearchStateSetterContext.Provider>
  );
}
