import { MEDIA_QUERY_SMALL_DOWN } from 'utils/constants/breakpoints';
import { useIntl } from '@alltrails/shared/react-intl';
import { ReactNode } from 'react';
import useSample from 'hooks/useSample';
import InstallInterstitial from 'components/DeepLink/InstallInterstitial';
import useDeeplinkComponent from 'components/DeepLink/hooks';
import InstallPromptLocation from '@alltrails/analytics/enums/InstallPromptLocation';
import * as styles from './LoginSignupLayout.module.scss';
import hero12800AvifSrc from './hero1-2800w.avif';
import hero12800JpgSrc from './hero1-2800w.jpg';
import hero12800WebpSrc from './hero1-2800w.webp';
import hero22800AvifSrc from './hero2-2800w.avif';
import hero22800JpgSrc from './hero2-2800w.jpg';
import hero22800WebpSrc from './hero2-2800w.webp';
import hero32800AvifSrc from './hero3-2800w.avif';
import hero32800JpgSrc from './hero3-2800w.jpg';
import hero32800WebpSrc from './hero3-2800w.webp';
import hero42800AvifSrc from './hero4-2800w.avif';
import hero42800JpgSrc from './hero4-2800w.jpg';
import hero42800WebpSrc from './hero4-2800w.webp';
import hero768AvifSrc from './hero-768w.avif';
import hero768JpgSrc from './hero-768w.jpg';
import hero768WebpSrc from './hero-768w.webp';

type Props = {
  children: ReactNode;
  showBranchInterstitial?: boolean;
};

export default function LoginSignupHero({ children, showBranchInterstitial }: Props) {
  const intl = useIntl();
  const [isInterstitialOpen, setIsInterstitialOpen] = useDeeplinkComponent('interstitial', true);

  const pictures = [
    {
      alt: intl.formatMessage({
        defaultMessage:
          'On one side, people ride bikes up a scenic road with a city in the distance; on the other, a woman walks a flat trail through a quiet, old-growth forest.'
      }),
      jpg: hero12800JpgSrc,
      avif: hero12800AvifSrc,
      webp: hero12800WebpSrc
    },
    // https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=4709%3A92747
    {
      alt: intl.formatMessage({
        defaultMessage:
          'On one side, a person prepares their mountain bike for a ride; on the other, a woman and her dog pause on a nature trail with the Hollywood sign behind them.'
      }),
      jpg: hero22800JpgSrc,
      avif: hero22800AvifSrc,
      webp: hero22800WebpSrc
    },
    // https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=4709%3A93079
    {
      alt: intl.formatMessage({
        defaultMessage:
          'On one side, a person is silhouetted atop a hill with the sunset behind them; on the other, a group in t-shirts walks a casual trail toward snow-peaked mountains.'
      }),
      jpg: hero32800JpgSrc,
      avif: hero32800AvifSrc,
      webp: hero32800WebpSrc
    },
    // https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=4709%3A93328
    {
      alt: intl.formatMessage({
        defaultMessage:
          'On one side, a person stands in the shadow of stunning red-rock formations, on the other, a woman smiles joyfully in a field with a flower over one ear.'
      }),
      jpg: hero42800JpgSrc,
      avif: hero42800AvifSrc,
      webp: hero42800WebpSrc
    }
  ];

  const picture = useSample(pictures);

  return (
    <>
      <div className={styles.content}>
        <picture className={styles.picture}>
          <source media={MEDIA_QUERY_SMALL_DOWN} srcSet={hero768AvifSrc} type="image/avif" />
          <source media={MEDIA_QUERY_SMALL_DOWN} srcSet={hero768WebpSrc} type="image/webp" />
          <source media={MEDIA_QUERY_SMALL_DOWN} srcSet={hero768JpgSrc} type="image/jpg" />
          <source srcSet={picture.avif} type="image/avif" />
          <source srcSet={picture.webp} type="image/webp" />
          {/* minor bug here with alt text and mobile */}
          <img src={picture.jpg} alt={picture.alt} className={styles.img} />
        </picture>
        <div className={styles.form}>{children}</div>
      </div>
      {showBranchInterstitial && (
        <InstallInterstitial
          isOpen={isInterstitialOpen}
          onRequestClose={() => setIsInterstitialOpen(false)}
          promptLocation={InstallPromptLocation.SignupPage}
        />
      )}
    </>
  );
}
