import { FormattedMessage } from '@alltrails/shared/react-intl';
import Link from '@alltrails/shared/denali/components/Link';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import Typography from '@alltrails/shared/denali/components/Typography';
import getHelpCenterUrl from '@alltrails/shared/utils/constants/helpCenterUrl';

const a1 = (chunks: string) => (
  <Link href="/terms" target="_blank" size="sm" testId="tos-a1">
    {chunks}
  </Link>
);

const a2 = (chunks: string) => (
  <Link href="/privacy" target="_blank" size="sm" testId="tos-a2">
    {chunks}
  </Link>
);

const a3 = (chunks: string, languageRegionCode: LanguageRegionCode) => {
  const helpUrl = getHelpCenterUrl(languageRegionCode);
  return (
    <Link href={`${helpUrl}/categories/360001252091-Account-Privacy`} target="_blank" size="sm" testId="tos-a3">
      {chunks}
    </Link>
  );
};

// https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=1571%3A79911
export default function TermsOfService() {
  const languageRegionCode = useLanguageRegionCode();
  return (
    <Typography color="secondary" component="div" variant="paragraph100">
      <FormattedMessage
        defaultMessage="By continuing to use AllTrails, you agree to our <a1>Terms of Service</a1> and <a2>Privacy Policy</a2>. Personal data added to AllTrails is public by default — refer to our <a3>Privacy FAQs</a3> to make changes."
        values={{
          a1,
          a2,
          a3: (chunks: string) => a3(chunks, languageRegionCode)
        }}
      />
    </Typography>
  );
}
