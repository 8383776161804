import { lazy, MouseEvent, Suspense, useState } from 'react';
import { PageStrings } from '@alltrails/shared/utils/constants/pageStringHelpers';
import NavigationHeader from '@alltrails/shared/components/NavigationHeader';
import useIpCountryCode from 'hooks/useIpCountryCode';
import useUser from 'hooks/useUser';
import { NoSsr } from '@material-ui/core';
import { defineMessages } from '@alltrails/shared/react-intl';
import { wrapUrlSafe } from 'utils/language_support_util';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { buildSignupUrl } from 'utils/roadblocks';
import handleRoadblock from 'utils/handleRoadblock';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import Link from '@alltrails/shared/denali/components/Link';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import InstallInterstitial from 'components/DeepLink/InstallInterstitial';
import useDeeplinkComponent from 'components/DeepLink/hooks';
import InstallPromptLocation from '@alltrails/analytics/enums/InstallPromptLocation';
import * as styles from './styles/styles.module.scss';
import CustomProvider from '../CustomProvider';
import MobileHomeLink from './MobileHomeLink';

const SignupModal = lazy(() => import('components/SignupModal'));

type NavigationWrapperProps = {
  currentPage: string;
  locationSlug: string;
};

const NAVIGATION_WRAPPER_STRINGS = defineMessages({
  MAP: {
    defaultMessage: 'Map'
  }
});

const Right = ({ currentPage, locationSlug }: NavigationWrapperProps): JSX.Element => {
  const languageRegionCode = useLanguageRegionCode();
  const user = useUser();
  const {
    formattedDefaultMessages: { MAP }
  } = useFormatMessage(NAVIGATION_WRAPPER_STRINGS);
  const [isInterstitialOpen, setIsInterstitialOpen] = useDeeplinkComponent('interstitial', false);

  // see https://github.com/alltrails/alltrails/blob/alpha/app/controllers/explore_controller.rb#L9
  // we can refactor this to use pathToRegexp so we don't need derived state of page strings and eliminate currentPage
  if (currentPage !== PageStrings.EXPLORE_ALL_PAGE) {
    if (locationSlug) {
      const href = wrapUrlSafe(`/explore/${locationSlug}?mobileMap=true`, languageRegionCode);

      return (
        <div className={styles.rightContainer}>
          <Link
            size="md"
            href={user ? href : buildSignupUrl(href, languageRegionCode)}
            testId="mobile-home-link-map"
            onClick={(e: MouseEvent<HTMLAnchorElement>) => {
              e.preventDefault();

              handleRoadblock({
                eventName: 'trail_header_map',
                languageRegionCode,
                user,
                returnToUrl: href,
                afterRoadblock: href,
                setIsInterstitialOpen
              });
            }}
          >
            {MAP}
          </Link>
          <InstallInterstitial
            isOpen={isInterstitialOpen}
            onRequestClose={() => setIsInterstitialOpen(false)}
            promptLocation={InstallPromptLocation.HomePage}
          />
        </div>
      );
    }
  }

  return (
    <div className={styles.rightContainer}>
      <MobileHomeLink />
    </div>
  );
};

type NavigationProps = {
  currentPage: string;
  locationSlug: string;
};

const Navigation = ({ currentPage, locationSlug }: NavigationProps): JSX.Element => {
  const ipCountryCode = useIpCountryCode();
  const user = useUser();
  const languageRegionCode = useLanguageRegionCode();
  const [{ isOpen, redirectUrl }, toggleSignupModal] = useState<{ isOpen: boolean; redirectUrl?: string }>({ isOpen: false });

  return (
    <>
      <NoSsr>
        <Suspense fallback={null}>
          <SignupModal
            trigger={CarouselDisplayTrigger.NavigateToFeature}
            isOpen={isOpen}
            onRequestClose={() => {
              toggleSignupModal({ isOpen: false });
            }}
            onSuccess={() => {
              // no-op because of redirectUrl
            }}
            redirectUrl={redirectUrl}
          />
        </Suspense>
      </NoSsr>
      <NavigationHeader
        className={styles.bottomBorder}
        inUS={ipCountryCode === 'US'}
        rightChildren={<Right locationSlug={locationSlug} currentPage={currentPage} />}
        user={user}
        toggleSignupModal={(e: MouseEvent<HTMLAnchorElement>, redirectUrl: string) => {
          // !user check isn't necessary because we only call this for logged out props
          e.preventDefault();
          toggleSignupModal({ isOpen: !isOpen, redirectUrl });
        }}
        languageRegionCode={languageRegionCode}
        irclickid={__AT_DATA__.irclickid}
      />
    </>
  );
};

function Wrapper({ currentPage, locationSlug }: NavigationProps) {
  return (
    <CustomProvider>
      <Navigation currentPage={currentPage} locationSlug={locationSlug} />
    </CustomProvider>
  );
}

export default Wrapper;
