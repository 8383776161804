import { FormattedMessage } from '@alltrails/shared/react-intl';
import Chevron from '@alltrails/shared/icons/Chevron';
import logHomepageRiverSeeMoreSelected from '@alltrails/analytics/events/logHomepageRiverSeeMoreSelected';
import { RiverAnalyticsData } from '@alltrails/modules/Lists/listAmplitudeHelpers';
import * as styles from './styles/styles.module.scss';

type ShowMoreCardProps = {
  url: string;
  riverAnalyticsData: RiverAnalyticsData;
  cardIndex: number;
};

const ShowMoreCard = ({ url, riverAnalyticsData, cardIndex }: ShowMoreCardProps) => {
  const handleClickShowMore = () => {
    logHomepageRiverSeeMoreSelected({
      river_filter_set: JSON.stringify(riverAnalyticsData?.filters),
      river_index: riverAnalyticsData?.riverIndex,
      river_item_index: cardIndex,
      river_title: riverAnalyticsData?.riverTitle,
      river_title_filter: riverAnalyticsData?.riverTitleFilter,
      section_type: riverAnalyticsData?.riverSectionType
    });
  };

  return (
    <a href={url} className={styles.container} onClick={handleClickShowMore}>
      <div className={styles.showMore}>
        <FormattedMessage defaultMessage="Show more" />
        <Chevron orientation="right" />
      </div>
    </a>
  );
};

export default ShowMoreCard;
