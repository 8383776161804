import { ReactNode, useState } from 'react';
import { defineMessages, FormattedMessage } from '@alltrails/shared/react-intl';
import Button from '@alltrails/shared/denali/components/Button';
import OutlinedTextField from '@alltrails/shared/components/OutlinedTextField';
import { execute } from 'utils/recaptcha_helpers';
import * as formStyles from 'components/forms/styles/styles.module.scss';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import type { User } from 'types/User';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import authSuccessCallback from 'utils/AuthHandlers';
import { wrapUrlSafe } from 'utils/language_support_util';
import logLogInScreenContinueButtonTapped from '@alltrails/analytics/events/logLogInScreenContinueButtonTapped';
import SignUpScreenContinueButtonType from '@alltrails/analytics/enums/SignUpScreenContinueButtonType';
import isValidEmail from 'utils/isValidEmail';
import LoginResponse from 'types/LoginResponse';
import { ApiError, post } from '@alltrails/shared/api';
import AuthenticationType from '@alltrails/analytics/enums/AuthenticationType';
import logLogInFailed from '@alltrails/analytics/events/logLogInFailed';
import useFeatures from 'hooks/useFeatures';

const messages = defineMessages({
  logIn: {
    defaultMessage: 'Log in'
  }
});

type Props = {
  handleLoginSuccess: ({ user }: { user: User }) => void;
  returnTo?: string;
};

function validateEmail(value: string) {
  if (!isValidEmail(value)) {
    return <FormattedMessage defaultMessage="Email is not valid." />;
  }

  return undefined; // text field uses undefined
}

function validatePassword(value: string) {
  if (value.length < 6) {
    return <FormattedMessage defaultMessage="Password must be at least 6 characters long." />;
  }

  if (value.length > 128) {
    return <FormattedMessage defaultMessage="Password must not exceed 128 characters." />;
  }

  return undefined; // text field uses undefined
}

export default function EmailPasswordLogin({ handleLoginSuccess, returnTo = '/' }: Props) {
  const features = useFeatures();

  const {
    formattedDefaultMessages: { logIn }
  } = useFormatMessage(messages);

  const languageRegionCode = useLanguageRegionCode();
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [userEmailError, setUserEmailError] = useState<ReactNode>();
  const [userPasswordError, setUserPasswordError] = useState<ReactNode>();
  const [serverError, setServerError] = useState<ReactNode>();
  const [submitting, setSubmitting] = useState(false);
  const [showErrorsOnType, setShowErrorsOnType] = useState(false);

  function failedFormValidation() {
    const emailError = validateEmail(userEmail);
    const passwordError = validatePassword(userPassword);

    setUserEmailError(emailError);
    setUserPasswordError(passwordError);

    return emailError || passwordError;
  }

  function handleChange(e: any) {
    e.preventDefault();
    const { name, value } = e.target;

    // we don't want to show errors on type unless a user has already tried to submit a form
    if (showErrorsOnType) {
      if (name === 'userEmail') {
        setUserEmailError(validateEmail(value));
      }

      if (name === 'userPassword') {
        setUserPasswordError(validatePassword(value));
      }
    }
  }

  async function handleLogin(e: React.FormEvent) {
    e.preventDefault();
    logLogInScreenContinueButtonTapped({ login_screen_continue_button_type: SignUpScreenContinueButtonType.Email });

    if (submitting) {
      return;
    }

    if (failedFormValidation()) {
      setShowErrorsOnType(true);
      return;
    }

    setSubmitting(true);

    try {
      let recaptcha;

      // on alpha we disable recaptcha for Cypress testing
      if (features.includes('recaptcha_user_signup_login')) {
        recaptcha = await execute('userSignupLogin');
      }

      const { useIdentityMock, identityWebClientId } = __AT_DATA__;

      const response =
        features.includes('identity_service') && !useIdentityMock
          ? await post<LoginResponse>('/identity/oauth/token', {
              client_id: identityWebClientId,
              username: userEmail,
              grant_type: 'password',
              password: userPassword,
              recaptcha
            })
          : await post<LoginResponse>('/api/alltrails/login', {
              create_session: true,
              email: userEmail,
              password: userPassword,
              recaptcha
            });

      authSuccessCallback(response, AuthenticationType.Email, response.is_collab_list_invite);

      if (handleLoginSuccess) {
        handleLoginSuccess(response);
      } else {
        const destinationUrl = returnTo || wrapUrlSafe('/', languageRegionCode);
        window.location.assign(destinationUrl);
      }
    } catch (e) {
      if (e instanceof ApiError && e.data?.errors?.length > 0) {
        setServerError(e.data.errors[0].message);
      } else {
        setServerError(<FormattedMessage defaultMessage="Error, please try again" />);
      }

      logLogInFailed({
        authentication_type: AuthenticationType.Email,
        failure_type: e instanceof ApiError && e.data?.errors?.length > 0 ? e.data.errors[0].code : 'unknown'
      });
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <form onSubmit={handleLogin}>
      <div className={formStyles.inputItem}>
        <OutlinedTextField
          autoFocus
          changeHandler={e => {
            handleChange(e);
            setUserEmail(e.target.value);
          }}
          enterKeyHandler={handleLogin}
          errorMessage={userEmailError}
          label={<FormattedMessage defaultMessage="Email address" />}
          name="userEmail"
          type="email"
          value={userEmail}
        />
      </div>
      <div className={formStyles.inputItem}>
        <OutlinedTextField
          changeHandler={e => {
            handleChange(e);
            setUserPassword(e.target.value);
          }}
          enterKeyHandler={handleLogin}
          errorMessage={userPasswordError}
          label={<FormattedMessage defaultMessage="Password" />}
          name="userPassword"
          type="password"
          value={userPassword}
        />
      </div>
      {serverError && <div className={formStyles.serverError}>{serverError}</div>}
      <Button text={logIn} disabled={submitting} testId="email-password-login-button" type="submit" variant="primary" width="full" />
    </form>
  );
}
