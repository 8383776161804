const setWindowExitGuard = on => {
  if (on) {
    window.onbeforeunload = () => {
      return true;
    };
  } else {
    window.onbeforeunload = null;
  }
};

const getQueryStringValue = key => {
  if (typeof window !== 'undefined') {
    // lifted from http://stackoverflow.com/questions/9870512/how-to-obtaining-the-querystring-from-the-current-url-with-javascript
    return unescape(
      window.location.search.replace(new RegExp(`^(?:.*[&\\?]${escape(key).replace(/[\.\+\*]/g, '\\$&')}(?:\\=([^&]*))?)?.*$`, 'i'), '$1')
    );
  }
};

export { setWindowExitGuard, getQueryStringValue };
