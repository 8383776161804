import { FormattedMessage } from '@alltrails/shared/react-intl';
import Button from '@alltrails/shared/denali/components/Button';
import * as styles from './styles/styles.module.scss';

const SkipToMainContentButton = () => (
  <Button
    className={styles.skipToMainContentButton}
    linkInfo={{ href: '#main-content' }}
    text={<FormattedMessage defaultMessage="Skip to main content" />}
    testId="header-skip-to-main-content"
    variant="primary"
  />
);
export default SkipToMainContentButton;
