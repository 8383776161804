import logError from './logError';

const SITE_KEY = '6Le5UOUUAAAAAGxhyG6f5iEA2qf75SAa1BivOtZm';

type Action = 'downloadRoute' | 'userPaymentAction' | 'userSignupLogin' | 'editProfile';

const loadRecaptcha = (successCallback: any) => {
  const script = document.createElement('script');
  script.src = `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`;
  script.async = true;
  script.onload = () => {
    grecaptcha.ready(() => {
      successCallback(grecaptcha);
    });
  };
  document.body.appendChild(script);
};

// see https://developers.google.com/recaptcha/docs/v3
const executeRecaptcha = (recaptchaObject: any, action: Action) => recaptchaObject.execute(SITE_KEY, { action });

async function execute(action: Action) {
  return new Promise<string>(resolve => {
    loadRecaptcha((recaptchaObject: any) => {
      executeRecaptcha(recaptchaObject, action).then((token: string) => resolve(token));
    });
  });
}

export { loadRecaptcha, executeRecaptcha, execute };
