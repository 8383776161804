const garminPermissionsKey = 'garminPermissions';
const garminPermissionsReturnKey = 'garmin_permissions_return';
const garminNewConnectionKey = 'new_garmin_connection';

const removeQueryParam = (key: string) => {
  if (typeof window !== 'undefined') {
    const url = new URL(window.location.href);
    url.searchParams.delete(key);
    window.history.replaceState({}, document.title, url.toString());
  }
};

export { garminPermissionsKey, garminPermissionsReturnKey, garminNewConnectionKey, removeQueryParam };
