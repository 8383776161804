// extracted by mini-css-extract-plugin
export var container = "styles-module__container___yYwa0";
export var header = "styles-module__header___JPWPE";
export var links = "styles-module__links___I4ETV";
export var next = "styles-module__next___Ib98y";
export var previous = "styles-module__previous___Ojnv3";
export var riverClass = "styles-module__riverClass___WxejI";
export var riverContainer = "styles-module__riverContainer___zLXOD";
export var subTitle = "styles-module__subTitle___WFaOA";
export var title = "styles-module__title___MO5zh";
export var titleContainer = "styles-module__titleContainer___aajYp";