import classNames from 'classnames';
import { useIntl } from '@alltrails/shared/react-intl';;
import GiftCard1 from 'components/GiftCard1';
import atGiftWalkWebp from './atgiftwalk.webp';
import atGiftWalkAvif from './atgiftwalk.avif';
import atGiftPathWebp from './atgiftpath.webp';
import atGiftPathAvif from './atgiftpath.avif';
import atGiftWalk from './atgiftwalk.jpg';
import atGiftPath from './atgiftpath.jpg';
import * as styles from './styles/styles.module.scss';

export const GC_LOGO = 'gc-logo';
export const GC_LEAF = 'gc-leaf';
export const GC_BEAR = 'gc-bear';

export type GiftCardVariants = typeof GC_LOGO | typeof GC_LEAF | typeof GC_BEAR;

type GiftCardProps = {
  variant: GiftCardVariants;
  className?: string;
};

const GiftCard = ({ variant, className }: GiftCardProps) => {
  const intl = useIntl();

  if (variant === GC_LOGO) {
    return <GiftCard1 />;
  }

  if (variant === GC_LEAF) {
    return (
      <div className={classNames(styles.gc, className)}>
        <picture>
          <source srcSet={atGiftWalkAvif} type="image/avif" />
          <source srcSet={atGiftWalkWebp} type="image/webp" />
          <img alt={intl.formatMessage({ defaultMessage: 'AllTrails gift subscription' })} src={atGiftWalk} />
        </picture>
      </div>
    );
  }

  if (variant === GC_BEAR) {
    return (
      <div className={classNames(styles.gc, className)}>
        <picture>
          <source srcSet={atGiftPathAvif} type="image/avif" />
          <source srcSet={atGiftPathWebp} type="image/webp" />
          <img alt={intl.formatMessage({ defaultMessage: 'AllTrails gift subscription' })} src={atGiftPath} />
        </picture>
      </div>
    );
  }

  return null;
};

export default GiftCard;
