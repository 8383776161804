import { string, number, shape, func, arrayOf, instanceOf, bool, object } from 'prop-types';

const routePropTypes = shape({
  id: number,
  description: string,
  lineDisplayProperty: shape({
    color: string
  }),
  lineGeoStats: shape({
    distanceTotal: string,
    elevationEnd: number,
    elevationGain: number,
    elevationLoss: number,
    elevationMax: number,
    elevationMin: number,
    elevationStart: number
  }),
  lineSegments: arrayOf(
    shape({
      dateTimeStart: instanceOf(Date),
      dateTime: instanceOf(Date),
      id: number,
      polyline: shape({
        elevationData: string,
        indexedElevationData: string,
        pointsData: string
      }),
      sequence_num: number
    })
  ),
  location: shape({
    city: string,
    country: string,
    country_id: number,
    latitude: string,
    longitude: string,
    postalCode: string,
    region: string
  }),
  name: string,
  rating: number,
  sequence_num: number,
  status: string,
  trailId: number
});

const providedPropTypes = shape({
  dragHandleProps: shape({
    'aria-describedby': string,
    'data-rdb-drag-handle-context-id': string,
    'data-rdb-drag-handle-draggable-id': string,
    draggable: bool,
    onDragStart: func,
    role: string,
    tabIndex: number
  }),
  draggableProps: shape({
    'data-rdb-draggable-context-id': string,
    'data-rdb-draggable-id': string,
    onTransitionEnd: func,
    style: shape({
      transform: string,
      transition: string
    }),
    innerRef: func
  })
});

const descriptionFormPropTypes = {
  title: string,
  description: string,
  activity: object,
  private: bool,
  date: instanceOf(Date)
};

const waypointPropTypes = shape({
  at_map_id: number,
  description: string,
  name: string,
  id: number,
  location: shape({
    latitude: number,
    longitude: number
  }),
  order: number,
  waypointDisplayProperty: shape({
    showTitle: bool
  }),
  enable_translations: bool
});

const trailContextPropTypes = shape({
  adminOrPendingOwner: bool,
  currentUser: object,
  displayMetric: bool,
  languageRegionCode: string,
  loggedInUser: bool,
  mobileBrowser: bool,
  proUser: bool,
  shortLocale: string,
  tabletBrowser: bool
});

const contextPropTypes = shape({
  currentUser: object,
  proUser: bool,
  mobileBrowser: bool,
  tabletBrowser: bool,
  displayMetric: bool,
  languageRegionCode: string
});

export { routePropTypes, providedPropTypes, descriptionFormPropTypes, waypointPropTypes, trailContextPropTypes, contextPropTypes };
