import React, { ReactNode, lazy, Suspense, useEffect, useState, forwardRef } from 'react';
import classNames from 'classnames';
import * as styles from './styles/styles.module.scss';

const SlickRiver = lazy(() => import('./SlickRiver'));

type Props = {
  className?: string;
  trackClassName?: string;
  slickSettings?: any;
  useSlick?: boolean;
  arrowStyles?: {
    next: string;
    previous: string;
  };
  children: ReactNode[];
  testId?: string;
};

type Ref = HTMLDivElement;

const River = forwardRef<Ref, Props>(({ className, trackClassName, children, useSlick, slickSettings, arrowStyles, testId }, ref) => {
  const [afterSSR, setAfterSSR] = useState(false);

  useEffect(() => {
    setAfterSSR(true);
  }, []);

  let river = (
    <div className={classNames(className, styles.river)} ref={ref}>
      <div className={classNames(trackClassName, styles.riverTrack)}>
        {children.map((child, i: number) => (
          <div className={styles.riverItem} key={`riverItem-${i}`}>
            {child}
          </div>
        ))}
      </div>
    </div>
  );

  if (useSlick && afterSSR) {
    river = (
      <Suspense fallback={river}>
        <SlickRiver arrowStyles={arrowStyles} slickSettings={slickSettings}>
          {children}
        </SlickRiver>
      </Suspense>
    );
  }

  return <div {...(testId ? { 'data-testid': testId } : {})}>{river}</div>;
});

River.defaultProps = {
  className: '',
  trackClassName: '',
  slickSettings: {},
  useSlick: false,
  arrowStyles: {
    next: '',
    previous: ''
  }
};

export default River;
