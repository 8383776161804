import produce from 'immer';
import { completedListId, favoritesListId } from '@alltrails/modules/Lists/listUtils';
import logListItemAdded from '@alltrails/analytics/events/logListItemAdded';
import logListItemRemoved from '@alltrails/analytics/events/logListItemRemoved';
import ListItemType from '@alltrails/analytics/enums/ListItemType';
import { addToList, removeFromList } from '@alltrails/shared/utils/requests/listRequests';

const LIST_ITEMS = 'listItems';
const USER_LIST_ITEMS = 'userListItems';
// reducer name
export const LISTS = 'lists';
// actions
export const ADD_ITEM_TO_LIST_ITEMS = 'ADD_ITEM_TO_LIST_ITEMS';
export const ADD_ITEM_TO_USER_LIST_ITEMS = 'ADD_ITEM_TO_USER_LIST_ITEMS';
export const REMOVE_ITEM_FROM_LIST_ITEMS = 'REMOVE_ITEM_FROM_LIST_ITEMS';
export const REMOVE_ITEM_FROM_USER_LIST_ITEMS = 'REMOVE_ITEM_FROM_USER_LIST_ITEMS';
export const ADD_CREATED_LIST = 'ADD_CREATED_LIST';
export const SET_LISTS_AND_LIST_ITEMS = 'SET_LISTS_AND_LIST_ITEMS';
export const UPDATE_LIST_ORDER = 'UPDATE_LIST_ORDER';
export const UPDATE_LIST_ITEMS = 'UPDATE_LIST_ITEMS';

const listIdToListItemType = listId => {
  if (listId === favoritesListId) {
    return ListItemType.Favorites;
  }
  if (listId === completedListId) {
    return ListItemType.Completed;
  }
  return ListItemType.CustomList;
};

/**
 * addItemToList
 *
 * dispatches a state update with a listItem that has been returned by the server.
 * if the list "belongsToCurrentUser", we need to make an update to the list that a user is viewing.
 *
 * @param {{ listId: number, type: string, objectId: number, id: number, order: number }}
 * - listItem (it returns more, but this is what we need in order to work with our lists)
 * @param {boolean} belongsToCurrentUser
 *
 * it will always dispatch ADD_ITEM_TO_USER_LIST_ITEMS
 * depending if a user is viewing their own list it will dispatch ADD_ITEM_TO_USER_LIST_ITEMS
 *
 */
export const addItemToList =
  (listItem, belongsToCurrentUser = false) =>
  (dispatch, getState) => {
    const {
      lists: { editingOtherUserListIds }
    } = getState();
    const { listId } = listItem;
    const isEditingOtherUserList = editingOtherUserListIds?.includes(listId);

    if (belongsToCurrentUser || isEditingOtherUserList) {
      dispatch({ type: ADD_ITEM_TO_USER_LIST_ITEMS, payload: { listItem, action: 'add' } });
    }

    if (!isEditingOtherUserList) {
      dispatch({ type: ADD_ITEM_TO_LIST_ITEMS, payload: { listItem } });
    }
  };

/**
 * removeItemFromList
 *
 * dispatches a state update with a deleted listItem that has been returned by the server.
 * if the list "belongsToCurrentUser", we need to make an update to the list that a user is viewing.
 *
 * @param {{ listId: number, type: string, objectId: number, id: number, order: number }}
 * - listItem (it returns more, but this is what we need in order to work with our lists)
 * @param {boolean} belongsToCurrentUser
 *
 * it will always dispatch REMOVE_ITEM_FROM_LIST_ITEMS
 * depending if a user is viewing their own list it will dispatch REMOVE_ITEM_FROM_USER_LIST_ITEMS
 *
 */
export const removeItemFromList = (listItem, belongsToCurrentUser) => (dispatch, getState) => {
  const {
    lists: { editingOtherUserListIds }
  } = getState();
  const { listId } = listItem;
  const isEditingOtherUserList = editingOtherUserListIds?.includes(listId);

  if (belongsToCurrentUser || isEditingOtherUserList) {
    dispatch({ type: REMOVE_ITEM_FROM_USER_LIST_ITEMS, payload: { listItem, action: 'remove' } });
  }

  if (!isEditingOtherUserList) {
    dispatch({ type: REMOVE_ITEM_FROM_LIST_ITEMS, payload: { listItem } });
  }
};

/**
 * addListItem
 *
 * responsible for making the addToList request, tracking the result and then adding it to the reducer
 * For user experience, we add the listItem to state and if there is an error then we remove it
 *
 * @param {number} userId
 * @param {string} type
 * @param {number} objectId
 * @param {number} listId
 * @param {boolean} belongsToCurrentUser
 *
 */
export const addListItem = (userId, type, objectId, listId, belongsToCurrentUser, amplitudeAnalyticsData) => async dispatch => {
  dispatch(addItemToList({ listId, type, objectId }, belongsToCurrentUser));
  try {
    const { listItems, errors } = await addToList(listId, type, objectId, userId);
    if (listItems && listItems.length > 0) {
      dispatch(addItemToList(listItems[0], belongsToCurrentUser));
      logListItemAdded(amplitudeAnalyticsData);
    }
    if (errors && errors.length > 0) {
      dispatch(removeItemFromList({ listId, type, objectId }, belongsToCurrentUser));
      console.error('THUNK ACTION addListItem error', errors[0].message);
    }
  } catch (error) {
    dispatch(removeItemFromList({ listId, type, objectId }, belongsToCurrentUser));
    console.error('THUNK ACTION addListItem error', error);
  }
};

/**
 * removeFromListItem
 *
 * responsible for making the removeFromList request, tracking the result and then removing it to the reducer
 * For user experience, we remove the listItem to state and if there is an error then we add it back
 *
 * @param {number} userId
 * @param {string} type
 * @param {number} itemId this represents the ID that connects a object (trail, map, recording) to a list
 * @param {number} listId
 * @param {number} objectId
 * @param {number} order
 * @param {boolean} belongsToCurrentUser
 *
 */
export const removeFromListItem = (userId, type, itemId, listId, objectId, order, belongsToCurrentUser) => async dispatch => {
  dispatch(removeItemFromList({ type, listId, objectId }, belongsToCurrentUser));
  if (!itemId) return;

  try {
    const { errors } = await removeFromList(listId, itemId, userId);
    if (errors && errors.length > 0) {
      dispatch(addItemToList({ listId, type, id: itemId, objectId, order }, belongsToCurrentUser));
      console.error('THUNK ACTION removeFromListItem error', errors[0].message);
    } else {
      logListItemRemoved({ listId, type: listIdToListItemType(listId) });
    }
  } catch (error) {
    dispatch(addItemToList({ listId, type, id: itemId, objectId, order }, belongsToCurrentUser));
    console.error('THUNK ACTION removeFromListItem error', error);
  }
};

/**
 * toggleListItem
 *
 * this method checks to see if a listItem is included in the list at hand and then either removes it if it
 * does exist or adds it to a list if it does not already exist
 *
 * @callback setListToastData
 * @param {number} userId
 * @param {number} listId
 * @param {string} type
 * @param {number} objectId
 * @param {amplitudeAnalyticsData} amplitudeAnalytics
 * @param {boolean} belongsToCurrentUserProp
 * @param {setListToastData} callback
 * @param {string} listTitle
 *
 */
export const toggleListItem =
  (userId, listId, type, objectId, amplitudeAnalyticsData, belongsToCurrentUserProp, setListToastData, listTitle) => (dispatch, getState) => {
    const {
      lists: { listItems, userListItems, belongsToCurrentUser, editingOtherUserListIds }
    } = getState();

    const canEdit = belongsToCurrentUser || belongsToCurrentUserProp;
    // If we're modifying the verified completed list we may be modifying the list for a different user
    const sourceListItems = editingOtherUserListIds?.includes(listId) ? userListItems : listItems;
    const item = sourceListItems && sourceListItems[listId] && sourceListItems[listId][type] && sourceListItems[listId][type][objectId];
    setListToastData?.({
      isVisible: true,
      prefixText: item ? 'removed' : 'saved',
      listId,
      listTitle,
      id: objectId,
      type,
      belongsToCurrentUser: canEdit
    });

    if (item) {
      dispatch(removeFromListItem(userId, type, item.id, listId, objectId, item.order, canEdit));
    } else {
      dispatch(addListItem(userId, type, objectId, listId, canEdit, amplitudeAnalyticsData));
    }
  };

/**
 * addCreatedList
 *
 * adds a new created list
 *
 * @param {{ id: number, order: number, title: string }}
 *
 */
export const addCreatedList = ({ id, order, title }) => ({ type: ADD_CREATED_LIST, payload: { id, order, title } });

/**
 * setListsAndListItems
 *
 * A big part of this thunk is to be able to keep the TrailPage and the explore view in coordination
 * since the trail page is rendered in an Iframe that actually hits a separate instance of
 * Alltrails. Not ideal, but in the meantime before we get rid of the iframe we have to have a way to keep
 * the two lists in sync
 *
 * @param {{ lists: {object} , listItems: {object} }}
 *
 */
export const setListsAndListItems = ({ lists, listItems }) => ({ type: SET_LISTS_AND_LIST_ITEMS, payload: { lists, listItems } });

/**
 * updateListOrder
 *
 * Updates a list with newly ordered items
 *
 * @param {string[]} listItems - updated list items
 * @param {string} listId - id of the list to update
 *
 */
export const updateListOrder = (listItems, listId) => ({ type: UPDATE_LIST_ORDER, payload: { listItems, listId } });

/**
 * updateListItems
 *
 * Updates a list new listItems
 *
 * @param {string[]} listItems - updated list items
 *
 */
export const updateListItems = listItems => ({ type: UPDATE_LIST_ITEMS, payload: { listItems } });

export const initialListState = {
  listItems: {},
  lists: [],
  userListItems: {},
  belongsToCurrentUser: false,
  latestListAction: null
};

// disabled default case because Immer automatically returns a default case
/* eslint-disable default-case, no-unused-expressions */

/**
 * handleAddToListOfExistingType
 *
 * if a type of content, ie a trail, map, activity is available in a list
 *
 * @param draftState -- immer draft state, allows us to mutabily change things immutability
 * @param {{listId: number, type: string, objectId: number, id: number, order: number, objectId}} listItem - this is a list that is added
 * @param {string} listItemsType - decides which state to push the new listItem into. users or their own list.
 *
 */
const handleAddToListExistingType = (draftState, { listId, type, id, order, objectId }, listItemsType = LIST_ITEMS) => {
  draftState[listItemsType][listId][type][objectId] = {
    id,
    order
  };
};

/**
 * handleAddToListNewType
 *
 * if a type of content, ie a trail, map, activity does not exist on a list, we need to update the object so it's type
 * is there.
 *
 * @param draftState -- immer draft state, allows us to mutabily change things immutability
 * @param {{listId: number, type: string, objectId: number, id: number, order: number}} listItem - this is a list that is added
 * @param {string} listItemsType - decides which state to push the new listItem into. users or their own list.
 *
 */
const handleAddToListNewType = (draftState, { listId, type, id, order, objectId }, listItemsType = LIST_ITEMS) => {
  if (!draftState[listItemsType][listId]) {
    draftState[listItemsType][listId] = {};
  }

  if (!draftState[listItemsType][listId][type]) {
    draftState[listItemsType][listId][type] = {};
  }

  draftState[listItemsType][listId][type][objectId] = {
    id,
    order
  };
};

/**
 * handleRemoveFromList
 *
 * removes a listItem from a list and if it has an empty type then we remove the type as well.
 *
 * @param draftState -- immer draft state, allows us to mutabily change things immutability
 * @param {{listId: number, type: string, objectId: number, id: number, order: number}} listItem - this is a list that is added
 * @param {string} action - this can be either added or removed. This is used to integrate with our existing system of updating
 * a filter on explore pages when a user makes an update to their own list.
 * @param {string} listItemsType - decides which state to push the new listItem into. users or their own list.
 *
 */
const handleRemoveFromList = (draftState, { listItem, action }, listItemsType = LIST_ITEMS) => {
  const list = draftState?.[listItemsType]?.[listItem?.listId];

  if (Object.keys(list).length > 0) {
    delete list[listItem.type][listItem.objectId];
    if (Object.keys(list[listItem.type]).length === 0) {
      delete list[listItem.type];
    }
  }

  if (action) {
    draftState.latestListAction = { listItem, action };
  }
};

/**
 * handleAddToList
 *
 * makes the determination if the type for the listItem already exists ie trail, map, activity
 * and adds it to an existing type or creates the type to bucket it in.
 *
 * @param draftState -- immer draft state, allows us to mutably change things immutability
 * @param {{listId: number, type: string, objectId: number, id: number, order: number}} listItem - this is a list that is added
 * @param {string} action - this can be either added or removed. This is used to integrate with our existing system of updating
 * a filter on explore pages when a user makes an update to their own list.
 * @param {string} listItemsType - decides which state to push the new listItem into. users or their own list.
 *
 */
const handleAddToList = (draftState, { listItem, action }, listItemsType = LIST_ITEMS) => {
  if (
    draftState?.[listItemsType]?.[listItem?.listId] &&
    Object.prototype.hasOwnProperty.call(draftState[listItemsType][listItem.listId], listItem.type)
  ) {
    handleAddToListExistingType(draftState, listItem, listItemsType);
  } else {
    handleAddToListNewType(draftState, listItem, listItemsType);
  }

  if (action) {
    draftState.latestListAction = { listItem, action };
  }
};

/**
 * handleListReordering
 *
 * converts an array of list items into the format that comes from the server
 * and updates the listItems state variable to persist list order changes on the client
 *
 * @param draftState -- immer draft state, allows us to mutably change things immutability
 * @param {string[]} listItems - updated list items
 * @param {string} listId - id of the list to update
 *
 */
const handleListReordering = (draftState, { listItems, listId }) => {
  const listInfo = {};
  listItems.forEach(({ id, objectId, type, order }) => {
    if (!Object.prototype.hasOwnProperty.call(listInfo, type)) {
      listInfo[type] = {};
    }
    listInfo[type][objectId] = { id, order };
  });

  draftState.listItems[listId] = listInfo;
};

/**
 * handleUpdateListItems
 *
 * updates the list items
 * and updates the listItems state variable
 *
 * @param draftState -- immer draft state, allows us to mutably change things immutability
 * @param {string[]} listItems - updated list items
 *
 */
const handleUpdateListItems = (draftState, { listItems }) => {
  draftState.listItems = listItems;
};

const lists = produce((draftState, action) => {
  switch (action.type) {
    case ADD_ITEM_TO_LIST_ITEMS:
      handleAddToList(draftState, action.payload);
      break;
    case ADD_ITEM_TO_USER_LIST_ITEMS:
      handleAddToList(draftState, action.payload, USER_LIST_ITEMS);
      break;
    case REMOVE_ITEM_FROM_LIST_ITEMS:
      handleRemoveFromList(draftState, action.payload);
      break;
    case REMOVE_ITEM_FROM_USER_LIST_ITEMS:
      handleRemoveFromList(draftState, action.payload, USER_LIST_ITEMS);
      break;
    case SET_LISTS_AND_LIST_ITEMS:
      draftState.lists = action.payload.lists;
      draftState.listItems = action.payload.listItems;
      break;
    case ADD_CREATED_LIST:
      if (draftState.lists.find(list => list.id === favoritesListId)) {
        const favorites = draftState.lists.find(list => list.id === favoritesListId);
        const favoritesIndex = draftState.lists.indexOf(favorites);
        draftState.lists.splice(favoritesIndex + 1, 0, { id: action.payload.id, order: action.payload.order, title: action.payload.title });
      } else {
        draftState.lists.unshift({ id: action.payload.id, order: action.payload.order, title: action.payload.title });
      }
      draftState.listItems[action.payload.id] = {};
      draftState.newLists[action.payload.id] = true;
      if (draftState.belongsToCurrentUser) {
        draftState.userListItems[action.payload.id] = {};
      }
      break;
    case UPDATE_LIST_ORDER:
      handleListReordering(draftState, action.payload);
      break;
    case UPDATE_LIST_ITEMS:
      handleUpdateListItems(draftState, action.payload);
      break;
  }
}, initialListState);

export default lists;
