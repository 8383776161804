import PropTypes from 'prop-types';
import * as styles from './ToggleRadioButtons.module.scss';

const ToggleRadioButtons = ({ options, handleOnChange, activeOption }) => {
  return (
    <div className={styles.container}>
      {options.map(option => {
        return (
          <div className={styles.optionContainer} key={option.value}>
            <input
              value={option.value}
              onChange={handleOnChange}
              className={styles.radioButton}
              id={option.value}
              name={option.name}
              type="radio"
              checked={activeOption === option.value}
            />
            <label htmlFor={option.value} className={styles.label}>
              <div className={styles.innerLabel}>{option.label}</div>
            </label>
          </div>
        );
      })}
    </div>
  );
};

ToggleRadioButtons.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.node, value: PropTypes.string })),
  handleOnChange: PropTypes.func,
  activeOption: PropTypes.string
};

ToggleRadioButtons.defaultProps = {
  options: [],
  handleOnChange: () => {},
  activeOption: ''
};

export default ToggleRadioButtons;
