import UAParser from 'ua-parser-js';
import { IntlShape } from '@alltrails/shared/react-intl';
import safariIconAvifSrc from '../assets/browsers/safari.avif';
import safariIconWebpSrc from '../assets/browsers/safari.webp';
import safariIconSrc from '../assets/browsers/safari.png';
import chromeIconAvifSrc from '../assets/browsers/chrome.avif';
import chromeIconWebpSrc from '../assets/browsers/chrome.webp';
import chromeIconSrc from '../assets/browsers/chrome.png';
import browserIconAvifSrc from '../assets/browsers/browser.avif';
import browserIconWebpSrc from '../assets/browsers/browser.webp';
import browserIconSrc from '../assets/browsers/browser.png';

// Next uses UAparser.js to detect the browser from user-agent data.
// We can use it directly in order to achieve the same results.
// https://www.npmjs.com/package/ua-parser-js

const getBrowserData = (intl: IntlShape) => {
  const parser = UAParser();
  const browserName = parser.browser?.name;

  if (browserName?.toLowerCase().includes('chrome')) {
    return {
      sources: {
        avif: chromeIconAvifSrc,
        webp: chromeIconWebpSrc,
        png: chromeIconSrc
      },
      buttonText: intl.formatMessage({ defaultMessage: 'Continue in Chrome' })
    };
  }

  if (browserName?.toLowerCase().includes('safari')) {
    return {
      sources: {
        avif: safariIconAvifSrc,
        webp: safariIconWebpSrc,
        png: safariIconSrc
      },
      buttonText: intl.formatMessage({ defaultMessage: 'Continue in Safari' })
    };
  }

  return {
    sources: {
      avif: browserIconAvifSrc,
      webp: browserIconWebpSrc,
      png: browserIconSrc
    },
    buttonText: intl.formatMessage({ defaultMessage: 'Continue in browser' })
  };
};

export default getBrowserData;
