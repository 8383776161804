import ContentPrivacySetting from '@alltrails/analytics/enums/ContentPrivacySetting';
import FollowerRequestsPrivacySetting from '@alltrails/analytics/enums/FollowerRequestsPrivacySetting';
import PrivacySettingsContentType from '@alltrails/analytics/enums/PrivacySettingsContentType';
import UserRelationshipsPrivacySetting from '@alltrails/analytics/enums/UserRelationshipsPrivacySetting';
import logDefaultContentPrivacyChanged from '@alltrails/analytics/events/logDefaultContentPrivacyChanged';
import logFollowerRequestsSettingChanged from '@alltrails/analytics/events/logFollowerRequestsSettingChanged';
import logIndividualContentPrivacyChanged from '@alltrails/analytics/events/logIndividualContentPrivacyChanged';
import logUserRelationshipsPrivacyChanged from '@alltrails/analytics/events/logUserRelationshipsPrivacyChanged';
import PrivacyPolicy from 'types/PrivacyPolicy';
import Visibility from 'types/Visibility';
import {
  ACTIVITIES,
  COMPLETED_TRAILS,
  CUSTOM_MAPS,
  LISTS,
  VISIBILITY_FOLLOWERS_ONLY,
  VISIBILITY_PRIVATE,
  VISIBILITY_PUBLIC
} from './privacy_policy_helpers';

type ContentCategory = typeof ACTIVITIES | typeof COMPLETED_TRAILS | typeof CUSTOM_MAPS | typeof LISTS;

const categories: ContentCategory[] = [ACTIVITIES, COMPLETED_TRAILS, CUSTOM_MAPS, LISTS];

const getFollowerRequestsPrivacySetting = (status: boolean) => (status ? FollowerRequestsPrivacySetting.On : FollowerRequestsPrivacySetting.Off);

const getContentPrivacySetting = (status: Visibility) => {
  switch (status) {
    case VISIBILITY_PUBLIC:
      return ContentPrivacySetting.PublicVisibility;
    case VISIBILITY_FOLLOWERS_ONLY:
      return ContentPrivacySetting.FollowersOnlyVisibility;
    case VISIBILITY_PRIVATE:
    default:
      return ContentPrivacySetting.PrivateVisibility;
  }
};

const getUserRelationshipsPrivacySetting = (status: Visibility) => {
  switch (status) {
    case VISIBILITY_PUBLIC:
      return UserRelationshipsPrivacySetting.PublicVisibility;
    case VISIBILITY_FOLLOWERS_ONLY:
      return UserRelationshipsPrivacySetting.FollowersOnlyVisibility;
    case VISIBILITY_PRIVATE:
    default:
      return UserRelationshipsPrivacySetting.PrivateVisibility;
  }
};

const getContentType = (content: ContentCategory) => {
  switch (content) {
    case 'activities':
      return PrivacySettingsContentType.Activities;
    case 'completedTrails':
      return PrivacySettingsContentType.CompletedTrails;
    case 'customMaps':
      return PrivacySettingsContentType.CustomMaps;
    case 'lists':
    default:
      return PrivacySettingsContentType.Lists;
  }
};

export const logPrivacyPolicyAmplitudeEvents = (oldPolicy: PrivacyPolicy, newPolicy: PrivacyPolicy) => {
  if (oldPolicy && newPolicy && oldPolicy !== newPolicy) {
    if (oldPolicy.socialNetwork.followRequestsRequired !== newPolicy.socialNetwork.followRequestsRequired) {
      logFollowerRequestsSettingChanged({
        old_status: getFollowerRequestsPrivacySetting(oldPolicy.socialNetwork.followRequestsRequired),
        new_status: getFollowerRequestsPrivacySetting(newPolicy.socialNetwork.followRequestsRequired)
      });
    }

    if (oldPolicy.profile.socialNetwork !== newPolicy.profile.socialNetwork) {
      logUserRelationshipsPrivacyChanged({
        old_status: getUserRelationshipsPrivacySetting(oldPolicy.profile.socialNetwork.visibility),
        new_status: getUserRelationshipsPrivacySetting(newPolicy.profile.socialNetwork.visibility)
      });
    }

    categories.forEach(category => {
      if (oldPolicy.content[category] !== newPolicy.content[category]) {
        logDefaultContentPrivacyChanged({
          content_type: getContentType(category),
          old_status: getContentPrivacySetting(oldPolicy.content[category].visibility),
          new_status: getContentPrivacySetting(newPolicy.content[category].visibility)
        });
      }
    });
  }
};

export const logIndividualContentPrivacyChangedAmplitudeEvent = (
  contentType: ContentCategory,
  contentId: number,
  defaultVisibility: Visibility,
  oldVisibility: Visibility,
  newVisibility: Visibility
) => {
  if (oldVisibility !== newVisibility) {
    logIndividualContentPrivacyChanged({
      content_id: contentId,
      content_type: getContentType(contentType),
      content_default_setting: getContentPrivacySetting(defaultVisibility),
      old_status: getContentPrivacySetting(oldVisibility),
      new_status: getContentPrivacySetting(newVisibility)
    });
  }
};
