import { FormattedMessage } from '@alltrails/shared/react-intl';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { MEDIA_QUERY_SMALL_DOWN } from 'utils/constants/breakpoints';
import Button from '@alltrails/shared/denali/components/Button';
import { wrapUrlSafe } from '../../../utils/language_support_util';
import smallAvifSrc from './gift-card-567w.avif';
import smallWebpSrc from './gift-card-567w.webp';
import smallJpgSrc from './gift-card-567w.png';
import largeAvifSrc from './gift-card-1180w.avif';
import largeWebpSrc from './gift-card-1180w.webp';
import largeSrc from './gift-card-1180w.png';
import * as styles from './GiftingBlock.module.scss';

export default function GenericGiftingBlock() {
  const languageRegionCode = useLanguageRegionCode();

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <picture>
          <source media={MEDIA_QUERY_SMALL_DOWN} srcSet={smallAvifSrc} type="image/avif" />
          <source media={MEDIA_QUERY_SMALL_DOWN} srcSet={smallJpgSrc} />
          <source media={MEDIA_QUERY_SMALL_DOWN} srcSet={smallWebpSrc} type="image/webp" />
          <source srcSet={largeAvifSrc} type="image/avif" />
          <source srcSet={largeWebpSrc} type="image/webp" />
          <img src={largeSrc} alt="" className={styles.image} loading="lazy" />
        </picture>
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          <FormattedMessage defaultMessage="Give the gift of adventure" />
        </div>
        <div className={styles.subText}>
          <FormattedMessage defaultMessage="Celebrate the nature lovers in your life with a whole year of offline maps, wrong-turn alerts, and lots more." />
        </div>
        <Button
          text={<FormattedMessage defaultMessage="Give AllTrails+" />}
          linkInfo={{ href: wrapUrlSafe('/gift', languageRegionCode) }}
          testId="gift-pro"
          variant="primary"
        />
      </div>
    </div>
  );
}
