import { useCallback, useState } from 'react';
import useResizeListener from './useResizeListener';

type Args = {
  dispatchWindowWidth?: (width: number) => void;
};

const useWindowWidth = ({ dispatchWindowWidth }: Args) => {
  const [windowWidth, updateWindowWidth] = useState(null);

  const handleResize = useCallback(() => {
    if (dispatchWindowWidth) {
      dispatchWindowWidth(window.innerWidth);
      return;
    }

    updateWindowWidth(window.innerWidth);
  }, [dispatchWindowWidth]);

  useResizeListener({ onResize: handleResize });

  return windowWidth;
};

export default useWindowWidth;
