import { defineMessages, FormattedMessage, useIntl } from '@alltrails/shared/react-intl';
import Button from '@alltrails/shared/denali/components/Button';
import logInviteFriendsBannerClicked from '@alltrails/analytics/events/logInviteFriendsBannerClicked';
import InviteFriendsBannerSource from '@alltrails/analytics/enums/InviteFriendsBannerSource';
import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import leafSrc from './leaf.svg';
import * as styles from './InviteYourFriendsBannerDesktop.module.scss';

const messages = defineMessages({
  inviteYourFriends: {
    defaultMessage: 'Invite friends'
  },
  leafAlt: {
    defaultMessage: 'leaf'
  }
});

export default function InviteYourFriendsBannerDesktop() {
  const intl = useIntl();
  const languageRegionCode = useLanguageRegionCode();

  return (
    <div className={styles.banner}>
      <div className={styles.content}>
        <div className={styles.iconAndText}>
          <img alt={intl.formatMessage(messages.leafAlt)} src={leafSrc} />
          <div className={styles.title}>
            <FormattedMessage defaultMessage="Invite your friends" />
          </div>
          <div className={styles.subtitle}>
            <FormattedMessage defaultMessage="1 friend = 2 trees" />
          </div>
        </div>
        <Button
          text={intl.formatMessage(messages.inviteYourFriends)}
          linkInfo={{ href: wrapUrlSafe('/refer', languageRegionCode) }}
          onClick={() => {
            logInviteFriendsBannerClicked({ source: InviteFriendsBannerSource.Homepage });
          }}
          testId="invite-your-friends-banner-cta"
          variant="primary"
        />
      </div>
    </div>
  );
}
