import { useMemo } from 'react';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import Button from '@alltrails/shared/denali/components/Button';
import * as styles from './styles/styles.module.scss';
import River from '../../shared/River';
import ShopifyCard from './ShopifyCard';
import { HOME_PAGE_WIDTH_BREAKPOINT, DESKTOP_BREAKPOINT } from '../../../utils/constants/breakpoints';

const slickSettings = { slidesToShow: 4, lazyLoad: 'progressive', slidesToScroll: 4, speed: 750 };

const ARROW_STYLES = { next: styles.next, previous: styles.previous };

type ShopifyPreviewProps = {
  shopifyProducts: {
    images: {
      src: string;
      alt: string;
    }[];
    title: string;
    shopItemUrl: string;
    price: string;
  }[];
  windowWidth: number;
};

const ShopifyPreview = ({ shopifyProducts = [], windowWidth }: ShopifyPreviewProps): JSX.Element => {
  // In the past Shopify has sent down [null] in the `images` array
  // so we filter the products to protect against that
  const formattedProducts = shopifyProducts
    .filter(({ images }) => images.length > 0 && !!images[0])
    .map(({ images, title, shopItemUrl, price }) => ({
      image: {
        src: images[0].src,
        altText: images[0].alt
      },
      title,
      shopItemUrl,
      price
    }));

  const visitTheStore = useMemo(
    () => (
      <div className={styles.storeLink}>
        <Button
          text={<FormattedMessage defaultMessage="Shop store" />}
          linkInfo={{
            href: 'https://shop.alltrails.com',
            target: '_blank'
          }}
          onClick={() => {
            /* do nothing - href opens lnk */
          }}
          testId="shopify-visit-store"
          variant="primary"
        />
      </div>
    ),
    []
  );

  const isMobile = useMemo(() => windowWidth && windowWidth < DESKTOP_BREAKPOINT, [windowWidth]);

  const isWideScreen = useMemo(() => windowWidth && windowWidth >= HOME_PAGE_WIDTH_BREAKPOINT, [windowWidth]);

  return (
    <div className={styles.container}>
      <div className={styles.riverContainer}>
        <div className={styles.header}>
          <div className={styles.title}>
            <FormattedMessage defaultMessage="Gear up for the adventure" />
          </div>
          {!isMobile && visitTheStore}
        </div>
        <River useSlick={isWideScreen} slickSettings={slickSettings} className={`${styles.riverClass} xlate-none`} arrowStyles={ARROW_STYLES}>
          {formattedProducts.map((product: any) => (
            <ShopifyCard key={product.title} {...product} />
          ))}
        </River>
      </div>
      {isMobile && visitTheStore}
      <div className={styles.divider} />
    </div>
  );
};

export default ShopifyPreview;
