import type { User } from 'types/User';
import { AnonymousMapUsername } from '../Constants';
import hasPermission from './hasPermission';

type DataUser = {
  id: number;
  username: string;
};

const userCanEdit = (user: User, dataUser: DataUser, isMobileEditable?: boolean) => {
  if (__AT_DATA__.mobileBrowser && !isMobileEditable) {
    return false;
  }

  if (hasPermission({ permission: 'trails:manage' })) {
    return true;
  }

  if (user && dataUser) {
    return user.id === dataUser.id;
  }

  return dataUser == null || dataUser.username === AnonymousMapUsername;
};

const usersContentPrivacyPolicy = (user: User, contentType: 'activities' | 'completedTrails' | 'customMaps' | 'favoriteTrails' | 'lists') => {
  if (!user) {
    return;
  }

  // eslint-disable-next-line consistent-return
  return user.privacyPolicy?.content[contentType]?.visibility;
};

export { userCanEdit, usersContentPrivacyPolicy };
