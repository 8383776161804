import useIsMobile from '@alltrails/shared/hooks/useIsMobile';
import { FormattedMessage, useIntl } from '@alltrails/shared/react-intl';
import Typography from '@alltrails/shared/denali/components/Typography';
import InstallPromptType from '@alltrails/analytics/enums/InstallPromptType';
import InstallPromptLocation from '@alltrails/analytics/enums/InstallPromptLocation';
import InstallPromptSource from '@alltrails/analytics/enums/InstallPromptSource';
import { useEffect, useState } from 'react';
import logInstallPromptViewed from '@alltrails/analytics/events/logInstallPromptViewed';
import logInstallPromptDismissed from '@alltrails/analytics/events/logInstallPromptDismissed';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import { Modal } from '@alltrails/modules/Modal';
import useFeatures from 'hooks/useFeatures';
import ChevronSm from '@alltrails/shared/icons/ChevronSm';
import logContinueInBrowserClicked from '@alltrails/analytics/events/logContinueInBrowserClicked';
import * as styles from './InstallBanner.module.scss';
import appIconSrc from '../../../assets/images/icons/app_icon.png';
import useDeeplink from '../DeepLink/hooks/useDeeplink';
import { deepLinkRedirect } from '../DeepLink/utils';
import '@alltrails/modules/Modal/index.css';
import getBrowserData from '../DeepLink/utils/getBrowserData';

type InstallBannerProps = {
  promptLocation: InstallPromptLocation;
  pageId?: number;
  pageType?: 'area' | 'trail';
  isOpen?: boolean;
  onRequestClose?: () => void;
  displayTrigger?: CarouselDisplayTrigger;
};

// Design link: https://www.figma.com/file/eQkMBkn2YnhYkVahhaXrZG/In-House-Branch?type=design&node-id=728-29259&mode=design&t=PL0roJqI0OHiLtzf-0

const InstallBanner = ({ pageId, pageType, promptLocation, isOpen: isOpenAttempt = false, displayTrigger, onRequestClose }: InstallBannerProps) => {
  const isAppsFlyerEnabled = useFeatures()?.includes('appsflyer_migration');
  const isMobile = useIsMobile();
  const intl = useIntl();
  const isOpen = isOpenAttempt && isMobile;
  const deeplink = useDeeplink({ pageType, pageId, isOpen, promptType: 'banner' });
  const [isMounted, setIsMounted] = useState(false);
  const ariaLabelElementId = 'install-prompt-label';

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isOpen) {
      logInstallPromptViewed({
        install_prompt_location: promptLocation,
        install_prompt_type: InstallPromptType.Banner,
        install_prompt_source: isAppsFlyerEnabled ? InstallPromptSource.NativeWithAppsflyerLink : InstallPromptSource.Native,
        signup_modal_trigger: displayTrigger
      });
    }
  }, [displayTrigger, isOpen, promptLocation, isAppsFlyerEnabled]);

  const handleCloseClick = () => {
    logInstallPromptDismissed({
      install_prompt_location: promptLocation,
      install_prompt_type: InstallPromptType.Banner,
      install_prompt_source: isAppsFlyerEnabled ? InstallPromptSource.NativeWithAppsflyerLink : InstallPromptSource.Native
    });
    onRequestClose();
  };

  const { sources, buttonText } = getBrowserData(intl);

  return (
    <Modal
      ariaLabelElementId={ariaLabelElementId}
      backgroundClassName={styles.background}
      isOpen={isOpen}
      onCloseRequest={(): null => null}
      shouldHideHeader
    >
      <div className={styles.variantContainer}>
        <button
          type="button"
          className={styles.optionContainer}
          onClick={() => {
            deepLinkRedirect(
              deeplink,
              InstallPromptType.Banner,
              promptLocation,
              isAppsFlyerEnabled ? InstallPromptSource.NativeWithAppsflyerLink : InstallPromptSource.Native
            );
          }}
        >
          <div className={styles.optionInfo}>
            <img src={appIconSrc} alt="" className={styles.atLogo} />
            <Typography variant="heading200" id={ariaLabelElementId}>
              <FormattedMessage defaultMessage="Continue in app" />
            </Typography>
          </div>
          <ChevronSm orientation="right" />
        </button>
        <div className={styles.divider} />
        <button
          type="button"
          className={styles.optionContainer}
          onClick={() => {
            logContinueInBrowserClicked({
              install_prompt_location: promptLocation,
              install_prompt_type: InstallPromptType.Banner,
              install_prompt_source: isAppsFlyerEnabled ? InstallPromptSource.NativeWithAppsflyerLink : InstallPromptSource.Native
            });
            handleCloseClick();
          }}
        >
          <div className={styles.optionInfo}>
            <div className={styles.browserImageContainer}>
              {isMounted && (
                <picture>
                  <source srcSet={sources.avif} type="image/avif" />
                  <source srcSet={sources.webp} type="image/webp" />
                  <img alt="" src={sources.png} className={styles.browserLogo} />
                </picture>
              )}
            </div>
            <Typography variant="heading200">{buttonText}</Typography>
          </div>
          <ChevronSm orientation="right" />
        </button>
      </div>
    </Modal>
  );
};

export default InstallBanner;
