import { getClientId } from '../client_id';
import { getApplicationConfigForIndex } from '../search';
import logError from '../logError';

const ONE_HOUR = 60 * 60 * 1000;

/**
 * sendBeacon provides common functionality for calling the Algolia REST Insights
 * API using the sendBeacon event in browsers.
 *
 * @param {object} event
 */
const sendBeacon = event => {
  const config = getApplicationConfigForIndex(event.index);
  if (!config) {
    logError(`Application config not found for ${event.index}`);
    return;
  }

  const blob = new Blob([JSON.stringify({ events: [event] })]);
  navigator.sendBeacon(
    `https://insights.algolia.io/1/events?X-Algolia-Application-Id=${config.applicationId}&X-Algolia-API-Key=${config.apiKey}`,
    blob
  );
};

/**
 * sendSearchResultsBeacon sends a click event using sendBeacon so that callers
 * do not have to wait for the call to complete.
 *
 * @param {string} objectID
 * @param {number} index
 * @param {number} position
 * @param {string} queryID
 * @param {string} userID
 * @param {string} pageName
 */
const sendSearchResultsBeacon = (objectID, index, position, queryID, userID, pageName) => {
  sendBeacon({
    eventType: 'click',
    eventName: `${pageName}-click`,
    index,
    userToken: `${userID || getClientId()}`,
    queryID,
    objectIDs: [objectID],
    positions: [position]
  });
};

/**
 * sendConversionBeacon sends a conversion event using sendBeacon so that callers
 * do not have to wait for the call to complete.
 *
 * @param {string} objectID
 * @param {number} index
 * @param {string} queryID
 * @param {string} userID
 * @param {string} pageName
 */
const sendConversionBeacon = (objectID, index, queryID, userID, pageName) => {
  sendBeacon({
    eventType: 'conversion',
    eventName: `${pageName}-conversion`,
    index,
    userToken: `${userID || getClientId()}`,
    queryID,
    objectIDs: [objectID]
  });
};

const saveAlgoliaQueryIdsToLocalStorage = (queryId, objectId) => {
  // place these data points in local storage to be used for conversion metrics
  if (window && window.localStorage) {
    window.localStorage.setItem('algoliaQueryId', queryId);
    window.localStorage.setItem('algoliaObjectId', objectId);
    window.localStorage.setItem('algoliaUpdatedAt', Date.now());
  }
};

/**
 * getSavedAlgoliaQueryId returns whatever the current/most recent algolia query was that
 * led to our current flow in the site.
 *
 * @returns string
 */
const getSavedAlgoliaQueryId = () => {
  if (!window || !window.localStorage) {
    return null;
  }

  const updatedAt = window.localStorage.getItem('algoliaUpdatedAt');
  // If we did not persist a date with the data, the data should not be trusted.
  if (!updatedAt) {
    return null;
  }

  const ageLimit = ONE_HOUR;
  if (Date.now() - updatedAt > ageLimit) {
    return null;
  }

  return window.localStorage.getItem('algoliaQueryId') || null;
};

// IMPORTANT: You MUST not change the order of these. If a new analytics tag is added it
// must be added to the end, and you must coordinate with the other platforms to make sure
// they are using the exact same position and values!
const getAnalyticsTags = ({ user, locale, origin, system, mobile, searchText }) => {
  const tags = [];

  if (user) {
    if (user.pro) {
      tags.push('auth:pro');
    } else {
      tags.push('auth:free');
    }
  } else {
    tags.push('auth:none');
  }

  if (mobile) {
    tags.push('platform:mobileweb');
  } else {
    tags.push('platform:web');
  }

  if (origin) {
    tags.push(`origin:${origin}`);
  }

  if (system) {
    tags.push('actor:system');
  } else {
    tags.push('actor:user');
  }

  if (locale) {
    tags.push(`lang:${locale.slice(0, 2)}`);
  } else {
    tags.push('lang:en');
  }

  if (searchText && searchText.length > 0) {
    tags.push('hasquery:true');
  } else {
    tags.push('hasquery:false');
  }

  return tags;
};

export { getAnalyticsTags, saveAlgoliaQueryIdsToLocalStorage, getSavedAlgoliaQueryId, sendConversionBeacon, sendSearchResultsBeacon };
