import { useIntl } from '@alltrails/shared/react-intl';
import * as styles from './GiftCard1.module.scss';
import atGiftCard from './atgiftcard.jpg';
import giftCardAvif from './atgiftcard.avif';
import giftCardWebp from './atgiftcard.webp';

function GiftCard1() {
  const intl = useIntl();

  return (
    <div className={styles.gc}>
      <picture>
        <source srcSet={giftCardAvif} type="image/avif" />
        <source srcSet={giftCardWebp} type="image/webp" />
        <img alt={intl.formatMessage({ defaultMessage: 'AllTrails gift subscription' })} src={atGiftCard} />
      </picture>
    </div>
  );
}

export default GiftCard1;
