import { FormattedMessage } from '@alltrails/shared/react-intl';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import PlaceTrail from '@alltrails/shared/icons/PlaceTrail';
import Community from '@alltrails/shared/icons/Community';
import NationalPark from '@alltrails/shared/icons/NationalPark';
import PlusBadge from '@alltrails/shared/denali/components/PlusBadge';
import Heart from '@alltrails/shared/icons/Heart';
import { User } from 'types/User';
import Shop from '@alltrails/shared/icons/Shop';
import ArrowExternal from '@alltrails/shared/icons/ArrowExternal';
import List from '@alltrails/shared/icons/List';
import Bookmark from '@alltrails/shared/icons/Bookmark';
import Map from '@alltrails/shared/icons/Map';
import WebHeaderSection from '@alltrails/analytics/enums/WebHeaderSection';
import WebHeaderDropDownLink from '@alltrails/analytics/enums/WebHeaderDropDownLink';
import { ReactNode } from 'react';
import { HeaderLink } from './headerLinks';

export type HeaderSection = {
  label: ReactNode;
  links: HeaderLink[];
  testId: string;
  webHeaderSection: WebHeaderSection;
};

export const getHeaderLinks = (user: User | null, languageRegionCode: LanguageRegionCode, inUS: boolean): HeaderSection[] => {
  const exploreSection: HeaderSection = {
    label: <FormattedMessage defaultMessage="Explore" />,
    links: [
      {
        leadingIcon: { background: true, icon: { Component: PlaceTrail } },
        linkInfo: { href: wrapUrlSafe('/explore?ref=header', languageRegionCode) },
        testId: 'header-nearby-trails',
        title: <FormattedMessage defaultMessage="Nearby trails" />,
        webHeaderDropDownLink: WebHeaderDropDownLink.NearbyTrails
      },
      {
        leadingIcon: { background: true, icon: { Component: Community } },
        linkInfo: { href: wrapUrlSafe('/community?ref=header', languageRegionCode) },
        testId: 'header-community',
        title: <FormattedMessage defaultMessage="Community" />,
        webHeaderDropDownLink: WebHeaderDropDownLink.Community
      },
      {
        leadingIcon: { background: true, icon: { Component: NationalPark } },
        linkInfo: { href: wrapUrlSafe('/guides?ref=header', languageRegionCode) },
        testId: 'header-national-park-guides',
        title: <FormattedMessage defaultMessage="National park guides" />,
        webHeaderDropDownLink: WebHeaderDropDownLink.NationalParkGuides
      }
    ],
    testId: 'header-explore',
    webHeaderSection: WebHeaderSection.Explore
  };

  const savedLinks: HeaderLink[] = user
    ? [
        {
          leadingIcon: { background: true, icon: { Component: Bookmark } },
          linkInfo: { href: wrapUrlSafe(`/members/${user.slug}/favorites?ref=header`, languageRegionCode) },
          testId: 'header-favorites',
          title: <FormattedMessage defaultMessage="Favorites" />,
          webHeaderDropDownLink: WebHeaderDropDownLink.Favorites
        },
        {
          leadingIcon: { background: true, icon: { Component: Map } },
          linkInfo: { href: wrapUrlSafe(`/members/${user.slug}/maps?ref=header`, languageRegionCode) },
          testId: 'header-maps',
          title: <FormattedMessage defaultMessage="My maps" />,
          webHeaderDropDownLink: WebHeaderDropDownLink.MyMaps
        },
        {
          leadingIcon: { background: true, icon: { Component: List } },
          linkInfo: { href: wrapUrlSafe(`/members/${user.slug}/lists?ref=header`, languageRegionCode) },
          testId: 'header-lists',
          title: <FormattedMessage defaultMessage="Show all lists" />,
          webHeaderDropDownLink: WebHeaderDropDownLink.ViewAllLists
        }
      ]
    : [
        {
          leadingIcon: { background: true, icon: { Component: List } },
          testId: 'header-create-list',
          title: <FormattedMessage defaultMessage="Create a list" />,
          requiresAuth: true,
          getLinkInfo: ({ slug }) => ({
            href: wrapUrlSafe(`/members/${slug}/lists?ref=header`, languageRegionCode)
          }),
          webHeaderDropDownLink: WebHeaderDropDownLink.CreateAList
        },
        {
          leadingIcon: { background: true, icon: { Component: Map } },
          testId: 'header-create-map',
          title: <FormattedMessage defaultMessage="Create a map" />,
          requiresAuth: true,
          getLinkInfo: () => ({ href: wrapUrlSafe('/explore/map/new', languageRegionCode) }),
          webHeaderDropDownLink: WebHeaderDropDownLink.CreateAMap
        }
      ];
  const savedSection: HeaderSection = {
    label: <FormattedMessage defaultMessage="Saved" />,
    links: savedLinks,
    testId: 'header-saved',
    webHeaderSection: WebHeaderSection.Save
  };

  const shopLinks: HeaderLink[] = [];
  if (!user?.pro) {
    shopLinks.push({
      leadingIcon: { background: true, customElement: <PlusBadge size="md" /> },
      linkInfo: { href: wrapUrlSafe('/plus?ref=header', languageRegionCode) },
      testId: 'header-alltrails-plus',
      title: <FormattedMessage defaultMessage="AllTrails+" />,
      webHeaderDropDownLink: WebHeaderDropDownLink.AtPlus
    });
  }
  shopLinks.push({
    leadingIcon: { background: true, icon: { Component: Heart } },
    linkInfo: { href: wrapUrlSafe('/gift?ref=header', languageRegionCode) },
    testId: 'header-give-alltrails-plus',
    title: <FormattedMessage defaultMessage="Give AllTrails+" />,
    webHeaderDropDownLink: WebHeaderDropDownLink.GiveAtPlus
  });
  if (inUS) {
    shopLinks.push({
      leadingIcon: { background: true, icon: { Component: Shop } },
      linkInfo: { href: 'https://shop.alltrails.com' },
      testId: 'header-shop',
      title: 'Gear Shop', // This is intentionally untranslated (DISCO-10275)
      trailingIcon: { icon: { Component: ArrowExternal, color: '--color-text-secondary', size: 'xs' } },
      webHeaderDropDownLink: WebHeaderDropDownLink.GearShop
    });
  }
  const shopSection: HeaderSection = {
    label: <FormattedMessage defaultMessage="Shop" />,
    links: shopLinks,
    testId: 'header-shop',
    webHeaderSection: WebHeaderSection.Shop
  };

  return [exploreSection, savedSection, shopSection];
};
