import { IntlShape } from '@alltrails/shared/react-intl';
import { GuidesImage } from 'components/blocks/GuidesHomepageModule/GuidesHomepageModule';

const guidesImages = (intl: IntlShape): GuidesImage[] => [
  {
    alt: 'national parks',
    srcJpg: 'https://cdn-assets.alltrails.com/guides/homepage/Big-Bend_Top-Picks_AdobeStock_103039156.jpg',
    srcAvif: 'https://cdn-assets.alltrails.com/guides/homepage/Big-Bend_Top-Picks_AdobeStock_103039156.avif',
    srcWebp: 'https://cdn-assets.alltrails.com/guides/homepage/Big-Bend_Top-Picks_AdobeStock_103039156.webp',
    mobileSrcJpg: 'https://cdn-assets.alltrails.com/guides/homepage/big-bend-3x.jpg',
    mobileSrcAvif: 'https://cdn-assets.alltrails.com/guides/homepage/big-bend-3x.avif',
    mobileSrcWebp: 'https://cdn-assets.alltrails.com/guides/homepage/big-bend-3x.webp',
    destination: intl.formatMessage({ defaultMessage: 'national parks' })
  },
  {
    alt: 'Yosemite',
    srcJpg: 'https://cdn-assets.alltrails.com/guides/homepage/Yosemite_Top-Picks_ben-petchel-uY50b2dqKUQ-unsplash.jpg',
    srcAvif: 'https://cdn-assets.alltrails.com/guides/homepage/Yosemite_Top-Picks_ben-petchel-uY50b2dqKUQ-unsplash.avif',
    srcWebp: 'https://cdn-assets.alltrails.com/guides/homepage/Yosemite_Top-Picks_ben-petchel-uY50b2dqKUQ-unsplash.webp',
    mobileSrcJpg: 'https://cdn-assets.alltrails.com/guides/homepage/yosemite-3x.jpg',
    mobileSrcAvif: 'https://cdn-assets.alltrails.com/guides/homepage/yosemite-3x.avif',
    mobileSrcWebp: 'https://cdn-assets.alltrails.com/guides/homepage/yosemite-3x.webp',
    destination: 'Yosemite'
  },
  {
    alt: 'Banff',
    srcJpg: 'https://cdn-assets.alltrails.com/guides/homepage/Banff_Top-Picks_AdobeStock_293462639.jpg',
    srcAvif: 'https://cdn-assets.alltrails.com/guides/homepage/Banff_Top-Picks_AdobeStock_293462639.avif',
    srcWebp: 'https://cdn-assets.alltrails.com/guides/homepage/Banff_Top-Picks_AdobeStock_293462639.webp',
    mobileSrcJpg: 'https://cdn-assets.alltrails.com/guides/homepage/banff-3x.jpg',
    mobileSrcAvif: 'https://cdn-assets.alltrails.com/guides/homepage/banff-3x.avif',
    mobileSrcWebp: 'https://cdn-assets.alltrails.com/guides/homepage/banff-3x.webp',
    destination: 'Banff'
  },
  {
    alt: 'Zion',
    srcJpg: 'https://cdn-assets.alltrails.com/guides/homepage/Zion_Top-Picks_AdobeStock_64497030.jpeg',
    srcAvif: 'https://cdn-assets.alltrails.com/guides/homepage/Zion_Top-Picks_AdobeStock_64497030.avif',
    srcWebp: 'https://cdn-assets.alltrails.com/guides/homepage/Zion_Top-Picks_AdobeStock_64497030.webp',
    mobileSrcJpg: 'https://cdn-assets.alltrails.com/guides/homepage/zion-3x-new.jpg',
    mobileSrcAvif: 'https://cdn-assets.alltrails.com/guides/homepage/zion-3x-new.avif',
    mobileSrcWebp: 'https://cdn-assets.alltrails.com/guides/homepage/zion-3x-new.webp',
    destination: 'Zion'
  },
  {
    alt: 'Lake District',
    srcJpg: 'https://cdn-assets.alltrails.com/guides/homepage/Lake-District_Top-Picks_AdobeStock_384355289.jpg',
    srcAvif: 'https://cdn-assets.alltrails.com/guides/homepage/Lake-District_Top-Picks_AdobeStock_384355289.avif',
    srcWebp: 'https://cdn-assets.alltrails.com/guides/homepage/Lake-District_Top-Picks_AdobeStock_384355289.webp',
    mobileSrcJpg: 'https://cdn-assets.alltrails.com/guides/homepage/lakes-district-3x.jpg',
    mobileSrcAvif: 'https://cdn-assets.alltrails.com/guides/homepage/lakes-district-3x.avif',
    mobileSrcWebp: 'https://cdn-assets.alltrails.com/guides/homepage/lakes-district-3x.webp',
    destination: intl.formatMessage({ defaultMessage: 'Lake District' })
  }
];

export default guidesImages;
