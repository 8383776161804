enum TrailConditionUid {
  Buggy = 'bugs',
  Muddy = 'muddy',
  Snowy = 'snow',
  Icy = 'icy',
  Slippery = 'slippery'
}

type TrailCondition = {
  count: number;
  lastMentionedAt: string;
  name: string;
  uid: TrailConditionUid;
};

export { TrailCondition, TrailConditionUid };
