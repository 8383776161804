import InstallPromptLocation from '@alltrails/analytics/enums/InstallPromptLocation';
import InstallPromptSource from '@alltrails/analytics/enums/InstallPromptSource';
import InstallPromptType from '@alltrails/analytics/enums/InstallPromptType';
import logInstallPromptClicked from '@alltrails/analytics/events/logInstallPromptClicked';

export const isAndroid = () => /Android/i.test(navigator.userAgent);
export const isIos = () => /iPad|iPhone|iPod/.test(navigator.userAgent);
export const iosAppStoreUrl = 'https://apps.apple.com/us/app/alltrails-hike-bike-run/id405075943';
export const androidAppStoreUrl = 'https://play.google.com/store/apps/details?id=com.alltrails.alltrails';

export const deepLinkRedirect = (
  deeplink: string,
  promptType: InstallPromptType,
  promptLocation: InstallPromptLocation,
  promptSource: InstallPromptSource
) => {
  logInstallPromptClicked({
    install_prompt_type: promptType,
    install_prompt_location: promptLocation,
    install_prompt_source: promptSource
  });

  window.location.assign(deeplink);
};
