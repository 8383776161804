import { IntlShape } from '@alltrails/shared/react-intl';
import type { VisitorUsage } from '@alltrails/shared/types/VisitorUsage';
import { Sort } from 'types/Search/Sort';
import { Access, Activities, CompletedStatus, Difficulty } from 'types/Trails';
import { FilterFeatures, Filters as FiltersBase } from 'types/Search/Filters';
import type { RouteTypeUid } from '@alltrails/shared/types/trail';
import type { Context } from 'types/Context';
import { SearchFiltersUtil } from 'utils/search_filters_util';
import { newFilters } from './filters';

export type ServerSort = 'best_match' | 'most_popular' | 'closest' | 'list_order' | 'date' | 'recently_added' | 'newly_added';

export type ServerFilters = Partial<{
  activity: Activities[];
  difficulty: Difficulty[];
  elevation_gain: {
    min: number;
    max: number;
  };
  feature: FilterFeatures[];
  length: {
    min: number;
    max: number;
  };
  route_type: RouteTypeUid[];
  sort: ServerSort;
  suitability: Access[];
  trail_completion: CompletedStatus[];
  trail_traffic: VisitorUsage[];
  limit: number | null;
  is_closed: boolean;
  is_private_property: boolean;
  rating: string;
}>;

const AppliedSortFilterParams: { value: number; selected: boolean } = {
  value: 0,
  selected: true
};

const sortFilters: Record<ServerSort, Record<string, { value: number; selected: boolean }>> = {
  best_match: {
    [Sort.BEST_MATCH]: AppliedSortFilterParams
  },
  most_popular: {
    [Sort.MOST_POPULAR]: AppliedSortFilterParams
  },
  closest: {
    [Sort.CLOSEST]: AppliedSortFilterParams
  },
  list_order: {
    [Sort.LIST_ORDER]: AppliedSortFilterParams
  },
  date: {
    [Sort.DATE]: AppliedSortFilterParams
  },
  newly_added: {
    [Sort.NEWLY_ADDED]: AppliedSortFilterParams
  },
  recently_added: {
    [Sort.RECENTLY_ADDED]: AppliedSortFilterParams
  }
};

type Filters = FiltersBase & { isClosed: boolean; isPrivateProperty: boolean; limit: number };

type ServerFilterKeys = keyof ServerFilters;
type ClientFilterKeys = keyof Filters;

const serverToClientMap: Readonly<Record<ServerFilterKeys, ClientFilterKeys>> = {
  is_private_property: 'isPrivateProperty',
  is_closed: 'isClosed',
  rating: 'minRating',
  suitability: 'access',
  activity: 'activities',
  difficulty: 'difficulty',
  feature: 'features',
  route_type: 'route_type',
  trail_completion: 'completed',
  elevation_gain: 'elevation_gain',
  length: 'lengths',
  sort: 'sort',
  trail_traffic: 'visitor_usage',
  limit: 'limit'
};

const arrayFilterUpdateKeys = ['suitability', 'feature', 'activity', 'difficulty', 'route_type', 'trail_completion'] as const;
const filterUpdateKeys = ['rating', 'is_private_property', 'is_closed', 'limit'] as const;
const minMaxFilterUpdateKeys = ['length', 'elevation_gain'] as const;

type ArrayServerFilterKeys = (typeof arrayFilterUpdateKeys)[number];
type DirectAssignServerKeys = (typeof filterUpdateKeys)[number];
type MinMaxServerKeys = (typeof minMaxFilterUpdateKeys)[number];

function updateArrayFilters(serverFilter: ServerFilters, clientFilter: Filters, serverKey: ArrayServerFilterKeys) {
  serverFilter[serverKey].forEach(s => (clientFilter[serverToClientMap[serverKey]][s].selected = true));
}

function updateFilterProperty(serverFilter: ServerFilters, clientFilter: Filters, serverKey: DirectAssignServerKeys) {
  // cast is needed because Filters doesn't infer the type based on the keys
  (clientFilter as Record<ClientFilterKeys, any>)[serverToClientMap[serverKey]] = serverFilter[serverKey];
}

function updateFilterMinMax(serverFilter: ServerFilters, clientFilter: Filters, serverKey: MinMaxServerKeys) {
  clientFilter[serverToClientMap[serverKey]].value = [serverFilter[serverKey].min, serverFilter[serverKey].max];
}

function isIn<T>(values: readonly T[], x: any): x is T {
  return values.includes(x);
}

export function homepageServerFiltersToWeb(serverFilter: ServerFilters, context: Context, intl: IntlShape) {
  // here we need to parse the filters to map to what the server has given us
  const clientFilter = newFilters({ context, intl }) as Filters;
  clientFilter.sort = sortFilters[serverFilter.sort];
  clientFilter.has_profile_photo = true;

  Object.keys(serverFilter).forEach((serverKey: ServerFilterKeys) => {
    if (isIn(arrayFilterUpdateKeys, serverKey)) updateArrayFilters(serverFilter, clientFilter, serverKey);
    if (isIn(filterUpdateKeys, serverKey)) updateFilterProperty(serverFilter, clientFilter, serverKey);
    if (isIn(minMaxFilterUpdateKeys, serverKey)) updateFilterMinMax(serverFilter, clientFilter, serverKey);
  });

  // this object is ready to communicate with the Algolia API
  return clientFilter;
}

type AroundPrecision = number | { from: number; value: number }[];

type GeoServerParams = Partial<{
  around_radius: number;
  around_precision: AroundPrecision;
  around_lat_lng: string;
}>;

type GeoClientParams = Partial<{
  aroundRadius: number;
  aroundPrecision: AroundPrecision;
  aroundLatLng: string;
}>;

type GeoClientKeys = keyof GeoClientParams;
type GeoServerParamsKeys = keyof GeoServerParams;

const serverToClientMapAlgoliaQueryParams: Readonly<Record<GeoServerParamsKeys, GeoClientKeys>> = {
  around_precision: 'aroundPrecision',
  around_radius: 'aroundRadius',
  around_lat_lng: 'aroundLatLng'
};

const DEFAULT_AROUND_RADIUS = 48270; // 30 miles in meters

export const getGeoAlgoliaQueryParams = (geo: GeoServerParams = {}, radiusMultiplier = 1) => {
  const params: { [key: string]: any } = { aroundRadius: DEFAULT_AROUND_RADIUS };
  // transform keys to be algolia client friendly
  Object.keys(geo).forEach((k: GeoServerParamsKeys) => {
    if (!Object.prototype.hasOwnProperty.call(serverToClientMapAlgoliaQueryParams, k)) return;

    params[serverToClientMapAlgoliaQueryParams[k]] = geo[k];
  });
  // add the radius multiplier after we pass it through
  params.aroundRadius *= radiusMultiplier;

  return params;
};

export type AlgoliaQueryObjectParams = {
  insidePolygon: any;
  filters: Filters;
  page: string;
  verifiedIds: number[];
  completedIds: number[];
  analyticsTags: string[];
  attributesToRetrieve: string[];
  attributesToHighlight: any;
};

export type AdditionalAlgoliaParams = {
  hitsPerPage?: number;
  aroundLatLng?: string;
  aroundRadius?: number;
  aroundPrecision?: AroundPrecision;
};

export function createAlgoliaObject(queryObjectParams: AlgoliaQueryObjectParams, additionalParams: AdditionalAlgoliaParams = {}) {
  const algoliaObject = SearchFiltersUtil.createAlgoliaQueryObject(queryObjectParams);
  return { ...algoliaObject, ...additionalParams };
}
