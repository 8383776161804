import { ReactNode, useState } from 'react';
import Typography from '@alltrails/shared/denali/components/Typography';
import Chevron from '@alltrails/shared/icons/Chevron';
import { COLOR_INTERACTIVE_SUBTLE } from '@alltrails/shared/denali/tokens';
import classNames from 'classnames';
import * as styles from './Expandable.module.scss';

type Props = {
  children: ReactNode;
  classes?: string;
  collapsed?: boolean;
  title: ReactNode;
};

const Expandable = ({ title, collapsed = true, classes = '', children }: Props) => {
  const [isCollapsed, toggleIsCollapsed] = useState(collapsed);

  const toggleContent = () => {
    toggleIsCollapsed(prevState => !prevState);
  };

  return (
    <div className={classNames(styles.container, classes)}>
      <div onClick={toggleContent} className={styles.control}>
        <Typography variant="heading300" component="div">
          {title}
        </Typography>
        <div className={styles.toggleButton}>
          <Chevron size="sm" orientation={isCollapsed ? 'down' : 'up'} color={COLOR_INTERACTIVE_SUBTLE} />
        </div>
      </div>
      <div className={isCollapsed ? styles.collapsed : styles.collapsible}>{children}</div>
    </div>
  );
};

export default Expandable;
