import { FormattedMessage } from '@alltrails/shared/react-intl';

const br = () => <br />;

/*
  Returns free-trial duration text for the Checkout page
  couponTrialDuration will be either "x month(s)", "x week(s)" or "x day(s)". 
  We need to handle singular & plural month/week/day text which requires separate translations.
*/

export default (couponTrialDuration?: string) => {
  if (couponTrialDuration) {
    const durationData = couponTrialDuration.split(' ');
    const durationNum = Number(durationData[0]);
    const durationType = durationData[1];

    if (durationType.includes('month')) {
      if (durationNum === 1) {
        return <FormattedMessage defaultMessage="Try AllTrails+ free<br></br> for 1 month" values={{ br }} />;
      }

      if (durationNum > 1) {
        return <FormattedMessage defaultMessage="Try AllTrails+ free<br></br> for {durationNum} months" values={{ br, durationNum }} />;
      }
    }

    if (durationType.includes('week')) {
      if (durationNum === 1) {
        return <FormattedMessage defaultMessage="Try AllTrails+ free<br></br> for 1 week" values={{ br }} />;
      }

      if (durationNum > 1) {
        return <FormattedMessage defaultMessage="Try AllTrails+ free<br></br> for {durationNum} weeks" values={{ br, durationNum }} />;
      }
    }

    if (durationType.includes('day')) {
      if (durationNum === 1) {
        return <FormattedMessage defaultMessage="Try AllTrails+ free<br></br> for 1 day" values={{ br }} />;
      }

      if (durationNum > 1) {
        return <FormattedMessage defaultMessage="Try AllTrails+ free<br></br> for {durationNum} days" values={{ br, durationNum }} />;
      }
    }
  }

  return <FormattedMessage defaultMessage="Try AllTrails+ free<br></br> for 7 days" values={{ br }} />;
};
