import useMediaQuery from '@material-ui/core/useMediaQuery';

// see https://getbootstrap.com/docs/4.0/layout/overview/
const BREAKPOINT_MEDIUM = 768;

export default function useMobileWidth(newWidth) {
  if (newWidth) {
    return !useMediaQuery(`(min-width:${newWidth}px)`);
  }
  return !useMediaQuery(`(min-width:${BREAKPOINT_MEDIUM}px)`);
}
