// extracted by mini-css-extract-plugin
export var button = "InstallInterstitial-module__button___taYEd";
export var close = "InstallInterstitial-module__close___AJTm6";
export var container = "InstallInterstitial-module__container___hb7AC";
export var contentContainer = "InstallInterstitial-module__contentContainer___jesPI";
export var divider = "InstallInterstitial-module__divider___kvVbF";
export var header = "InstallInterstitial-module__header___YFP3q";
export var imageContainer = "InstallInterstitial-module__imageContainer___KXgxi";
export var logoAndRatingContainer = "InstallInterstitial-module__logoAndRatingContainer___K2DC6";
export var nameAndRatingContainer = "InstallInterstitial-module__nameAndRatingContainer___qKZlh";
export var phone = "InstallInterstitial-module__phone___Wrlu6";
export var ratingContainer = "InstallInterstitial-module__ratingContainer___KguoM";
export var texture = "InstallInterstitial-module__texture___bF6Kn";