import * as styles from './styles/styles.module.scss';
import GiftCard, { GiftCardVariants, GC_LOGO, GC_LEAF, GC_BEAR } from '../GiftCard';

type GiftCardRadioButtonsProps = {
  onChange?: (e: any) => void;
  selectedVariant?: GiftCardVariants;
};

const GiftCardRadioButtons = ({ onChange, selectedVariant }: GiftCardRadioButtonsProps) => {
  const variants: GiftCardVariants[] = [GC_LOGO, GC_LEAF, GC_BEAR];

  return (
    <div className={styles.container}>
      {variants.map(variant => (
        <div key={variant} className={styles.cardRadioContainer}>
          <input
            value={variant}
            onChange={onChange}
            name="variant"
            className={styles.radioButton}
            id={variant}
            type="radio"
            checked={selectedVariant === variant}
          />
          <label htmlFor={variant} className={styles.label}>
            <GiftCard variant={variant} />
          </label>
        </div>
      ))}
    </div>
  );
};

export default GiftCardRadioButtons;
