import { defineMessage, FormattedMessage, useIntl } from '@alltrails/shared/react-intl';
import Link from '@alltrails/shared/denali/components/Link';
import logInviteFriendsBannerClicked from '@alltrails/analytics/events/logInviteFriendsBannerClicked';
import InviteFriendsBannerSource from '@alltrails/analytics/enums/InviteFriendsBannerSource';
import ArrowRec from '@alltrails/shared/icons/ArrowRec';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import leafSrc from './leaf.svg';
import * as styles from './InviteYourFriendsBannerMobile.module.scss';

export default function InviteYourFriendsBannerMobile() {
  const intl = useIntl();
  const languageRegionCode = useLanguageRegionCode();

  const leafAltText = defineMessage({
    defaultMessage: 'leaf'
  });

  return (
    <div className={styles.banner}>
      <div className={styles.content}>
        <div className={styles.iconAndText}>
          <img alt={intl.formatMessage(leafAltText)} src={leafSrc} />
          <div className={styles.text}>
            <div className={styles.title}>
              <FormattedMessage defaultMessage="Invite your friends" />
            </div>
            <div className={styles.subtitle}>
              <FormattedMessage defaultMessage="1 friend = 2 trees" />
            </div>
          </div>
        </div>
        <Link
          href={wrapUrlSafe('/refer', languageRegionCode)}
          onClick={() => {
            logInviteFriendsBannerClicked({ source: InviteFriendsBannerSource.Homepage });
          }}
        >
          <ArrowRec orientation="right" size="md" />
        </Link>
      </div>
    </div>
  );
}
