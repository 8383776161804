// extracted by mini-css-extract-plugin
export var button = "DiscoverTheAppOfTheYear-module__button___BYgU0";
export var container = "DiscoverTheAppOfTheYear-module__container___ENIig";
export var content = "DiscoverTheAppOfTheYear-module__content___j8i2R";
export var download = "DiscoverTheAppOfTheYear-module__download___Omfni";
export var heading = "DiscoverTheAppOfTheYear-module__heading___uP3dk";
export var img = "DiscoverTheAppOfTheYear-module__img___bTd_u";
export var laurel = "DiscoverTheAppOfTheYear-module__laurel___i_2IQ";
export var laurels = "DiscoverTheAppOfTheYear-module__laurels___ZaY_k";
export var logo = "DiscoverTheAppOfTheYear-module__logo____Xm0v";
export var qr = "DiscoverTheAppOfTheYear-module__qr___J91S4";
export var text = "DiscoverTheAppOfTheYear-module__text___V4rCL";
export var video = "DiscoverTheAppOfTheYear-module__video___p0kdU";
export var videoContainer = "DiscoverTheAppOfTheYear-module__videoContainer___BbWru";