import { ReactNode } from 'react';

export type TokenValidationErrors = {
  nameError: ReactNode;
  postalCodeError: ReactNode;
  ccInputStateError: ReactNode;
};

export enum CARD_VALIDATION_NAMES {
  CREDIT_CARD = 'ccInputState',
  NAME = 'name',
  POSTAL_CODE = 'postalCode'
}

export enum CARD_RECURLY_IDs {
  NUMBER = 'number',
  MONTH = 'month',
  YEAR = 'year',
  CVV = 'cvv',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  POSTAL_CODE = 'postal_code',
  COUNTRY = 'country'
}
