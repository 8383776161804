import { useState, useRef, useEffect } from 'react';
import { defineMessages } from '@alltrails/shared/react-intl';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import { unlinkRequest } from 'utils/requests/garminRequests';
import logError from 'utils/logError';
import Toast from '@alltrails/shared/denali/components/Toast';
import CheckOutline from '@alltrails/shared/icons/CheckOutline';
import Error from '@alltrails/shared/icons/Error';
import logGarminConnectModalViewed from '@alltrails/analytics/events/logGarminConnectModalViewed';
import logGarminConnectSucceeded from '@alltrails/analytics/events/logGarminConnectSucceeded';
import GarminModalType from '@alltrails/analytics/enums/GarminModalType';
import GarminIcon from './GarminIcon';
import AccountConnected from '../AccountConnectButtons/AccountConnected';
import GarminInformationalModal from './GarminInformationalModal';
import GarminSettingsModal from './GarminSettingsModal';
import getGarminConnectUrl from './utils/getGarminConnectUrl';
import GarminDisconnectModal from './GarminDisconnectModal';
import { garminNewConnectionKey, removeQueryParam } from './utils/modalHelpers';
import * as styles from './GarminSettings.module.scss';

const messages = defineMessages({
  CONNECT: { defaultMessage: 'Connect with Garmin' },
  DISCONNECT_SUCCESS: { defaultMessage: 'Garmin successfully disconnected.' },
  DISCONNECT_ERROR: {
    defaultMessage: 'Something went wrong when trying to disconnect your Garmin account.'
  }
});

export type GarminSettingsProps = {
  garminConnectToken?: string;
  isPlus: boolean;
};

const GarminSettings = ({ garminConnectToken, isPlus }: GarminSettingsProps) => {
  const {
    formattedDefaultMessages: { CONNECT, DISCONNECT_SUCCESS, DISCONNECT_ERROR }
  } = useFormatMessage(messages);

  const [isInformationalModalOpen, setIsInformationalModalOpen] = useState(false);
  // We want to hide the settings modal when the user views the disconnect modal, but we don't want to
  // trigger the `logSettingsGarminConnectPageViewed` event when the user closes the disconnect modal.
  const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [isDisconnectingAccount, setIsDisconnectingAccount] = useState(false);
  const [isDisconnectModalOpen, setIsDisconnectModalOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [isGarminConnected, setIsGarminConnected] = useState(!!garminConnectToken);
  const prevGarminConnectTokenRef = useRef<string | undefined>(garminConnectToken);
  const garminConnectUrl = getGarminConnectUrl(isPlus);

  const handleClick = () => {
    logGarminConnectModalViewed({ modal_type: GarminModalType.Connect });
    setIsInformationalModalOpen(true);
  };

  const handleToggleSettingsModal = () => {
    setIsSettingsModalOpen(!isSettingsModalOpen);
    setIsSettingsModalVisible(!isSettingsModalVisible);
  };

  useEffect(() => {
    if (!prevGarminConnectTokenRef.current && garminConnectToken) {
      setIsGarminConnected(true);
      setIsSettingsModalOpen(true);
      setIsSettingsModalVisible(true);
    }
    prevGarminConnectTokenRef.current = garminConnectToken;

    return () => {
      setIsInformationalModalOpen(false);
      setIsSettingsModalOpen(false);
      setIsSettingsModalVisible(false);
      setIsDisconnectModalOpen(false);
      setIsGarminConnected(false);
    };
  }, [garminConnectToken]);

  const handleDisconnect = () => {
    setIsDisconnectingAccount(true);
    unlinkRequest('garmin')
      .then(() => {
        setToastMessage({ message: DISCONNECT_SUCCESS, type: 'success' });
        setIsDisconnectModalOpen(false); // this triggers handleToggleSettingsModal via onRequestClose
        setIsGarminConnected(false);
      })
      .catch(err => {
        setToastMessage({ message: DISCONNECT_ERROR, type: 'error' });
        logError(err);
        setIsDisconnectingAccount(false);
        setIsDisconnectModalOpen(false);
        setIsSettingsModalVisible(true);
      });
  };

  const handleRequestDisconnect = () => {
    setIsSettingsModalVisible(false);
    setIsDisconnectModalOpen(true);
  };

  const handleCancelDisconnect = () => {
    setIsDisconnectModalOpen(false);
    setIsSettingsModalVisible(true);
  };

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | null = null;

    if (toastMessage?.message) {
      timeoutId = setTimeout(
        () => {
          setToastMessage(null);
        },
        toastMessage?.type === 'success' ? 3000 : 5000
      );
    }

    return () => clearTimeout(timeoutId);
  }, [toastMessage]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const userJustConnected = searchParams?.has(garminNewConnectionKey);

    if (userJustConnected) {
      logGarminConnectSucceeded({});
      removeQueryParam(garminNewConnectionKey);
    }
  }, []);

  if (garminConnectToken && isGarminConnected) {
    return (
      <>
        <AccountConnected linkText="Manage" isLoading={isDisconnectingAccount} onClick={handleToggleSettingsModal} />
        <GarminSettingsModal
          isOpen={isSettingsModalOpen}
          isVisible={isSettingsModalVisible}
          garminConnectUrl={garminConnectUrl}
          isPlus={isPlus}
          handleDisconnect={handleRequestDisconnect}
          onRequestClose={handleToggleSettingsModal}
        />
        {isDisconnectModalOpen && <GarminDisconnectModal onDisconnect={handleDisconnect} onRequestClose={handleCancelDisconnect} />}
        {toastMessage && <Toast message={toastMessage.message} type="error" testId="garmin-disconnect-toast" icon={{ Component: Error }} />}
      </>
    );
  }

  return (
    <>
      <button className={styles.button} type="button" data-testid="connect-with-garmin" onClick={handleClick}>
        <GarminIcon />
        <span>{CONNECT}</span>
      </button>
      <GarminInformationalModal
        garminConnectUrl={garminConnectUrl}
        isOpen={isInformationalModalOpen}
        onRequestClose={() => setIsInformationalModalOpen(false)}
      />
      {toastMessage && <Toast message={toastMessage.message} type="success" testId="garmin-disconnect-toast" icon={{ Component: CheckOutline }} />}
    </>
  );
};

export default GarminSettings;
