import { debounce } from 'debounce';
import { useEffect } from 'react';

type Args = {
  debounceTiming?: number;
  // onResize should always be wrapped by useMemo/useCallback or it should exist
  // outside of the render function. If the upstream caller changes a prop that
  // causes the onResize method to be re-created then it will look like that
  // argument here is always changing and a render loop will occur.
  onResize: () => void;
};

export default function useResizeListener({ debounceTiming = 300, onResize }: Args) {
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const debouncedOnResize = debounce(onResize, debounceTiming);

    // Fire this immediately for initial layout.
    onResize();

    window.addEventListener('resize', debouncedOnResize);

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('resize', debouncedOnResize);
    };
  }, [debounceTiming, onResize]);
}
