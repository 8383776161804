enum Sort {
  BEST_MATCH = 'best-match',
  MOST_POPULAR = 'most-popular',
  CLOSEST = 'closest',
  LIST_ORDER = 'list-order',
  DATE = 'date',
  NAME = 'name',
  NEWLY_ADDED = 'newly-added',
  RECENTLY_ADDED = 'recently-added'
}

export { Sort };
