// extracted by mini-css-extract-plugin
export var closeButton = "styles-module__closeButton___ITVH1";
export var container = "styles-module__container___AJJ8s";
export var iconImage = "styles-module__iconImage___Gq0vd";
export var isPrint = "styles-module__isPrint___FxqSE";
export var largeText = "styles-module__largeText___jcLnW";
export var mobileWidth = "styles-module__mobileWidth___XIJb9";
export var pdfButton = "styles-module__pdfButton___nxk27";
export var pdfContainer = "styles-module__pdfContainer___nY2EX";
export var pdfImage = "styles-module__pdfImage___jrNLT";
export var printContainer = "styles-module__printContainer___jSSvj";
export var text = "styles-module__text___PeErg";