import { v4 as uuidv4 } from 'uuid';

const CLIENT_ID_STORAGE_KEY = 'clientId';

const getClientId = () => {
  let clientId = window.localStorage.getItem(CLIENT_ID_STORAGE_KEY);
  if (!clientId) {
    clientId = uuidv4();
    window.localStorage.setItem(CLIENT_ID_STORAGE_KEY, clientId);
  }
  return clientId;
};

export { getClientId };
