import logWebHeaderUpgradeToProTapped from '@alltrails/analytics/events/logWebHeaderUpgradeToProTapped';
import useUser from 'hooks/useUser';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import WebHeaderUpgradeToProCopyText from '@alltrails/analytics/enums/WebHeaderUpgradeToProCopyText';
import Link from '@alltrails/shared/denali/components/Link';
import * as styles from './MobileHomeLink.module.scss';

export default function MobileHomeLink() {
  const user = useUser();

  return (
    <>
      {!user && (
        <Link className={styles.link} size="sm" testId="headerSignUp" href="/plus">
          <FormattedMessage defaultMessage="Try AllTrails+" />
        </Link>
      )}
      {user && !user.pro && (
        <Link
          className={styles.link}
          size="sm"
          href="/plus"
          testId="get-plus-mobile-home-link"
          onClick={() => {
            logWebHeaderUpgradeToProTapped({
              copy_text: user.isPromoEligible ? WebHeaderUpgradeToProCopyText.TryProFree : WebHeaderUpgradeToProCopyText.UpgradeToPro
            });
          }}
        >
          {user.isPromoEligible ? <FormattedMessage defaultMessage="Try AllTrails+" /> : <FormattedMessage defaultMessage="Get AllTrails+" />}
        </Link>
      )}
    </>
  );
}
