import Button from '@alltrails/shared/denali/components/Button';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { wrapUrlSafe } from 'utils/language_support_util';
import { FormattedMessage, useIntl } from '@alltrails/shared/react-intl';
import useUser from 'hooks/useUser';
import { MEDIA_QUERY_SMALL_DOWN } from 'utils/constants/breakpoints';
import Logo from '@alltrails/shared/denali/components/Logo';
import * as styles from './PlusUpsellNoAuth.module.scss';
import mobileSrc from './plusUpsellNoAuth-655w.jpg';
import mobileSrcAvif from './plusUpsellNoAuth-655w.avif';
import mobileSrcWebp from './plusUpsellNoAuth-655w.webp';
import webSrc from './plusUpsellNoAuth-1241w.jpg';
import webSrcAvif from './plusUpsellNoAuth-1241w.avif';
import webSrcWebp from './plusUpsellNoAuth-1241w.webp';

// https://www.figma.com/file/o24ZQP00Um7Ie23xWMwIoL/Sketches?type=design&node-id=108-26105&mode=dev
// Desktop design: https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=1753%3A82097
// Mobile design: https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=1753%3A82098
export default function PlusUpsellNoAuth() {
  const intl = useIntl();
  const languageRegionCode = useLanguageRegionCode();
  const user = useUser();

  return (
    <div className={styles.container}>
      <div className={styles.imageAndTextContainer}>
        <div className={styles.imageContainer}>
          <picture>
            <source media={MEDIA_QUERY_SMALL_DOWN} srcSet={mobileSrc} type="image/jpg" />
            <source media={MEDIA_QUERY_SMALL_DOWN} srcSet={mobileSrcAvif} type="image/avif" />
            <source media={MEDIA_QUERY_SMALL_DOWN} srcSet={mobileSrcWebp} type="image/webp" />
            <source srcSet={webSrcAvif} type="image/avif" />
            <source srcSet={webSrcWebp} type="image/webp" />
            <img
              alt={intl.formatMessage({ defaultMessage: 'Hikers walk through a grassy field bordered by pine forest.' })}
              src={webSrc}
              className={styles.image}
              loading="lazy"
            />
          </picture>
        </div>
        <div className={styles.textContainer}>
          <Logo className={styles.logoPlusImage} color="light" size="md" variant="plus-logomark" />
          <h2 className={styles.title}>
            <FormattedMessage defaultMessage="More tools for more adventures" />
          </h2>
          <p className={styles.text}>
            <FormattedMessage defaultMessage="With offline maps, wrong-turn alerts, and extra planning features, AllTrails+ helps you make the most of every minute outdoors." />
          </p>
          <div className={styles.buyLink}>
            <Button
              text={
                !user || user.isPromoEligible ? (
                  <FormattedMessage defaultMessage="Try AllTrails+ free" />
                ) : (
                  <FormattedMessage defaultMessage="Buy AllTrails+" />
                )
              }
              linkInfo={{ href: wrapUrlSafe('/plus', languageRegionCode) }}
              testId="try-free-or-buy-plus"
              variant="accent"
              allowWrap
            />
          </div>
        </div>
      </div>
    </div>
  );
}
