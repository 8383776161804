import { ReactNode } from 'react';
import useIsMobileSizedScreen from './useIsMobileSizedScreen';

type ConditionalRendererProps = {
  children: ReactNode;
  renderWhen: 'desktop' | 'mobile';
};

const ConditionalRenderer = ({ children, renderWhen }: ConditionalRendererProps) => {
  const isMobile = useIsMobileSizedScreen();

  if (renderWhen === 'mobile') {
    return isMobile ? (children as JSX.Element) : null;
  }
  return isMobile ? null : (children as JSX.Element);
};

export default ConditionalRenderer;
