import { useEffect } from 'react';
import { defineMessages, IntlShape, useIntl } from '@alltrails/shared/react-intl';
import LegacyModal from 'components/LegacyModal';
import errorIcon from 'images/shared/download-failed-new@3x.png';
import successUpload from 'images/icons/share/filled@2x.png';
import pdfDownload from 'images/icons/share/icon-pdf@2x.png';
import Button from '@alltrails/shared/denali/components/Button';
import * as styles from './styles/styles.module.scss';
import LoadingSpinner from '../LoadingSpinner';

type Variant =
  | 'saving'
  | 'error-saving'
  | 'error'
  | 'error-print-expired'
  | 'error-cannot-route'
  | 'error-routing'
  | 'error-deleting-map'
  | 'loading'
  | 'saved'
  | 'downloading'
  | 'downloaded'
  | 'creating-alert'
  | 'created-alert'
  | 'saving-alert'
  | 'saved-alert'
  | 'saving-group'
  | 'group-saved'
  | 'adding-to-group'
  | 'added-to-group';

const messages = defineMessages({
  SAVING_YOUR_MAP: { defaultMessage: 'Saving your map' },
  ERROR_SAVING: { defaultMessage: 'Error saving' },
  ERROR_OCCURRED: { defaultMessage: 'Error occurred' },
  SESSION_EXPIRED: { defaultMessage: 'Your session has expired. Please reload the page and try again.' },
  CANNOT_ROUTE: { defaultMessage: 'Could not route between points. Please try a different activity type, or switch to drawing mode.' },
  ERROR_ROUTING: { defaultMessage: 'An error occurred while attempting to route between points.' },
  ERROR_DELETING: { defaultMessage: 'Error deleting map' },
  LOADING: { defaultMessage: 'Loading...' },
  MAP_SAVED: { defaultMessage: 'Map saved' },
  CREATING_MAP: { defaultMessage: 'Creating your map' },
  PDF_DOWNLOADED: { defaultMessage: 'Downloaded' },
  READY_MAP: { defaultMessage: 'Woohoo! Your map is ready.' },
  OPEN_PDF: { defaultMessage: 'Open PDF' },
  CREATING_ALERT: { defaultMessage: 'Creating alert' },
  ALERT_CREATED: { defaultMessage: 'Alert created!' },
  SAVING_ALERT: { defaultMessage: 'Saving alert' },
  ALERT_SAVED: { defaultMessage: 'Alert saved!' },
  SAVING_GROUP: { defaultMessage: 'Saving group' },
  GROUP_SAVED: { defaultMessage: 'Group saved!' },
  ADDING_TO_GROUP: { defaultMessage: 'Adding to group' },
  ADDED_TO_GROUP: { defaultMessage: 'Added to group!' }
});

const getVariant = (variant: Variant, intl: IntlShape) => {
  switch (variant) {
    case 'saving':
      return {
        element: <LoadingSpinner />,
        text: `${intl.formatMessage(messages.SAVING_YOUR_MAP)}...`
      };
    case 'saving-group':
      return {
        element: <LoadingSpinner />,
        text: `${intl.formatMessage(messages.SAVING_GROUP)}`
      };
    case 'error-saving':
      return {
        element: <img src={errorIcon} className={styles.iconImage} alt={intl.formatMessage(messages.ERROR_SAVING)} />,
        text: intl.formatMessage(messages.ERROR_SAVING)
      };
    case 'error':
      return {
        element: <img src={errorIcon} className={styles.iconImage} alt={intl.formatMessage(messages.ERROR_OCCURRED)} />,
        text: intl.formatMessage(messages.ERROR_OCCURRED)
      };
    case 'error-print-expired':
      return {
        element: <img src={errorIcon} className={styles.iconImage} alt={intl.formatMessage(messages.ERROR_SAVING)} />,
        text: intl.formatMessage(messages.SESSION_EXPIRED)
      };
    case 'error-cannot-route':
      return {
        element: <img src={errorIcon} className={styles.iconImage} alt={intl.formatMessage(messages.CANNOT_ROUTE)} />,
        text: intl.formatMessage(messages.CANNOT_ROUTE)
      };
    case 'error-routing':
      return {
        element: <img src={errorIcon} className={styles.iconImage} alt={intl.formatMessage(messages.ERROR_ROUTING)} />,
        text: intl.formatMessage(messages.ERROR_ROUTING)
      };
    case 'error-deleting-map':
      return {
        element: <img src={errorIcon} className={styles.iconImage} alt={intl.formatMessage(messages.ERROR_DELETING)} />,
        text: intl.formatMessage(messages.ERROR_DELETING)
      };
    case 'loading': {
      return {
        element: <LoadingSpinner />,
        text: intl.formatMessage(messages.LOADING)
      };
    }
    case 'saved':
      return {
        element: <img src={successUpload} className={styles.iconImage} alt={intl.formatMessage(messages.MAP_SAVED)} />,
        text: `${intl.formatMessage(messages.MAP_SAVED)}!`
      };
    case 'downloading':
      return {
        element: <LoadingSpinner />,
        text: `${intl.formatMessage(messages.CREATING_MAP)}...`
      };
    case 'downloaded':
      return {
        element: <img src={pdfDownload} className={styles.pdfImage} alt={intl.formatMessage(messages.PDF_DOWNLOADED)} />,
        text: `${intl.formatMessage(messages.READY_MAP)}`
      };
    case 'creating-alert':
      return {
        element: <LoadingSpinner />,
        text: intl.formatMessage(messages.CREATING_ALERT)
      };
    case 'created-alert':
      return {
        element: <img src={successUpload} className={styles.iconImage} alt={intl.formatMessage(messages.ALERT_CREATED)} />,
        text: intl.formatMessage(messages.ALERT_CREATED)
      };
    case 'saving-alert':
      return {
        element: <LoadingSpinner />,
        text: intl.formatMessage(messages.SAVING_ALERT)
      };
    case 'saved-alert':
      return {
        element: <img src={successUpload} className={styles.iconImage} alt={intl.formatMessage(messages.ALERT_SAVED)} />,
        text: intl.formatMessage(messages.ALERT_SAVED)
      };
    case 'group-saved':
      return {
        element: <img src={successUpload} className={styles.iconImage} alt={intl.formatMessage(messages.GROUP_SAVED)} />,
        text: intl.formatMessage(messages.GROUP_SAVED)
      };
    case 'adding-to-group':
      return {
        element: <LoadingSpinner />,
        text: `${intl.formatMessage(messages.ADDING_TO_GROUP)}...`
      };
    case 'added-to-group':
      return {
        element: <img src={successUpload} className={styles.iconImage} alt={intl.formatMessage(messages.ADDED_TO_GROUP)} />,
        text: intl.formatMessage(messages.ADDED_TO_GROUP)
      };
    default:
      return {};
  }
};

type Props = {
  downloadLink?: string;
  isMobileWidth: boolean;
  variant: any;
  openPdf?: any;
  updateProgressDialog: any;
};

const ProgressDialog = ({ updateProgressDialog, variant, downloadLink, openPdf, isMobileWidth }: Props) => {
  const intl = useIntl();
  const closeModal = () => {
    updateProgressDialog(null);
  };

  useEffect(() => {
    // automatically closes the modal if saved or error
    if (['saved', 'error', 'error-deleting-map', 'created-alert', 'saved-alert'].includes(variant)) {
      setTimeout(() => {
        closeModal();
      }, 2000);
    }
  }, [variant]);

  const { element, text } = getVariant(variant, intl);

  return (
    <LegacyModal handleBackgroundClick={closeModal}>
      <div className={`${downloadLink ? `${styles.printContainer} ${isMobileWidth && styles.mobileWidth}` : styles.container}`}>
        {variant === 'downloaded' && <button className={styles.closeButton} onClick={closeModal} type="button" data-testid="close" />}
        {variant === 'downloaded' ? (
          <div className={styles.pdfContainer}>
            {isMobileWidth && element}
            <div className={isMobileWidth ? styles.text : styles.largeText}>{text}</div>
            {!isMobileWidth && element}
          </div>
        ) : (
          <div>
            {element}
            <div className={styles.text}>{text}</div>
          </div>
        )}
        {downloadLink && (
          <div className={styles.pdfButton}>
            <Button text={intl.formatMessage(messages.OPEN_PDF)} onClick={openPdf} testId="progress-dialog-open-pdf" variant="primary" />
          </div>
        )}
      </div>
    </LegacyModal>
  );
};

export default ProgressDialog;
