import { string, func, bool, arrayOf, shape } from 'prop-types';
import ModalBackground from '@alltrails/shared/components/modals/ModalBackground';
import DescriptiveRadioModal from '@alltrails/shared/components/modals/DescriptiveRadioModal';
import CustomProvider from 'components/CustomProvider';

// Wrapper component that will continue to grow with tracking and the rest of privacy
// along with this ticket: https://alltrails.atlassian.net/browse/CMTY-856

const IndividualPrivacyModal = ({
  includeModalBackground,
  title,
  description,
  closeModal,
  goBack,
  isMounted,
  onTransitionEnd,
  radioOptions,
  selected,
  acceptCallback,
  subject
}) => {
  const modalInterior = (
    <CustomProvider>
      <DescriptiveRadioModal
        title={title}
        description={description}
        closeModal={closeModal}
        goBack={goBack}
        isMounted={isMounted}
        onTransitionEnd={onTransitionEnd}
        radioOptions={radioOptions}
        selected={selected}
        acceptCallback={acceptCallback}
        collaborativeListSubtext={subject && subject === 'lists'}
      />
    </CustomProvider>
  );

  return includeModalBackground ? (
    <ModalBackground closeModal={closeModal} goBack={goBack}>
      {modalInterior}
    </ModalBackground>
  ) : (
    modalInterior
  );
};

IndividualPrivacyModal.propTypes = {
  includeModalBackground: bool,
  title: string.isRequired,
  description: string,
  closeModal: func.isRequired,
  goBack: func,
  isMounted: bool,
  onTransitionEnd: func,
  radioOptions: arrayOf(shape({ id: string, name: string, title: string, subtext: string, value: string })).isRequired,
  selected: string.isRequired,
  acceptCallback: func.isRequired,
  subject: string
};

IndividualPrivacyModal.defaultProps = {
  includeModalBackground: false,
  description: '',
  goBack: null,
  isMounted: false,
  onTransitionEnd: () => {}
};

export default IndividualPrivacyModal;
