import { getPrivacyIcon } from '@alltrails/modules/Privacy/privacyHelpers';

const doubleBreak = (
  <>
    <br />
    <br />
  </>
);

// privacy policy visibility options
export const VISIBILITY_PUBLIC = 'urn:alltrails:visibility:public';
export const VISIBILITY_FOLLOWERS_ONLY = 'urn:alltrails:visibility:followers-only';
export const VISIBILITY_PRIVATE = 'urn:alltrails:visibility:private';
export const TRUE = true;
export const FALSE = false;

export const VISIBILITY_ARRAY = [VISIBILITY_PUBLIC, VISIBILITY_FOLLOWERS_ONLY, VISIBILITY_PRIVATE];

export const VISIBILITY_TO_OPTION = {
  [VISIBILITY_PUBLIC]: 'Public',
  [VISIBILITY_FOLLOWERS_ONLY]: 'Followers only',
  [VISIBILITY_PRIVATE]: 'Private',
  [TRUE]: 'Yes',
  [FALSE]: 'No'
};

export const visibilityToTranslatedOption = (key, intl) => {
  const VISIBILITY_TO_TRANSLATED_OPTION = {
    [VISIBILITY_PUBLIC]: intl.formatMessage({ defaultMessage: 'Public' }),
    [VISIBILITY_FOLLOWERS_ONLY]: intl.formatMessage({ defaultMessage: 'Followers only' }),
    [VISIBILITY_PRIVATE]: intl.formatMessage({ defaultMessage: 'Private' }),
    [TRUE]: intl.formatMessage({ defaultMessage: 'Yes' }),
    [FALSE]: intl.formatMessage({ defaultMessage: 'No' })
  };

  return VISIBILITY_TO_TRANSLATED_OPTION[key];
};

// privacy policy subjects
export const COMPLETED_TRAILS = 'completedTrails';
export const ACTIVITIES = 'activities';
export const ACTIVITY = 'activity';
export const CUSTOM_MAPS = 'customMaps';
export const CUSTOM_MAP = 'customMap';
export const LISTS = 'lists';
export const SOCIAL_NETWORK = 'socialNetwork';
export const FOLLOW_REQUESTS_REQUIRED = 'followRequestsRequired';
export const LIST = 'list';

export const subjectToTitle = (key, intl) => {
  const SUB_TO_TITLE = {
    [FOLLOW_REQUESTS_REQUIRED]: intl.formatMessage({ defaultMessage: 'Require follower requests' }),
    [SOCIAL_NETWORK]: intl.formatMessage({ defaultMessage: 'Your community' }),
    [COMPLETED_TRAILS]: intl.formatMessage({ defaultMessage: 'Completed trails' }),
    [ACTIVITIES]: intl.formatMessage({ defaultMessage: 'Activities' }),
    [CUSTOM_MAPS]: intl.formatMessage({ defaultMessage: 'Maps' }),
    [LISTS]: intl.formatMessage({ defaultMessage: 'Lists' }),
    [CUSTOM_MAP]: intl.formatMessage({ defaultMessage: 'Map privacy' }),
    [ACTIVITY]: intl.formatMessage({ defaultMessage: 'Activity privacy level' }),
    [LIST]: intl.formatMessage({ defaultMessage: 'List privacy' })
  };

  return SUB_TO_TITLE[key];
};

export const PRIVACY_POLICY_KEYS_MAP = {
  [FOLLOW_REQUESTS_REQUIRED]: ['socialNetwork', 'followRequestsRequired'],
  [SOCIAL_NETWORK]: ['profile', 'visibility'],
  [COMPLETED_TRAILS]: ['content', 'visibility'],
  [ACTIVITIES]: ['content', 'visibility'],
  [CUSTOM_MAPS]: ['content', 'visibility'],
  [LISTS]: ['content', 'visibility']
};

const generateRadioProps = (title, description, selected, subtexts, intl) => ({
  title,
  description,
  selected,
  radioOptions: subtexts.map((subtext, idx) => ({
    id: VISIBILITY_TO_OPTION[VISIBILITY_ARRAY[idx]],
    name: VISIBILITY_TO_OPTION[VISIBILITY_ARRAY[idx]],
    title: visibilityToTranslatedOption([VISIBILITY_ARRAY[idx]], intl),
    subtext,
    value: VISIBILITY_ARRAY[idx],
    icon: getPrivacyIcon(VISIBILITY_ARRAY[idx])
  }))
});

export const policyToRadioForm = (subject, selected, intl) => {
  const title = subjectToTitle(subject, intl);

  switch (subject) {
    case FOLLOW_REQUESTS_REQUIRED:
      return {
        title,
        description: intl.formatMessage({ defaultMessage: 'Your community includes who you follow and who follows you.' }),
        selected,
        radioOptions: [
          {
            id: VISIBILITY_TO_OPTION[FALSE],
            name: VISIBILITY_TO_OPTION[FALSE],
            title: visibilityToTranslatedOption(FALSE, intl),
            subtext: intl.formatMessage({ defaultMessage: 'Do not require follower requests.' }),
            value: FALSE.toString()
          },
          {
            id: VISIBILITY_TO_OPTION[TRUE],
            name: VISIBILITY_TO_OPTION[TRUE],
            title: visibilityToTranslatedOption(TRUE, intl),
            subtext: intl.formatMessage({ defaultMessage: 'Require follower requests.' }),
            value: TRUE.toString()
          }
        ]
      };
    case SOCIAL_NETWORK:
      return generateRadioProps(
        title,
        intl.formatMessage({ defaultMessage: 'Your community includes who you follow and who follows you.' }),
        selected,
        [
          intl.formatMessage({ defaultMessage: 'Your community is visible to anyone.' }),
          intl.formatMessage({ defaultMessage: 'Your community is only visible to your followers.' }),
          intl.formatMessage({ defaultMessage: 'Your community is only visible to you.' })
        ],
        intl
      );
    case COMPLETED_TRAILS:
      return generateRadioProps(
        title,
        intl.formatMessage({
          defaultMessage: 'Your completed trails do not include the date or time the trail was completed, just that you’ve completed it.'
        }),
        selected,
        [
          intl.formatMessage({ defaultMessage: 'Your completed trails are visible to anyone.' }),
          intl.formatMessage({ defaultMessage: 'Your completed trails are only visible to your followers.' }),
          intl.formatMessage({ defaultMessage: 'Your completed trails are only visible to you.' })
        ],
        intl
      );
    case ACTIVITIES:
      return generateRadioProps(
        title,
        <div>
          {intl.formatMessage({
            defaultMessage:
              'Anytime you use Navigator, you have the option to save your activity. This saved activity includes details like your route, distance and elevation, the date and time of your trip, and any photos you add. These activities can appear on trail pages, in community feeds, and on your profile.'
          })}
          {doubleBreak} {intl.formatMessage({ defaultMessage: 'Note: You can always change the privacy level for any individual activity.' })}
        </div>,
        selected,
        [
          intl.formatMessage({
            defaultMessage: 'Your activities are visible to anyone. They may appear on trail pages, in community feeds and on your profile.'
          }),
          intl.formatMessage({
            defaultMessage: 'Your activities are only visible to your followers. They will appear in your followers feeds and on your profile.'
          }),
          intl.formatMessage({ defaultMessage: 'Your activities are only visible to you.' })
        ],
        intl
      );
    case CUSTOM_MAPS:
      return generateRadioProps(
        title,
        <div>
          {intl.formatMessage({ defaultMessage: 'This includes maps you’ve created or downloaded, along with those tied to an activity.' })}{' '}
          {doubleBreak} {intl.formatMessage({ defaultMessage: 'Note: You can always change the privacy level for any individual map.' })}
        </div>,
        selected,
        [
          intl.formatMessage({ defaultMessage: 'Your maps are visible by anyone.' }),
          intl.formatMessage({ defaultMessage: 'Your maps are only visible to your followers.' }),
          intl.formatMessage({ defaultMessage: 'Your maps are only visible to you.' })
        ],
        intl
      );

    case LISTS:
      return generateRadioProps(
        title,
        <div>
          {intl.formatMessage({ defaultMessage: 'The lists you create can include activities, trails, and maps you’ve favorited.' })} {doubleBreak}
          {intl.formatMessage({ defaultMessage: 'Note: You can always change the privacy level for any individual list.' })}
        </div>,
        selected,
        [
          intl.formatMessage({ defaultMessage: 'Your lists are visible by anyone.' }),
          intl.formatMessage({ defaultMessage: 'Your lists are only visible to your followers.' }),
          intl.formatMessage({ defaultMessage: 'Your lists are only visible to you and any collaborators.' })
        ],
        intl
      );
    case LIST:
      return generateRadioProps(
        title,
        intl.formatMessage({ defaultMessage: 'Choose who can view this list' }),
        selected,
        [
          intl.formatMessage({ defaultMessage: 'This list is visible by anyone.' }),
          intl.formatMessage({ defaultMessage: 'This list is only visible to your followers.' }),
          intl.formatMessage({ defaultMessage: 'This list is only visible to you.' })
        ],
        intl
      );
    case CUSTOM_MAP:
      return generateRadioProps(
        title,
        intl.formatMessage({ defaultMessage: 'Choose who can view this map.' }),
        selected,
        [
          intl.formatMessage({ defaultMessage: 'This map is visible by anyone.' }),
          intl.formatMessage({ defaultMessage: 'This map is only visible to your followers.' }),
          intl.formatMessage({ defaultMessage: 'This map is only visible to you.' })
        ],
        intl
      );
    case ACTIVITY:
      return generateRadioProps(
        title,
        intl.formatMessage({ defaultMessage: 'Choose who can view this activity and where it will appear.' }),
        selected,
        [
          intl.formatMessage({
            defaultMessage: 'This activity is visible to anyone. It may appear on trail pages, in community feeds and on your profile.'
          }),
          intl.formatMessage({
            defaultMessage: 'This activity is only visible to your followers. It will appear in your followers feeds and on your profile.'
          }),
          intl.formatMessage({ defaultMessage: 'This activity is only visible to you.' })
        ],
        intl
      );
    default:
      return null;
  }
};
