import PlusCheckout from 'components/PlusCheckout';
import Coupon from 'types/Coupon';
import CurrencyCode from 'types/CurrencyCode';
import MinimalLayout from 'components/MinimalLayout';
import Plan from 'types/Plan';
import { useEffect } from 'react';
import logProCheckoutPageViewed from '@alltrails/analytics/events/logProCheckoutPageViewed';
import ProUpsellFormat from '@alltrails/analytics/enums/ProUpsellFormat';
import * as styles from './CheckoutRoute.module.scss';

type Props = {
  nonTrialPlan: Plan;
  coupon: Coupon;
  currencyCode: CurrencyCode;
  plan: Plan;
  userId: number;
  whenExpired: string;
};

export default function CheckoutRoute({ nonTrialPlan, coupon, currencyCode, userId, whenExpired, plan }: Props) {
  useEffect(() => {
    logProCheckoutPageViewed({ upsell_format: ProUpsellFormat.Standard });
  }, []);

  return (
    <MinimalLayout>
      <PlusCheckout
        className={styles.content}
        coupon={coupon}
        currencyCode={currencyCode}
        initialNonTrialPlan={nonTrialPlan}
        initialPlan={plan}
        userId={userId}
        whenExpired={whenExpired}
      />
    </MinimalLayout>
  );
}
