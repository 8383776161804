import { useEffect } from 'react';
import logInviteFriendsBannerViewed from '@alltrails/analytics/events/logInviteFriendsBannerViewed';
import InviteFriendsBannerSource from '@alltrails/analytics/enums/InviteFriendsBannerSource';
import InviteYourFriendsBannerMobile from './InviteYourFriendsBannerMobile';
import InviteYourFriendsBannerDesktop from './InviteYourFriendsBannerDesktop';

export default function InviteYourFriendsBanner() {
  useEffect(() => {
    logInviteFriendsBannerViewed({ banner_source: InviteFriendsBannerSource.Homepage });
  }, []);

  return (
    <>
      <InviteYourFriendsBannerMobile />
      <InviteYourFriendsBannerDesktop />
    </>
  );
}
