import Typography from '@alltrails/shared/denali/components/Typography';
import * as styles from './LinkSection.module.scss';

type LinkSectionProps = {
  heading: string;
  links: { url: string; title: string }[];
};

const LinkSection = ({ heading, links }: LinkSectionProps) => (
  <div className={styles.container}>
    <Typography variant="heading200" component="div" className={styles.header}>
      {heading}
    </Typography>
    <div className={styles.columnedSection}>
      <ul className={styles.linksColumn}>
        {links.slice(0, 8).map(link => (
          <li key={link.url} className={styles.listItem}>
            <a href={link.url} className={styles.link}>
              {link.title}
            </a>
          </li>
        ))}
      </ul>
      <ul className={styles.linksColumn}>
        {links.slice(8, 15).map(link => (
          <li key={link.url} className={styles.listItem}>
            <a className={styles.link} href={link.url}>
              {link.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default LinkSection;
