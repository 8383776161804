import type { Permission } from 'types/User';

type Args = {
  permission: Permission;
};

/**
 * hasPermission is a utility function that checks if the current user has a specific permission.
 * This function uses __AT_DATA__ and is implemented intentionally as a util rather than a hook.
 * As of time of writing, almost half the monolith code cannot easily leverage a modern hook.
 * Directly accessing the __AT_DATA__ object is a common pattern in the monolith, but an anti-pattern.
 * This seems like the most readable and consistent option we have today.
 *
 * @param permission - The permission to check for
 * returns - A boolean indicating if the user has the permission
 */
export default function hasPermission({ permission }: Args) {
  if (__AT_DATA__.store?.user?.permissions?.includes(permission)) {
    return true;
  }

  // Fall back to legacy admin check.
  return Boolean(__AT_DATA__.store?.user?.admin);
}
