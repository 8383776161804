import { shape, string, boolean } from 'prop-types';

export default shape({
  content: shape({
    completedTrails: shape({
      visibility: string
    }),
    activities: shape({
      visibility: string
    }),
    customMaps: shape({
      visibility: string
    }),
    lists: shape({
      visibility: string
    })
  }),
  profile: shape({
    socialNetwork: shape({
      visibility: string
    })
  }),
  socialNetwork: shape({
    followRequestsRequired: boolean
  })
});
