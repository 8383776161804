const { postcodeValidator } = require('postcode-validator');

// See https://developers.whatismybrowser.com/useragents/explore/software_name/internet-explorer/
const isMicrosoftInternetExplorer = () => {
  if (window.navigator) {
    const userAgentPatterns = ['MSIE ', 'Trident/'];
    return new RegExp(userAgentPatterns.join('|')).test(window.navigator.userAgent);
  }
  return false;
};

const validatePostcode = (value, locale) => {
  if (isMicrosoftInternetExplorer()) {
    return value && value.toString().length > 0;
  }
  return postcodeValidator(value, locale);
};

export { validatePostcode };
