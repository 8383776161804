// extracted by mini-css-extract-plugin
export var button = "GiftReview-module__button___Lp7hO";
export var cardColumn = "GiftReview-module__cardColumn___AbvCB";
export var container = "GiftReview-module__container___GKHjw";
export var giftAndMessageMobile = "GiftReview-module__giftAndMessageMobile___q5rPB";
export var giftCard = "GiftReview-module__giftCard___jr2l5";
export var giftMessage = "GiftReview-module__giftMessage___ROdT4";
export var header = "GiftReview-module__header___A6_bx";
export var metadata = "GiftReview-module__metadata___yNceB";
export var metadataColumn = "GiftReview-module__metadataColumn___NWySY";
export var paragraphEnd = "GiftReview-module__paragraphEnd___Cqwnx";
export var spacer = "GiftReview-module__spacer___aXJ6J";
export var value = "GiftReview-module__value___Yu0eC";