import Footer from '@alltrails/shared/components/Footer';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import useUser from 'hooks/useUser';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { generateNewLanguageUrl } from '@alltrails/shared/utils/languageSupport';
import CustomProvider from '../CustomProvider';

type FooterWrapperProps = {
  wideFooter: boolean;
  noFollowSocials?: boolean | null;
  noReferrerAppLinks?: boolean | null;
};

const FooterWrapper = ({ wideFooter, noFollowSocials = true, noReferrerAppLinks = true }: FooterWrapperProps): JSX.Element => {
  const user = useUser();
  const languageRegionCode = useLanguageRegionCode();

  const noFollowSocialsDefaultedToTrue = noFollowSocials === null ? true : noFollowSocials;

  const noReferrerAppLinkDefaultedToTrue = noReferrerAppLinks === null ? true : noReferrerAppLinks;

  return (
    <Footer
      enabledLanguages={__AT_DATA__.enabledLanguages}
      user={user}
      wideFooter={wideFooter}
      handleLanguageChange={(newLanguageRegionCode: LanguageRegionCode) => {
        window.location.href = generateNewLanguageUrl(window.location.pathname, newLanguageRegionCode);
      }}
      languageRegionCode={languageRegionCode}
      noFollowSocials={noFollowSocialsDefaultedToTrue}
      noReferrerAppLinks={noReferrerAppLinkDefaultedToTrue}
      irclickid={__AT_DATA__.irclickid}
    />
  );
};

export default function Wrapper({ wideFooter, noFollowSocials, noReferrerAppLinks }: FooterWrapperProps) {
  return (
    <CustomProvider>
      <FooterWrapper wideFooter={wideFooter} noFollowSocials={noFollowSocials} noReferrerAppLinks={noReferrerAppLinks} />
    </CustomProvider>
  );
}
