import { TrackParams } from 'types/TTDUniversalPixelApi';

const trackTinuitiOOHPixelEvent = (event: TrackParams) => {
  if (typeof TTDUniversalPixelApi === 'function') {
    const universalPixelApi = new TTDUniversalPixelApi();
    universalPixelApi.init('nbid12p', ['b5033m2'], 'https://insight.adsrvr.org/track/up', event);
  }
};

const trackTinuitiOOHPurchase = (value: number, currencyCode: string) => {
  const purchaseEvent = {
    v: value,
    vf: currencyCode,
    td1: 'purchase'
  };
  trackTinuitiOOHPixelEvent(purchaseEvent);
};

const trackTinuitiOOHRegistration = () => {
  const registrationEvent = {
    td1: 'registration'
  };
  trackTinuitiOOHPixelEvent(registrationEvent);
};

export { trackTinuitiOOHPurchase, trackTinuitiOOHRegistration };
