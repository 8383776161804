import { ComponentProps } from 'react';
import { useIntl } from '@alltrails/shared/react-intl';
import { AlgoliaSearchBox } from '@alltrails/modules/AlgoliaSearch';
import '@alltrails/modules/AlgoliaSearch/index.css';
import useExperiment from 'hooks/useExperiment';
import SearchOrigin from '@alltrails/analytics/enums/SearchOrigin';
import * as styles from './styles/styles.module.scss';

type OnResultSelect = ComponentProps<typeof AlgoliaSearchBox>['onResultSelect'];

type SearchBoxProps = {
  navigateOnSelect: boolean;
  onResultSelect?: OnResultSelect;
  placeholder?: string;
  useNewSearch?: boolean;
  value?: string;
};

const SearchBox = ({ navigateOnSelect, onResultSelect, placeholder, useNewSearch, value = '' }: SearchBoxProps) => {
  const intl = useIntl();
  const inNewSearchTreatment = useExperiment('web-disco-rm-global-nav')?.value === 'treatment_b';
  const newSearchEnabled = useNewSearch === undefined ? inNewSearchTreatment : useNewSearch || inNewSearchTreatment;

  return (
    <div className={styles.searchBox}>
      <AlgoliaSearchBox
        clearOnSelect
        configs={global.__AT_DATA__.algoliaConfig}
        dropdownVariant="enveloping"
        navigateOnSelect={navigateOnSelect}
        onResultSelect={onResultSelect}
        placeholder={placeholder || intl.formatMessage({ defaultMessage: 'Search' })}
        searchOrigin={SearchOrigin.GlobalNav}
        searchTypes="all"
        testId="header-search"
        variant="subtle"
        useNewSearch={newSearchEnabled}
        value={value}
      />
    </div>
  );
};
export default SearchBox;
