import * as styles from './styles/styles.module.scss';

export type ActivityRiverItemProps = {
  url: string;
  imgSrc: string;
  title: string;
};

const ActivityRiverItem = ({ url, imgSrc, title = '' }: ActivityRiverItemProps): JSX.Element => (
  <div className={styles.container} data-testid={title}>
    <a href={url}>
      <img src={imgSrc} className={styles.image} alt={title} loading="lazy" />
      <div className={styles.title}>{title}</div>
    </a>
  </div>
);

export default ActivityRiverItem;
