import { useDispatch } from 'react-redux';
import Link from '@alltrails/shared/denali/components/Link';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import Logo from '@alltrails/shared/denali/components/Logo';
import Typography from '@alltrails/shared/denali/components/Typography';
import RegisterForm from 'components/RegisterForm';
import TermsOfService from 'components/TermsOfService';
import LoginResponse from 'types/LoginResponse';
import type { User } from 'types/User';
import { ReactNode } from 'react';
import * as styles from './CreateAccount.module.scss';
import { setUser } from '../../userSlice';
import { setFormAuthenticityToken } from '../../formAuthenticityTokenSlice';

// Desktop: https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=6514%3A99348
// Mobile: https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=6514%3A99372

type Props = {
  heading?: ReactNode;
  onSuccess: (user: User) => void;
  redirectUrl?: string;
};

export default function CreateAccount({ heading, onSuccess, redirectUrl }: Props) {
  const dispatch = useDispatch();

  function handleRegistrationSuccess({ form_authenticity_token, user }: LoginResponse) {
    if (redirectUrl) {
      window.location.assign(redirectUrl);
    } else {
      dispatch(setFormAuthenticityToken(form_authenticity_token));
      dispatch(setUser(user));
      onSuccess(user);
      global.google?.accounts.id.cancel(); // google can be undefined on mobile web
    }
  }

  return (
    <div className={styles.content}>
      <div className={styles.logo}>
        <Logo variant="symbol" size="lg" color="dark" />
      </div>
      <Typography component="div" variant="heading400" className={styles.header}>
        {heading || <FormattedMessage defaultMessage="To keep exploring trails, log in or sign up for free" />}
      </Typography>
      <RegisterForm autoFocus handleRegistrationSuccess={handleRegistrationSuccess} />
      <Typography component="div" mb="32" variant="paragraph200" color="secondary">
        <FormattedMessage defaultMessage="Already have an account?" />{' '}
        <Link href="/login" useReactRouter>
          <FormattedMessage defaultMessage="Log in" />
        </Link>
      </Typography>
      <TermsOfService />
    </div>
  );
}
