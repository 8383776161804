import { useState } from 'react';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import Button from '@alltrails/shared/denali/components/Button';
import Apple from '@alltrails/shared/icons/Apple';
import { unlinkRequest } from 'utils/requests/garminRequests';
import logError from 'utils/logError';
import AccountConnected from '../AccountConnectButtons/AccountConnected';
import { appleConstants } from '../../Constants';
import * as styles from './ConnectAppleButton.module.scss';

export type ConnectAppleProps = {
  appleSigninId?: string;
  authState?: string;
};

const ConnectAppleButton = ({ authState, appleSigninId }: ConnectAppleProps) => {
  const [isDisconnectingAccount, setIsDisconnectingAccount] = useState(false);

  const redirectURI = `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }/users/auth/apple/callback`;

  const authUrl = `${appleConstants.AUTHORIZE_URL + appleConstants.AUTHORIZE_PARAMS}&client_id=${
    appleConstants.CLIENT_ID
  }&redirect_uri=${encodeURIComponent(redirectURI)}&scope=${encodeURIComponent(appleConstants.SCOPES)}&auth_state=${authState}`;

  const handleClick = () => window.location.assign(authUrl);

  const handleUnlinkClick = () => {
    setIsDisconnectingAccount(true);
    unlinkRequest('apple')
      .then(() => {
        window.location.reload(); // this is necessary for now to render the flash message
      })
      .catch(err => {
        logError(err);
        setIsDisconnectingAccount(false);
      });
  };

  if (appleSigninId) {
    return <AccountConnected linkText="Unlink" isLoading={isDisconnectingAccount} onClick={handleUnlinkClick} />;
  }

  return (
    <Button
      className={styles.button}
      text={<FormattedMessage defaultMessage="Connect with Apple" />}
      testId="connect-with-apple"
      icon={{ Component: Apple }}
      onClick={handleClick}
      variant="primary"
    />
  );
};

export default ConnectAppleButton;
