// extracted by mini-css-extract-plugin
export var activeChangeContainer = "GarminSettingsModal-module__activeChangeContainer___cVH49";
export var container = "GarminSettingsModal-module__container___mVHNG";
export var description = "GarminSettingsModal-module__description___LG6Wr";
export var disabled = "GarminSettingsModal-module__disabled___Mnh2f";
export var disconnectButton = "GarminSettingsModal-module__disconnectButton___cOohH";
export var divider = "GarminSettingsModal-module__divider___feQnm";
export var getPlusButton = "GarminSettingsModal-module__getPlusButton___a2gMR";
export var inactive = "GarminSettingsModal-module__inactive___L6aod";
export var loading = "GarminSettingsModal-module__loading___tuPP6";
export var manageRoutes = "GarminSettingsModal-module__manageRoutes___ApeOL";
export var privacyButton = "GarminSettingsModal-module__privacyButton___wfoIZ";
export var routes = "GarminSettingsModal-module__routes___XPqny";
export var routesContainer = "GarminSettingsModal-module__routesContainer___b4BxN";
export var routesUpsellContainer = "GarminSettingsModal-module__routesUpsellContainer___vqvd6";
export var sendRoutes = "GarminSettingsModal-module__sendRoutes___a0Cg5";
export var textAfterIcon = "GarminSettingsModal-module__textAfterIcon___Ecl_E";
export var title = "GarminSettingsModal-module__title___JiFZn";