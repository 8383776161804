import { get, post } from '@alltrails/shared/api';
import type { UserId } from 'types/User';

export const activityExport = 'ACTIVITY_EXPORT';
export const courseImport = 'COURSE_IMPORT';
export type GarminPermissionTypes = typeof activityExport | typeof courseImport;

export type GarminPermissionsResponse = {
  userId: UserId;
  permissions: GarminPermissionTypes[];
};

type UnlinkRequestType = 'garmin' | 'apple' | 'google' | 'facebook';

const unlinkRequest = (unlinkRequestType: UnlinkRequestType) => post<void>(`/api/alltrails/${unlinkRequestType}/auth/unlink`, null);

const getGarminPermissions = () => get<GarminPermissionsResponse>('/api/alltrails/garmin/users');

const postGarminCourse = (mapId: number) => post<void>('/api/alltrails/garmin/courses', { at_map_id: mapId });

export { unlinkRequest, getGarminPermissions, postGarminCourse };
