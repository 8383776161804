import { createContext, useCallback, useEffect, useState } from 'react';
import { PageSource } from 'components/shared/Page/Page';
import { LOCATION_INFO } from 'utils/constants/SearchAnalyticsConstants';
import {
  getSavedAlgoliaQueryId,
  saveAlgoliaQueryIdsToLocalStorage,
  sendConversionBeacon,
  sendSearchResultsBeacon
} from '../../utils/requests/algolia_requests';

export const SearchInsightsContext = createContext({
  pageName: 'unknown',
  context: {},
  logClickEvent: (objectID, position) => {},
  logConversionEvent: () => {},
  updateSearchId: newSearchId => {}
});

/**
 * SearchInsightsProvider creates a wrapper component to bind together search
 * results and later details page views to track conversions even if the user
 * navigates to a server-side rendered page.
 *
 * For Algolia there is a react component that automatically binds together algolia searches
 * and the queryID for subsequent event tracking. This may be a preferred solution, but seems
 * like it may only be suited to their stock components at this time.
 * https://www.algolia.com/doc/guides/building-search-ui/going-further/send-insights-events/react/
 *
 * @param {{ pageName: string, context: object, children: ReactNode }}
 * @returns
 */
const SearchInsightsProvider = ({ pageName, context, children }) => {
  const [searchId, setSearchId] = useState(null);
  const analyticsPageName = pageName === PageSource.Seo ? LOCATION_INFO : pageName;

  /**
   * updateSearchId allows various consumers to update the search id wherever
   * a new search may have occurred.
   *
   * @param {string} newSearchId
   */
  const updateSearchId = newSearchId => {
    if (newSearchId) {
      saveAlgoliaQueryIdsToLocalStorage(newSearchId);
      setSearchId(newSearchId);
    }
  };

  /**
   * logInsightWrapper wraps a function to provide common insights logging variables.
   */
  const logInsightWrapper = useCallback(
    fn => {
      if (!searchId) {
        return;
      }

      const algoliaIndexId = context?.primaryAlgoliaIndexId;
      const userId = context?.currentUser?.id || null;

      fn(algoliaIndexId, userId);
    },
    [searchId, context]
  );

  // Load any persisted query ids if available. We want this for cases when a user opens
  // a result in a new tab or our navigation changed the page url so we couldn't
  // track the last search id in memory.
  useEffect(() => {
    const savedSearchId = getSavedAlgoliaQueryId();
    if (savedSearchId) {
      setSearchId(savedSearchId);
    }
  }, []);

  /**
   * logClickEvent uses the current search information and provided arguments to
   * log events related to the flow of a user's journey on the website.
   *
   * @param {string} objectID
   * @param {number} position
   */
  const logClickEvent = (objectID, position) => {
    logInsightWrapper((algoliaIndexId, userId) => {
      sendSearchResultsBeacon(objectID, algoliaIndexId, position, searchId, userId, analyticsPageName);
    });
  };

  /**
   * logConversionEvent uses the current search information and provided object
   * id to log events related to a conversion.
   *
   * @param {string} objectID
   */
  const logConversionEvent = objectID => {
    logInsightWrapper((algoliaIndexId, userId) => {
      sendConversionBeacon(objectID, algoliaIndexId, searchId, userId, analyticsPageName);
    });
  };

  return (
    <SearchInsightsContext.Provider value={{ logClickEvent, logConversionEvent, updateSearchId, searchId }}>
      {children}
    </SearchInsightsContext.Provider>
  );
};

export default SearchInsightsProvider;
