import { defineMessages, FormattedMessage } from '@alltrails/shared/react-intl';
import classNames from 'classnames';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import Typography from '@alltrails/shared/denali/components/Typography';
import LinkSection from './LinkSection';
import * as styles from './SeoLinks.module.scss';

const SEO_SECTION_STRINGS = defineMessages({
  TRAILS: {
    defaultMessage: 'Top {count} trails'
  },
  PARKS: {
    defaultMessage: 'Top {count} parks'
  },
  CITIES: {
    defaultMessage: 'Top {count} cities'
  },
  REGIONS: {
    defaultMessage: 'Top {count} regions'
  },
  STATES: {
    defaultMessage: 'Top {count} states'
  }
});

type SectionKey = 'REGIONS' | 'STATES' | 'TRAILS' | 'PARKS' | 'CITIES';

export type SeoLinks = {
  key: SectionKey;
  data: {
    url: string;
    title: string;
  }[];
}[];

export type SeoLinksProps = {
  data: SeoLinks;
  className?: string;
};

// Desktop: https://www.figma.com/file/deyiqSQ1gfAajm667k20DU/SEO---Increase-internal-links?node-id=54%3A33765
// Mobile: https://www.figma.com/file/deyiqSQ1gfAajm667k20DU/SEO---Increase-internal-links?node-id=8%3A12152
const SeoLinks = ({ data, className }: SeoLinksProps): JSX.Element => {
  const { formatMessage } = useFormatMessage(SEO_SECTION_STRINGS);

  return (
    <div className={classNames(styles.container, className)}>
      <Typography component="h2" variant="heading400">
        <FormattedMessage defaultMessage="Adventure anywhere" />
      </Typography>
      <div className={styles.linkSection}>
        {data.map(section => {
          if (section.data.length === 0) return null;
          // The max number of links we display is 15
          const links = section.data.slice(0, 15);
          const heading = formatMessage(section.key, { count: links.length });
          return <LinkSection links={links} heading={heading} key={section.key} />;
        })}
      </div>
    </div>
  );
};

export default SeoLinks;
