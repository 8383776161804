import { useEffect, useState, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import logPrivacySettingsPageViewed from '@alltrails/analytics/events/logPrivacySettingsPageViewed';
import Lock from '@alltrails/shared/icons/Lock';
import type { Context } from 'types/Context';
import Link from '@alltrails/shared/denali/components/Link';
import CustomProvider from 'components/CustomProvider';
import { getPrivacyPolicy, updatePrivacyPolicy } from 'utils/requests/privacy_policy_requests';
import useUser from 'hooks/useUser';
import PrivacyPolicy from 'types/PrivacyPolicy';
import logError from 'utils/logError';
import GeneralPrivacyPolicyModal from '../modals/GeneralPrivacyPolicyModal';
import { logPrivacyPolicyAmplitudeEvents } from '../../../utils/privacy_policy_amplitude_helpers';
import { wrapEventHandler } from '../../../utils/handlers';
import * as styles from './styles/styles.module.scss';

const DEFAULT_PRIVACY_POLICY: { id: string; policy: PrivacyPolicy } = { id: null, policy: null };

type GeneralPrivacyPolicyProps = {
  context: Context;
};

const GeneralPrivacyPolicy = ({ context }: GeneralPrivacyPolicyProps) => {
  const user = useUser();
  const userId = user?.id;
  const [showModal, setShowModal] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(DEFAULT_PRIVACY_POLICY);
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    setPrivacyPolicy(DEFAULT_PRIVACY_POLICY);
  };

  const handlePrivacyPolicyClick = useCallback(() => {
    setShowModal(true);
    logPrivacySettingsPageViewed();
    getPrivacyPolicy(userId)
      .then(privatePolicy => {
        const { id, policy } = privatePolicy;
        setPrivacyPolicy({ id, policy });
      })
      .catch(err => {
        console.error('handlePrivacyPolicyClick, <GeneralPrivacyPolicy />', err);
        closeModal();
      });
  }, [userId]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const isEditingPrivacy = params.get('privacy');
    if (isEditingPrivacy === 'true') {
      handlePrivacyPolicyClick();
    }
  }, [handlePrivacyPolicyClick]);

  const handleSave = (policy: PrivacyPolicy, applyBulk: boolean) => {
    setLoading(true);
    updatePrivacyPolicy(userId, policy, applyBulk)
      .then(() => {
        closeModal();
      })
      .catch(err => {
        logError(err);
      });
    logPrivacyPolicyAmplitudeEvents(privacyPolicy?.policy, policy);
    setLoading(false);
  };

  return (
    <>
      {showModal &&
        createPortal(
          <GeneralPrivacyPolicyModal
            initialPrivacyPolicy={privacyPolicy?.policy}
            closeModal={closeModal}
            loading={loading}
            handleSave={handleSave}
            context={context}
          />,
          document.getElementById('modalPortal')
        )}
      <Link
        className={styles.link}
        onClick={wrapEventHandler(handlePrivacyPolicyClick)}
        size="md"
        icon={{ Component: Lock }}
        testId="privacy-settings"
      >
        <FormattedMessage defaultMessage="Privacy settings" />
      </Link>
    </>
  );
};

export default function Wrapper({ context }: GeneralPrivacyPolicyProps) {
  return (
    <CustomProvider>
      <GeneralPrivacyPolicy context={context} />
    </CustomProvider>
  );
}
