import { useCallback, useEffect, useState } from 'react';
import logError from 'utils/logError';

const oneDay = 60 * 60 * 24 * 1000;
const oneWeek = 60 * 60 * 24 * 7 * 1000;

export enum DeepLinkLocalStorageKeys {
  BANNER = 'banner-dismissal-date',
  INTERSTITIAL = 'interstitial-dismissal-date'
}

const bannerData = {
  localStorageKey: DeepLinkLocalStorageKeys.BANNER,
  dismissalDuration: oneDay
};

const interstitialData = {
  localStorageKey: DeepLinkLocalStorageKeys.INTERSTITIAL,
  dismissalDuration: oneWeek
};

const storeDismissalDate = (localStorageKey: DeepLinkLocalStorageKeys) => {
  try {
    const dismissalDateTime = new Date().getTime(); // store date milliseconds
    window.localStorage?.setItem(localStorageKey, dismissalDateTime.toString());
  } catch (error) {
    logError(error);
  }
};

const didPassDismissalDuration = (localStorageKey: DeepLinkLocalStorageKeys, duration: number) => {
  try {
    const dismissalDateTimeString = window.localStorage?.getItem(localStorageKey);

    // If dismissalDateTimeString is undefined, the banner hasn't been dismissed yet, so the component can be displayed.
    if (!dismissalDateTimeString) {
      return true;
    }

    if (dismissalDateTimeString && Number(dismissalDateTimeString)) {
      const now = new Date().getTime();
      if (now - Number(dismissalDateTimeString) >= duration) {
        return true;
      }
    }

    return false;
  } catch (error) {
    // Default to displaying the component if an error occurs.
    logError(error);
    return true;
  }
};

type DeeplinkHookReturnType = [boolean, (shouldOpenDeeplinkComponent: boolean) => void];

/**
 * Banners & interstitials can only open if a certain duration of time has passed since they were dismissed.
 * The dismissal time is stored in local storage, and read when attempting to open the component again.
 */

const useDeeplinkComponent = (deeplinkComponentType: 'banner' | 'interstitial', shouldOpenInitial: boolean): DeeplinkHookReturnType => {
  const [isOpen, setIsOpen] = useState(false);
  const { dismissalDuration, localStorageKey } = deeplinkComponentType === 'interstitial' ? interstitialData : bannerData;

  const setIsOpenAttempt = useCallback(
    (shouldOpen: boolean) => {
      if (shouldOpen && didPassDismissalDuration(localStorageKey, dismissalDuration)) {
        setIsOpen(true);
      }

      if (!shouldOpen) {
        storeDismissalDate(localStorageKey);
        setIsOpen(false);
      }
    },
    [dismissalDuration, localStorageKey]
  );

  useEffect(() => {
    if (shouldOpenInitial) {
      setIsOpenAttempt(true);
    }
  }, [setIsOpenAttempt, shouldOpenInitial]);

  return [isOpen, setIsOpenAttempt];
};

export default useDeeplinkComponent;
