import { useReducer } from 'react';
import Router from '@alltrails/shared/components/ReactRouter';
import MinimalLayout from 'components/MinimalLayout';
import CurrencyCode from 'types/CurrencyCode';
import Plan from 'types/Plan';
import CustomProvider from 'components/CustomProvider';
import GiftFlowRouting from '../../components/GiftFlow/GiftFlowRouting';
import { GiftFlowContext, giftFlowReducer, initialState } from './giftContext';

type Props = {
  currencyCode: CurrencyCode;
  giftSubscription?: any;
  plan: Plan;
  fullpath: any;
  shopifyProducts?: any;
};

export default function Gift({ currencyCode, giftSubscription, shopifyProducts = [], plan, fullpath }: Props) {
  const [giftFlowState, dispatchGiftFlow] = useReducer(giftFlowReducer, {
    ...initialState,
    purchasedGiftCard: giftSubscription
  });

  return (
    <CustomProvider>
      <MinimalLayout>
        {/* child styles depend upon a wrapping div */}
        <div>
          <GiftFlowContext.Provider value={{ giftFlowState, dispatchGiftFlow }}>
            <Router fullpath={fullpath}>
              <GiftFlowRouting plan={plan} currencyCode={currencyCode} shopifyProducts={shopifyProducts} />
            </Router>
          </GiftFlowContext.Provider>
        </div>
      </MinimalLayout>
    </CustomProvider>
  );
}
