import { Page, PageSource } from 'components/shared/Page/Page';
import type { Context } from 'types/Context';
import { ComponentProps } from 'react';
import * as styles from './styles/styles.module.scss';
import useWindowWidth from '../../hooks/useWindowWidth';
import SectionSwitch from '../../components/SectionSwitch';
// eslint-disable-next-line import/no-cycle
import HomeHeader from '../../components/blocks/HomeHeader';
import SearchInsightsProvider from '../../components/SearchInsightsProvider';
import HomePageDialog from './HomePageDialog/HomePageDialog';

export type Greetings = { daily_start_time: string; daily_end_time: string; custom_title: string[] }[];

type Props = {
  context: Context;
  heroData: {
    greetingIndex: number;
    images: {
      alt: string;
      w2880Jpg: string;
      w2880Avif: string;
      w750Jpg: string;
      w750Avif: string;
    }[];
  };
  json: {
    greetings: Greetings;
    sections: ComponentProps<typeof SectionSwitch>['initialSections'];
  };
  lists: {
    listItems?: unknown[];
    lists?: unknown[];
  };
  locationData: {
    city: {
      names: { [key: string]: string };
    };
    country: string;
    latLng: (string | number)[];
  };
};

const HomePage = ({ context, locationData, heroData, json, lists }: Props) => {
  const windowWidth = useWindowWidth({});

  return (
    <Page pageSource={PageSource.Home} context={context}>
      <SearchInsightsProvider context={context} pageName={PageSource.Home}>
        <main id="main-content" className={styles.container}>
          <HomeHeader windowWidth={windowWidth} greetings={json.greetings} heroData={heroData} />
          <SectionSwitch initialSections={json.sections} context={context} windowWidth={windowWidth} lists={lists} locationData={locationData} />
          <HomePageDialog />
        </main>
      </SearchInsightsProvider>
    </Page>
  );
};

export default HomePage;
