import Typography from '@alltrails/shared/denali/components/Typography';
import { defineMessages, FormattedMessage } from '@alltrails/shared/react-intl';
import AltLoginsForm from 'components/AltLoginsForm';
import SectionDivider from 'components/shared/SectionDivider';
import Button from '@alltrails/shared/denali/components/Button';
import Link from '@alltrails/shared/denali/components/Link';
import Logo from '@alltrails/shared/denali/components/Logo';
import logSignUpScreenContinueButtonTapped from '@alltrails/analytics/events/logSignUpScreenContinueButtonTapped';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import TermsOfService from 'components/TermsOfService';
import { useDispatch } from 'react-redux';
import LoginResponse from 'types/LoginResponse';
import { GoogleLoginResponse } from 'react-google-login';
import SignUpScreenContinueButtonType from '@alltrails/analytics/enums/SignUpScreenContinueButtonType';
import type { User } from 'types/User';
import logWebSignUpModalLogInTapped from '@alltrails/analytics/events/logWebSignUpModalLogInTapped';
import { ReactNode } from 'react';
import useGoogleHandler from 'hooks/useGoogleHandler';
import useFacebookHandler from 'hooks/useFacebookHandler';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import useAppleHandler from 'hooks/useAppleHandler';
import * as styles from './InitialSignUpScreen.module.scss';
import { setUser } from '../../userSlice';
import { setFormAuthenticityToken } from '../../formAuthenticityTokenSlice';

const messages = defineMessages({
  createFreeAccount: {
    defaultMessage: 'Create a free account'
  },
  logIn: {
    defaultMessage: 'Log in'
  }
});

const br = () => <br className={styles.inline} />;

type Props = {
  heading?: ReactNode;
  onSuccess: (user: User) => void;
  redirectUrl?: string;
};

// Desktop: https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=251%3A16532
// Mobile: https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=250%3A16053

export default function InitialSignUpScreen({ heading, onSuccess, redirectUrl }: Props) {
  const appleHandler = useAppleHandler();
  const facebookHandler = useFacebookHandler();
  const googleHandler = useGoogleHandler();
  const {
    formattedDefaultMessages: { createFreeAccount, logIn }
  } = useFormatMessage(messages);

  const dispatch = useDispatch();

  function successCallback({ form_authenticity_token, user }: LoginResponse) {
    if (redirectUrl) {
      window.location.assign(redirectUrl);
    } else {
      dispatch(setFormAuthenticityToken(form_authenticity_token));
      dispatch(setUser(user));
      onSuccess(user);
      global.google?.accounts.id.cancel(); // google can be undefined on mobile web
    }
  }

  async function handleGoogle(response: GoogleLoginResponse) {
    logSignUpScreenContinueButtonTapped({ signup_screen_continue_button_type: SignUpScreenContinueButtonType.Google });
    successCallback(await googleHandler(response, 'signup'));
  }

  async function handleFacebook(response: ReactFacebookLoginInfo) {
    logSignUpScreenContinueButtonTapped({ signup_screen_continue_button_type: SignUpScreenContinueButtonType.Facebook });
    successCallback(await facebookHandler(response, 'login'));
  }

  function handleApple() {
    logSignUpScreenContinueButtonTapped({ signup_screen_continue_button_type: SignUpScreenContinueButtonType.Apple });
    appleHandler({ redirectTo: redirectUrl });
  }

  return (
    <div className={styles.content}>
      <Typography component="div" mb="32">
        <Logo color="dark" size="lg" variant="symbol" />
      </Typography>
      <Typography component="h1" mb="32" variant="heading400">
        {heading || <FormattedMessage defaultMessage="To keep exploring trails,<br></br> log in or sign up for free" values={{ br }} />}
      </Typography>
      <AltLoginsForm googleLoginHandler={handleGoogle} facebookLoginHandler={handleFacebook} appleLoginHandler={handleApple} />
      <SectionDivider classes={styles.divider}>
        <FormattedMessage defaultMessage="or" />
      </SectionDivider>
      <div className={styles.signUpLogInContainer}>
        <Button
          text={createFreeAccount}
          className={styles.create}
          linkInfo={{ href: '/register', useReactRouter: true }}
          onClick={() => {
            /* linkInfo handles router nav  */
            logSignUpScreenContinueButtonTapped({ signup_screen_continue_button_type: SignUpScreenContinueButtonType.Email });
          }}
          testId="initial-sign-up-screen-create-account"
          variant="primary"
        />
      </div>
      <div className={styles.signUpLogInContainer}>
        <Typography className={styles.inline} color="secondary" component="div" variant="text200">
          <FormattedMessage defaultMessage="Already have an account?" />{' '}
        </Typography>
        <Link
          href="/login"
          useReactRouter
          size="md"
          testId="sign-up-log-in-nav"
          onClick={() => {
            logWebSignUpModalLogInTapped();
          }}
        >
          {logIn}
        </Link>
      </div>
      <TermsOfService />
    </div>
  );
}
