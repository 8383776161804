import { func, bool } from 'prop-types';
import { defineMessages, useIntl } from '@alltrails/shared/react-intl';
import { ManagedMembersSection } from '@alltrails/modules/ManagedMembers';
import '@alltrails/modules/ManagedMembers/index.css';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import ModalInterior from '@alltrails/shared/components/modals/ModalInterior';
import PrivacyTrigger from '@alltrails/shared/components/PrivacyTrigger';
import Checkbox from '@alltrails/shared/denali/components/Checkbox';
import PrivacyInformationBlock from '../../PrivacyInformationBlock';
import PrivacyPolicyPropType from '../../../../proptypes/PrivacyPolicyPropType';
import * as styles from './styles/styles.module.scss';
import {
  subjectToTitle,
  COMPLETED_TRAILS,
  ACTIVITIES,
  CUSTOM_MAPS,
  LISTS,
  SOCIAL_NETWORK,
  FOLLOW_REQUESTS_REQUIRED,
  visibilityToTranslatedOption
} from '../../../../utils/privacy_policy_helpers';

const INITIAL_PRIVACY_POLICY_MODAL_STRINGS = defineMessages({
  SAVE: {
    defaultMessage: 'Save'
  },
  COMMUNITY: {
    defaultMessage: 'Community'
  },
  CONTENT: {
    defaultMessage: 'Content'
  },
  YOUR_NAME_YOUR_PROFILE_HOMETOWN: {
    defaultMessage: 'Your name, profile picture, and hometown may be seen by anyone who views your profile.'
  },
  YOUR_CONTENT_DISPLAYED_WHEN_SOMEONE_ELSE: {
    defaultMessage:
      'Your content can display in 3 places: on your profile, in community feeds, and on trail pages. Choose your default level of privacy for each type of newly-created content. Changes made now will not affect the privacy levels of existing content.'
  },
  REVIEWS_AND_UPLOADED_SHARED: {
    defaultMessage: 'Trail reviews are shared publicly to help others know what to expect.'
  },
  PRIVACY_SETTINGS: { defaultMessage: 'Privacy settings' },
  APPLY_TO_PAST_CONTENT: { defaultMessage: 'Apply to past content' }
});

const InitialPrivacyPolicyModal = ({
  isMounted,
  goBack,
  handleCloseModal,
  handleOnTransitionEnd,
  onPrivacyClick,
  onManagedMembersListClick,
  privacyPolicy,
  loading,
  handleSave,
  applyBulk,
  toggleApplyBulk
}) => {
  const {
    formattedDefaultMessages: {
      SAVE,
      CONTENT,
      COMMUNITY,
      YOUR_CONTENT_DISPLAYED_WHEN_SOMEONE_ELSE,
      YOUR_NAME_YOUR_PROFILE_HOMETOWN,
      REVIEWS_AND_UPLOADED_SHARED,
      PRIVACY_SETTINGS,
      APPLY_TO_PAST_CONTENT
    }
  } = useFormatMessage(INITIAL_PRIVACY_POLICY_MODAL_STRINGS);
  const intl = useIntl();

  return (
    <ModalInterior
      title={PRIVACY_SETTINGS}
      isMounted={isMounted}
      closeModal={handleCloseModal}
      goBack={goBack}
      onTransitionEnd={handleOnTransitionEnd}
      baseCtas={{
        primaryCta: {
          onClick: () => handleSave(privacyPolicy, applyBulk),
          text: SAVE,
          testId: SAVE,
          loading,
          name: SAVE
        }
      }}
    >
      <PrivacyInformationBlock title={COMMUNITY} descriptions={[YOUR_NAME_YOUR_PROFILE_HOMETOWN]} />
      <PrivacyTrigger
        className={styles.privacyTrigger}
        onClick={() => onPrivacyClick(FOLLOW_REQUESTS_REQUIRED, privacyPolicy.socialNetwork.followRequestsRequired.toString())}
        label={subjectToTitle(FOLLOW_REQUESTS_REQUIRED, intl)}
        currentOption={visibilityToTranslatedOption(privacyPolicy.socialNetwork.followRequestsRequired.toString(), intl)}
        testId="follow-requests-privacy-trigger"
      />
      <PrivacyTrigger
        className={styles.privacyTrigger}
        onClick={() => onPrivacyClick(SOCIAL_NETWORK, privacyPolicy.profile.socialNetwork.visibility)}
        label={subjectToTitle(SOCIAL_NETWORK, intl)}
        currentOption={visibilityToTranslatedOption(privacyPolicy.profile.socialNetwork.visibility, intl)}
        testId="social-network-privacy-trigger"
      />
      <PrivacyInformationBlock title={CONTENT} descriptions={[YOUR_CONTENT_DISPLAYED_WHEN_SOMEONE_ELSE, REVIEWS_AND_UPLOADED_SHARED]} />
      <PrivacyTrigger
        className={styles.privacyTrigger}
        onClick={() => onPrivacyClick(COMPLETED_TRAILS, privacyPolicy.content.completedTrails.visibility)}
        label={subjectToTitle(COMPLETED_TRAILS, intl)}
        currentOption={visibilityToTranslatedOption(privacyPolicy.content.completedTrails.visibility, intl)}
        testId="completed-trails-privacy-trigger"
      />
      <PrivacyTrigger
        className={styles.privacyTrigger}
        onClick={() => onPrivacyClick(ACTIVITIES, privacyPolicy.content.activities.visibility)}
        label={subjectToTitle(ACTIVITIES, intl)}
        currentOption={visibilityToTranslatedOption(privacyPolicy.content.activities.visibility, intl)}
        testId="activities-privacy-trigger"
      />
      <PrivacyTrigger
        className={styles.privacyTrigger}
        onClick={() => onPrivacyClick(CUSTOM_MAPS, privacyPolicy.content.customMaps.visibility)}
        label={subjectToTitle(CUSTOM_MAPS, intl)}
        currentOption={visibilityToTranslatedOption(privacyPolicy.content.customMaps.visibility, intl)}
        testId="custom-maps-privacy-trigger"
      />
      <PrivacyTrigger
        className={styles.privacyTrigger}
        onClick={() => onPrivacyClick(LISTS, privacyPolicy.content.lists.visibility)}
        label={subjectToTitle(LISTS, intl)}
        currentOption={visibilityToTranslatedOption(privacyPolicy.content.lists.visibility, intl)}
        testId="lists-privacy-trigger"
      />
      <Checkbox
        id="applyBulk"
        testId="checkbox-applyBulk"
        size="md"
        selected={applyBulk}
        onChange={() => toggleApplyBulk(!applyBulk)}
        className={styles.checkbox}
        labelElement={<div className={styles.checkboxLabel}>{APPLY_TO_PAST_CONTENT}</div>}
      />
      <ManagedMembersSection className={styles.managedMembers} onListClick={onManagedMembersListClick} />
    </ModalInterior>
  );
};

InitialPrivacyPolicyModal.propTypes = {
  isMounted: bool.isRequired,
  goBack: func,
  handleCloseModal: func.isRequired,
  handleOnTransitionEnd: func.isRequired,
  onPrivacyClick: func.isRequired,
  onManagedMembersListClick: func.isRequired,
  privacyPolicy: PrivacyPolicyPropType.isRequired,
  loading: bool,
  handleSave: func.isRequired,
  applyBulk: bool.isRequired,
  toggleApplyBulk: func.isRequired
};

InitialPrivacyPolicyModal.defaultProps = {
  goBack: null,
  loading: false
};

export default InitialPrivacyPolicyModal;
