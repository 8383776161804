import SectionDivider from 'components/shared/SectionDivider';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import { ComponentProps, useState } from 'react';
import Coupon from 'types/Coupon';
import CurrencyCode from 'types/CurrencyCode';
import Plan from 'types/Plan';
import ProResponse from 'types/ProResponse';
import CountryCode from 'types/CountryCode';
import TokenizedCreditCardForm from 'components/forms/TokenizedCreditCardForm';
import { TokenValidationErrors } from 'components/forms/TokenizedCreditCardForm/hooks/useTokenizedFormValidation';
import { CardElementState } from '@recurly/react-recurly';
import { CARD_VALIDATION_NAMES } from 'components/forms/TokenizedCreditCardForm/types';
import ApplePayButton from './ApplePayButton';
import ErrorText from './ErrorText';
import * as styles from './PaymentForm.module.scss';
import PayPalButton from './PayPalButton';
import GooglePayButton from './GooglePayButton';
import LoadingOverlay from './LoadingOverlay';

type Props = {
  billingCountry: CountryCode;
  coupon: Coupon;
  currencyCode: CurrencyCode;
  errors: ProResponse['errors'];
  onApplePayToken: ComponentProps<typeof ApplePayButton>['onApplePayToken'];
  onGooglePayToken: ComponentProps<typeof GooglePayButton>['onGooglePayToken'];
  onPayPalToken: ComponentProps<typeof PayPalButton>['onPayPalToken'];
  plan: Plan;
  setErrors: (errors: ProResponse['errors']) => void;
  onBillingCountryChange: (countryCode: CountryCode, arg1: any) => void;
  setClientValidationErrors: (
    inputName: CARD_VALIDATION_NAMES,
    value: string | { number: CardElementState; expiry: CardElementState; cvv: CardElementState }
  ) => void;
  validationErrors: TokenValidationErrors;
};

export default function PaymentForm({
  billingCountry,
  coupon,
  currencyCode,
  onApplePayToken,
  onGooglePayToken,
  onPayPalToken,
  plan,
  errors,
  setErrors,
  onBillingCountryChange,
  validationErrors,
  setClientValidationErrors
}: Props) {
  // this submitting state is used for non-creditcard services
  const [submitting, setSubmitting] = useState<boolean>(false);

  return (
    <>
      <div className={styles.grid}>
        <ApplePayButton
          coupon={coupon}
          currencyCode={currencyCode}
          onApplePayToken={onApplePayToken}
          plan={plan}
          setErrors={setErrors}
          setSubmitting={setSubmitting}
        />
        <GooglePayButton
          coupon={coupon}
          currencyCode={currencyCode}
          onGooglePayToken={onGooglePayToken}
          plan={plan}
          setErrors={setErrors}
          setSubmitting={setSubmitting}
        />
        <PayPalButton
          coupon={coupon}
          onPayPalToken={onPayPalToken}
          plan={plan}
          setErrors={setErrors}
          setSubmitting={setSubmitting}
          submitting={submitting}
        />
      </div>
      <SectionDivider classes={styles.divider}>
        <FormattedMessage defaultMessage="Or enter payment details" />
      </SectionDivider>
      <TokenizedCreditCardForm
        validationErrors={validationErrors}
        handleInputChange={(
          input: CARD_VALIDATION_NAMES,
          value: string | { number: CardElementState; expiry: CardElementState; cvv: CardElementState }
        ) => {
          if (errors) {
            setErrors(null);
          }
          setClientValidationErrors(input, value);
        }}
        billingCountry={{
          onChange: onBillingCountryChange,
          value: billingCountry
        }}
        isCouponInputExpanded={false}
        isCouponInputShown={false}
      />
      {errors && <ErrorText errors={errors} />}
      {submitting && <LoadingOverlay />}
    </>
  );
}
