import { useEffect, useState } from 'react';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import { UserConnectionListKey } from '@alltrails/shared/types/belay';
import type { Context } from 'types/Context';
import logFollowersViewed from '@alltrails/analytics/events/logFollowersViewed';
import logFollowingViewed from '@alltrails/analytics/events/logFollowingViewed';
import { buildStore } from '@alltrails/modules/UserConnections/redux';
import useUserConnections from '@alltrails/modules/UserConnections/useUserConnections';
import CustomProvider from 'components/CustomProvider';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import logError from 'utils/logError';
import BelayUserConnections from './BelayUserConnections';

type BaseAvatarStackContainerProps = {
  firstParty: boolean;
  userId: number;
};

const BaseAvatarStackContainer = ({ firstParty, userId }: BaseAvatarStackContainerProps): JSX.Element => {
  const languageRegionCode = useLanguageRegionCode();
  const [showFollowersModal, setShowFollowersModal] = useState(false);
  const [showFollowingModal, setShowFollowingModal] = useState(false);
  const [showMutualModal, setShowMutualModal] = useState(false);

  const {
    fetchConnections: fetchFollowers,
    totalItemCount: followersCount,
    userConnections: followers
  } = useUserConnections(UserConnectionListKey.Followers, languageRegionCode, userId);
  const {
    fetchConnections: fetchFollowing,
    totalItemCount: followingCount,
    userConnections: following
  } = useUserConnections(UserConnectionListKey.Following, languageRegionCode, userId);
  const {
    fetchConnections: fetchMutual,
    totalItemCount: mutualCount,
    userConnections: mutuals
  } = useUserConnections(UserConnectionListKey.Mutual, languageRegionCode, userId);

  useEffect(() => {
    try {
      fetchFollowers();
      fetchFollowing();
      if (!firstParty) {
        fetchMutual();
      }
    } catch (error) {
      logError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const amplitudeEventObject = { owned_by_user: firstParty, owner_id: `${userId}` };

  return (
    <>
      {followersCount > 0 && (
        <BelayUserConnections
          title={<FormattedMessage defaultMessage="Followers" />}
          connections={followers}
          count={followersCount}
          onOverflowClick={() => {
            logFollowersViewed(amplitudeEventObject);
            setShowFollowersModal(true);
          }}
          listKey={UserConnectionListKey.Followers}
          showModal={showFollowersModal}
          setShowModal={setShowFollowersModal}
          userId={userId}
        />
      )}
      {followingCount > 0 && (
        <BelayUserConnections
          title={<FormattedMessage defaultMessage="Following" />}
          connections={following}
          count={followingCount}
          listKey={UserConnectionListKey.Following}
          onOverflowClick={() => {
            logFollowingViewed(amplitudeEventObject);
            setShowFollowingModal(true);
          }}
          showModal={showFollowingModal}
          setShowModal={setShowFollowingModal}
          userId={userId}
        />
      )}
      {mutualCount > 0 && (
        <BelayUserConnections
          title={<FormattedMessage defaultMessage="Mutual" />}
          connections={mutuals}
          count={mutualCount}
          listKey={UserConnectionListKey.Mutual}
          onOverflowClick={() => setShowMutualModal(true)}
          showModal={showMutualModal}
          setShowModal={setShowMutualModal}
          userId={userId}
        />
      )}
    </>
  );
};

type AvatarStackContainerProps = {
  context: Context;
  userId: number;
};

const AvatarStackContainer = ({ context, userId }: AvatarStackContainerProps) => (
  <CustomProvider store={buildStore({ context })}>
    <BaseAvatarStackContainer firstParty={context.currentUser?.id === userId} userId={userId} />
  </CustomProvider>
);

export default AvatarStackContainer;
