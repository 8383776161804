import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: null, // populated from preloadState
  reducers: {
    setUser(state, action) {
      // This is an anti-pattern. Do not lean on this as a positive example.
      // This is a convenient shortcut to update global user state after client-side login.
      __AT_DATA__.store.user = action.payload;

      return action.payload;
    }
  }
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
