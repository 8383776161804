import { AlertType } from '@alltrails/shared/types/alert';
import { FilterConfigToggleEntry } from '@alltrails/shared/types/filters';
import { IntlShape, defineMessages } from '@alltrails/shared/react-intl';
import getActivityType from '@alltrails/shared/utils/getActivityType';
import { PageStrings } from '@alltrails/shared/utils/constants/pageStringHelpers';
import type { Context } from 'types/Context';
import { FilterConfig, FilterConfigRange, FilterConfigSlider, FiltersKey, LinkedStatus } from 'types/Search';
import { Sort } from 'types/Search/Sort';
import { Access, Activities, CompletedStatus, Features } from 'types/Trails';
import { convertIndexToValue, convertValueToIndex, formattedStepForIndex } from 'utils/slider';
import { FILTER_ROUTE_TYPES_FILTER, FILTER_SORT_TYPES_STRINGS, FILTER_USAGES_STRING } from './filters.strings';

export type FilterConfigConstructorArgs = {
  context: Context;
  initialUserSlug?: string;
  page?: string;
  intl?: IntlShape;
};

const accessStrings = defineMessages({
  ada: { defaultMessage: 'Wheelchair-friendly' },
  'wheelchair-friendly': { defaultMessage: 'Wheelchair-friendly' },
  dogs: { defaultMessage: 'Dog-friendly' },
  'dogs-leash': { defaultMessage: 'Dogs on leash' },
  kids: { defaultMessage: 'Kid-friendly' },
  'kids-friendly': { defaultMessage: 'Kid-friendly' },
  'no-dogs': { defaultMessage: 'Dogs not allowed' },
  'dogs-no': { defaultMessage: 'Dogs not allowed' },
  paved: { defaultMessage: 'Paved' },
  'partially-paved': { defaultMessage: 'Partially paved' },
  strollers: { defaultMessage: 'Stroller-friendly' },
  'stroller-friendly': { defaultMessage: 'Stroller-friendly' }
});

const completionStrings = defineMessages({
  not_completed: { defaultMessage: 'Not completed' },
  completed: { defaultMessage: 'Completed' },
  verified_completed: { defaultMessage: 'Verified completed' }
});

const featureTypesStrings = defineMessages({
  beach: { defaultMessage: 'Beaches' },
  cave: { defaultMessage: 'Caves' },
  'city-walk': { defaultMessage: 'City walks' },
  event: { defaultMessage: 'Events' },
  forest: { defaultMessage: 'Forests' },
  'historic-site': { defaultMessage: 'Historic sites' },
  'hot-springs': { defaultMessage: 'Hot springs' },
  lake: { defaultMessage: 'Lakes' },
  'pub-crawl': { defaultMessage: 'Pub walks' },
  'rails-trails': { defaultMessage: 'Rail trails' },
  river: { defaultMessage: 'Rivers' },
  views: { defaultMessage: 'Views' },
  waterfall: { defaultMessage: 'Waterfalls' },
  'wild-flowers': { defaultMessage: 'Wildflowers' },
  wildlife: { defaultMessage: 'Wildlife' }
});

type FilterConfigConstructor = ({ context, initialUserSlug, page, intl }: FilterConfigConstructorArgs) => FilterConfig;

function newAccessFilterConfig({ intl }: FilterConfigConstructorArgs) {
  const access = {} as Record<Access, FilterConfigToggleEntry>;

  [Access.DOGS, Access.KIDS, Access.ADA, Access.STROLLERS, Access.PAVED, Access.PARTIALLY_PAVED].forEach(value => {
    access[value] = {
      value: value === Access.DOGS ? [Access.DOGS, Access.DOGS_LEASH] : value,
      name: intl.formatMessage(accessStrings[value]),
      selected: false
    };
  });

  return access;
}

function newActivitiesFilterConfig({ intl }: FilterConfigConstructorArgs) {
  const activities = {} as Record<Activities, FilterConfigToggleEntry>;
  // This order comes from DISCO-8 and https://docs.google.com/spreadsheets/d/1imyOWwQA1_2wcnyZnD4AwmPjOUmjxa40gllCr_1XtQs/edit#gid=15327538
  [
    Activities.HIKING,
    Activities.MOUNTAIN_BIKING,
    Activities.TRAIL_RUNNING,
    Activities.ROAD_BIKING,
    Activities.BACKPACKING,
    Activities.WALKING,
    Activities.OFF_ROAD_DRIVING,
    Activities.SCENIC_DRIVING,
    Activities.BIKE_TOURING,
    Activities.SNOWSHOEING,
    Activities.CROSS_COUNTRY_SKIING,
    Activities.SKIING,
    Activities.PADDLE_SPORTS,
    Activities.CAMPING,
    Activities.FISHING,
    Activities.BIRDING,
    Activities.HORSEBACK_RIDING,
    Activities.ROCK_CLIMBING,
    Activities.VIA_FERRATA
  ].forEach(value => {
    activities[value] = {
      value,
      name: getActivityType(intl, value),
      selected: false
    };
  });

  return activities;
}

function newClosedStatusFilterConfig({ intl }: FilterConfigConstructorArgs) {
  return {
    open: {
      value: false,
      name: intl.formatMessage({ defaultMessage: 'Open' }),
      selected: false
    },
    closed: {
      value: true,
      name: intl.formatMessage({ defaultMessage: 'Closed' }),
      selected: false
    }
  };
}

function newCompletedStatusFilterConfig({ intl }: FilterConfigConstructorArgs) {
  const completed = {} as Record<CompletedStatus, FilterConfigToggleEntry>;

  ['not_completed', 'completed', 'verified_completed'].forEach((value: CompletedStatus) => {
    completed[value] = {
      value,
      name: intl.formatMessage(completionStrings[value]),
      selected: false
    };
  });

  return completed;
}

function newDifficultyFilterConfig({ intl }: FilterConfigConstructorArgs) {
  return {
    easy: {
      value: [1, 2],
      name: intl.formatMessage({ defaultMessage: 'Easy' }),
      selected: false
    },
    moderate: {
      value: [3, 4],
      name: intl.formatMessage({ id: 'difficulty.Moderate', defaultMessage: 'Moderate' }),
      selected: false
    },
    hard: {
      value: [5, 6, 7, 8],
      name: intl.formatMessage({ defaultMessage: 'Hard' }),
      selected: false
    }
  };
}

function newElevationGainFilterConfig({ context }: FilterConfigConstructorArgs) {
  if (context.displayMetric) {
    return {
      stepIncreaseAt: [0, 10],
      stepIncreaseBy: [30, 100],
      upperBound: 22,
      displayFunction: 'm',
      multiplier: 1,
      value: [0, -1],
      displayValue: [0, 22],
      formattedRange: ['0', '1,500+']
    };
  }

  return {
    stepIncreaseAt: [0, 10],
    stepIncreaseBy: [100, 250],
    upperBound: 26,
    displayFunction: 'ft',
    multiplier: 0.3048,
    value: [0, -1],
    displayValue: [0, 26],
    formattedRange: ['0', '5,000+']
  };
}

function newHighestPointFilterConfig({ context }: FilterConfigConstructorArgs) {
  if (context.displayMetric) {
    return {
      stepIncreaseAt: [0],
      stepIncreaseBy: [300],
      upperBound: 15,
      displayFunction: 'm',
      multiplier: 1,
      value: [0, -1],
      displayValue: [0, 15],
      formattedRange: ['0', '4,500+']
    };
  }

  return {
    stepIncreaseAt: [0],
    stepIncreaseBy: [500],
    upperBound: 30,
    displayFunction: 'ft',
    multiplier: 0.3048,
    value: [0, -1],
    displayValue: [0, 30],
    formattedRange: ['0', '15,000+']
  };
}

function newEmptyArrayFilterConfig(): [] {
  return [];
}

function newEmptyObjectFilterConfig() {
  return {};
}

function newFalseFilterConfig() {
  return false;
}

function newInitialSlugFilterConfig({ initialUserSlug }: FilterConfigConstructorArgs) {
  return initialUserSlug;
}

function newFeaturesFilterConfig({ intl }: FilterConfigConstructorArgs) {
  const features = {} as Record<Features, FilterConfigToggleEntry>;

  [
    Features.WATERFALL,
    Features.VIEWS,
    Features.LAKE,
    Features.RIVER,
    Features.FOREST,
    Features.HOT_SPRINGS,
    Features.BEACH,
    Features.WILD_FLOWERS,
    Features.WILDLIFE,
    Features.CAVE,
    Features.HISTORIC_SITE,
    Features.RAILS_TRAILS,
    Features.CITY_WALK,
    Features.PUB_CRAWL,
    Features.EVENT
  ].forEach(value => {
    features[value] = {
      value,
      name: intl.formatMessage(featureTypesStrings[value as keyof typeof featureTypesStrings]),
      selected: false
    };
  });

  return features;
}

function newLengthsFilterConfig({ context }: FilterConfigConstructorArgs) {
  if (context.displayMetric) {
    return {
      // 0 - 40 step by 1, 40 - 80 step by 8
      stepIncreaseAt: [0, 40],
      stepIncreaseBy: [1, 8],
      upperBound: 45,
      displayFunction: 'km',
      multiplier: 1000.0,
      value: [0, -1],
      displayValue: [0, 45],
      formattedRange: ['0', '80+']
    };
  }

  return {
    // 0 - 25 step by 1, 25 - 50 step by 5
    stepIncreaseAt: [0, 25],
    stepIncreaseBy: [1, 5],
    upperBound: 30,
    displayFunction: 'mi',
    multiplier: 1609.344,
    value: [0, -1],
    displayValue: [0, 30],
    formattedRange: ['0', '50+']
  };
}

function newLinkedStatusFilterConfig({ intl }: FilterConfigConstructorArgs) {
  return {
    unlinked: {
      value: 'unlinked',
      name: intl.formatMessage({ defaultMessage: 'Not linked to trail' }),
      selected: false
    },
    linked: {
      value: 'linked',
      name: intl.formatMessage({ defaultMessage: 'Linked to trail' }),
      selected: false
    }
  } as Record<LinkedStatus, FilterConfigToggleEntry>;
}

function newNullFilterConfig(): null {
  return null;
}

function newDistanceAwayFilterConfig({ context }: FilterConfigConstructorArgs) {
  if (context.displayMetric) {
    return {
      stepIncreaseAt: [0, 2],
      stepIncreaseBy: [10, 20],
      upperBound: 7,
      displayFunction: 'km',
      multiplier: 1000.0,
      value: -1,
      displayValue: 7
    };
  }

  return {
    stepIncreaseAt: [0, 2, 3],
    stepIncreaseBy: [5, 10, 20],
    upperBound: 6,
    displayFunction: 'mi',
    multiplier: 1609.344,
    value: -1,
    displayValue: 6
  };
}

function newRouteTypeFilterConfig({ intl }: FilterConfigConstructorArgs) {
  return {
    L: {
      value: 'L',
      name: intl.formatMessage(FILTER_ROUTE_TYPES_FILTER.LOOP),
      selected: false
    },
    O: {
      value: 'O',
      name: intl.formatMessage(FILTER_ROUTE_TYPES_FILTER.OUT_AND_BACK),
      selected: false
    },
    P: {
      value: 'P',
      name: intl.formatMessage(FILTER_ROUTE_TYPES_FILTER.POINT_TO_POINT),
      selected: false
    }
  };
}

function newSortFilterConfig({ page, intl }: FilterConfigConstructorArgs) {
  if ([PageStrings.EXPLORE_ALL_PAGE, PageStrings.EXPLORE_COMMUNITY_CONTENT_PAGE, PageStrings.EXPLORE_TRAIL_MAP_PAGE].includes(page)) {
    return {
      [Sort.BEST_MATCH]: {
        name: intl.formatMessage(FILTER_SORT_TYPES_STRINGS.BEST_MATCH),
        value: 0,
        selected: true
      },
      [Sort.MOST_POPULAR]: {
        name: intl.formatMessage(FILTER_SORT_TYPES_STRINGS.MOST_POPULAR),
        value: 0,
        selected: false
      },
      [Sort.NEWLY_ADDED]: {
        name: intl.formatMessage(FILTER_SORT_TYPES_STRINGS.NEWLY_ADDED),
        value: 0,
        selected: false
      },
      [Sort.CLOSEST]: {
        name: intl.formatMessage(FILTER_SORT_TYPES_STRINGS.CLOSEST),
        value: 0,
        selected: false
      }
    } as Record<Sort, FilterConfigToggleEntry>;
  }

  if ([PageStrings.EXPLORE_COMPLETED_PAGE].includes(page)) {
    return {
      [Sort.LIST_ORDER]: {
        name: intl.formatMessage(FILTER_SORT_TYPES_STRINGS.LIST_ORDER),
        value: -1,
        selected: true
      }
    } as Record<Sort, FilterConfigToggleEntry>;
  }

  if ([PageStrings.EXPLORE_CUSTOM_PAGE, PageStrings.EXPLORE_FAVORITE_PAGE].includes(page)) {
    return {
      [Sort.RECENTLY_ADDED]: {
        name: intl.formatMessage(FILTER_SORT_TYPES_STRINGS.RECENTLY_ADDED),
        value: 1,
        selected: true
      },
      [Sort.NAME]: {
        name: intl.formatMessage(FILTER_SORT_TYPES_STRINGS.NAME),
        value: 1,
        selected: false
      }
    } as Record<Sort, FilterConfigToggleEntry>;
  }

  if ([PageStrings.SEO_PAGE].includes(page)) {
    return {
      [Sort.MOST_POPULAR]: {
        name: intl.formatMessage(FILTER_SORT_TYPES_STRINGS.MOST_POPULAR),
        value: 0,
        selected: true
      }
    } as Record<Sort, FilterConfigToggleEntry>;
  }

  return {
    [Sort.DATE]: {
      name: intl.formatMessage(FILTER_SORT_TYPES_STRINGS.DATE),
      value: 1,
      selected: true
    },
    [Sort.NAME]: {
      name: intl.formatMessage(FILTER_SORT_TYPES_STRINGS.NAME),
      value: 1,
      selected: false
    }
  } as Record<Sort, FilterConfigToggleEntry>;
}

function newVisitorUsageFilterConfig({ intl }: FilterConfigConstructorArgs) {
  return {
    '1': {
      value: '1',
      name: intl.formatMessage(FILTER_USAGES_STRING.LIGHT),
      selected: false
    },
    '2': {
      value: '2',
      name: intl.formatMessage(FILTER_USAGES_STRING.MODERATE),
      selected: false
    },
    '3': {
      value: ['3', '4'],
      name: intl.formatMessage(FILTER_USAGES_STRING.HEAVY),
      selected: false
    }
  };
}

function newAlertTypeFilterConfig({ intl }: FilterConfigConstructorArgs) {
  return {
    [AlertType.WARNING]: {
      value: AlertType.WARNING,
      name: intl.formatMessage({ defaultMessage: 'Caution' }),
      selected: false
    },
    [AlertType.INFORMATION]: {
      value: AlertType.INFORMATION,
      name: intl.formatMessage({ defaultMessage: 'Informational' }),
      selected: false
    },
    [AlertType.CLOSURE]: {
      value: AlertType.CLOSURE,
      name: intl.formatMessage({ defaultMessage: 'Closure' }),
      selected: false
    },
    [AlertType.DANGER]: {
      value: AlertType.DANGER,
      name: intl.formatMessage({ defaultMessage: 'Danger' }),
      selected: false
    }
  };
}

const filterKeyToConstructor = {
  access: newAccessFilterConfig,
  alert_type: newAlertTypeFilterConfig,
  areas: newEmptyObjectFilterConfig,
  activities: newActivitiesFilterConfig,
  boundingBox: newNullFilterConfig,
  cities: newEmptyObjectFilterConfig,
  closed_status: newClosedStatusFilterConfig,
  completed: newCompletedStatusFilterConfig,
  countries: newEmptyObjectFilterConfig,
  difficulty: newDifficultyFilterConfig,
  distance_away: newDistanceAwayFilterConfig,
  elevation_gain: newElevationGainFilterConfig,
  features: newFeaturesFilterConfig,
  has_profile_photo: newNullFilterConfig,
  highest_point: newHighestPointFilterConfig,
  initialUserSlug: newInitialSlugFilterConfig,
  initLocationObject: newNullFilterConfig,
  isMinimumViableContent: newNullFilterConfig,
  lengths: newLengthsFilterConfig,
  linked: newLinkedStatusFilterConfig,
  locationObject: newNullFilterConfig,
  mapIds: newEmptyArrayFilterConfig,
  minRating: newNullFilterConfig,
  mobileMap: newFalseFilterConfig,
  poiIds: newEmptyObjectFilterConfig,
  queryTerm: newNullFilterConfig,
  route_type: newRouteTypeFilterConfig,
  sort: newSortFilterConfig,
  states: newEmptyObjectFilterConfig,
  trailIds: newEmptyArrayFilterConfig,
  trackIds: newEmptyArrayFilterConfig,
  alertIds: newEmptyArrayFilterConfig,
  visitor_usage: newVisitorUsageFilterConfig,
  zoomLevel: newNullFilterConfig
} as Record<FiltersKey, FilterConfigConstructor>;

function newFilterConfigForKey(key: FiltersKey, args: FilterConfigConstructorArgs): FilterConfig {
  const configConstructor = filterKeyToConstructor[key];
  if (!configConstructor) {
    console.warn('no filter config constructor for:', key);
    return null;
  }

  return configConstructor(args);
}

function applyRangeUpdates(filter: FilterConfigRange, values: [number, number]) {
  const { multiplier, upperBound, stepIncreaseAt, stepIncreaseBy } = filter;

  let parsedData = [...values];
  const maxParsedValue = parsedData[1];

  const maxPossibleValue = convertIndexToValue({
    index: filter.upperBound,
    stepIncreaseAt: filter.stepIncreaseAt,
    stepIncreaseBy: filter.stepIncreaseBy,
    multiplier: filter.multiplier
  });

  if (maxParsedValue > maxPossibleValue) {
    parsedData = [0, maxPossibleValue];
  }

  const updates = {
    displayRange: [convertValueToIndex({ value: parsedData[0], multiplier, stepIncreaseAt, stepIncreaseBy })],
    convertedRange: [parsedData[0]],
    formattedRange: []
  } as {
    displayRange: number[];
    convertedRange: number[];
    formattedRange: string[];
  };

  updates.formattedRange[0] = formattedStepForIndex({ index: updates.displayRange[0], upperBound, stepIncreaseAt, stepIncreaseBy });

  if (parsedData[1] === -1) {
    updates.displayRange.push(filter.upperBound);
    updates.convertedRange.push(-1);
    updates.formattedRange.push(formattedStepForIndex({ index: filter.upperBound, upperBound, stepIncreaseAt, stepIncreaseBy }));
  } else {
    updates.displayRange.push(convertValueToIndex({ value: parsedData[1], multiplier, stepIncreaseAt, stepIncreaseBy }));
    updates.convertedRange.push(parsedData[1]);
    updates.formattedRange.push(formattedStepForIndex({ index: updates.displayRange[1], upperBound, stepIncreaseAt, stepIncreaseBy }));
  }

  // Compare the current filter values against the updates. If they are identical
  // then no updates need to be applied. This is a tiny optimization.
  if (JSON.stringify(filter.value) === JSON.stringify(updates.convertedRange)) {
    return;
  }

  filter.value = updates.convertedRange;
  filter.displayValue = updates.displayRange;
  if (updates.formattedRange) {
    filter.formattedRange = updates.formattedRange;
  }
}

function applySliderUpdates(filter: FilterConfigSlider, value: number) {
  const { multiplier, upperBound, stepIncreaseAt, stepIncreaseBy } = filter;

  const maxPossibleValue = convertIndexToValue({
    index: filter.upperBound,
    stepIncreaseAt: filter.stepIncreaseAt,
    stepIncreaseBy: filter.stepIncreaseBy,
    multiplier: filter.multiplier
  });

  if (value > maxPossibleValue) {
    value = maxPossibleValue;
  }

  const updates = {
    displayRange: [convertValueToIndex({ value, multiplier, stepIncreaseAt, stepIncreaseBy })],
    convertedRange: [value],
    formattedRange: []
  } as {
    displayRange: number[];
    convertedRange: number[];
    formattedRange: string[];
  };

  updates.formattedRange[0] = formattedStepForIndex({ index: updates.displayRange[0], upperBound, stepIncreaseAt, stepIncreaseBy });

  if (value === -1) {
    updates.displayRange = [filter.upperBound];
    updates.convertedRange = [-1];
    updates.formattedRange.push(formattedStepForIndex({ index: filter.upperBound, upperBound, stepIncreaseAt, stepIncreaseBy }));
  } else {
    updates.displayRange.push(convertValueToIndex({ value, multiplier, stepIncreaseAt, stepIncreaseBy }));
    updates.convertedRange.push(value);
    updates.formattedRange.push(
      formattedStepForIndex({
        index: updates.displayRange[1],
        upperBound,
        stepIncreaseAt,
        stepIncreaseBy
      })
    );
  }

  // Compare the current filter value against the update. If they are identical
  // then no updates need to be applied. This is a tiny optimization.
  if (filter.value === updates.convertedRange[0]) {
    return;
  }

  [filter.value] = updates.convertedRange;
  [filter.displayValue] = updates.displayRange;
  if (updates.formattedRange) {
    [filter.formattedRange] = updates.formattedRange;
  }
}

export { applyRangeUpdates, applySliderUpdates, filterKeyToConstructor, newFilterConfigForKey, newSortFilterConfig };
