import Router, { Route, Switch } from '@alltrails/shared/components/ReactRouter';
import ApplicationLayout from 'components/ApplicationLayout/ApplicationLayout';
import CustomProvider from 'components/CustomProvider';
import Coupon from 'types/Coupon';
import CurrencyCode from 'types/CurrencyCode';
import Plan from 'types/Plan';
import { Context } from 'types/Context';
import CheckoutRoute from './CheckoutRoute';
import LoginRoute from './LoginRoute';
import RegisterRoute from './RegisterRoute';

type ProRouterProps = {
  isPromoEligible: boolean;
  context: Context;
  coupon?: Coupon;
  currencyCode: CurrencyCode;
  userId?: number;
  whenExpired: string;
  trialPlan: Plan;
  nonTrialPlan: Plan;
};

function ProRouter({ context, isPromoEligible, currencyCode, coupon, userId, whenExpired, trialPlan, nonTrialPlan }: ProRouterProps) {
  const params = new URLSearchParams(__AT_DATA__.search);

  const plan = !params.has('multiyear_conversion') && !coupon && isPromoEligible ? trialPlan : nonTrialPlan;

  return (
    <Router fullpath={global.__AT_DATA__.pathname}>
      <Switch>
        <Route path="/:locale?/signup">
          <ApplicationLayout context={context}>
            <RegisterRoute coupon={coupon} />
          </ApplicationLayout>
        </Route>
        <Route path="/:locale?/login">
          <ApplicationLayout context={context}>
            <LoginRoute coupon={coupon} />
          </ApplicationLayout>
        </Route>
        <Route path="/:locale?/plus/checkout">
          <CheckoutRoute
            coupon={coupon}
            currencyCode={currencyCode}
            nonTrialPlan={nonTrialPlan}
            plan={plan}
            userId={userId}
            whenExpired={whenExpired}
          />
        </Route>
        <Route path="/:locale?/plus/signup">
          <ApplicationLayout context={context}>
            <RegisterRoute />
          </ApplicationLayout>
        </Route>
      </Switch>
    </Router>
  );
}

export default function Pro(props: ProRouterProps) {
  return (
    <CustomProvider>
      <ProRouter {...props} />
    </CustomProvider>
  );
}
