import { useState, useRef, Key, useEffect } from 'react';
import classNames from 'classnames';
import Slider from 'react-slick';
import { useIntl } from '@alltrails/shared/react-intl';
import useIsMounted from '@alltrails/shared/hooks/useIsMounted';
import Logo from '@alltrails/shared/denali/components/Logo';
import Button from '@alltrails/shared/denali/components/Button';
import useInterval from 'hooks/useInterval';
import { MEDIA_QUERY_MOBILE_DOWN } from 'utils/constants/breakpoints';
import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import logGuideIndexClicked from '@alltrails/analytics/events/logGuideIndexClicked';
import GuideIndexLocation from '@alltrails/analytics/enums/GuideIndexLocation';

import * as styles from './styles/styles.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export type GuidesImage = {
  alt: string;
  srcJpg: string;
  srcAvif: string;
  srcWebp: string;
  mobileSrcJpg: string;
  mobileSrcAvif: string;
  mobileSrcWebp: string;
  destination: string;
};

type Props = {
  images: GuidesImage[];
};

const duration = 5000;

// https://www.figma.com/file/Yx0YYdPi7uXzlCDKJFOHI3/Guides?node-id=3286%3A264248&mode=dev
const GuidesHomepageModule = ({ images }: Props) => {
  const [delay, setDelay] = useState<number>(duration);
  const [currentIndex, setCurrentIndex] = useState<number>(null);
  const intl = useIntl();
  const sliderRef = useRef<Slider>(null);
  const isMounted = useIsMounted();
  const languageRegionCode = useLanguageRegionCode();

  useEffect(() => {
    if (!currentIndex && isMounted()) {
      setCurrentIndex(0);
    }
  }, [currentIndex, isMounted]);

  useInterval(() => {
    let nextIndex = currentIndex + 1;

    if (nextIndex === images.length) {
      nextIndex = 0;
    }

    if (sliderRef.current && sliderRef.current.slickNext) {
      sliderRef.current.slickNext();
    }
    setDelay(duration);
  }, delay);

  const onModuleClick = (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    event.stopPropagation();
    logGuideIndexClicked({
      guide_index_location: GuideIndexLocation.Homepage
    });
    window.location.assign(wrapUrlSafe('/guides/country', languageRegionCode));
  };

  const destinationSliderSettings = {
    beforeChange: (oldIndex: number, index: number) => setCurrentIndex(index),
    accessibility: false,
    arrows: false,
    dots: false,
    autoplay: false,
    speed: 500,
    vertical: true,
    pauseOnHover: false
  };

  const getAnimationDurationTime = (index: number, currentImageIndex: number) => {
    const animationStyle: { animationDuration: string; animationPlayState: string } = {
      animationDuration: '',
      animationPlayState: ''
    };
    if (index === currentImageIndex) {
      animationStyle.animationDuration = `${duration}ms`;
      animationStyle.animationPlayState = 'running';
    }
    return animationStyle;
  };

  return (
    <div className={styles.container}>
      <div className={styles.slider} onClick={e => onModuleClick(e)} onKeyDown={e => onModuleClick(e)} tabIndex={0} role="button">
        <div className={classNames(isMounted() ? styles.imageContainer : styles.loadingContainer)}>
          {images.map((image: GuidesImage, index: number) => (
            <picture className={classNames({ [styles.none]: index !== currentIndex })} key={`${index}-${image.alt}` as Key}>
              <source media={MEDIA_QUERY_MOBILE_DOWN} srcSet={image.mobileSrcJpg} type="image/jpg" />
              <source media={MEDIA_QUERY_MOBILE_DOWN} srcSet={image.mobileSrcAvif} type="image/avif" />
              <source media={MEDIA_QUERY_MOBILE_DOWN} srcSet={image.mobileSrcWebp} type="image/webp" />
              <source srcSet={image.srcAvif} type="image/avif" />
              <source srcSet={image.srcWebp} type="image/webp" />
              <img
                alt={image.alt}
                src={image.srcJpg}
                style={getAnimationDurationTime(index, currentIndex)}
                className={classNames(styles.guideImage, currentIndex % 2 === 0 ? styles.zoomInAnimation : styles.zoomOutAnimation)}
              />
            </picture>
          ))}
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.details}>
            <div className={styles.introContainer}>
              <Logo className={styles.logo} variant="plus-logomark" size="sm" color="light" />
            </div>
            <div className={styles.noStretch}>
              <div className={styles.title}> {intl.formatMessage({ defaultMessage: 'Guides to' })} </div>
              <Slider {...destinationSliderSettings} ref={sliderRef} className={styles.destinationSlider}>
                {images.map((image: GuidesImage, index: number) => (
                  <div className={styles.destination} key={image.destination} style={getAnimationDurationTime(index, currentIndex)}>
                    {image.destination}
                  </div>
                ))}
              </Slider>
              <div className={styles.subtitle}> {intl.formatMessage({ defaultMessage: 'Introducing 200+ national park guides' })} </div>
            </div>
            <Button
              text={intl.formatMessage({ defaultMessage: 'Explore' })}
              variant="elevated"
              testId="explore-guides-btn"
              size="md"
              onClick={e => onModuleClick(e)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuidesHomepageModule;
