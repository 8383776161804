import { Activity, ActivityType } from '@alltrails/shared/types/activity';
import { Map } from '@alltrails/shared/types/map';
import Track, { TrackId } from '@alltrails/shared/types/track';
import { Trail as NewTrail, TrailPhoto } from '@alltrails/shared/types/trail';
import Trail from 'types/Trails/Trail';
import { getLocationName, getLocationSlug } from './location_parsing';

const sharedTrailFromTrail = (trail: Trail) => ({
  id: trail.ID,
  name: trail.name,
  slug: trail.slug,
  area: { name: trail.area_name, slug: trail.area_slug },
  avgRating: trail.avg_rating,
  defaultActivityStats: { difficulty: Number(trail.difficulty_rating), rating: trail.avg_rating },
  defaultPhoto: trail.has_profile_photo || trail.profile_photo_data ? ({} as TrailPhoto) : undefined,
  trailCounts: { reviewCount: trail.num_reviews },
  trailDetail: { description: trail.description },
  trailGeoStats: { durationMinutes: trail.duration_minutes?.toString() || '0', length: trail.length || 0 }
});

const getTrackUserName = (track: Track) => {
  const firstName = track.user?.firstName || track.user?.first_name;
  const lastName = track.user?.lastName || track.user?.last_name;
  return [firstName, lastName].filter(str => str && str.length > 0).join(' ');
};

export const getMapFromTrack = (track: Track, minutes: number): Map => ({
  id: track.ID as TrackId,
  slug: track.slug,
  name: track.name,
  minutes,
  meters: track.length || track.distance,
  user: { id: track.user?.id, name: getTrackUserName(track), slug: track.user?.slug },
  hasPhotos: track.photo_count > 0,
  locationName: getLocationName(track),
  locationSlug: getLocationSlug(track)
});

export const getActivityFromTrack = (track: Track, minutes: number): Activity => ({
  id: track.ID as TrackId,
  slug: track.slug,
  name: track.name,
  minutes,
  meters: track.length || track.distance,
  elevationGain: track.elevation_gain,
  activityType: (track.activities?.length ? track.activities[0] : '') as ActivityType,
  user: { id: track.user?.id, name: getTrackUserName(track), slug: track.user?.slug },
  createdAt: typeof track.created_at === 'string' ? track.created_at : track.created_at.toUTCString(),
  hasPhotos: track.photo_count > 0
});

// Remove the "trail/"" from the trail slug so that we can treat the slug like all others (no path info)
const formatTrailSlug = (slug: string) => (slug.startsWith('trail/') ? slug.slice(6) : slug);

export const getTrailForTrailCard = (trail: Trail, minutes: number): NewTrail => {
  const trailCopy = { ...trail };
  trailCopy.slug = formatTrailSlug(trailCopy.slug);
  trailCopy.duration_minutes = minutes;

  const newTrail = sharedTrailFromTrail(trailCopy);
  newTrail.area = { name: getLocationName(trail), slug: getLocationSlug(trail) };
  return newTrail;
};
