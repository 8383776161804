import logSignUpPageViewed from '@alltrails/analytics/events/logSignUpPageViewed';
import TermsOfService from 'components/TermsOfService';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from '@alltrails/shared/react-intl';
import Typography from '@alltrails/shared/denali/components/Typography';
import AltLoginsForm from 'components/AltLoginsForm';
import { GoogleLoginResponse } from 'react-google-login';
import Button from '@alltrails/shared/denali/components/Button';
import Link from '@alltrails/shared/denali/components/Link';
import SectionDivider from 'components/shared/SectionDivider';
import Logo from '@alltrails/shared/denali/components/Logo';
import RegisterForm from 'components/RegisterForm';
import IconButton from '@alltrails/shared/denali/components/IconButton';
import ArrowSq from '@alltrails/shared/icons/ArrowSq';
import LoginResponse from 'types/LoginResponse';
import logSignUpScreenContinueButtonTapped from '@alltrails/analytics/events/logSignUpScreenContinueButtonTapped';
import SignUpScreenContinueButtonType from '@alltrails/analytics/enums/SignUpScreenContinueButtonType';
import useGoogleHandler from 'hooks/useGoogleHandler';
import useFacebookHandler from 'hooks/useFacebookHandler';
import useAppleHandler from 'hooks/useAppleHandler';
import * as styles from './SignUpForm.module.scss';

type SignUpFormProps = {
  handleRegistrationSuccess?(data: LoginResponse): unknown;
  redirectTo?: string;
  referralLink?: string;
  useReactRouter?: boolean;
};

// Desktop: https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=1571%3A79911
// Mobile: https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=665%3A41750
function SignUpForm({ handleRegistrationSuccess, redirectTo, referralLink, useReactRouter }: SignUpFormProps) {
  const appleHandler = useAppleHandler();
  const facebookHandler = useFacebookHandler();
  const googleHandler = useGoogleHandler();
  const intl = useIntl();

  // see https://alltrails.atlassian.net/browse/MON-1776
  // make sure we bring returnTo querystring into login
  const params = new URLSearchParams(__AT_DATA__.search);
  const search = params.toString();

  const [path, setPath] = useState<'signup' | 'register'>('signup');

  const a = (chunk: string) => (
    <Link href={`/login${search ? `?${search}` : ''}`} useReactRouter={useReactRouter}>
      {chunk}
    </Link>
  );

  useEffect(() => {
    logSignUpPageViewed();
  }, []);

  function registrationSuccessCallback(data: LoginResponse) {
    if (handleRegistrationSuccess) {
      handleRegistrationSuccess(data);
    }

    if (!redirectTo) {
      return;
    }

    window.location.href = redirectTo;
  }

  async function handleGoogle(response: GoogleLoginResponse) {
    logSignUpScreenContinueButtonTapped({ signup_screen_continue_button_type: SignUpScreenContinueButtonType.Google });
    registrationSuccessCallback(await googleHandler(response, 'signup', referralLink));
  }

  async function handleFacebook(response: any) {
    logSignUpScreenContinueButtonTapped({ signup_screen_continue_button_type: SignUpScreenContinueButtonType.Facebook });
    registrationSuccessCallback(await facebookHandler(response, 'signup', referralLink));
  }

  return (
    <div className={styles.container}>
      {path === 'signup' && (
        <>
          <div className={styles.header} />
          <div className={styles.create}>
            <div>
              <Logo color="dark" size="lg" variant="symbol" />
            </div>
            <Typography variant="heading400">
              <FormattedMessage defaultMessage="Create your free account" />
            </Typography>
            <div>
              <AltLoginsForm
                googleLoginHandler={handleGoogle}
                facebookLoginHandler={handleFacebook}
                appleLoginHandler={() => {
                  logSignUpScreenContinueButtonTapped({ signup_screen_continue_button_type: SignUpScreenContinueButtonType.Apple });
                  appleHandler({ redirectTo, referralLink });
                }}
              />
              <SectionDivider classes={styles.or}>
                <FormattedMessage defaultMessage="or" />
              </SectionDivider>
              <Button
                className={styles.button}
                onClick={() => {
                  logSignUpScreenContinueButtonTapped({ signup_screen_continue_button_type: SignUpScreenContinueButtonType.Email });
                  setPath('register');
                }}
                testId="create-free-account"
                text={<FormattedMessage defaultMessage="Create a free account" />}
                variant="primary"
              />
            </div>
            <Typography component="div" variant="text200">
              <FormattedMessage
                defaultMessage="Already have an account? <a>Log in</a>"
                values={{
                  a
                }}
              />
            </Typography>
            <TermsOfService />
          </div>
        </>
      )}
      {path === 'register' && (
        <>
          {/* Desktop: https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=6514%3A99348 */}
          {/* Mobile: https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=6514%3A99372 */}
          <div className={styles.header}>
            <IconButton
              onClick={() => setPath('signup')}
              icon={{ Component: ArrowSq, orientation: 'left' }}
              testId="go-back"
              title={intl.formatMessage({ defaultMessage: 'Go back' })}
              variant="flat"
            />
          </div>
          <div className={styles.content}>
            <div>
              <Logo color="dark" size="lg" variant="symbol" />
            </div>
            <Typography component="div" variant="heading400">
              <FormattedMessage defaultMessage="Sign up today to start planning your next adventure" />
            </Typography>
            <div className={styles.form}>
              <RegisterForm autoFocus handleRegistrationSuccess={registrationSuccessCallback} referralLink={referralLink} />
              <Typography component="div" variant="text200">
                <FormattedMessage
                  defaultMessage="Already have an account? <a>Log in</a>"
                  values={{
                    a
                  }}
                />
              </Typography>
              <TermsOfService />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default SignUpForm;
