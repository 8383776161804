/* eslint-disable import/first */
// shared css require needs to come first so component css can override shared css without needing !important
require('@alltrails/shared/sharedCss.css');

import AccountConnectButtons from 'components/AccountConnectButtons';
import AccountLinks from 'components/profile/AccountLinks';
import AvatarStackContainer from 'components/profile/AvatarStackContainer';
import FacebookSettings from 'components/FacebookSettings';
import FooterWrapper from 'components/FooterWrapper';
import GeneralPrivacyPolicy from 'components/privacyPolicy/GeneralPrivacyPolicy';
import Gift from 'pages/Gift/Gift';
import GlobalActions from 'components/GlobalActions';
import HomePage from 'pages/HomePage';
import Login from 'pages/Login';
import MobileHomeLink from 'components/NavigationWrapper/MobileHomeLink';
import NavBar from 'components/NavBar';
import NotificationsSettings from 'components/NotificationsSettings';
import Pro from 'components/Pro';
import ProfileSidebar from 'components/profile/ProfileSidebar';
import ProfileSlidingTabs from 'components/profile/ProfileSlidingTabs';
import QuoteRotator from 'components/QuoteRotator';
import RedemptionPage from 'components/RedemptionPage';
import SignUp from 'pages/SignUp';
import UpdatePaymentForm from 'components/forms/UpdatePaymentForm';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const ReactRailsUJS = require('react_ujs');

const context = {
  AccountConnectButtons,
  AccountLinks,
  AvatarStackContainer,
  FacebookSettings,
  FooterWrapper,
  GeneralPrivacyPolicy,
  Gift,
  GlobalActions,
  HomePage,
  Login,
  MobileHomeLink,
  NavBar,
  NotificationsSettings,
  Pro,
  ProfileSidebar,
  ProfileSlidingTabs,
  QuoteRotator,
  RedemptionPage,
  SignUp,
  UpdatePaymentForm
};

ReactRailsUJS.getConstructor = className => context[className];
