import useFeatures from 'hooks/useFeatures';
import { useEffect, useState } from 'react';
import getAFOneLinkConfig from '../utils/getAFOneLinkConfig';

const oneLinkFallback = 'https://alltrails.onelink.me/EFvD/hvsmar15';
const branchLinkFallback = 'https://alltrails.io/rgGVELw1wCb';

type UseDeepLinkParams = {
  pageType?: 'area' | 'trail';
  pageId?: number;
  isOpen?: boolean;
  promptType?: 'banner' | 'interstitial';
};

const useDeeplink = ({ pageType, pageId, isOpen, promptType }: UseDeepLinkParams) => {
  const isAppsFlyerEnabled = useFeatures()?.includes('appsflyer_migration');

  const [deeplink, setDeeplink] = useState(isAppsFlyerEnabled ? oneLinkFallback : branchLinkFallback);

  useEffect(() => {
    const branchLinkBase = 'https://alltrails-alternate.app.link';

    let appPath = `${isAppsFlyerEnabled ? '' : 'alltrails://'}screen/explore`;

    if (pageType && pageId) {
      appPath = `${isAppsFlyerEnabled ? '' : 'alltrails://'}${pageType}/${pageId}`;
    }

    if (isAppsFlyerEnabled) {
      if (isOpen) {
        const createOneLink = () => {
          if (window.AF_SMART_SCRIPT) {
            const resultUrl = window.AF_SMART_SCRIPT.generateOneLinkURL(getAFOneLinkConfig(appPath));

            if (resultUrl?.clickURL) {
              setDeeplink(resultUrl.clickURL);

              // Timeout required according to AF documentation.
              // https://dev.appsflyer.com/hc/docs/dl_smart_script_v2#fire-an-impression
              setTimeout(() => {
                window.AF_SMART_SCRIPT.fireImpressionsLink();
              }, 1000);
            }
          }
        };

        if (window.AF_SMART_SCRIPT) {
          createOneLink();
        } else {
          const onelinkScript = document.querySelector('#af-onelink-script');
          if (onelinkScript) {
            onelinkScript.addEventListener('load', () => {
              if (window.AF_SMART_SCRIPT) {
                createOneLink();
              }
            });
          }
        }
      }
    } else {
      // Branch long-link isn't built using URLParams to prevent serializing deeplink paths.
      // eslint-disable-next-line max-len
      const branchDeeplink = `${branchLinkBase}?type=install_prompt&$ios_deeplink_path=${appPath}&$android_deeplink_path=${appPath}&feature=native_${promptType}`;
      setDeeplink(branchDeeplink);
    }
  }, [isAppsFlyerEnabled, pageId, pageType, isOpen, promptType]);

  return deeplink;
};

export default useDeeplink;
