import { useEffect, useState, ReactNode, lazy, Suspense } from 'react';
import useGoogleClientId from 'hooks/useGoogleClientId';
import logOneTapDisplayed from '@alltrails/analytics/events/logOneTapDisplayed';
import { LanguageSupportUtil } from 'utils/language_support_util';
import { appleRedirectHandler } from 'utils/OAuthHandlers';
import useUser from 'hooks/useUser';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import CustomProvider from 'components/CustomProvider';
import Cookies from 'js-cookie';
import logConsentDecisionMade from '@alltrails/analytics/events/logConsentDecisionMade';
import useGoogleHandler from 'hooks/useGoogleHandler';
import NoSsr from '@material-ui/core/NoSsr';
import { trackTinuitiPageView, handleTinuitiCookie } from '@alltrails/shared/utils/tinuitiUtils';
import { isAndroid } from 'components/DeepLink/utils';
import logProUpsellScreenDismissed from '@alltrails/analytics/events/logProUpsellScreenDismissed';
import DismissAction from '@alltrails/analytics/enums/DismissAction';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import PurchaseScreenTypePlan from '@alltrails/analytics/enums/PurchaseScreenTypePlan';
import OsanoConsentObject from 'types/OsanoConsentObject';
import useMobileWidth from '../../hooks/useMobileWidth';
import logError from '../../utils/logError';
import setUserProperties from './utils/setUserProperties';

const NavigatorModal = lazy(() => import('../NavigatorModal'));
const AppInstallModal = lazy(() => import('../MarketingModal/AppInstallModal'));
const GalleryModal = lazy(() => import('../GalleryModal'));
const ParksModal = lazy(() => import('../ParksModal'));
const CollectionsModal = lazy(() => import('../CollectionsModal'));
const FlashMessages = lazy(() => import('../FlashMessages'));
const PostAuthUpsell = lazy(() => import('../PostAuthUpsell/PostAuthUpsell'));

const setAppsflyerMeasurement = (consentObject: OsanoConsentObject) => {
  if (consentObject.ANALYTICS === 'ACCEPT') {
    window?.AF_SDK?.PLUGINS?.PBA?.enableMeasurement();
  } else {
    window?.AF_SDK?.PLUGINS?.PBA?.disableMeasurement();
  }
};

const GlobalActions = () => {
  const googleHandler = useGoogleHandler();
  const languageRegionCode = useLanguageRegionCode();
  const user = useUser();
  const googleClientId = useGoogleClientId();
  const [children, setChildren] = useState<ReactNode>(null);
  const isMobileWidth = useMobileWidth();

  useEffect(() => {
    function showMarketingModal() {
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has('navigator_desktop')) {
        setChildren(
          <Suspense fallback={null}>
            <NavigatorModal isOpen onRequestClose={() => setChildren(null)} />
          </Suspense>
        );
      }

      if (searchParams.has('appinstall_desktop')) {
        setChildren(
          <Suspense fallback={null}>
            <AppInstallModal />
          </Suspense>
        );
      }

      if (searchParams.has('gallery_desktop')) {
        setChildren(
          <Suspense fallback={null}>
            <GalleryModal isOpen onRequestClose={() => setChildren(null)} />
          </Suspense>
        );
      }

      if (searchParams.has('parks_desktop')) {
        setChildren(
          <Suspense fallback={null}>
            <ParksModal isOpen onRequestClose={() => setChildren(null)} />
          </Suspense>
        );
      }

      if (searchParams.has('collections_desktop')) {
        setChildren(
          <Suspense fallback={null}>
            <CollectionsModal isOpen onRequestClose={() => setChildren(null)} />
          </Suspense>
        );
      }
    }

    showMarketingModal();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    function showUpsellModal() {
      const isHomepage = window.location.pathname === '/';
      if (searchParams.has('apple_oauth') && isHomepage && !user?.pro) {
        setChildren(
          <Suspense fallback={null}>
            <PostAuthUpsell
              isOpen
              onCloseRequest={() => {
                logProUpsellScreenDismissed({
                  dismiss_action: DismissAction.XOut,
                  trigger: CarouselDisplayTrigger.CompleteAuthentication,
                  purchase_screen_type_plan: user?.isPromoEligible ? PurchaseScreenTypePlan.FreeTrial : PurchaseScreenTypePlan.NonTrial
                });
                setChildren(null);
              }}
            />
          </Suspense>
        );
      }
    }

    showUpsellModal();
  }, [user?.isPromoEligible, user?.pro]);

  useEffect(() => {
    async function callback(resp: any) {
      const response = await googleHandler(
        resp.credential,
        'login',
        null, // TODO: This should be passed-in
        true
      );

      if (response.welcome_notice) {
        const params = new URLSearchParams(window.location.search);
        params.set('welcome', response.welcome_notice);
        const search = params.toString();
        window.location.replace(window.location.href + (search !== '' ? `?${search}` : ''));
      } else {
        window.location.replace(window.location.href);
      }
    }

    const setUpGoogleOneTap = () => {
      window.google.accounts.id.initialize({
        callback,
        cancel_on_tap_outside: false,
        client_id: googleClientId,
        prompt_parent_id: 'g_one_tap',
        use_fedcm_for_prompt: true
      });

      if (!__AT_DATA__.signupModalIsOpen) {
        window.google.accounts.id.prompt((notification: any) => {
          if (notification.isDisplayMoment() && notification.isDisplayed()) {
            logOneTapDisplayed();
          }
        });
      }
    };

    if (
      !user &&
      (!isMobileWidth || isAndroid()) &&
      !['/about', '/careers', '/plus', '/pro', '/signup', '/login'].some(
        path => LanguageSupportUtil.wrapUrlSafe(path, languageRegionCode) === window.location.pathname
      )
    ) {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = () => setUpGoogleOneTap();
      script.onerror = () => {
        logError('Failed to load Google one-tap script');
      };
      document.body.appendChild(script);
    }
  }, [user, googleClientId, isMobileWidth, languageRegionCode, googleHandler]);

  useEffect(() => {
    if (user) {
      appleRedirectHandler(user);
    }
  }, [user]);

  // Track Osano consent decisions.
  useEffect(() => {
    const setConsentUserProperty = (consentObject?: OsanoConsentObject) => {
      setUserProperties({ ...consentObject });
    };

    const handleOsanoInit = (consentObject?: OsanoConsentObject) => {
      setConsentUserProperty(consentObject);
    };

    const handleOsanoConsentSaved = (consentObject?: OsanoConsentObject) => {
      const consent = Cookies.get('at_osano');
      const stringConsent = JSON.stringify({ consent: consentObject });

      if (consent === stringConsent) return;
      setConsentUserProperty(consentObject);

      logConsentDecisionMade({
        analytics: consentObject.ANALYTICS,
        essential: consentObject.ESSENTIAL,
        marketing: consentObject.MARKETING,
        optout: consentObject.OPT_OUT,
        personalization: consentObject.PERSONALIZATION,
        storage: consentObject.STORAGE
      });

      setAppsflyerMeasurement(consentObject);

      Cookies.set('at_osano', stringConsent);
    };

    window.Osano?.cm?.addEventListener('osano-cm-consent-saved', handleOsanoConsentSaved);
    window.Osano?.cm?.addEventListener('osano-cm-initialized', handleOsanoInit);

    return () => {
      window.Osano?.cm?.removeEventListener('osano-cm-consent-saved', handleOsanoConsentSaved);
      window.Osano?.cm?.removeEventListener('osano-cm-initialized', handleOsanoInit);
    };
  }, []);

  useEffect(() => {
    handleTinuitiCookie();
    trackTinuitiPageView(user?.id?.toString());
  }, [user]);

  return (
    <>
      <Suspense fallback={null}>
        <FlashMessages flashes={__AT_DATA__.flashes} />
      </Suspense>
      {children}
    </>
  );
};

function GlobalActionsWrapper() {
  return (
    <NoSsr>
      <CustomProvider>
        <GlobalActions />
      </CustomProvider>
    </NoSsr>
  );
}

export default GlobalActionsWrapper;
