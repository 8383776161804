import { GC_LOGO } from 'components/GiftFlow/GiftCard';
import { createContext, useContext } from 'react';
import GiftSubscription from 'types/GiftSubscription';

export type ContextType = {
  dispatchGiftFlow: any;
  giftFlowState: {
    giftCustomizeParams: {
      message: string;
      recipientEmail: string;
      recipientName: string;
      sendDate: Date;
      senderEmail: string;
      sendMethod: 'email' | 'print';
      senderName: string;
      variant: 'gc-logo';
    };
    purchasedGiftCard: GiftSubscription;
    serverErrors: any;
  };
};

export const GiftFlowContext = createContext<ContextType>({ dispatchGiftFlow: null, giftFlowState: null });

export function useGiftFlowContext() {
  const context = useContext(GiftFlowContext);
  return context;
}

export const initialState = {
  giftCustomizeParams: {
    variant: GC_LOGO,
    sendMethod: 'email',
    sendDate: new Date(),
    recipientName: '',
    recipientEmail: '',
    message: '',
    senderName: '',
    senderEmail: ''
  },
  serverErrors: [] as any,
  purchasedGiftCard: null as GiftSubscription
};

export const UPDATE_GIFT_CUSTOMIZE_FORM = 'UPDATE_GIFT_CUSTOMIZE_FORM';
export const UPDATE_SERVER_ERRORS = 'UPDATE_SERVER_ERRORS';
export const UPDATE_PURCHASED_GIFT_CARD = 'UPDATE_PURCHASED_GIFT_CARD';
export const RESTART_GIFT_FLOW = 'RESTART_GIFT_FLOW';

export const giftFlowReducer = (state: any, action: any) => {
  switch (action.type) {
    case UPDATE_GIFT_CUSTOMIZE_FORM: {
      return { ...state, giftCustomizeParams: action.payload };
    }
    case UPDATE_SERVER_ERRORS: {
      return { ...state, serverErrors: action.payload };
    }
    case UPDATE_PURCHASED_GIFT_CARD:
      return { ...state, purchasedGiftCard: action.payload };
    case RESTART_GIFT_FLOW:
      return { ...state, ...initialState };
    default:
      return state;
  }
};
