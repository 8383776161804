import TrailStandardCardShimmer from '@alltrails/shared/components/TrailStandardCard/TrailStandardCardShimmer';
import * as styles from './styles/styles.module.scss';

type TrailRiverShimmerProps = {
  className?: string;
};

const TrailRiverShimmer = ({ className = '' }: TrailRiverShimmerProps): JSX.Element => {
  const shimmerCard = (
    <div className={styles.card}>
      <TrailStandardCardShimmer onSiteBackground />
    </div>
  );
  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.innerContainer}>
        <div className={styles.title} />
        <div className={styles.river}>
          {shimmerCard}
          {shimmerCard}
          {shimmerCard}
          {shimmerCard}
        </div>
      </div>
    </div>
  );
};

export default TrailRiverShimmer;
