export const EDIT_PROFILE = 'edit-profile';
export const EXPLORE = 'explore';
export const TRAIL_PAGE = 'trail';
export const LOCATION_PAGE = 'location-page';
export const LOCATION_INFO = 'location-info';
export const PARK_SELECTION = 'park-selection';
export const TRAIL_DETAILS = 'trail-details';

// Search triggers
export const MAP_PAN = 'map-pan';
export const FILTER = 'filter';
export const SHOW_MORE = 'show-more';
