import { SocialCard } from 'components/blocks/UgcSocialFooter/SocialCard/SocialCard';
import anastasiasAvif from './images/anastasias.true.avif';
import anastasiasJpg from './images/anastasias.true.jpg';
import anastasiasWebp from './images/anastasias.true.webp';
import arunachellanAvif from './images/arunachellan.avif';
import arunachellanJpg from './images/arunachellan.jpg';
import arunachellanWebp from './images/arunachellan.webp';
import brittWebp from './images/britt.venturesout.avif';
import brittAvif from './images/britt.venturesout.webp';
import brittJpg from './images/britt.venturesout.jpg';
import everydayadventurefamAvif from './images/everydayadventurefam.avif';
import everydayadventurefamWebp from './images/everydayadventurefam.webp';
import everydayadventurefamJpg from './images/everydayadventurefam.jpg';
import jadeteetselAvif from './images/jadeteetsel.avif';
import jadeteetselWebp from './images/jadeteetsel.webp';
import jadeteetselJpg from './images/jadeteetsel.jpg';
import kylamsteeleAvif from './images/kylamsteele.avif';
import kylamsteeleWebp from './images/kylamsteele.webp';
import kylamsteeleJpg from './images/kylamsteele.jpg';
import sabrina_obryanAvif from './images/sabrina_obryan.avif';
import sabrina_obryanWebp from './images/sabrina_obryan.webp';
import sabrina_obryanJpg from './images/sabrina_obryan.jpg';
import veganpattyyAvif from './images/veganpattyy.avif';
import veganpattyyWebp from './images/veganpattyy.webp';
import veganpattyyJpg from './images/veganpattyy.jpg';

// https://www.figma.com/file/4jQyetMgmp9fZfD5hN1He6/Denali%2B-Social%2BLifecycle-Components?type=design&node-id=4025-4970&mode=design&t=WJmbFZWWNRWgyRho-0

const CARDS: SocialCard[] = [
  { handle: '@everydayadventurefam', images: { jpg: everydayadventurefamJpg, webp: everydayadventurefamWebp, avif: everydayadventurefamAvif } },
  { handle: '@kylamsteele', images: { jpg: kylamsteeleJpg, webp: kylamsteeleWebp, avif: kylamsteeleAvif } },
  { handle: '@veganpattyy', images: { jpg: veganpattyyJpg, webp: veganpattyyWebp, avif: veganpattyyAvif } },
  { handle: '@anastasias.true', images: { jpg: anastasiasJpg, webp: anastasiasWebp, avif: anastasiasAvif } },
  { handle: '@arunachellan', images: { jpg: arunachellanJpg, webp: arunachellanWebp, avif: arunachellanAvif } },
  { handle: '@sabrina_obryan', images: { jpg: sabrina_obryanJpg, webp: sabrina_obryanWebp, avif: sabrina_obryanAvif } },
  { handle: '@britt.venturesout', images: { jpg: brittJpg, webp: brittWebp, avif: brittAvif } },
  { handle: '@jadeteetsel 1', images: { jpg: jadeteetselJpg, webp: jadeteetselWebp, avif: jadeteetselAvif } }
];

export default CARDS;
