import { FormattedMessage, useIntl } from '@alltrails/shared/react-intl';
import LazyLoad from 'react-lazyload';
import classNames from 'classnames';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import type { Context } from 'types/Context';
import InviteYourFriendsBanner from 'components/InviteYourFriendsBanner';
import useUser from 'hooks/useUser';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { PageStrings } from '@alltrails/shared/utils/constants/pageStringHelpers';
import { NUM_CURATED_TRAILS, LOGGED_DISTANCE, NUM_USERS } from 'constants/CommunityStats';
import InstallBanner from 'components/InstallBanner';
import GuidesHomepageModule from 'components/blocks/GuidesHomepageModule';
import guidesImages from 'utils/guides_homepage_util';
import useDeeplinkComponent from 'components/DeepLink/hooks';
import UgcSocialFooter from 'components/blocks/UgcSocialFooter';
import InstallPromptLocation from '@alltrails/analytics/enums/InstallPromptLocation';
import type Trail from 'types/Trails/Trail';
import { ComponentProps, Fragment, useMemo } from 'react';
import AppOfTheYear from 'components/DiscoverTheAppOfTheYear';
import * as styles from './styles/styles.module.scss';
import GenericGiftingBlock from '../blocks/GiftingBlock/GiftingBlock';
import ShopifyPreview from '../blocks/ShopifyPreview';
import OnePercentBlock from '../blocks/OnePercentBlock';
import ExploreAndDiscover from '../blocks/ExploreAndDiscover';
import CommunityStats from '../blocks/CommunityStats/CommunityStats';
import TrailRiver from '../blocks/TrailRiver';
import SignupActivitySuitability from '../blocks/SignupActivitySuitability';
import useListItems from '../../hooks/useListItems';
import useSections from '../../hooks/useSections';
import PlusUpsell from '../blocks/PlusUpsell';
import PlusUpsellNoAuth from '../blocks/PlusUpsellNoAuth';
import ActivitiesRiver from '../blocks/ActivitiesRiver';
import SeoLinks from '../blocks/SeoLinks';
import { wrapUrlSafe } from '../../utils/language_support_util';
import {
  ATTRIBUTE,
  COMMUNITY_STATS,
  EDITORS_CHOICE_APP,
  EXPLORE_AND_DISCOVER,
  GIFTING_UPSELL,
  MERCHANDISE_STORE,
  ON_TRACK_WITH_PRO_CHECKLIST,
  ON_TRACK_WITH_PRO,
  ONE_PERCENT_PLANET,
  SEO_LINKS,
  SIGNUP_ACTIVITY_SUITABILITY,
  TRAIL_LIST,
  GUIDES_CAROUSEL,
  UGC_SOCIAL_FOOTER,
  GOOGLEBOT_TRAIL_LIST
} from './constants';
import { US } from '../../constants/CountryCode';

type TrailListSection = {
  filters: any;
  hits: (Trail & { exploreMoreUrl: string })[];
  river_section_type: 'personalized_trail_list';
  river_title_filter: string[];
  section_type: 'trail_list';
  title: string;
};

type Section =
  | {
      section_type:
        | 'gifting_upsell'
        | 'on_track_with_pro'
        | 'on_track_with_pro_checklist'
        | 'community_stats'
        | 'guides_carousel'
        | 'invite_your_friends'
        | 'one_percent_planet'
        | 'editors_choice_app'
        | 'explore_and_discover'
        | 'ugc_social_footer';
    }
  | { products: ComponentProps<typeof ShopifyPreview>['shopifyProducts']; section_type: 'merchandise_store' }
  | {
      country_slug: string;
      section_type: 'googlebot_trail_list';
      title: string;
      trails: any;
    }
  | TrailListSection
  | { objects: any; section_type: 'signup_activity_suitability' }
  | { data: any; section_type: 'seo_links' }
  | { objects: any; section_type: 'attribute'; title: string };

type Props = {
  context: Context;
  initialSections: Section[];
  windowWidth?: number;
  lists: {
    listItems?: object;
    lists?: {
      id?: number;
      title?: string;
    }[];
  };
  locationData: {
    city: {
      names: { [key: string]: string };
    };
    country: string;
    latLng: (string | number)[];
  };
};

enum SectionType {
  Attribute = 'attribute',
  InviteYourFriends = 'invite_your_friends'
}

const SectionSwitch = ({ context, initialSections, windowWidth, lists, locationData }: Props) => {
  const languageRegionCode = useLanguageRegionCode();
  const user = useUser();
  const intl = useIntl();
  const { listMethods, renderModal, renderCollabListPrivacyModal, renderSignUpModal } = useListItems(lists, PageStrings.HOMEPAGE);
  const sections = useSections(initialSections, locationData.latLng, context, lists);
  const [isBannerOpen, setIsBannerOpen] = useDeeplinkComponent('banner', true);

  const firstTrailRiverIndex = useMemo(
    () => sections.findIndex((section: { section_type: string }) => [TRAIL_LIST, GOOGLEBOT_TRAIL_LIST].includes(section.section_type)),
    [sections]
  );

  const trailRiverTitle = (section: TrailListSection, idx: number) => {
    if (idx === 0 && locationData.city && section.river_section_type !== 'personalized_trail_list') {
      const shortRegionCode = languageRegionCode.split('-')[0];

      // Maxmind possible values: de, en, es, fr, ja, pt-BR, ru, zh-CN
      const cityName =
        locationData.city.names[languageRegionCode === 'pt-BR' || languageRegionCode === 'pt-PT' ? 'pt-BR' : shortRegionCode] ||
        locationData.city.names.en;

      return (
        <>
          <FormattedMessage defaultMessage="Local favorites near" /> <a href={wrapUrlSafe('/explore', languageRegionCode)}>{cityName}</a>
        </>
      );
    }

    return section.title;
  };

  const images = guidesImages(intl);

  return (
    <>
      <InstallBanner isOpen={isBannerOpen} onRequestClose={() => setIsBannerOpen(false)} promptLocation={InstallPromptLocation.HomePage} />
      {renderModal()}
      {renderSignUpModal()}
      {renderCollabListPrivacyModal()}
      {sections.map((section: Section, idx: number) => {
        switch (section.section_type) {
          case GUIDES_CAROUSEL:
            return <GuidesHomepageModule key={section.section_type} images={images} />;
          case SectionType.InviteYourFriends:
            return <InviteYourFriendsBanner key={section.section_type} />;
          case ATTRIBUTE:
            return <ActivitiesRiver key={section.section_type} title={section.title} activities={section.objects} windowWidth={windowWidth} />;
          case COMMUNITY_STATS:
            return (
              <CommunityStats
                key={section.section_type}
                curatedTrails={NUM_CURATED_TRAILS}
                fellowExplorers={NUM_USERS}
                loggedDistanceMeters={LOGGED_DISTANCE}
              />
            );
          case ON_TRACK_WITH_PRO_CHECKLIST:
            return <PlusUpsell key={section.section_type} />;
          case ON_TRACK_WITH_PRO:
            return <PlusUpsellNoAuth key={section.section_type} />;
          case GIFTING_UPSELL:
            return <GenericGiftingBlock key={section.section_type} />;
          case MERCHANDISE_STORE:
            return (
              locationData.country === US &&
              languageRegionCode === LanguageRegionCode.English && (
                <LazyLoad key={section.section_type}>
                  <ShopifyPreview shopifyProducts={section.products} windowWidth={windowWidth} key={section.section_type} />
                </LazyLoad>
              )
            );
          case ONE_PERCENT_PLANET:
            return <OnePercentBlock key={section.section_type} />;
          case EDITORS_CHOICE_APP:
            return <AppOfTheYear key={section.section_type} />;
          case EXPLORE_AND_DISCOVER:
            return (
              <Fragment key={section.section_type}>
                <AppOfTheYear />
                <ExploreAndDiscover windowWidth={windowWidth} />
              </Fragment>
            );
          case SEO_LINKS:
            return <SeoLinks key={section.section_type} data={section.data} />;
          case SIGNUP_ACTIVITY_SUITABILITY:
            return (
              <SignupActivitySuitability
                activities={section.objects}
                key={section.section_type}
                mobileBrowser={__AT_DATA__.mobileBrowser}
                windowWidth={windowWidth}
              />
            );
          case TRAIL_LIST:
            return (
              <TrailRiver
                {...section}
                isFirstRiverOnPage={idx === firstTrailRiverIndex}
                windowWidth={windowWidth}
                listMethods={listMethods}
                className={classNames(styles.trailRiverPadding, { [styles.authenticatedSpacing]: user && idx === 0 })}
                title={trailRiverTitle(section, idx)}
                key={section.title}
                riverAnalyticsData={{
                  riverIndex: idx,
                  filters: section.filters,
                  riverSectionType: section.river_section_type,
                  riverTitleFilter: section.river_title_filter,
                  riverTitle: section.title
                }}
              />
            );
          case GOOGLEBOT_TRAIL_LIST: {
            const hits = section.trails.concat({
              exploreMoreUrl: `/explore/${section.country_slug}`
            });
            return (
              <TrailRiver
                hits={hits}
                isFirstRiverOnPage={idx === firstTrailRiverIndex}
                windowWidth={windowWidth}
                listMethods={listMethods}
                className={classNames(styles.trailRiverPadding)}
                title={section.title}
                key={section.title}
              />
            );
          }
          case UGC_SOCIAL_FOOTER:
            return <UgcSocialFooter windowWidth={windowWidth} key={section.section_type} />;
          default:
            return null;
        }
      })}
    </>
  );
};

export default SectionSwitch;
