import CustomProvider from 'components/CustomProvider';
import { useContext, ReactNode, createContext } from 'react';
import type { Context } from 'types/Context';

// used for trail card analytics
enum PageSource {
  Home = 'homepage',
  Explore = 'explore',
  Trail = 'trail',
  Seo = 'seo',
  Unknown = 'unknown'
}

type PageState = {
  context: Context;
  pageSource: PageSource;
};

type PageValue = {
  globalState: PageState;
};

const PageContext = createContext<PageValue>({
  globalState: { context: {} as Context, pageSource: PageSource.Unknown }
});

type PageProviderProps = {
  pageSource?: PageSource;
  context: Context;
  children: ReactNode;
};

const Page = ({ context, pageSource, children }: PageProviderProps): JSX.Element => (
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  <PageContext.Provider value={{ globalState: { context, pageSource } }}>
    <CustomProvider>{children}</CustomProvider>
  </PageContext.Provider>
);

function usePageContext() {
  const context = useContext(PageContext);

  if (context === undefined) {
    throw new Error('The page context is undefined. Are you using usePageContext within PageContext?');
  }

  return context.globalState.context;
}

export { Page, PageContext, PageSource, usePageContext };
