enum Access {
  ADA = 'ada',
  DOGS = 'dogs',
  DOGS_LEASH = 'dogs-leash',
  KIDS = 'kids',
  PARTIALLY_PAVED = 'partially-paved',
  PAVED = 'paved',
  STROLLERS = 'strollers'
}

export { Access };
