enum Features {
  ADA = 'ada',
  BEACH = 'beach',
  CAVE = 'cave',
  DOGS = 'dogs',
  DOGS_LEASH = 'dogs-leash',
  DOGS_NO = 'dogs-no',
  EVENT = 'event',
  FOREST = 'forest',
  LAKE = 'lake',
  KIDS = 'kids',
  HOT_SPRINGS = 'hot-springs',
  PAVED = 'paved',
  PARTIALLY_PAVED = 'partially-paved',
  RAILS_TRAILS = 'rails-trails',
  RIVER = 'river',
  STROLLERS = 'strollers',
  STROLLER_FRIENDLY = 'stroller-friendly',
  WHEELCHAIR_FRIENDLY = 'wheelchair-friendly',
  VIEWS = 'views',
  WATERFALL = 'waterfall',
  WILD_FLOWERS = 'wild-flowers',
  WILDLIFE = 'wildlife',
  BLOWDOWN = 'blowdown',
  BRIDGE_OUT = 'bridge-out',
  BUGS = 'bugs',
  MUDDY = 'muddy',
  OVER_GROWN = 'over-grown',
  ROCKY = 'rocky',
  SCRAMBLE = 'scramble',
  SNOW = 'snow',
  WASHED_OUT = 'washed_out',
  CLOSED = 'closed',
  OFF_TRAIL = 'off-trail',
  NO_SHADE = 'no-shade',
  PRIVATE_PROPERTY = 'private-property',
  CITY_WALK = 'city-walk',
  HISTORIC_SITE = 'historic-site',
  PUB_CRAWL = 'pub-crawl',
  FEE = 'fee'
}

export { Features };
