import TabBar from '@alltrails/shared/denali/components/TabBar';
import type { Context } from 'types/Context';
import logStatsViewed from '@alltrails/analytics/events/logStatsViewed';
import logReviewsViewed from '@alltrails/analytics/events/logReviewsViewed';
import logPhotosViewed from '@alltrails/analytics/events/logPhotosViewed';
import { LanguageSupportUtil } from '../../../utils/language_support_util';

import * as styles from './styles/styles.module.scss';

type Tab = {
  id: string;
  label: string;
  linkPath: string;
  isActive: boolean;
  openInNewWindow: boolean;
  tab: string;
};

type LinkInfo = {
  href: string;
  target: '_blank' | '_self' | '_parent' | '_top';
};

type TabLink = {
  id: string;
  label: string;
  testId: string;
  linkInfo: LinkInfo;
  isActive?: boolean;
};

type TabCount = {
  [name: string]: number;
};

type Props = {
  tabs: Tab[];
  context: Context;
  userId: string | number;
  tabCount: TabCount;
};

const ProfileSlidingTabs = ({ tabs, context, userId, tabCount }: Props) => {
  const tabLinks: TabLink[] = tabs.map(({ label, linkPath, openInNewWindow, tab, isActive }) => {
    const href = LanguageSupportUtil.wrapUrlSafe(linkPath, context?.languageRegionCode);
    const target = openInNewWindow ? '_blank' : null;
    const count = tabCount[tab];
    return {
      id: tab,
      label: count !== undefined ? `${label} (${count})` : label,
      testId: `profile-tab-${label}`,
      linkInfo: { href, target },
      isActive
    };
  });

  const logAmplitudeEvents = (tab: string) => {
    const isOwnedByUser = context.currentUser?.id === userId;
    const amplitudeEventObject = { owned_by_user: isOwnedByUser, owner_id: userId as string };
    switch (tab) {
      case 'stats':
        return logStatsViewed(amplitudeEventObject);
      case 'reviews':
        return logReviewsViewed(amplitudeEventObject);
      case 'photos':
        return logPhotosViewed(amplitudeEventObject);
      default:
        return '';
    }
  };

  return (
    <div className={styles.profileSlidingTabsContainer}>
      <TabBar
        activeTab={tabs.find(tab => tab.isActive).tab}
        className={styles.tabBar}
        hideBottomBorder
        onChange={(id: string) => {
          logAmplitudeEvents(id);
        }}
        tabs={tabLinks}
        testId="profile-tab-bar"
      />
    </div>
  );
};

export default ProfileSlidingTabs;
