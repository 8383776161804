import { OnErrorCallback, Client, NotifiableError } from '@bugsnag/js';
import { getBugsnag } from 'utils/bugsnag';

export default function logError(error: NotifiableError, event?: OnErrorCallback) {
  const Bugsnag = getBugsnag() as Client;

  if (Bugsnag) {
    Bugsnag.notify(error, event);
  }
  // eslint-disable-next-line no-console
  console.error(error);
}
