import AuthenticationType from '@alltrails/analytics/enums/AuthenticationType';
import logLogInFailed from '@alltrails/analytics/events/logLogInFailed';
import logSignUpFailed from '@alltrails/analytics/events/logSignUpFailed';
import { ApiError, post } from '@alltrails/shared/api';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import LoginResponse from 'types/LoginResponse';
import authSuccessCallback from 'utils/AuthHandlers';
import useFeatures from './useFeatures';

export default function useFacebookHandler() {
  const isIdentityServiceEnabled = useFeatures().includes('identity_service') && !__AT_DATA__.useIdentityMock;

  async function facebookHandler(loginInfo: ReactFacebookLoginInfo, handlerType: 'login' | 'signup', referralLink?: string) {
    let response;

    try {
      response = isIdentityServiceEnabled
        ? await post<LoginResponse>('/identity/auth/facebook', {
            client_id: __AT_DATA__.identityWebClientId,
            facebook_id: loginInfo.userID,
            facebook_token: loginInfo.accessToken,
            facebook_expires: loginInfo.expiresIn,
            ...(referralLink && { referral_code: referralLink })
          })
        : await post<LoginResponse>('/api/alltrails/facebook/login', {
            facebook_id: loginInfo.userID,
            facebook_token: loginInfo.accessToken,
            facebook_expires: loginInfo.expiresIn,
            create_session: true,
            ...(referralLink && { referral_code: referralLink })
          });

      authSuccessCallback(response, AuthenticationType.Facebook, response.is_collab_list_invite);
    } catch (e) {
      const failureType = e instanceof ApiError && e.data?.errors?.length > 0 ? e.data.errors[0].code : 'unknown';

      if (handlerType === 'signup') {
        logSignUpFailed({ authentication_type: AuthenticationType.Facebook, failure_type: failureType });
      } else {
        logLogInFailed({ authentication_type: AuthenticationType.Facebook, failure_type: failureType });
      }

      throw e;
    }

    return response;
  }

  return facebookHandler;
}
