import { createContext, useMemo } from 'react';
import { ReactNode } from 'react';
import { Filters } from 'types/Search';

type SearchValue = {
  filters: Filters;
};

const SearchStateContext = createContext<SearchValue>({
  filters: {} as Filters
});

type Props = {
  children: ReactNode;
  filters: Filters;
};

function SearchStateProvider({ children, filters }: Props) {
  const value = useMemo(() => ({ filters }), [filters]);

  return <SearchStateContext.Provider value={value}>{children}</SearchStateContext.Provider>;
}

export { SearchStateProvider, SearchStateContext };
