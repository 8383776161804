import { useState, useEffect, useRef } from 'react';
import { useIntl } from '@alltrails/shared/react-intl';
import seedrandom from 'seedrandom';
import SearchOrigin from '@alltrails/analytics/enums/SearchOrigin';
import { completedListId, verifiedListId } from '@alltrails/modules/Lists/listUtils';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { TRAIL_LIST } from '../components/SectionSwitch/constants';
import { SearchFiltersUtil, CARD_ATTRIBUTES } from '../utils/search_filters_util';
import { homepageServerFiltersToWeb, createAlgoliaObject, getGeoAlgoliaQueryParams } from '../utils/filters/homepageFilters';
import { getPrimaryIndex } from '../utils/search';
import { getAnalyticsTags } from '../utils/requests/algolia_requests';
import useUser from './useUser';

const MAX_RADIUS_MULTIPLIER = 3;
const TRAILS_PER_RIVER = 7;
const RANDOMNESS_FACTOR = 20; // more random = more shuffling, but potentially less ideal top results

const useSections = (initialSections, latLng, context, lists) => {
  const user = useUser();
  const intl = useIntl();
  const languageRegionCode = useLanguageRegionCode();
  const [sections, updateSections] = useState(initialSections);
  const [searchRadiusModifier, updateSearchRadiusModifier] = useState(1);
  const [lat, lng] = latLng;

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const getTrailCarouselSections = () => {
    return sections.filter(s => s.section_type === TRAIL_LIST);
  };

  const getCompletedTrailIds = magicID => {
    // can be completed or verified completed
    if (!lists.listItems || !lists?.listItems[magicID]?.trail) {
      return [];
    }

    return Object.keys(lists.listItems[magicID].trail);
  };

  const getAlgoliaObject = (filters, geo, idx = 0) => {
    return createAlgoliaObject(
      {
        filters,
        page: 'home',
        verifiedIds: getCompletedTrailIds(verifiedListId),
        completedIds: getCompletedTrailIds(completedListId),
        analyticsTags: getAnalyticsTags({
          user,
          mobile: __AT_DATA__.mobileBrowser,
          locale: languageRegionCode,
          system: true,
          origin: SearchOrigin.Homepage
        }),
        attributesToRetrieve: CARD_ATTRIBUTES
      },
      {
        hitsPerPage: TRAILS_PER_RIVER * (idx + 1) + Math.ceil(TRAILS_PER_RIVER / 2),
        aroundLatLng: `${lat},${lng}`,
        ...getGeoAlgoliaQueryParams(geo, searchRadiusModifier)
      }
    );
  };

  const handleBatchSearchSuccess = ({ results }, filters) => {
    // Cancel any potential long-running effects if the component unmounted.
    if (!isMounted.current) {
      return;
    }

    const isViable = results.some(result => result.hits.length >= 8); // any river has at least 8 results
    if (!isViable && searchRadiusModifier < MAX_RADIUS_MULTIPLIER) {
      updateSearchRadiusModifier(searchRadiusModifier + 1);
      return;
    }

    const now = new Date();
    const today = [now.getFullYear(), now.getMonth(), now.getDate()].join('-');
    const stickyRandomNumber = seedrandom(today); // same random numbers generated throughout the day
    const getScore = trail => {
      return trail.popularity + trail.avg_rating * 4 + stickyRandomNumber() * RANDOMNESS_FACTOR;
    };

    let resultIndex = 0;
    let hits;
    const hitIds = [];
    const resultLimiter = results.length - 1;
    // this callback maps the search results to the rows that have TRAIL_LIST
    // we can only have a top limit of 12 and we cannot have repeat trail cards between sections
    updateSections(prevSections =>
      prevSections
        .map(section => {
          if (section.section_type !== TRAIL_LIST || resultIndex > resultLimiter) {
            return section;
          }

          hits = [];

          const trailsSortedByPopularity = [...results[resultIndex].hits].sort((a, b) => b.popularity - a.popularity);
          // Take the top TRAILS_PER_RIVER trails that haven't been displayed yet
          trailsSortedByPopularity.forEach(hit => {
            if (!hitIds.includes(hit.ID) && hits.length < TRAILS_PER_RIVER) {
              hits.push(hit);
              hitIds.push(hit.ID);
            }
          });

          hits.sort((a, b) => {
            return getScore(b) - getScore(a);
          });

          hits.push({
            exploreMoreUrl: `${SearchFiltersUtil.createUrlFromPageAndFilters(
              'EXPLORE',
              filters[resultIndex],
              languageRegionCode
            )}?ref=homepage_explore_showmorecard`
          });

          resultIndex += 1;
          return { ...section, hits };
        })
        .filter(section => {
          if (section.section_type !== TRAIL_LIST) {
            return true;
          }
          // if the section has less than 3 hits we filter out the section
          return section.hits && section.hits.length > 3;
        })
    );
  };

  const generateTrailListFilters = filters => {
    let parsedFilter;
    const parsedFilters = [];

    const algoliaObjects = filters.map((filter, idx) => {
      const { geo } = filter;
      parsedFilter = homepageServerFiltersToWeb(filter, context, intl);
      parsedFilters.push(parsedFilter);
      return getAlgoliaObject(parsedFilter, geo, idx);
    });

    getPrimaryIndex(languageRegionCode)
      .batchSearch(algoliaObjects)
      .then(response => handleBatchSearchSuccess(response, parsedFilters));
  };

  useEffect(() => {
    const initialFilters = getTrailCarouselSections().map(list => list.filters);
    generateTrailListFilters(initialFilters);
  }, [searchRadiusModifier]);

  return sections;
};

export default useSections;
