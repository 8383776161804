import { useState } from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import type { Context } from 'types/Context';
import Link from '@alltrails/shared/denali/components/Link';
import Bell from '@alltrails/shared/icons/Bell';
import { NotificationsSettingsModal } from '@alltrails/modules/NotificationsSettings';
import '@alltrails/modules/NotificationsSettings/index.css';
import CustomProvider from 'components/CustomProvider';
import * as styles from './styles/styles.module.scss';
import { wrapEventHandler } from '../../utils/handlers';

const NotificationsSettings = ({ context }: { context: Context }): JSX.Element | null => {
  const userId = context.currentUser?.id;
  const [showModal, setShowModal] = useState(false);

  return userId ? (
    <CustomProvider>
      {showModal &&
        createPortal(<NotificationsSettingsModal userId={userId} closeModal={() => setShowModal(false)} />, document.getElementById('modalPortal'))}
      <Link
        className={styles.link}
        onClick={wrapEventHandler(() => setShowModal(true))}
        size="md"
        icon={{ Component: Bell }}
        testId="push-notification"
      >
        <FormattedMessage defaultMessage="Push notifications" />
      </Link>
    </CustomProvider>
  ) : null;
};

export default NotificationsSettings;
