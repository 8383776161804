import classNames from 'classnames';
import { defineMessages, FormattedMessage } from '@alltrails/shared/react-intl';
import useIsMounted from '@alltrails/shared/hooks/useIsMounted';
import type { User } from 'types/User';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import { SPACE_16 } from '@alltrails/shared/denali/tokens';
import RotatingHero from 'components/shared/RotatingHero';
import { betweenHours } from '@alltrails/shared/utils/timeHelpers';
import useUser from 'hooks/useUser';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import useFeatures from 'hooks/useFeatures';
import * as styles from './styles/styles.module.scss';
import HomeSearchBar from '../../search/HomeSearchBar/HomeSearchBar';
import { DESKTOP_BREAKPOINT } from '../../../utils/constants/breakpoints';
import { wrapUrlSafe } from '../../../utils/language_support_util';
import { Greetings } from '../../../pages/HomePage/HomePage';

const messages = defineMessages({
  FIND_YOUR_OUTDOORS: {
    defaultMessage: 'Find your outside'
  }
});

const FIRST_NAME_PLACEHOLDER = '{first_name}';

const chooseGreeting = (greetings: Greetings, user: User, greetingIndex: number, defaultText: string): string => {
  if (!(user && greetings)) {
    return defaultText;
  }

  let text = '';
  greetings.forEach(greet => {
    if (text.length === 0) {
      if (greet.daily_start_time && greet.daily_end_time && betweenHours(greet.daily_start_time, greet.daily_end_time)) {
        text = greet.custom_title[greetingIndex];
      }
    }
  });

  if (user && user.firstName && text.includes('{first_name}')) {
    text = text.replace(FIRST_NAME_PLACEHOLDER, user.firstName);
  }

  return text;
};

type Props = {
  greetings?: Greetings;
  heroData: {
    greetingIndex: number;
    images: {
      alt: string;
      w2880Jpg: string;
      w2880Avif: string;
      w750Jpg: string;
      w750Avif: string;
    }[];
  };
  windowWidth: number;
};

// https://www.figma.com/file/o24ZQP00Um7Ie23xWMwIoL/Sketches?type=design&node-id=113-34181&mode=dev
// Desktop (logged out): https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=593%3A31304&t=42V2GrDcXYKs9Nf2-1
// Desktop (logged in): https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=593%3A31686&t=42V2GrDcXYKs9Nf2-1
// Mobile (logged out): https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=593%3A31994&t=42V2GrDcXYKs9Nf2-1
// Mobile (logged in): https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=593%3A31825&t=42V2GrDcXYKs9Nf2-1
const HomeHeader = ({ windowWidth, greetings, heroData }: Props): JSX.Element => {
  const languageRegionCode = useLanguageRegionCode();
  const user = useUser();
  const {
    formattedDefaultMessages: { FIND_YOUR_OUTDOORS }
  } = useFormatMessage(messages);
  const showGradient = useFeatures().includes('global_nav');

  const isMounted = useIsMounted();

  const deviceType = __AT_DATA__.mobileBrowser || (windowWidth && windowWidth <= DESKTOP_BREAKPOINT) ? 'mobile' : 'desktop';

  const headerText = chooseGreeting(greetings, user, heroData.greetingIndex, FIND_YOUR_OUTDOORS);

  if (user) {
    const buttonContainerStyle = deviceType === 'mobile' ? { marginBottom: SPACE_16 } : null;

    return (
      <RotatingHero duration={6000} images={heroData.images} className={styles.loggedInContainer} buttonContainerStyle={buttonContainerStyle}>
        {showGradient && <div className={styles.gradient} />}
        <h1 className={styles.header}>{isMounted() ? headerText : ''}</h1>
        <div className={`${styles.searchBarContainer} ${styles.loggedInSearchContainer}`}>
          <HomeSearchBar />
        </div>
      </RotatingHero>
    );
  }

  return (
    <RotatingHero duration={6000} images={heroData.images} className={styles.container}>
      {showGradient && <div className={styles.gradient} />}
      <div className={styles.contentContainer}>
        <h1 className={classNames(styles.header, styles.loggedOutHeader)}>{headerText}</h1>
        <div className={styles.searchBarContainer}>
          <HomeSearchBar />
        </div>
        <div className={styles.exploreLink}>
          <a href={wrapUrlSafe('/explore?ref=homepage_explore_homeheader', languageRegionCode)}>
            <FormattedMessage defaultMessage="Explore nearby trails" />
          </a>
        </div>
      </div>
    </RotatingHero>
  );
};

export default HomeHeader;
