import { useIntl } from '@alltrails/shared/react-intl';
import CountryCode from 'types/CountryCode';
import Select from '@alltrails/shared/denali/components/Select';

type BillingCountrySelectorProps = {
  billingCountryCode: string;
  onChangeBillingCountry: (countryCode: CountryCode) => void;
  className?: string;
  labelText?: string;
};

const euCountryCodes = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE'
];

export const isBillingCountryEu = (billingCountryCode: CountryCode) => euCountryCodes.includes(billingCountryCode);

const BillingCountrySelector = ({ billingCountryCode, onChangeBillingCountry, className, labelText }: BillingCountrySelectorProps) => {
  const intl = useIntl();
  const countryOptions = [
    { label: intl.formatMessage({ defaultMessage: 'Austria' }), value: 'AT' },
    { label: intl.formatMessage({ defaultMessage: 'Belgium' }), value: 'BE' },
    { label: intl.formatMessage({ defaultMessage: 'Bulgaria' }), value: 'BG' },
    { label: intl.formatMessage({ defaultMessage: 'Croatia' }), value: 'HR' },
    { label: intl.formatMessage({ defaultMessage: 'Republic of Cyprus' }), value: 'CY' },
    { label: intl.formatMessage({ defaultMessage: 'Czech Republic' }), value: 'CZ' },
    { label: intl.formatMessage({ defaultMessage: 'Denmark' }), value: 'DK' },
    { label: intl.formatMessage({ defaultMessage: 'Estonia' }), value: 'EE' },
    { label: intl.formatMessage({ defaultMessage: 'Finland' }), value: 'FI' },
    { label: intl.formatMessage({ defaultMessage: 'France' }), value: 'FR' },
    { label: intl.formatMessage({ defaultMessage: 'Germany' }), value: 'DE' },
    { label: intl.formatMessage({ defaultMessage: 'Greece' }), value: 'GR' },
    { label: intl.formatMessage({ defaultMessage: 'Hungary' }), value: 'HU' },
    { label: intl.formatMessage({ defaultMessage: 'Ireland' }), value: 'IE' },
    { label: intl.formatMessage({ defaultMessage: 'Italy' }), value: 'IT' },
    { label: intl.formatMessage({ defaultMessage: 'Latvia' }), value: 'LV' },
    { label: intl.formatMessage({ defaultMessage: 'Lithuania' }), value: 'LT' },
    { label: intl.formatMessage({ defaultMessage: 'Luxembourg' }), value: 'LU' },
    { label: intl.formatMessage({ defaultMessage: 'Malta' }), value: 'MT' },
    { label: intl.formatMessage({ defaultMessage: 'Netherlands' }), value: 'NL' },
    { label: intl.formatMessage({ defaultMessage: 'Poland' }), value: 'PL' },
    { label: intl.formatMessage({ defaultMessage: 'Portugal' }), value: 'PT' },
    { label: intl.formatMessage({ defaultMessage: 'Romania' }), value: 'RO' },
    { label: intl.formatMessage({ defaultMessage: 'Slovakia' }), value: 'SK' },
    { label: intl.formatMessage({ defaultMessage: 'Slovenia' }), value: 'SI' },
    { label: intl.formatMessage({ defaultMessage: 'Spain' }), value: 'ES' },
    { label: intl.formatMessage({ defaultMessage: 'Sweden' }), value: 'SE' }
  ];

  const selectedOption = countryOptions.find(option => option.value === billingCountryCode);
  const billingCountryText = intl.formatMessage({ defaultMessage: 'Billing country' });

  const defaultLabelText = selectedOption
    ? intl.formatMessage({ defaultMessage: 'Not in {countryName}? Update your location' }, { countryName: selectedOption.label })
    : billingCountryText;

  return (
    <Select
      labelText={labelText || defaultLabelText}
      onChange={onChangeBillingCountry}
      options={countryOptions}
      placeholder={billingCountryText}
      testId="billing-country"
      value={billingCountryCode}
      className={className}
    />
  );
};

export default BillingCountrySelector;
