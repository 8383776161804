import { ComponentProps, ReactNode } from 'react';
import { IntlProvider } from '@alltrails/shared/react-intl';
import { Provider } from 'react-redux';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import getStore from '../store';

type Props = {
  children?: ReactNode;
  store?: ComponentProps<typeof Provider>['store'];
};

function CustomProvider({ children, store = getStore() }: Props) {
  const languageRegionCode = useLanguageRegionCode();
  const messages = global.__TRANSLATION_JSON__;

  return (
    <Provider store={store}>
      <IntlProvider defaultLocale={LanguageRegionCode.English} locale={languageRegionCode} messages={messages}>
        {children}
      </IntlProvider>
    </Provider>
  );
}

export default CustomProvider;
