import { ReactNode } from 'react';
import * as styles from './styles/styles.module.scss';

type Props = {
  title: ReactNode;
  iconSrc: string;
  text: ReactNode;
};

const OnePercentModule = ({ title, iconSrc, text }: Props) => (
  <div className={styles.container}>
    <div className={styles.icon}>
      {/* alt text is empty see https://html.spec.whatwg.org/multipage/images.html#a-short-phrase-or-label-with-an-alternative-graphical-representation:-icons,-logos */}
      <img alt="" loading="lazy" src={iconSrc} />
    </div>
    <div className={styles.title}>{title}</div>
    <div className={styles.text}>{text}</div>
  </div>
);

export default OnePercentModule;
